<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{content.text}}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "full-draft-program",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Full Technical Program',
      contents:[
        {
          type:'text',
          text:'Last update: 10/13/2021',
        },
        {
          type:'text',
          text:'',
        },
        {
          type:'text',
          text:'',
        },
        {
          type:'text',
          text:'This year ACM Multimedia will be held via a hybrid mode, i.e. jointly onsite in Chengdu, China and online. In particular, all the conference papers this year are expected to be presented more informatively to both onsite and online attendees.  The following points summarize the properties of this year conference:'
        },
        {
          type:'noList',
          text:'We adopt the traditional onsite conference form and divide the papers to oral and poster sessions with live streaming in each conference room, encouraging the onsite attendees to present their work physically and online attendees to provide live talks.'
        },
        {
          type:'noList',
          text:'We have built a web portable platform (both <a class="demo-link" href="https://whova.com/"> Whova </a> App and its desktop App) to collect the pre-recoded videos and posters of all papers, which will facilitate all attendees to view these resources before and during the conference. The primary contact author of each paper in all tracks will receive an email that shows the instructions of uploading videos and posters to Whova. Moreover, the details instructions and requirements for preparing video/poster files can be found in <a class="demo-link" href="https://2021.acmmm.org/author-instruction"> https://2021.acmmm.org/author-instruction</a>. '
        },
        {
          type:'noList',
          text:'Free poster printing service will be available during the conference to enable the attendees onsite to view the printed posters of the authors who cannot physically attend the conference, which is expected to further enrich their exchange on the research work. '
        },

        {
          type:'text',
          text:'<b>Please find more information in the <a class="demo-link" href="https://2021.acmmm.org/documents/ACMMM21_Full_Technical_Program.pdf">ACMMM21_Full_Technical_Program.pdf</a> for the draft Technical Program,</b> which will be slightly updated in the following days. The following notes need your attentions: ',
        },
        {
          type:'noList',
          text:'The time slot of each session (including Workshops, Tutorials and Main conference session) may be slightly changed or extended depending on the onsite conditions. '
        },
        {
          type:'noList',
          text:'Each session is assigned to a local conference room onsite, which also has a Zoom meeting ID. The online attendees (only registered audiences) can join the corresponding rooms at the session time.'
        },
        {
          type:'noList',
          text:'Onsite and online attendees can refer to <a class="demo-link" href="https://2021.acmmm.org/attendee-guide">https://2021.acmmm.org/attendee-guide</a> to prepare for the coming conference '
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Draft Full Program'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>