<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{content.text}}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'paperTitle'">
        <el-col class="paperTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'paperAuthor'">
        <el-col class="paperAuthor">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main Track Paper List",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Main Track Paper List',
      contents:[
        {
          type:'primaryTitle',
          text:'Oral Papers',
        },
		{
			type:"paperTitle",
			text:"42 <b>Hierarchical View Predictor: Unsupervised 3D Global Feature Learning through Hierarchical Prediction among Unordered Views<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhizhong Han; Xiyang Wang; Yu-Shen Liu*; Matthias Zwicker",
		},
		{
			type:"paperTitle",
			text:"66 <b>Multi-Perspective Video Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Yi Bin*; Xindi Shang; Bo Peng; Yujuan Ding; Tat-Seng Chua",
		},
		{
			type:"paperTitle",
			text:"87 <b>Towards robust cross-domain image understanding with unsupervised noise removal<b>",
		},
		{
			type:"paperAuthor",
			text:"Lei Zhu; Zhaojing Luo*; Wei Wang; Meihui Zhang; Gang Chen; Kaiping Zheng",
		},
		{
			type:"paperTitle",
			text:"88 <b>DL-Easy: An Easy-to-Use Framework for MultiModal Analysis<b>",
		},
		{
			type:"paperAuthor",
			text:"Naili Xing; Chris Yeung; Cheng-Hao Cai; Teck Khim Ng; Wei Wang; Kaiyuan Yang; Nan Yang; Meihui Zhang; Gang Chen; Beng Chin Ooi*",
		},
		{
			type:"paperTitle",
			text:"99 <b>Self-supervised Multi-view Multi-Human Association and Tracking<b>",
		},
		{
			type:"paperAuthor",
			text:"Yiyang Gan; Ruize Han*; Liqiang Yin; Wei Feng; Song Wang",
		},
		{
			type:"paperTitle",
			text:"114 <b>Semi-Autoregressive Image Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Xu Yan; Zhengcong Fei*; Zekang Li; Shuhui Wang; Qingming Huang; Qi Tian",
		},
		{
			type:"paperTitle",
			text:"119 <b>Dual Learning Music Composition and Dance Choreography<b>",
		},
		{
			type:"paperAuthor",
			text:"Shuang Wu*; Zhenguang Liu; Shijian Lu; Li Cheng",
		},
		{
			type:"paperTitle",
			text:"147 <b>PUGCQ: A Large Scale Dataset for Quality Assessment of Professional User-Generated Content<b>",
		},
		{
			type:"paperAuthor",
			text:"Guo Li; Baoliang Chen; Lingyu Zhu; Qinwen He; Hongfei Fan; Shiqi Wang*",
		},
		{
			type:"paperTitle",
			text:"154 <b>Learning Hierarchal Channel Attention for Fine-grained Visual Classification<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiang Guan; Guoqing Wang*; Xing Xu; Yi Bin",
		},
		{
			type:"paperTitle",
			text:"161 <b>DSP: Dual Soft-Paste for Unsupervised Domain Adaptive Semantic Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Li Gao; Jing Zhang; Lefei Zhang*; Dacheng Tao",
		},
		{
			type:"paperTitle",
			text:"167 <b>Efficient Multi-Modal Fusion with Diversity Analysis<b>",
		},
		{
			type:"paperAuthor",
			text:"Shuhui Qu*; Yan Kang; Janghwan Lee",
		},
		{
			type:"paperTitle",
			text:"188 <b>Multi-Modal Sarcasm Detection with Interactive In-Modal and Cross-Modal Graphs<b>",
		},
		{
			type:"paperAuthor",
			text:"Bin Liang*; Chenwei Lou; Xiang Li; Lin Gui; Min Yang; Ruifeng Xu",
		},
		{
			type:"paperTitle",
			text:"196 <b> Multi-Source Fusion and Automatic Predictor Selection for Zero-Shot Video Object Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiaoqi Zhao*; Youwei Pang; Jiaxing Yang; Lihe Zhang; Huchuan Lu",
		},
		{
			type:"paperTitle",
			text:"197 <b>Progressive Graph Attention Network for Video Question Answering<b>",
		},
		{
			type:"paperAuthor",
			text:"Liang Peng; Shuangji Yang; Yi Bin*; Guoqing Wang",
		},
		{
			type:"paperTitle",
			text:"204 <b>MageAdd: Real-Time Interaction Simulation for Scene Synthesis<b>",
		},
		{
			type:"paperAuthor",
			text:"Shao-Kui Zhang; Yi-Xiao Li; Yu He; Yongliang Yang; Song-Hai Zhang*",
		},
		{
			type:"paperTitle",
			text:"208 <b>Video Background Music Generation with Controllable Music Transformer<b>",
		},
		{
			type:"paperAuthor",
			text:"Shangzhe Di; Zeren Jiang; Si Liu*; Zhaokai Wang; Leyan Zhu; Zexin He; Hongming Liu; Shuicheng Yan",
		},
		{
			type:"paperTitle",
			text:"235 <b>Robust Shadow Detection by Exploring Effective Shadow Contexts<b>",
		},
		{
			type:"paperAuthor",
			text:"Xianyong Fang*; Xiaohao He; Linbo Wang; Jianbing Shen",
		},
		{
			type:"paperTitle",
			text:"237 <b>Towards Cross-Granularity Few-Shot Learning: Coarse-to-Fine Pseudo-Labeling with Visual-Semantic Meta-Embedding<b>",
		},
		{
			type:"paperAuthor",
			text:"Jinhai Yang; Hua Yang*; Lin Chen",
		},
		{
			type:"paperTitle",
			text:"258 <b>One-Stage Incomplete Multi-view Clustering via Late Fusion<b>",
		},
		{
			type:"paperAuthor",
			text:"Yi Zhang*; Xinwang Liu; Siwei Wang; Jiyuan Liu; Sisi Dai; En Zhu",
		},
		{
			type:"paperTitle",
			text:"261 <b>Mix-order Attention Networks for Image Restoration<b>",
		},
		{
			type:"paperAuthor",
			text:"Tao Dai; Yalei Lv; Bin Chen*; Zhi Wang; Zexuan Zhu; Shu-Tao Xia",
		},
		{
			type:"paperTitle",
			text:"270 <b>GCCN: Geometric Constraint Co-attention Network for 6D Object Pose Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Yongming Wen; Yiquan Fang; Junhao Cai; Kimwa Tung; HUI CHENG*",
		},
		{
			type:"paperTitle",
			text:"294 <b>Image Re-composition via Regional Content-Style Decoupling<b>",
		},
		{
			type:"paperAuthor",
			text:"Rong Zhang; Wei Li; Yiqun Zhang; Hong Zhang; Jinhui Yu; Ruigang Yang; Weiwei Xu*",
		},
		{
			type:"paperTitle",
			text:"298 <b>Enhanced Invertible Encoding for Learned Image Compression<b>",
		},
		{
			type:"paperAuthor",
			text:"Yueqi Xie; Ka Leong Cheng; Qifeng Chen*",
		},
		{
			type:"paperTitle",
			text:"303 <b>Learning Spatial-angular Fusion for Compressive Light Field Imaging in a Cycle-consistent Framework<b>",
		},
		{
			type:"paperAuthor",
			text:"Xianqiang LYU; Zhiyu Zhu; Mantang Guo; Jing Jin; Junhui Hou*; Huanqiang Zeng",
		},
		{
			type:"paperTitle",
			text:"312 <b>Group-based Distinctive Image Captioning with Memory Attention<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiuniu Wang*; Wenjia Xu; Qingzhong Wang; Antoni Chan",
		},
		{
			type:"paperTitle",
			text:"320 <b>WeClick: Weakly-Supervised Video Semantic Segmentation with Click Annotations<b>",
		},
		{
			type:"paperAuthor",
			text:"Peidong Liu*; Zibin He; Xiyu Yan; Yong Jiang; Shu-Tao Xia; Feng Zheng; Maowei Hu",
		},
		{
			type:"paperTitle",
			text:"338 <b>SimulLR: Simultaneous Lip Reading Transducer with Attention-Guided Adaptive Memory<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhijie Lin*; Zhou Zhao; Haoyuan Li; Jinglin Liu; Meng Zhang; Xingshan Zeng; Xiaofei He",
		},
		{
			type:"paperTitle",
			text:"345 <b>Two-stage Visual Cues Enhancement Network for Referring Image Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Yang Jiao; Zequn Jie; Weixin Luo; Jingjing Chen*; Yu-Gang Jiang; Xiaolin Wei; Lin Ma",
		},
		{
			type:"paperTitle",
			text:"348 <b>Speech2AffectiveGestures: Synthesizing Co-Speech Gestures with Generative Adversarial Affective Expression Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Uttaran Bhattacharya*; Elizabeth Childs; Nicholas S Rewkowski; Dinesh Manocha",
		},
		{
			type:"paperTitle",
			text:"353 <b>VQMG: Hierarchical Vector Quantised and Multi-hops Graph Reasoning for Explicit Representation Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Lei Li*; Chun Yuan",
		},
		{
			type:"paperTitle",
			text:"381 <b>How to Learn a Domain-Adaptive Event Simulator?<b>",
		},
		{
			type:"paperAuthor",
			text:"Daxin Gu; Jia Li*; Yu Zhang; Yonghong Tian",
		},
		{
			type:"paperTitle",
			text:"419 <b>Latent Memory-augmented Graph Transformer for Visual Storytelling<b>",
		},
		{
			type:"paperAuthor",
			text:"Mengshi Qi*; Jie Qin; Di Huang; Zhiqiang Shen; Yi Yang; Jiebo Luo",
		},
		{
			type:"paperTitle",
			text:"430 <b>PIMNet: A Parallel, Iterative and Mimicking Network for Scene Text Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhi Qiao; Yu Zhou*; Jin Wei; Wei Wang; Yuan Zhang; Ning Jiang; Hongbin Wang; Weiping Wang",
		},
		{
			type:"paperTitle",
			text:"437 <b>Fine-grained Cross-modal Alignment Network for Text-Video Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Ning Han; Jingjing Chen; Guangyi Xiao; Hao Zhang; Yawen Zeng; Hao Chen*",
		},
		{
			type:"paperTitle",
			text:"438 <b>DC-GNet: Deep Mesh Relation Capturing Graph Convolution Network for 3D Human Shape Reconstruction<b>",
		},
		{
			type:"paperAuthor",
			text:"Shihao Zhou*; Mengxi Jiang; Shanshan Cai; Yunqi Lei",
		},
		{
			type:"paperTitle",
			text:"464 <b>Vehicle Counting Network with Attention-based Mask Refinement and Spatial-awareness Block Loss<b>",
		},
		{
			type:"paperAuthor",
			text:"Ji Zhang; Jian-Jun Qiao; Xiao Wu*; Wei Li",
		},
		{
			type:"paperTitle",
			text:"474 <b>Cross-Modal Generalization: Learning in Low Resource Modalities via Meta-Alignment<b>",
		},
		{
			type:"paperAuthor",
			text:"Paul Pu Liang*; Peter Wu; Liu Ziyin; Louis-Philippe Morency; Ruslan Salakhutdinov",
		},
		{
			type:"paperTitle",
			text:"510 <b>Hierarchical Multi-Task Learning for Diagram Question Answering with Multi-Modal Transformer<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhaoquan Yuan; Xiao Peng; Xiao Wu*; Changsheng Xu",
		},
		{
			type:"paperTitle",
			text:"518 <b>Combining Attention with Flow for Person Image Synthesis<b>",
		},
		{
			type:"paperAuthor",
			text:"Yurui Ren; Yubo Wu; Thomas H Li; Shan Liu; Ge Li*",
		},
		{
			type:"paperTitle",
			text:"525 <b>Mining Latent Structures for Multimedia Recommendation<b>",
		},
		{
			type:"paperAuthor",
			text:"Jinghao Zhang; Yanqiao ZHU*; Qiang Liu; Shu Wu; Shuhui Wang; Liang Wang",
		},
		{
			type:"paperTitle",
			text:"526 <b>Hierarchical Fusion for Practical Ghost-free High Dynamic Range Imaging<b>",
		},
		{
			type:"paperAuthor",
			text:"Pengfei Xiong*; Yu Chen",
		},
		{
			type:"paperTitle",
			text:"551 <b>Video Visual Relation Detection via Iterative Inference<b>",
		},
		{
			type:"paperAuthor",
			text:"Xindi Shang*; Yicong Li; Junbin Xiao; Wei Ji; Tat-Seng Chua",
		},
		{
			type:"paperTitle",
			text:"558 <b>CausalRec: Causal Inference for Visual Debiasing in Visually-Aware Recommendation<b>",
		},
		{
			type:"paperAuthor",
			text:"Ruihong Qiu*; Sen Wang; Zhi Chen; Hongzhi Yin; Zi Huang",
		},
		{
			type:"paperTitle",
			text:"563 <b>Deep Clustering based on Bi-Space Association Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Hao Huang*; Shinjae Yoo; Chenxiao Xu",
		},
		{
			type:"paperTitle",
			text:"567 <b>Feature Stylization and Domain-aware Contrastive Learning for Domain Generalization<b>",
		},
		{
			type:"paperAuthor",
			text:"Seogkyu Jeon*; Kibeom Hong ; Pilhyeon Lee; Jewook Lee; Hyeran Byun",
		},
		{
			type:"paperTitle",
			text:"574 <b>HDA-Net: Horizontal Deformable Attention Network for Stereo Matching<b>",
		},
		{
			type:"paperAuthor",
			text:"Qi Zhang*; Xuesong Zhang; Baoping Li; Yuzhong Chen; Anlong Ming",
		},
		{
			type:"paperTitle",
			text:"578 <b>UniCon: Unified Context Network for Robust Active Speaker Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuanhang Zhang*; Susan Liang; Shuang Yang; Xiao Liu; Zhongqin Wu; Shiguang Shan; Xilin Chen",
		},
		{
			type:"paperTitle",
			text:"610 <b>CONQUER: Contextual Query-aware Ranking for Video Corpus Moment Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhijian Hou*; Chong-Wah Ngo; W. K. Chan",
		},
		{
			type:"paperTitle",
			text:"614 <b>Neighbor-view Enhanced Model for Vision and Language Navigation<b>",
		},
		{
			type:"paperAuthor",
			text:"Dong An; Yuankai Qi; Yan Huang*; Qi Wu; Liang Wang; Tieniu Tan",
		},
		{
			type:"paperTitle",
			text:"685 <b>FoodLogoDet-1500: A Dataset for Large-Scale Food Logo Detection via Multi-Scale Feature Decoupling Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Qiang Hou; Weiqing Min; Jing Wang; Sujuan Hou*; Yuanjie Zheng; Shuqiang Jiang",
		},
		{
			type:"paperTitle",
			text:"690 <b>Edge-oriented Convolution Block for Real-time Super Resolution on Mobile Devices<b>",
		},
		{
			type:"paperAuthor",
			text:"Xindong Zhang; Hui Zeng; Lei Zhang*",
		},
		{
			type:"paperTitle",
			text:"708 <b>MS-GraphSIM: Inferring Point Cloud Quality via Multiscale Graph Similarity<b>",
		},
		{
			type:"paperAuthor",
			text:"Yujie Zhang*; Qi Yang; Yiling Xu",
		},
		{
			type:"paperTitle",
			text:"709 <b>CaFGraph: Context-aware Facial Multi-graph Representation for Facial Action Unit Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Yingjie Chen; Diqi Chen; Yizhou Wang; Tao Wang*; Yun Liang",
		},
		{
			type:"paperTitle",
			text:"778 <b>Product-oriented Machine Translation with Cross-modal Cross-lingual Pre-training<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuqing Song*; Shizhe Chen; Qin Jin; Wei Luo; Jun Xie; Fei Huang",
		},
		{
			type:"paperTitle",
			text:"788 <b>Multiple Object Tracking by Trajectory Map Regression with Temporal Priors Embedding<b>",
		},
		{
			type:"paperAuthor",
			text:"Xingyu Wan*; Sanping Zhou; Jinjun Wang; Rongye Meng",
		},
		{
			type:"paperTitle",
			text:"813 <b>VoteHMR: Occlusion-Aware Voting Network for Robust 3D Human Mesh Recovery from Partial Point Clouds<b>",
		},
		{
			type:"paperAuthor",
			text:"Guanze Liu; Yu Rong; Lu Sheng*",
		},
		{
			type:"paperTitle",
			text:"839 <b>From Voxel to Point: IoU-guided 3D Object Detection for PointCloud with Voxel-to-Point Decoder<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiale Li; Hang Dai*; Ling Shao; Yong Ding",
		},
		{
			type:"paperTitle",
			text:"864 <b>Ada-VSR: Adaptive Video Super-Resolution with Meta-Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Akash Gupta*; Padmaja Jonnalagedda; Bir Bhanu; Amit K. Roy-Chowdhury",
		},
		{
			type:"paperTitle",
			text:"897 <b>MBRS : Enhancing Robustness of DNN-based Watermarking by Mini-Batch of Real and Simulated JPEG Compression<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhaoyang Jia*; Han Fang; Weiming Zhang",
		},
		{
			type:"paperTitle",
			text:"898 <b>QoE Ready to Respond: A QoE-aware MEC Selection Scheme for DASH-based Adaptive Video Streaming to Mobile Users<b>",
		},
		{
			type:"paperAuthor",
			text:"Wanxin Shi; Qing Li*; Ruishan Zhang; Gengbiao Shen; Yong Jiang; Zhenhui Yuan; Gabriel-Miro Muntean",
		},
		{
			type:"paperTitle",
			text:"907 <b>Multimodal Global Relation Knowledge Distillation for Egocentric Action Anticipation <b>",
		},
		{
			type:"paperAuthor",
			text:"Yi Huang; Xiaoshan Yang; Changsheng Xu*",
		},
		{
			type:"paperTitle",
			text:"932 <b>From Synthetic to Real: Image Dehazing Collaborating with Unlabeled Real Data<b>",
		},
		{
			type:"paperAuthor",
			text:"Ye Liu; Lei Zhu; Shunda Pei; Huazhu Fu; Jing Qin; Qing Zhang; Liang Wan*; Wei Feng",
		},
		{
			type:"paperTitle",
			text:"954 <b>Parametric Reshaping of Portraits in Videos<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiangjun Tang; WenXin Sun; Yongliang Yang; Xiaogang Jin*",
		},
		{
			type:"paperTitle",
			text:"968 <b>Instance-wise or Class-wise? A Tale of Neighbor Shapley for Concept-based Explanation<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiahui Li*; Kun Kuang; Lin Li; Long Chen; Songyang Zhang; Jian Shao; Jun Xiao",
		},
		{
			type:"paperTitle",
			text:"980 <b>Extending 6-DoF VR Experience Via Multi-Sphere Images Interpolation<b>",
		},
		{
			type:"paperAuthor",
			text:"Jisheng Li*; Yuze He; Jinghui Jiao; Yubin Hu; Yuxing Han; Jiangtao Wen",
		},
		{
			type:"paperTitle",
			text:"1059 <b>X-GGM: Graph Generative Modeling for Out-of-distribution Generalization in Visual Question Answering<b>",
		},
		{
			type:"paperAuthor",
			text:"Jingjing Jiang*; Ziyi Liu; Yifan Liu; Zhixiong Nan; Nanning Zheng",
		},
		{
			type:"paperTitle",
			text:"1066 <b>Disentangle Your Dense Object Detector<b>",
		},
		{
			type:"paperAuthor",
			text:"Zehui Chen; Chenhongyi Yang; Qiaofei Li; Feng Zhao*; Zheng-Jun Zha; Feng Wu",
		},
		{
			type:"paperTitle",
			text:"1098 <b>Hybrid Network Compression via Meta-Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Jianming Ye; Shiliang Zhang*; Jingdong Wang",
		},
		{
			type:"paperTitle",
			text:"1129 <b>Exploring Contextual-Aware Representation and Linguistic-Diverse Expression for Visual Dialog<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiangpeng Li; Lianli Gao; Lei Zhao; Jingkuan Song*",
		},
		{
			type:"paperTitle",
			text:"1178 <b>Do We Really Need Frame-by-Frame Annotation Datasets for Object Tracking?<b>",
		},
		{
			type:"paperAuthor",
			text:"Lei Hu; Shaoli Huang; Shilei Wang; Wei Liu; Jifeng Ning*",
		},
		{
			type:"paperTitle",
			text:"1192 <b>Identity-Preserving Face Anonymization via Adaptively Facial Attributes Obfuscation<b>",
		},
		{
			type:"paperAuthor",
			text:"Jingzhi Li*; Lutong Han; Ruoyu Chen; Hua Zhang; Bing Han; Lili Wang; Xiaochun Cao",
		},
		{
			type:"paperTitle",
			text:"1201 <b>DSSL: Deep Surroundings-person Separation Learning for Text-based Person Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Aichun Zhu*; Zijie Wang; Yifeng Li; Xili Wan; Jing Jin; Tian Wang; Fangqiang Hu; Gang Hua",
		},
		{
			type:"paperTitle",
			text:"1261 <b>Self-Representation Subspace Clustering for Incomplete Multi-view Data<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiyuan Liu*; Xinwang Liu; Yi Zhang; Pei Zhang; Wenxuan Tu; Siwei Wang; Sihang Zhou; Weixuan Liang; Siqi Wang; Yuexiang Yang",
		},
		{
			type:"paperTitle",
			text:"1271 <b>Cross-Camera Feature Prediction for Intra-Camera Supervised Person Re-identification across Distant Scenes<b>",
		},
		{
			type:"paperAuthor",
			text:"Wenhang Ge; Chunyan Pan; Ancong Wu*; Hongwei Zheng; WEI-SHI ZHENG",
		},
		{
			type:"paperTitle",
			text:"1292 <b>DocTr: Document Image Transformer for Geometric Unwarping and Illumination Correction<b>",
		},
		{
			type:"paperAuthor",
			text:"Hao Feng*; Yuechen Wang; Wengang Zhou; Jiajun Deng; Houqiang Li",
		},
		{
			type:"paperTitle",
			text:"1348 <b>Multimodal Entity Linking: A New Dataset and A Baseline<b>",
		},
		{
			type:"paperAuthor",
			text:"Jingru Gan*; Jinchang Luo; Haiwei Wang; Shuhui Wang; Wei He; Qingming Huang",
		},
		{
			type:"paperTitle",
			text:"1388 <b>ReconVAT: A Semi-Supervised Automatic Music Transcription Framework for Low-Resource Real-World Data<b>",
		},
		{
			type:"paperAuthor",
			text:"Kin Wai Cheuk*; Dorien Herremans; Li Su",
		},
		{
			type:"paperTitle",
			text:"1392 <b>Learning Unified Embeddings for Recommendation via Meta-path Semantics<b>",
		},
		{
			type:"paperAuthor",
			text:"Qianxiu Hao*; Qianqian Xu; Zhiyong Yang; Qingming Huang",
		},
		{
			type:"paperTitle",
			text:"1394 <b>Video Semantic Segmentation with Sparse Temporal Transformer<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiangtong Li*; Wentao Wang; Junjie Chen; Li Niu; Jianlou Si; Chen Qian; Liqing Zhang",
		},
		{
			type:"paperTitle",
			text:"1410 <b>iButter: Neural Interactive Bullet Time Generator for Human Free-viewpoint Rendering<b>",
		},
		{
			type:"paperAuthor",
			text:"Liao Wang*; Ziyu Wang; Pei Lin; Yuheng Jiang; Xin Suo; Minye Wu; Lan Xu; Jingyi Yu",
		},
		{
			type:"paperTitle",
			text:"1429 <b>Elastic Tactile Simulation Towards Tactile-Visual Perception<b>",
		},
		{
			type:"paperAuthor",
			text:"Yikai Wang*; Wenbing Huang; Bin Fang; Fuchun Sun; Chang Li",
		},
		{
			type:"paperTitle",
			text:"1447 <b>Self-supervised Consensus Representation Learning for Attributed Graph<b>",
		},
		{
			type:"paperAuthor",
			text:"Changshu Liu*; Liangjian Wen; Zhao Kang; Guangchun Luo; Ling Tian",
		},
		{
			type:"paperTitle",
			text:"1465 <b>Learning Fine-Grained Motion Embedding for Landscape Animation<b>",
		},
		{
			type:"paperAuthor",
			text:"Hongwei Xue; Bei Liu*; Huan Yang; Jianlong Fu; Houqiang Li; Jiebo Luo",
		},
		{
			type:"paperTitle",
			text:"1474 <b>Video-to-Image Casting: A Flatting Method for Video Analysis<b>",
		},
		{
			type:"paperAuthor",
			text:"Xu Chen*; Chenqiang Gao; Feng Yang; Xiaohan Wang; Yi Yang; Yahong Han",
		},
		{
			type:"paperTitle",
			text:"1486 <b>Automated Playtesting with a Cognitive Model of Sensorimotor Coordination<b>",
		},
		{
			type:"paperAuthor",
			text:"Injung Lee; Hyunchul Kim; Byungjoo Lee*",
		},
		{
			type:"paperTitle",
			text:"1500 <b>Diverse Image Inpainting with Bidirectional and Autoregressive Transformers<b>",
		},
		{
			type:"paperAuthor",
			text:"Yingchen Yu; Fangneng Zhan; Rongliang WU; Jianxiong Pan; Kaiwen Cui; Shijian Lu*; Feiying Ma; Xuansong Xie; Chunyan Miao",
		},
		{
			type:"paperTitle",
			text:"1508 <b>FMSing: Fast Multi-Singer Vocoder with A large-Scale Singing Voice Corpus<b>",
		},
		{
			type:"paperAuthor",
			text:"Rongjie Huang*; Feiyang Chen; Yi Ren; Jinglin Liu; Chenye Cui; Zhou Zhao",
		},
		{
			type:"paperTitle",
			text:"1510 <b>TSA-Net: Tube Self-Attention Network for Action Quality Assessment<b>",
		},
		{
			type:"paperAuthor",
			text:"Shunli Wang; Dingkang Yang; Peng Zhai; Chixiao Chen; Lihua Zhang*",
		},
		{
			type:"paperTitle",
			text:"1537 <b>Neural Free-Viewpoint Performance Rendering under Complex Human-object Interactions<b>",
		},
		{
			type:"paperAuthor",
			text:"Guoxing Sun*; Xin Chen; Yizhang Chen; Anqi Pang; Pei Lin; Yuheng Jiang; Lan Xu; Jingyi Yu; Jingya Wang",
		},
		{
			type:"paperTitle",
			text:"1542 <b>Multifocal Attention-Based Cross-Scale Network for Image De-raining<b>",
		},
		{
			type:"paperAuthor",
			text:"Zheyu Zhang; Yurui Zhu; Xueyang Fu*; Zhiwei Xiong; Zheng-Jun Zha; Feng Wu",
		},
		{
			type:"paperTitle",
			text:"1561 <b>Learning Disentangled Factors from Paired Data in Cross-Modal Retrieval: An Implicit Identifiable VAE Approach<b>",
		},
		{
			type:"paperAuthor",
			text:"Minyoung Kim*; Ricardo Guerrero ; Vladimir Pavlovic",
		},
		{
			type:"paperTitle",
			text:"1573 <b>A Novel Patch Convolutional Neural Network for View-based 3D Model Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Zan Gao; Yuxiang Shao; Weili Guan; Meng Liu; Zhiyong Cheng*; Shengyong Chen",
		},
		{
			type:"paperTitle",
			text:"1584 <b>Meta Self-Paced Learning for Cross-Modal Matching<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiwei Wei; Xing Xu*; Zheng Wang; Guoqing Wang",
		},
		{
			type:"paperTitle",
			text:"1606 <b>SSFlow: Style-guided neural Spline Flows for Face Image Manipulation<b>",
		},
		{
			type:"paperAuthor",
			text:"Hanbang Liang; Xianxu Hou; Linlin Shen*",
		},
		{
			type:"paperTitle",
			text:"1614 <b>Contrastive Disentangled Meta-Learning for Signer-Independent Sign Language Translation<b>",
		},
		{
			type:"paperAuthor",
			text:"Tao Jin*; Zhou Zhao",
		},
		{
			type:"paperTitle",
			text:"1627 <b>Personality Recognition by Modelling Person-specific Cognitive Processes using Graph Representation<b>",
		},
		{
			type:"paperAuthor",
			text:"Zilong Shao; Siyang Song*; Shashank Jaiswal; Linlin Shen; Michel Valstar; Hatice Gunes",
		},
		{
			type:"paperTitle",
			text:"1676 <b>DPT: Deformable Patch-based Transformer for Visual Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhiyang Chen*; Yousong Zhu; Chaoyang Zhao; Guosheng Hu; Wei Zeng; Jinqiao Wang; Ming Tang",
		},
		{
			type:"paperTitle",
			text:"1678 <b>MeshNet++: A Network with a Face<b>",
		},
		{
			type:"paperAuthor",
			text:"Vinit Veerendraveer Singh*; Shivanand Venkanna Sheshappanavar; Chandra Kambhamettu",
		},
		{
			type:"paperTitle",
			text:"1681 <b>Scene Text Image Super-Resolution via Parallelly Contextual Attention Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Cairong Zhao *; Shuyang Feng; Brain Nlong Zhao; Zhijun Ding; Jun Wu; Fumin Shen; Heng Tao Shen",
		},
		{
			type:"paperTitle",
			text:"1686 <b>Is Visual Context Really Helpful for Knowledge Graph? A Representation Learning Perspective<b>",
		},
		{
			type:"paperAuthor",
			text:"Meng Wang*; Sen Wang; Han Yang; Zheng Zhang; Xi Chen; Guilin Qi",
		},
		{
			type:"paperTitle",
			text:"1703 <b>EVRNet: Efficient Video Restoration on Edge Devices<b>",
		},
		{
			type:"paperAuthor",
			text:"Sachin Mehta*; Amit Kumar; Fitsum Reda; Varun Nasery; Vikram Mulukutla; Rakesh Ranjan; Vikas Chandra",
		},
		{
			type:"paperTitle",
			text:"1732 <b>InterBN: Channel Fusion for Adversarial Unsupervised Domain Adaptation<b>",
		},
		{
			type:"paperAuthor",
			text:"Mengzhu Wang; Wei Wang; Baopu Li*; Xiang Zhang; Long Lan; Tan H Huibin; Tianyi Liang; Wei Yu; Zhigang Luo",
		},
		{
			type:"paperTitle",
			text:"1748 <b>Perceptual Quality Assessment of Internet Videos<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiahua Xu; Jing Li*; XingGuang Zhou; Wei Zhou; Baichao Wang; Zhibo Chen",
		},
		{
			type:"paperTitle",
			text:"1763 <b>Exploring Pathologist Knowledge for Automatic Assessment of Breast Cancer Metastases in Whole-slide Image<b>",
		},
		{
			type:"paperAuthor",
			text:"Liuan Wang*; Li Sun; Mingjie Zhang; Huigang Zhang; Wang Ping; Rong Zhou; Jun Sun",
		},
		{
			type:"paperTitle",
			text:"1783 <b>Complementary Trilateral Decoder for Fast and Accurate Salient Object Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhirui Zhao; Changqun Xia*; Chenxi Xie; Jia Li",
		},
		{
			type:"paperTitle",
			text:"1798 <b>Constrained Graphic Layout Generation via Latent Optimization<b>",
		},
		{
			type:"paperAuthor",
			text:"Kotaro Kikuchi*; Edgar Simo-Serra; Mayu Otani; Kota Yamaguchi",
		},
		{
			type:"paperTitle",
			text:"1812 <b>Transfer Vision Patterns for Multi-Task Pixel Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiaoya Zhang; Ling Zhou; Yong Li; Zhen Cui*; Jin Xie; Jian Yang",
		},
		{
			type:"paperTitle",
			text:"1815 <b>AI-Lyricist: Generating Music and Vocabulary Constrained Lyrics<b>",
		},
		{
			type:"paperAuthor",
			text:"Xichu Ma; Ye Wang*; Min-Yen Kan; Wee Sun Lee",
		},
		{
			type:"paperTitle",
			text:"1828 <b>Theophany: Multimodal Speech Augmentation in Instantaneous Privacy Channels<b>",
		},
		{
			type:"paperAuthor",
			text:"Abhishek Kumar*; Tristan Braud; Lik Hang Lee; Pan Hui",
		},
		{
			type:"paperTitle",
			text:"1843 <b>Video Representation Learning with Graph Contrastive Augmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Jingran Zhang*; Xing Xu; Fumin Shen; Yazhou Yao; Jie Shao; Xiaofeng Zhu",
		},
		{
			type:"paperTitle",
			text:"1859 <b>Why Do We Click: Visual Impression-aware News Recommendation<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiahao Xun*; Shengyu Zhang; Zhou Zhao; Jieming Zhu; Qi Zhang; Jingjie Li; Xiuqiang He; Xiaofei He; Tat-Seng Chua; Fei Wu",
		},
		{
			type:"paperTitle",
			text:"1892 <b>MeronymNet: A Hierarchical Model for Unified and Controllable Multi-Category Object Generation<b>",
		},
		{
			type:"paperAuthor",
			text:"Rishabh Baghel; Abhishek Trivedi; Tejas Ravichandran; Ravi Kiran Sarvadevabhatla*",
		},
		{
			type:"paperTitle",
			text:"1901 <b>ArtScience and the ICECUBE LED Display [ILDm^3]<b>",
		},
		{
			type:"paperAuthor",
			text:"Mark-David Hosale*; Robert Allison; Jim Madsen; Marcus Gordon",
		},
		{
			type:"paperTitle",
			text:"1907 <b>Diverse Multimedia Layout Generation with Multi Choice Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"David D Nguyen*; Surya Nepal; Salil Kanhere",
		},
		{
			type:"paperTitle",
			text:"1908 <b>Semi-supervised Domain Adaptive Retrieval via Discriminative Hashing Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Haifeng Xia; Taotao Jing; Chen Chen; Zhengming Ding*",
		},
		{
			type:"paperTitle",
			text:"1913 <b>Semi-supervised Learning via Improved Teacher-Student Network For Robust 3D Reconstruction of Stereo Endoscopic Image<b>",
		},
		{
			type:"paperAuthor",
			text:"Hongkuan Shi; Zhiwei Wang; Jinxin Lv; Yilang Wang; Peng Zhang; Fei Zhu; Qiang Li*",
		},
		{
			type:"paperTitle",
			text:"1922 <b>Actions speak louder than listening: evaluating music style transfer based on editing experience<b>",
		},
		{
			type:"paperAuthor",
			text:"Wei-Tsung Lu; Meng-Hsuan Wu; Yuh-Ming Chiu; Li Su*",
		},
		{
			type:"paperTitle",
			text:"1932 <b>Towards Accurate Localization by Instance Search<b>",
		},
		{
			type:"paperAuthor",
			text:"Yi-Geng Hong; Hui-Chu Xiao; Wan-Lei Zhao*",
		},
		{
			type:"paperTitle",
			text:"1939 <b>Object-aware Long-short-range Spatial Alignment for Few-Shot Fine-Grained Image Classification<b>",
		},
		{
			type:"paperAuthor",
			text:"Yike Wu; Bo Zhang; Gang Yu; Weixi Zhang; Bin Wang; Tao Chen*; Jiayuan Fan",
		},
		{
			type:"paperTitle",
			text:"1970 <b>CoReD: Generalizing Fake Media Detection with Continual Representation using Distillation<b>",
		},
		{
			type:"paperAuthor",
			text:"Minha Kim; Shahroz Tariq*; Simon Woo",
		},
		{
			type:"paperTitle",
			text:"1985 <b>I Know Your Keyboard Input: A Robust Keystroke Eavesdropper Based-on Acoustic Signals<b>",
		},
		{
			type:"paperAuthor",
			text:"Jia-Xuan Bai*; Bin Liu; Luchuan Song",
		},
		{
			type:"paperTitle",
			text:"2002 <b>Towards Multiple Black-boxes Attack via Adversarial Example Generation Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Duan Mingxing; Kenli Li; Lingxi Xie; Qi Tian*; Bin Xiao",
		},
		{
			type:"paperTitle",
			text:"2056 <b>Dense Semantic Contrast for Self-Supervised Visual Representation Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiaoni Li; Yu Zhou*; Yifei Zhang; aoting zhang; Wei Wang; Ning Jiang; Haiying Wu; Weiping Wang",
		},
		{
			type:"paperTitle",
			text:"2067 <b>Enhancing Knowledge tracing via adversarial training<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiaopeng Guo*; Zhijie Huang; Jie Gao; Mingyu Shang; Maojing shu; Jun Sun",
		},
		{
			type:"paperTitle",
			text:"2094 <b>Multimodal Asymmetric Dual Learning for Unsupervised Eyeglasses Removal<b>",
		},
		{
			type:"paperAuthor",
			text:"Qing Lin; Bo Yan*; Weimin Tan",
		},
		{
			type:"paperTitle",
			text:"2096 <b>Deep Marginal Fisher Analysis based CNN for Image Representation and Classification<b>",
		},
		{
			type:"paperAuthor",
			text:"Xun Cai; Jiajing Chai; Yanbo Gao*; Shuai Li; Bo Zhu",
		},
		{
			type:"paperTitle",
			text:"2107 <b>Learning Structure Affinity for Video Depth Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuanzhouhan Cao; Yidong Li*; Haokui Zhang; Chao Ren; Yifan Liu",
		},
		{
			type:"paperTitle",
			text:"2113 <b>Viewing From Frequency Domain: A DCT-based Information Enhancement Network for Video Person Re-Identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Liangchen Liu; Xi Yang*; Nannan Wang; Xinbo Gao",
		},
		{
			type:"paperTitle",
			text:"2166 <b>Improving Pedestrian Detection from a Long-tailed Domain Perspective<b>",
		},
		{
			type:"paperAuthor",
			text:"Mengyuan Ding*; Shanshan Zhang; Jian Yang",
		},
		{
			type:"paperTitle",
			text:"2184 <b>MusicBERT: A Self-supervised Learning of Music Representation<b>",
		},
		{
			type:"paperAuthor",
			text:"Hongyuan Zhu*; Ye Niu; Di Fu; Hao Wang",
		},
		{
			type:"paperTitle",
			text:"2194 <b>Structure-aware Mathematical Expression Recognition with Sequence-Level Modeling<b>",
		},
		{
			type:"paperAuthor",
			text:"Minli Li; Peilin Zhao; Yifan Zhang; Shuaicheng Niu; Qingyao Wu; Mingkui Tan*",
		},
		{
			type:"paperTitle",
			text:"2203 <b>Informative Class-Conditioned Feature Alignment for Unsupervised Domain Adaptation<b>",
		},
		{
			type:"paperAuthor",
			text:"Wanxia Deng; Yawen Cui; Zhen Liu; Gangyao Kuang; Dewen Hu; Matti Pietikinen; Li Liu*",
		},
		{
			type:"paperTitle",
			text:"2235 <b>HetEmotionNet: Two-Stream Heterogeneous Graph Recurrent Neural Network for Multi-modal Emotion Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Ziyu Jia; Youfang Lin; Jing Wang*; Zhiyang Feng; Xiangheng Xie; Caijie Chen",
		},
		{
			type:"paperTitle",
			text:"2261 <b>Graph Neural Networks for Knowledge Enhanced Visual Representation of Paintings<b>",
		},
		{
			type:"paperAuthor",
			text:"Athanasios Efthymiou*; Stevan Rudinac; Monika Kackovic; Marcel Worring; Nachoem Wijnberg",
		},
		{
			type:"paperTitle",
			text:"2275 <b>Is Someone Speaking? Exploring Long-term Temporal Features for Audio-visual Active Speaker Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Ruijie Tao*; Zexu Pan; Rohan Kumar Das; Xinyuan Qian; Mike Zheng Shou; Haizhou Li",
		},
		{
			type:"paperTitle",
			text:"2288 <b>ChartPointFlow for Topology-Aware 3D Point Cloud Generation<b>",
		},
		{
			type:"paperAuthor",
			text:"Takumi Kimura; Takashi Matsubara*; Kuniaki Uehara",
		},
		{
			type:"paperTitle",
			text:"2297 <b>TBRA: Tiling and Bitrate Adaptation for Mobile 360-Degree Video Streaming<b>",
		},
		{
			type:"paperAuthor",
			text:"Lei Zhang*; Yanyan Suo; Ximing Wu; Feng Wang; Yuchi Chen; Laizhong Cui; Jiangchuan Liu; Zhong Ming",
		},
		{
			type:"paperTitle",
			text:"2335 <b>DeepGame: Efficient Video Encoding for Cloud Gaming<b>",
		},
		{
			type:"paperAuthor",
			text:"Omar Mossad*; Khaled Diab; Ihab Amer; Mohamed Hefeeda",
		},
		{
			type:"paperTitle",
			text:"2340 <b>Cross-View Exocentric to Egocentric Video Synthesis<b>",
		},
		{
			type:"paperAuthor",
			text:"Gaowen Liu; Hao Tang*; Hugo M Latapie; Jason J Corso; Yan Yan",
		},
		{
			type:"paperTitle",
			text:"2365 <b>Graph Convolutional Multi-modal Hashing for Flexible Multimedia Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Xu Lu; Lei Zhu; Li Liu; Liqiang Nie; Huaxiang Zhang*",
		},
		{
			type:"paperTitle",
			text:"2405 <b>Two-pronged Strategy: Lightweight Augmented Graph Network Hashing for Scalable Image Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Hui Cui; Lei Zhu*; Jingjing Li; Zhiyong Cheng; Zheng Zhang",
		},
		{
			type:"paperTitle",
			text:"2406 <b>Beyond OCR + VQA: Involving OCR into the Flow for Robust and Accurate TextVQA<b>",
		},
		{
			type:"paperAuthor",
			text:"Gangyan Zeng; Yuan Zhang; Yu Zhou*; Xiaomeng Yang",
		},
		{
			type:"paperTitle",
			text:"2427 <b>Direction Relation Transformer for Image Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Zeliang Song; Xiaofei Zhou*; Linhua Dong; Jianlong Tan; Li Guo",
		},
		{
			type:"paperTitle",
			text:"2447 <b>Generating Point Cloud from Single Image in The Few Shot Scenario<b>",
		},
		{
			type:"paperAuthor",
			text:"Yu Lin*; Jinghui Guo; Yang Gao; Yi-Fan Li; Zhuoyi Wang; Latifur Khan",
		},
		{
			type:"paperTitle",
			text:"2469 <b>CAA: Candidate-Aware Aggregation for Temporal Action Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Yifan Ren; Xing Xu*; Fumin Shen; Yazhou Yao; Huimin Lu",
		},
		{
			type:"paperTitle",
			text:"2483 <b>Progressive Semantic Matching for Video-Text Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Hongying Liu; Ruyi Luo; Fanhua Shang*; Mantang Niu; Yuanyuan Liu",
		},
		{
			type:"paperTitle",
			text:"2486 <b>Co-learning: Learning from noisy labels with self-supervision<b>",
		},
		{
			type:"paperAuthor",
			text:"Cheng Tan*; Jun Xia; Lirong Wu; Stan Z. Li",
		},
		{
			type:"paperTitle",
			text:"2488 <b>AITransfer: Progressive AI-powered Transmission for Real-Time Point Cloud Video Streaming<b>",
		},
		{
			type:"paperAuthor",
			text:"Yakun Huang*; Yuanwei Zhu; Xiuquan Qiao; Zhijie Tan; Boyuan Bai",
		},
		{
			type:"paperTitle",
			text:"2495 <b>SRNet: Spatial Relation Network for Efficient Single-stage Instance Segmentation in Videos<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiaowen Ying*; Xin Li; Mooi Choo Chuah",
		},
		{
			type:"paperTitle",
			text:"2503 <b>Learning Human Motion Prediction via Stochastic Differential Equations<b>",
		},
		{
			type:"paperAuthor",
			text:"Kedi Lyu*; Zhenguang Liu; Shuang Wu; Haipeng Chen; Xuhong Zhang; Yuyu Yin",
		},
		{
			type:"paperTitle",
			text:"2513 <b>Using Interaction Data to Predict Engagement with Interactive Media<b>",
		},
		{
			type:"paperAuthor",
			text:"Jonathan Carlton*; Andy Brown; Dr.Caroline Jay; John Keane",
		},
		{
			type:"paperTitle",
			text:"2519 <b>Disentangled Representation Learning and Enhancement Network for Single Image De-Raining<b>",
		},
		{
			type:"paperAuthor",
			text:"Guoqing Wang; Changming Sun; Xing Xu; Jingjing Li; Zheng Wang*; Zeyu Ma",
		},
		{
			type:"paperTitle",
			text:"2546 <b>Space-Time Interaction Graph Parsing Networks for Human-Object Interaction Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Ning Wang; Guangming Zhu*; Liang Zhang; Peiyi Shen; Hongsheng Li; Cong Hua",
		},
		{
			type:"paperTitle",
			text:"2588 <b>A Stepwise Matching Method for Multimodal Image based on Cascaded Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Jinming Mu; Shuiping Gou; Shasha Mao*; Shankui Zheng",
		},
		{
			type:"paperTitle",
			text:"2596 <b>Zero-shot Video Emotion Recognition via Multimodal Protagonist-aware Transformer Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Fan Qi*; Xiaoshan Yang; Changsheng Xu",
		},
		{
			type:"paperTitle",
			text:"2608 <b>Knowledge perceived multi-modal pretraining in E-commerce<b>",
		},
		{
			type:"paperAuthor",
			text:"Yushan Zhu*; Huaixiao Zhao; Wen Zhang; Ganqiang Ye; Hui Chen; Ningyu Zhang; Huajun Chen",
		},
		{
			type:"paperTitle",
			text:"2629 <b>PFFN: Progressive Feature Fusion Network for Lightweight Image Super-Resolution<b>",
		},
		{
			type:"paperAuthor",
			text:"Dongyang Zhang; Changyu Li; Ning Xie; Guoqing Wang; Jie Shao*",
		},
		{
			type:"paperTitle",
			text:"2659 <b>Game Theory-driven Rate Control for 360-Degree Video Coding<b>",
		},
		{
			type:"paperAuthor",
			text:"Tiesong Zhao*; Jielian Lin; Yanjie Song; Xu Wang; Yuzhen Niu",
		},
		{
			type:"paperTitle",
			text:"2687 <b>Differentiated Learning for Multi-Modal Domain Adaptation<b>",
		},
		{
			type:"paperAuthor",
			text:"Jianming Lv*; Kaijie Liu; Shengfeng He",
		},
		{
			type:"paperTitle",
			text:"2691 <b>Learning What and When to Drop: Adaptive Multimodal and Contextual Dynamics for Emotion Recognition in Conversation<b>",
		},
		{
			type:"paperAuthor",
			text:"Feiyu Chen; Zhengxiao Sun; Deqiang Ouyang; Xueliang Liu; Jie Shao*",
		},
		{
			type:"paperTitle",
			text:"2703 <b>Unsupervised Portrait Shadow Removal via Generative Prior<b>",
		},
		{
			type:"paperAuthor",
			text:"Yingqing HE; Yazhou Xing; Tianjia Zhang; Qifeng Chen*",
		},
		{
			type:"paperTitle",
			text:"2773 <b>Self-Supervised Regional and Temporal Auxiliary Tasks for Facial Action Unit Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Jingwei Yan*; Jingjing Wang; Qiang Li; Chunmao Wang; Shiliang Pu",
		},
		{
			type:"paperTitle",
			text:"2799 <b>Exploring Logical Reasoning for Referring Expression Comprehension<b>",
		},
		{
			type:"paperAuthor",
			text:"Ying Cheng*; Ruize Wang; Jiashuo Yu; Rui-Wei Zhao; Yuejie Zhang; Rui Feng",
		},
		{
			type:"paperTitle",
			text:"2805 <b>aBio: Active Bi-Olfactory Display Using Subwoofers for Virtual Reality<b>",
		},
		{
			type:"paperAuthor",
			text:"You-Yang Hu*; Yao-Fu Jan; Kuan-Wei Tseng; You-Shin Tsai; Hung-Ming Sung; Jin-Yao Lin; Yi-Ping Hung ",
		},
		{
			type:"paperTitle",
			text:"2829 <b>Database-adaptive Re-ranking for Enhancing Cross-modal Image Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Rintaro Yanagi*; Ren Togo; Takahiro Ogawa; Miki Haseyama",
		},
		{
			type:"paperTitle",
			text:"2840 <b>Fast video visual quality and resolution improvement using SR-UNet<b>",
		},
		{
			type:"paperAuthor",
			text:"Federico Vaccaro; Marco Bertini*; Tiberio Uricchio; Alberto Del Bimbo",
		},
		{
			type:"paperTitle",
			text:"2850 <b>Linking the Characters: Video-oriented Social Graph Generation via Hierarchical-cumulative GCN<b>",
		},
		{
			type:"paperAuthor",
			text:"Shiwei Wu*; Joya Chen; Tong Xu; Liyi Chen; Lingfei Wu; Yao Hu; Enhong Chen",
		},
		{
			type:"paperTitle",
			text:"2883 <b>Human attributes prediction under privacy-preserving conditions<b>",
		},
		{
			type:"paperAuthor",
			text:"Anshu Singh*; Shaojing Fan; Mohan Kankanhalli",
		},
		{
			type:"paperTitle",
			text:"2896 <b>Deep Unsupervised 3D SfM Face Reconstruction Based on Massive Landmark Bundle Adjustment<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuxing Wang; Yawen Lu; Zhihua Xie; Guoyu Lu*",
		},
		{
			type:"paperTitle",
			text:"2902 <b>Collocation and Try-on Network: Whether an outfit is Compatible<b>",
		},
		{
			type:"paperAuthor",
			text:"Na Zheng*; Xuemeng Song; Qingying Niu; Xue Dong; Yibing Zhan; Liqiang Nie",
		},
		{
			type:"paperTitle",
			text:"2905 <b>Exploiting BERT For Multimodal Target Sentiment Classification Through Input Space Translation<b>",
		},
		{
			type:"paperAuthor",
			text:"Zaid Khan*; Yun Fu",
		},
		{
			type:"paperTitle",
			text:"2918 <b>Air-Text: Air-Writing and Recognition System<b>",
		},
		{
			type:"paperAuthor",
			text:"Sun-Kyung Lee; Jong-Hwan Kim*",
		},
		{
			type:"paperTitle",
			text:"2920 <b>Multi-label Pattern Image Retrieval via Attention Mechanism Driven Graph Convolutional Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Ying Li*; Hongwei Zhou; Yeyu Yin; Jiaquan Gao",
		},
		{
			type:"paperTitle",
			text:"2954 <b>Partial Tubal Nuclear Norm Regularized Multi-view Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Yongyong Chen*; Shuqin Wang; Chong Peng; Guangming Lu; Yicong Zhou",
		},
		{
			type:"paperTitle",
			text:"2970 <b>Simplifying Multimodal Emotion Recognition with Single Eye Movement Modality<b>",
		},
		{
			type:"paperAuthor",
			text:"Xu Yan*; Liming Zhao; Bao-Liang Lu",
		},
		{
			type:"paperTitle",
			text:"2982 <b>Cross-View Representation Learning for Multi-View Logo Classification with Information Bottleneck<b>",
		},
		{
			type:"paperAuthor",
			text:"Jing Wang; Yuanjie Zheng*; Jingqi Song; Sujuan Hou",
		},
		{
			type:"paperTitle",
			text:"3023 <b>Pre-training Graph Transformer with Multimodal Side Information for Recommendation<b>",
		},
		{
			type:"paperAuthor",
			text:"Yong Liu*; Susen Yang; Chenyi Lei; Guoxin Wang; Haihong Tang; Juyong Zhang; Aixin Sun; Chunyan Miao",
		},
		{
			type:"paperTitle",
			text:"3052 <b>Learning to Compose Stylistic Calligraphy Artwork with Emotions<b>",
		},
		{
			type:"paperAuthor",
			text:"Shaozu Yuan; Ruixue Liu; Meng Chen*; Baoyang Chen; Zhijie Qiu; Xiaodong He",
		},
		{
			type:"paperTitle",
			text:"3063 <b>Scene Graph with 3D Information for Change Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Zeming Liao; Qingbao Huang*; Yu Liang; Mingyi Fu; Yi Cai; Qing Li",
		},
        {
          type:'primaryTitle',
          text:'Poster Papers',
        },
		{
			type:"paperTitle",
			text:"23 <b>JPGNet: Joint Predictive Filtering and Generative Network for Image Inpainting<b>",
		},
		{
			type:"paperAuthor",
			text:"Qing Guo*; Xiaoguang Li; Felix Juefei-Xu; Hongkai Yu; Yang Liu; Song Wang",
		},
		{
			type:"paperTitle",
			text:"29 <b>AdvFilter: Predictive Perturbation-aware Filtering against Adversarial Attack via Multi-domain Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Yihao Huang; Qing Guo*; Felix Juefei-Xu; Lei Ma; Weikai Miao; Yang Liu; Geguang Pu",
		},
		
		{
			type:"paperTitle",
			text:"86 <b>Pixel-level Intra-domain Adaptation for Semantic Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Zizheng Yan*; Xianggang Yu; Yipeng Qin; Yushuang Wu; Xiaoguang Han; Shuguang Cui",
		},
		{
			type:"paperTitle",
			text:"103 <b>Mask is All You Need: Rethinking Mask R-CNN for Dense and Arbitrary-Shaped Scene Text Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Xugong Qin; Yu Zhou*; Youhui Guo; Dayan Wu; Zhihong Tian; Ning Jiang; Hongbin Wang; Weiping Wang",
		},
		{
			type:"paperTitle",
			text:"132 <b>Windowing Decomposition Convolutional Neural Network for Image Enhancement<b>",
		},
		{
			type:"paperAuthor",
			text:"Chuanjun Zheng*; Daming Shi; Yukun Liu",
		},
		{
			type:"paperTitle",
			text:"146 <b>Joint Optimization in Edge-Cloud Continuum for Federated Unsupervised Person Re-identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Weiming Zhuang*; Yonggang Wen; Shuai Zhang",
		},
		{
			type:"paperTitle",
			text:"156 <b>Multi-view 3D Smooth Human Pose Estimation based on Heatmap Filtering and Spatio-temporal Information<b>",
		},
		{
			type:"paperAuthor",
			text:"Zehai Niu; Ke Lu; Jian Xue*; Haifeng Ma; Runchen Wei",
		},
		{
			type:"paperTitle",
			text:"162 <b>Imitative Learning for Multi-Person Action Forecasting<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuke Li*; Pin Wang; Mang Ye; Ching-Yao Chan",
		},
		{
			type:"paperTitle",
			text:"182 <b>Stereo Video Super-Resolution via Exploiting View-Temporal Correlations<b>",
		},
		{
			type:"paperAuthor",
			text:"Ruikang Xu; Zeyu Xiao; Mingde Yao; Yueyi Zhang*; Zhiwei Xiong",
		},
		{
			type:"paperTitle",
			text:"195 <b>M3TR: Multi-modal Multi-label Recognition with Transformer<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiawei Zhao; Yifan Zhao; Jia Li*",
		},
		{
			type:"paperTitle",
			text:"211 <b>TACR-Net: Editing on Deep Video and Voice Portraits<b>",
		},
		{
			type:"paperAuthor",
			text:"Luchuan Song*; Bin Liu; Guojun Yin; Xiaoyi Dong; Yufei Zhang; Jia-Xuan Bai",
		},
		{
			type:"paperTitle",
			text:"230 <b>Annotation-Efficient Untrimmed Video Action Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Yixiong Zou*; Shanghang Zhang; Guangyao Chen; Yonghong Tian; Kurt Keutzer; José M. F. Moura",
		},
		{
			type:"paperTitle",
			text:"234 <b>Face-based Voice Conversion: Learning the Voice behind a Face<b>",
		},
		{
			type:"paperAuthor",
			text:"Hsiao-Han Lu*; Shao-En Weng; Ya-Fan Yen; Hong-Han Shuai; Wen-Huang Cheng",
		},
		{
			type:"paperTitle",
			text:"242 <b>A Large-Scale Benchmark for Food Image Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiongwei Wu*; Xin Fu; Ying Liu; Ee-peng Lim; Steven Hoi; Qianru Sun",
		},
		{
			type:"paperTitle",
			text:"243 <b>HAT: Hierarchical Aggregation Transformers for Person Re-identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Guowen Zhang; Pingping Zhang; Jinqing Qi; Huchuan Lu*",
		},
		{
			type:"paperTitle",
			text:"245 <b>Long-Range Feature Propagating for Natural Image Matting<b>",
		},
		{
			type:"paperAuthor",
			text:"Qinglin Liu*; Haozhe Xie; Shengping Zhang; Bineng Zhong; Rongrong Ji",
		},
		{
			type:"paperTitle",
			text:"263 <b>Towards Controllable and Photorealistic Region-wise Image Manipulation<b>",
		},
		{
			type:"paperAuthor",
			text:"Ansheng You*; Chenglin Zhou; Qixuan Zhang; Lan Xu",
		},
		{
			type:"paperTitle",
			text:"266 <b>Information-Growth Attention Network for Image Super-Resolution<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhuangzi Li*; Ge Li; Thomas H Li; Shan Liu; Wei Gao",
		},
		{
			type:"paperTitle",
			text:"269 <b>Anchor-free 3D Single Stage Detector with Mask-Guided Attention for Point Cloud<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiale Li; Hang Dai*; Ling Shao; Yong Ding",
		},
		{
			type:"paperTitle",
			text:"275 <b>Shape Controllable Virtual Try-on for Underwear Models<b>",
		},
		{
			type:"paperAuthor",
			text:"Xin Gao*; Zhenjiang Liu; Zunlei Feng; Chengji Shen; Kairi Ou; Haihong Tang; Mingli Song",
		},
		{
			type:"paperTitle",
			text:"278 <b>E^2Net: Excitative-Expansile Learning for Weakly Supervised Object Localization<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhiwei Chen*; Liujuan Cao; Yunhang Shen; Feihong Lian; Yongjian Wu; Rongrong Ji",
		},
		{
			type:"paperTitle",
			text:"314 <b>Few-shot Fine-Grained Action Recognition via Bidirectional Attention and Contrastive Meta-Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiahao Wang*; Yunhong Wang; Sheng Liu; Annan Li",
		},
		{
			type:"paperTitle",
			text:"325 <b>Selective Dependency Aggregation for Action Classification<b>",
		},
		{
			type:"paperAuthor",
			text:"Yi Tan; Yanbin Hao*; Xiangnan He; Yinwei Wei; Xun Yang",
		},
		{
			type:"paperTitle",
			text:"335 <b>Conditional Directed Graph Convolution for 3D Human Pose Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Wenbo Hu*; Changgong Zhang; Fangneng Zhan; Lei Zhang; Tien-Tsin Wong",
		},
		{
			type:"paperTitle",
			text:"339 <b>Cross Chest Graph for Disease Diagnosis with Structural Relational Reasoning<b>",
		},
		{
			type:"paperAuthor",
			text:"Gangming Zhao*",
		},
		{
			type:"paperTitle",
			text:"355 <b>ZiGAN:Fine-grained Chinese Calligraphy Font Generation via a Few-shot Style Transfer Approach<b>",
		},
		{
			type:"paperAuthor",
			text:"Qi Wen; Shuang Li; Bingfeng Han; Yi Yuan*",
		},
		{
			type:"paperTitle",
			text:"359 <b>Cycle-Consistent Inverse GAN for Text-to-Image Synthesis<b>",
		},
		{
			type:"paperAuthor",
			text:"Hao Wang*; Guosheng Lin; Steven Hoi; Chunyan Miao",
		},
		{
			type:"paperTitle",
			text:"366 <b>Fully Quantized Image Super-Resolution Networks<b>",
		},
		{
			type:"paperAuthor",
			text:"Hu Wang*; Peng Chen; Bohan Zhuang; Chunhua Shen",
		},
		{
			type:"paperTitle",
			text:"369 <b>AKECP: Adaptive Knowledge Extraction from Feature Maps for Fast and Efficient Channel Pruning<b>",
		},
		{
			type:"paperAuthor",
			text:"Haonan Zhang; Longjun Liu*; Hengyi Zhou; Wenxuan Hou; Hongbin Sun; Nanning Zheng",
		},
		{
			type:"paperTitle",
			text:"383 <b>Dynamic Momentum Adaptation for Zero-Shot Cross-Domain Crowd Counting<b>",
		},
		{
			type:"paperAuthor",
			text:"Qiangqiang Wu*; Jia Wan; Antoni Chan",
		},
		{
			type:"paperTitle",
			text:"393 <b>Auto-MSFNet: Search Multi-scale Fusion Network for Salient Object Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Miao Zhang; Tingwei Liu; Yongri Piao*; Shunyu Yao; Huchuan Lu",
		},
		{
			type:"paperTitle",
			text:"402 <b>Few-shot Unsupervised Domain Adaptation with Image-to-Class Sparse Similarity Encoding<b>",
		},
		{
			type:"paperAuthor",
			text:"Shengqi Huang; Wanqi Yang*; Lei Wang; Luping Zhou; Ming Yang",
		},
		{
			type:"paperTitle",
			text:"403 <b>Semantic-aware Transfer with Instance-adaptive Parsing for Crowded Scenes Pose Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Xuanhan Wang*; Lianli Gao; Yan Dai; Yixuan Zhou; Jingkuan Song",
		},
		{
			type:"paperTitle",
			text:"411 <b>Multimodal Dialog System: Relational Graph-based Context-aware Question Understanding<b>",
		},
		{
			type:"paperAuthor",
			text:"Haoyu Zhang; Meng Liu; Zan Gao; Xiaoqiang Lei; Yinglong Wang; Liqiang Nie*",
		},
		{
			type:"paperTitle",
			text:"413 <b>Shadow Detection via Predicting the Confidence Maps of Shadow Detection Methods<b>",
		},
		{
			type:"paperAuthor",
			text:"JingWei Liao*; Yanli Liu; GuanYu Xing; Housheng Wei; JueYu Chen; Songhua Xu",
		},
		{
			type:"paperTitle",
			text:"424 <b>TEID: A New State-of-the-art Approach for Future Motion Prediction<b>",
		},
		{
			type:"paperAuthor",
			text:"Pengxiang Su*; Zhenguang Liu; Shuang Wu; Lei Zhu; Yifang Yin; Xuanjing Shen",
		},
		{
			type:"paperTitle",
			text:"431 <b>Q-Art Code: Generating Scanning-robust Art-style QR Codes by Deformable Convolution<b>",
		},
		{
			type:"paperAuthor",
			text:"Hao Su*; jianwei Niu; Xuefeng Liu; Qingfeng Li; Ji Wan; Mingliang Xu",
		},
		{
			type:"paperTitle",
			text:"433 <b>Depth Quality-Inspired Feature Manipulation for Efficient RGB-D Salient Object Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Wenbo Zhang; Ge-Peng Ji; Zhuo Wang; Keren Fu*; Qijun Zhao",
		},
		{
			type:"paperTitle",
			text:"441 <b>Revisiting Mid-Level Patterns for Cross-Domain Few-Shot Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Yixiong Zou*; Shanghang Zhang; Jianpeng Yu; Yonghong Tian; José M. F. Moura",
		},
		{
			type:"paperTitle",
			text:"451 <b>Space-Angle Super-Resolution for Multi-View Images<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuqi Sun; Ri Cheng; Bo Yan*; Shili Zhou",
		},
		{
			type:"paperTitle",
			text:"461 <b>Weakly-Supervised Video Object Grounding via Stable Context Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Wei Wang*; Junyu Gao; Changsheng Xu",
		},
		{
			type:"paperTitle",
			text:"477 <b>Modeling the Uncertainty for Self-supervised 3D Skeleton Action Representation Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Yukun Su; Guosheng Lin; RuiZhou Sun; Yun Hao; Qingyao Wu*",
		},
		{
			type:"paperTitle",
			text:"482 <b>D3Net: Dual-Branch Disturbance Disentangling Network for Facial Expression Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Rongyun Mo; Yan Yan*; Jing-Hao Xue; Si Chen; Hanzi Wang",
		},
		{
			type:"paperTitle",
			text:"488 <b>Towards a Unified Middle Modality Learning for Visible-Infrared Person Re-Identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhang Yukang; Yan Yan; Yang Lu; Hanzi Wang*",
		},
		{
			type:"paperTitle",
			text:"493 <b>ROSITA: Enhancing Vision-and-Language Semantic Alignments via Cross- and Intra-modal Knowledge Integration<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuhao Cui; Zhou Yu*; Chunqi Wang; Zhongzhou Zhao; Ji Zhang; Meng Wang; Jun Yu",
		},
		{
			type:"paperTitle",
			text:"497 <b>Object Point Cloud Classification via Poly-ConvolutionalArchitecture Search<b>",
		},
		{
			type:"paperAuthor",
			text:"Xuanxiang Lin*; Ke Chen; Kui Jia",
		},
		{
			type:"paperTitle",
			text:"499 <b>Semantic-Guided Relation Propagation Network for Few-shot Action Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiao Wang; Weirong Ye; Zhongang Qi; Xun Zhao; Guangge Wang; Ying Shan; Hanzi Wang*",
		},
		{
			type:"paperTitle",
			text:"505 <b>Anti-Distillation Backdoor Attacks: Backdoors Really Can Survive in The Knowledge Distillation<b>",
		},
		{
			type:"paperAuthor",
			text:"Yunjie Ge*; Qian Wang; Baolin Zheng; Xinlu Zhuang; Qi Li; Chao Shen; Cong Wang",
		},
		{
			type:"paperTitle",
			text:"519 <b>One-stage Context and Identity Hallucination Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Yinglu Liu*; Mingcan Xiang; Hailin Shi; Tao Mei",
		},
		{
			type:"paperTitle",
			text:"520 <b>Enhancing Generalized Zero-Shot Learning by Visual Perturbation<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhi Chen*; Yadan Luo; Sen Wang; Ruihong Qiu; Jingjing Li; Zi Huang",
		},
		{
			type:"paperTitle",
			text:"528 <b>Weakly-Supervised Temporal Action Localization via Cross-Stream Collaborative Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuan Ji*; Xu Jia; Huchuan Lu; Xiang Ruan",
		},
		{
			type:"paperTitle",
			text:"537 <b>Deep Interactive Video Inpainting: an Invisibility Cloak for Harry Potter<b>",
		},
		{
			type:"paperAuthor",
			text:"Cheng Chen*; Jiayin Cai; Yao Hu; Xu Tang; Xinggang Wang; Chun Yuan; Xiang Bai; Song Bai",
		},
		{
			type:"paperTitle",
			text:"552 <b>Searching Motion Graphs for Human Motion Synthesis<b>",
		},
		{
			type:"paperAuthor",
			text:"Chenchen Liu; Yadong Mu*",
		},
		{
			type:"paperTitle",
			text:"555 <b>When Video Classification Meets Incremental Classes<b>",
		},
		{
			type:"paperAuthor",
			text:"Hanbin Zhao; Xin Qin; Shihao Su; Yongjian Fu; Zibo Lin; Xi Li*",
		},
		{
			type:"paperTitle",
			text:"559 <b>Fast and Accurate Lane Detection via Frequency Domain Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Yulin He*; Wei Chen; Zhengfa Liang; Dan Chen; Yusong Tan; Xin Luo; Chen Li; Yulan Guo",
		},
		{
			type:"paperTitle",
			text:"560 <b>Learning Multi-context Aware Location Representations from Large-scale Geotagged Images<b>",
		},
		{
			type:"paperAuthor",
			text:"Yifang Yin*; Ying Zhang; Zhenguang Liu; Yuxuan Liang; Sheng Wang; Rajiv Ratn Shah; Roger Zimmermann",
		},
		{
			type:"paperTitle",
			text:"562 <b>MV-TON: Memory-based Video Virtual Try-on network<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiaojing Zhong; Zhonghua Wu; Taizhe Tan; Guosheng Lin; Qingyao Wu*",
		},
		{
			type:"paperTitle",
			text:"568 <b>Token Shift Transformer for Video Classification<b>",
		},
		{
			type:"paperAuthor",
			text:"Hao Zhang; Yanbin Hao*; Chong-Wah Ngo",
		},
		{
			type:"paperTitle",
			text:"576 <b>Attribute-specific Control Units in StyleGAN for Fine-grained Image Manipulation<b>",
		},
		{
			type:"paperAuthor",
			text:"Rui Wang; Jian Chen; Gang Yu; Li Sun; Changqian Yu; Changxin Gao*; Nong Sang",
		},
		{
			type:"paperTitle",
			text:"588 <b>Attention-driven Graph Clustering Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhihao Peng; Hui Liu; Yuheng Jia; Junhui Hou*",
		},
		{
			type:"paperTitle",
			text:"590 <b>Lifting the Veil of Frequency in Joint Segmentation and Depth Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Tianhao Fu*; Yingying Li; Xiaoqing Ye; Xiao Tan; Hao Sun; Fumin Shen; Errui Ding",
		},
		{
			type:"paperTitle",
			text:"594 <b>Visual Co-Occurrence Alignment Learning for Weakly-Supervised Video Moment Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Zheng Wang*; Jingjing Chen; Yu-Gang Jiang",
		},
		{
			type:"paperTitle",
			text:"606 <b>Adaptive Normalized Representation Learning for Generalizable FaceAnti-Spoofing<b>",
		},
		{
			type:"paperAuthor",
			text:"ShuBao Liu*; Ke-Yue Zhang; Taiping Yao; Mingwei Bi; Shouhong Ding; Jilin Li; Feiyue Huang; Lizhuang Ma",
		},
		{
			type:"paperTitle",
			text:"609 <b>Imitating Arbitrary Talking Style for Realistic Audio-Driven Talking Face Synthesis<b>",
		},
		{
			type:"paperAuthor",
			text:"Haozhe Wu*; Jia Jia; Haoyu Wang; Yishun Dou; Chao Duan; Qingshan Deng",
		},
		{
			type:"paperTitle",
			text:"618 <b>Pose-guided Inter-and Intra-part Relational Transformer for Occluded Person Re-Identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhongxing Ma; Yifan Zhao; Jia Li*",
		},
		{
			type:"paperTitle",
			text:"624 <b>VLAD-VSA: Cross-Domain Face Presentation Attack Detection with Vocabulary Separation and Adaptation<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiong Wang; Zhou Zhao*; Weike Jin; Xinyu Duan; Zhen Lei; Baoxing Huai; Yiling Wu; Xiaofei He",
		},
		{
			type:"paperTitle",
			text:"651 <b>End-to-End Video Object Detection with Spatial-Temporal Transformers<b>",
		},
		{
			type:"paperAuthor",
			text:"Lu He*; Qianyu Zhou; Xiangtai Li; Li Niu; Guangliang Cheng; Xiao Li; Wenxuan Liu; Yunhai Tong; Lizhuang Ma; Liqing Zhang",
		},
		{
			type:"paperTitle",
			text:"653 <b>Joint-teaching: Learning to Refine Knowledge for Resource-constrained Unsupervised Cross-modal Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Peng-Fei Zhang*; Jiasheng Duan; Zi Huang; Hongzhi Yin",
		},
		{
			type:"paperTitle",
			text:"665 <b>AggNet for Self-supervised Monocular Depth Estimation: Go An Aggressive Step Furthe<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhi Chen; Xiaoqing Ye; Liang Du; Wei Yang*; Liusheng Huang; Xiao Tan; Zhenbo Shi; Fumin Shen; Errui Ding",
		},
		{
			type:"paperTitle",
			text:"681 <b>Boosting Lightweight Single Image Super-resolution via Joint-distillation<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiaotong Luo*; Qiuyuan Liang; Ding Liu; Yanyun Qu",
		},
		{
			type:"paperTitle",
			text:"688 <b>Discriminator-free Generative Adversarial Attack<b>",
		},
		{
			type:"paperAuthor",
			text:"Shaohao Lu; Yuqiao Xian; Ke Yan; Yi Hu; Xing Sun; Xiaowei Guo; Feiyue Huang; Wei-Shi Zheng*",
		},
		{
			type:"paperTitle",
			text:"691 <b>Former-DFER: Dynamic Facial Expression Recognition Transformer<b>",
		},
		{
			type:"paperAuthor",
			text:"Zengqun Zhao; Qingshan Liu*",
		},
		{
			type:"paperTitle",
			text:"702 <b>Discovering Density-Preserving Latent Space Walks in GANs for Semantic Image Transformations<b>",
		},
		{
			type:"paperAuthor",
			text:"Guanyue Li; Yi Liu; Xiwen Wei; Yang Zhang; Si Wu*; Yong Xu; Hau San Wong",
		},
		{
			type:"paperTitle",
			text:"715 <b>MGH: Metadata Guided Hypergraph Modeling for Unsupervised Person Re-identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Yiming Wu; Xintian Wu; Xi Li*; Jian Tian",
		},
		{
			type:"paperTitle",
			text:"718 <b>Recovering the Unbiased Scene Graphs from the Biased Ones<b>",
		},
		{
			type:"paperAuthor",
			text:"Meng-Jiun Chiou*; Henghui Ding; Hanshu YAN; Changhu Wang; Roger Zimmermann; Jiashi Feng",
		},
		{
			type:"paperTitle",
			text:"719 <b>Cross-modal Consensus Network for Weakly Supervised Temporal Action Localization<b>",
		},
		{
			type:"paperAuthor",
			text:"Fa-Ting Hong; Jia-Chang Feng; Dan Xu; Ying Shan; Wei-Shi Zheng*",
		},
		{
			type:"paperTitle",
			text:"722 <b>Searching a Hierarchically Aggregated Fusion Architecture for Fast Multi-Modality Image Fusion<b>",
		},
		{
			type:"paperAuthor",
			text:"Risheng Liu*; Zhu Liu; Jinyuan Liu; Xin Fan",
		},
		{
			type:"paperTitle",
			text:"724 <b>SuperFront: From Low-resolution to High-resolution Frontal Face Synthesis<b>",
		},
		{
			type:"paperAuthor",
			text:"Yu Yin*; Joseph Robinson; Songyao Jiang; Yue Bai; Can Qin; Yun Fu",
		},
		{
			type:"paperTitle",
			text:"736 <b>Learning Segment Similarity and Alignment in Large-Scale Content Based Video Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Chen Jiang*; Kaiming Huang; Sifeng He; Xudong Yang; Wei Zhang; Xiaobo Zhang; Yuan Cheng; Lei Yang; Qing Wang; Furong Xu; Tan Pan; Wei Chu",
		},
		{
			type:"paperTitle",
			text:"770 <b>Cut-Thumbnail: A Novel Data Augmentation for Convolutional Neural Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Tianshu Xie; Xuan Cheng; Xiaomin Wang; Minghui Liu; Jiali Deng; Tao Zhou; Ming Liu*",
		},
		{
			type:"paperTitle",
			text:"793 <b>Diffusing the Liveness Cues for Face Anti-spoofing<b>",
		},
		{
			type:"paperAuthor",
			text:"Sheng Li; Xun Zhu; Guorui Feng; Xinpeng Zhang*; Zhenxing Qian",
		},
		{
			type:"paperTitle",
			text:"800 <b>Co-Transport for Class-Incremental Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Da-Wei Zhou*; Han-Jia Ye; De-Chuan Zhan",
		},
		{
			type:"paperTitle",
			text:"802 <b>Skeleton-Contrastive 3D Action Representation Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Fida Mohammad Thoker*; Hazel Doughty; Cees Snoek",
		},
		{
			type:"paperTitle",
			text:"806 <b>Fast-forwarding, Rewinding, and Path Exploration in Interactive Branched Video Streaming<b>",
		},
		{
			type:"paperAuthor",
			text:"Albin Vogel; Erik Kronberg; Niklas Carlsson*",
		},
		{
			type:"paperTitle",
			text:"819 <b>Multiview Detection with Shadow Transformer (and View-Coherent Data Augmentation)<b>",
		},
		{
			type:"paperAuthor",
			text:"Yunzhong Hou*; Liang Zheng",
		},
		{
			type:"paperTitle",
			text:"820 <b>Domain Generalization via Feature Variation Decorrelation<b>",
		},
		{
			type:"paperAuthor",
			text:"Chang Liu*; Lichen Wang; Kai Li; Yun Fu",
		},
		{
			type:"paperTitle",
			text:"829 <b>Occlusion-aware Bi-directional Guided Network for Light Field Salient Object Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Dong Jing*; Shuo Zhang; Runmin Cong; Youfang Lin",
		},
		{
			type:"paperTitle",
			text:"831 <b>One-Stage Visual Grounding via Semantic-Aware Feature Filter<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiabo Ye; Xin Lin*; Liang He; Dingbang Li; Qin Chen",
		},
		{
			type:"paperTitle",
			text:"840 <b>Few-Shot Multi-Agent Perception<b>",
		},
		{
			type:"paperAuthor",
			text:"Chenyou Fan*; Junjie Hu; Jianwei Huang",
		},
		{
			type:"paperTitle",
			text:"847 <b>SI3DP: Source Identification Challenges and Benchmark for Consumer-Level 3D Printer Forensics<b>",
		},
		{
			type:"paperAuthor",
			text:"Bo Seok Shim; You Seung Shin; Seong wook Park; Jong-Uk Hou*",
		},
		{
			type:"paperTitle",
			text:"850 <b>Exploring Sequence Feature Alignment for Domain Adaptive Detection Transformers<b>",
		},
		{
			type:"paperAuthor",
			text:"Wen Wang; Yang Cao*; Jing Zhang; Fengxiang He; Zheng-Jun Zha; Yonggang Wen; Dacheng Tao",
		},
		{
			type:"paperTitle",
			text:"853 <b>Towards Realistic Visual Dubbing with Heterogeneous Sources<b>",
		},
		{
			type:"paperAuthor",
			text:"Tianyi Xie*; Liucheng Liao; Cheng Bi; Benlai Tang; Xiang Yin; Jianfei Yang; Mingjie Wang; Jiali Yao; Yang Zhang; Zejun Ma",
		},
		{
			type:"paperTitle",
			text:"859 <b>Deep Self-Supervised t-SNE for Multi-modal Subspace Clustering<b>",
		},
		{
			type:"paperAuthor",
			text:"Qianqian Wang*; Wei Xia; Zhiqiang Tao; Quanxue Gao; Xiaochun Cao",
		},
		{
			type:"paperTitle",
			text:"890 <b>Multimodal Video Summarization via Time-Aware Transformers<b>",
		},
		{
			type:"paperAuthor",
			text:"Xindi Shang*; Zehuan Yuan; Anran Wang; Changhu Wang",
		},
		{
			type:"paperTitle",
			text:"892 <b>State-aware Video Procedural Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Taichi Nishimura*; Atsushi Hashimoto; Yoshitaka Ushiku; Hirotaka Kameko; Shinsuke Mori",
		},
		{
			type:"paperTitle",
			text:"896 <b>AMSS-Net: Audio Manipulation on User-Specified Sources with Textual Queries<b>",
		},
		{
			type:"paperAuthor",
			text:"Woosung Choi*; Minseok Kim; Marco Martínez Ramrez; Jaehwa Chung; Soonyoung Jung",
		},
		{
			type:"paperTitle",
			text:"904 <b>Fully Functional Image Manipulation Using Scene Graphs in A Bounding-Box Free Way<b>",
		},
		{
			type:"paperAuthor",
			text:"Sitong Su*; Lianli Gao; Junchen Zhu; Jie Shao; Jingkuan Song",
		},
		{
			type:"paperTitle",
			text:"908 <b>Multi-Level Counterfactual Contrast for Visual Commonsense Reasoning<b>",
		},
		{
			type:"paperAuthor",
			text:"Xi Zhang*; Feifei Zhang; Changsheng Xu",
		},
		{
			type:"paperTitle",
			text:"916 <b>Data-Free Ensemble Knowledge Distillation for Privacy-conscious Multimedia Model Compression<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhiwei Hao*; Yong Luo; Han Hu; Jianping An; Yonggang Wen",
		},
		{
			type:"paperTitle",
			text:"926 <b>SM-SGE: A Self-Supervised Multi-Scale Skeleton Graph Encoding Framework for Person Re-Identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Haocong Rao*; Xiping Hu; Jun Cheng; Bin Hu",
		},
		{
			type:"paperTitle",
			text:"940 <b>Video Transformer for Deepfake Detection with Incremental Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Sohail Ahmed Khan; Hang Dai*",
		},
		{
			type:"paperTitle",
			text:"950 <b>Chinese Character Inpainting with Contextual Semantic Constraints<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiahao Wang; Gang Pan*; Di Sun; Jiawan Zhang",
		},
		{
			type:"paperTitle",
			text:"960 <b>Curriculum-Based Meta-learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Ji Zhang; Jingkuan Song*; Yazhou Yao; Lianli Gao",
		},
		{
			type:"paperTitle",
			text:"967 <b>Ego-Deliver: A Large-Scale Dataset for Egocentric Video Analysis<b>",
		},
		{
			type:"paperAuthor",
			text:"Haonan Qiu; Pan He; Shuchun Liu; weiyuan shao; Feiyun Zhang; Jiajun Wang; Liang He; Feng Wang*",
		},
		{
			type:"paperTitle",
			text:"975 <b>Adversarial Pixel Masking: A Defense against Physical Attacks for Pre-trained Object Detectors<b>",
		},
		{
			type:"paperAuthor",
			text:"Bing-Han Chiang; Chi-Shen Chan; Shan-Hung Wu*",
		},
		{
			type:"paperTitle",
			text:"987 <b>Knowledge-Supervised Learning: Knowledge Consensus Constraints for Person Re-Identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Li Wang; Baoyu Fan*; Zhenhua Guo; Yaqian Zhao; Runze Zhang; Rengang Li; Weifeng Gong; Endong Wang",
		},
		{
			type:"paperTitle",
			text:"991 <b>View-normalized Skeleton Generation for Action Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Qingzhe Pan; Zhifu Zhao; Xuemei Xie*; Jianan Li; Yuhan Cao; Guangming Shi",
		},
		{
			type:"paperTitle",
			text:"995 <b>Learning Hierarchical Embedding for Video Instance Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Zheyun Qin; Xiankai Lu*; Xiushan Nie; Xiantong Zhen; Yilong Yin",
		},
		{
			type:"paperTitle",
			text:"1013 <b>Text as Neural Operator:Image Manipulation by Text Instruction<b>",
		},
		{
			type:"paperAuthor",
			text:"Tianhao Zhang*; Hung-Yu Tseng; Lu Jiang; Weilong Yang; Honglak Lee; Irfan Essa",
		},
		{
			type:"paperTitle",
			text:"1017 <b>DSANet: Dynamic Segment Aggregation Network for Video-Level Representation Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Wenhao Wu*; Yuxiang Zhao; Yanwu Xu; Xiao Tan; Dongliang He; Zhikang Zou; Jin Ye; Yingying Li; Mingde Yao; Dong Zichao; Yifeng Shi",
		},
		{
			type:"paperTitle",
			text:"1029 <b>Structured Text Understanding with Multi-Modal Transformers<b>",
		},
		{
			type:"paperAuthor",
			text:"Yulin Li*; Yuxi Qian; Yuechen Yu; Xiameng Qin; Chengquan Zhang; Yan Liu; Kun Yao; Junyu Han; Jingtuo Liu; Errui Ding",
		},
		{
			type:"paperTitle",
			text:"1034 <b>Local Graph Convolutional Networks for Cross-Modal Hashing<b>",
		},
		{
			type:"paperAuthor",
			text:"Yudong Chen*; Sen Wang; Jianglin Lu; Zhi Chen; Zheng Zhang; Zi Huang",
		},
		{
			type:"paperTitle",
			text:"1040 <b>Metric Learning for Anti-Compression Facial Forgery Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Cao Shenhao; Qin Zou*; Xiuqing Mao; Dengpan Ye; Zhongyuan Wang",
		},
		{
			type:"paperTitle",
			text:"1042 <b>ASFM-Net: Asymmetrical Siamese Feature Matching Network for Point Completion<b>",
		},
		{
			type:"paperAuthor",
			text:"Yaqi Xia*; Yan Xia; Wei Li; Song Rui; Kailang Cao; Uwe M Stilla",
		},
		{
			type:"paperTitle",
			text:"1049 <b>Capsule-based Object Tracking with Natural Language Specification<b>",
		},
		{
			type:"paperAuthor",
			text:"Ding Ma*; XiangQian Wu",
		},
		{
			type:"paperTitle",
			text:"1084 <b>Faster-PPN: Towards Real-Time Semantic Segmentation with Dual Mutual Learning for Ultra-High Resolution Images<b>",
		},
		{
			type:"paperAuthor",
			text:"Bicheng Dai; Kaisheng Wu; Tong Wu; Kai Li; Yanyun Qu*; Yuan Xie; Yun Fu",
		},
		{
			type:"paperTitle",
			text:"1104 <b>Distributed Attention for Grounded Image Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Nenglun Chen*; Xingjia Pan; Runnan Chen; Lei Yang; Zhiwen Lin; Yuqiang Ren; Haolei Yuan; Xiaowei Guo; Feiyue Huang; Wenping Wang",
		},
		{
			type:"paperTitle",
			text:"1112 <b>Multi-initialization Optimization Network for Accurate 3D Human Pose and Shape Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhiwei Liu*; Xiangyu Zhu; Lu Yang; Xiang Yan; Ming Tang; Zhen Lei; Guibo Zhu; Xuetao Feng; Yan Wang; Jinqiao Wang",
		},
		{
			type:"paperTitle",
			text:"1121 <b>Feedback Network for Mutually Boosted Stereo Image Super-Resolution and Disparity Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Qinyan Dai; Juncheng Li; Qiaosi Yi; Faming Fang*; Guixu Zhang",
		},
		{
			type:"paperTitle",
			text:"1124 <b>Merging Multiple Template Matching Predictions in Intra Coding with Attentive Convolutional Neural Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Qijun Wang*; GuoDong Zheng",
		},
		{
			type:"paperTitle",
			text:"1136 <b>Camera-Agnostic Person Re-Identification via Adversarial Disentangling Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Hao Ni*; Jingkuan Song; Xiaosu Zhu; Feng Zheng; Lianli Gao",
		},
		{
			type:"paperTitle",
			text:"1138 <b>Learning to Understand Traffic Signs<b>",
		},
		{
			type:"paperAuthor",
			text:"Yunfei Guo*; Wei Feng; Fei yin; Tao Xue; Shuqi Mei; Cheng-Lin Liu",
		},
		{
			type:"paperTitle",
			text:"1142 <b>R-GAN: Exploring Human-like Way for Reasonable Text-to-Image Synthesis via Generative Adversarial Networks<b>",
		},
		{
			type:"paperAuthor",
			text:"Yanyuan Qiao*; Qi Chen; Chaorui Deng; Ning Ding; Yuankai Qi; Mingkui Tan; Xincheng Ren; Qi Wu",
		},
		{
			type:"paperTitle",
			text:"1168 <b>Cross-modality Discrepant Interaction Network for RGB-D Salient Object Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Chen Zhang; Runmin Cong*; Qinwei Lin; Lin Ma; Feng Li; Yao Zhao; Sam Kwong",
		},
		{
			type:"paperTitle",
			text:"1187 <b>Deconfounded and Explainable Interactive Vision-Language Retrieval of Complex Scenes<b>",
		},
		{
			type:"paperAuthor",
			text:"Junda Wu; Tong Yu; Shuai Li*",
		},
		{
			type:"paperTitle",
			text:"1197 <b>Long Short-term Convolutional Transformer for No-Reference Video Quality Assessment<b>",
		},
		{
			type:"paperAuthor",
			text:"Junyong You*",
		},
		{
			type:"paperTitle",
			text:"1205 <b>Automatic Channel Pruning with Hyper-parameter Search and Dynamic Masking<b>",
		},
		{
			type:"paperAuthor",
			text:"Baopu Li*; Yanwen Fan; Zhihong Pan; Yuchen Bian; Gang Zhang",
		},
		{
			type:"paperTitle",
			text:"1215 <b>SVHAN: Sequential View Based Hierarchical Attention Network for 3D Shape Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Yue Zhao; Weizhi Nie*; An-An Liu; Zan Gao; Yu-ting Su",
		},
		{
			type:"paperTitle",
			text:"1218 <b>ASFD: Automatic and Scalable Face Detector<b>",
		},
		{
			type:"paperAuthor",
			text:"Jian Li*; Bin Zhang; Yabiao Wang; Ying Tai; Zhenyu Zhang; Chengjie Wang; Jilin Li; Xiaoming Huang; Yili Xia",
		},
		{
			type:"paperTitle",
			text:"1223 <b>BridgeNet: A Joint Learning Network of Depth Map Super-Resolution and Monocular Depth Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Qi Tang; Runmin Cong*; Ronghui Sheng; Lingzhi He; Dan Zhang; Yao Zhao; Sam Kwong",
		},
		{
			type:"paperTitle",
			text:"1224 <b>LSTC: Boosting Atomic Action Detection with Long-Short-Term Context<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuxi Li*; Boshen Zhang; Jian Li; Yabiao Wang; Weiyao Lin; Chengjie Wang; Jilin Li; Feiyue Huang",
		},
		{
			type:"paperTitle",
			text:"1229 <b>UACANet: Uncertainty Augmented Context Attention for Polyp Semgnetaion<b>",
		},
		{
			type:"paperAuthor",
			text:"Taehun Kim*; Hyemin Lee; Daijin Kim",
		},
		{
			type:"paperTitle",
			text:"1234 <b>Weight Evolution: Improving Deep Neural Networks Training through Evolving Inferior Weight Values<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhenquan Lin; Kailing Guo*; Xiaofen Xing; Xiangmin Xu",
		},
		{
			type:"paperTitle",
			text:"1241 <b>Coarse to Fine: Domain Adaptive Crowd Counting via Adversarial Scoring Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhikang Zou*; Xiaoye Qu; Pan Zhou; Shuangjie Xu; Xiaoqing Ye; Wenhao Wu; Jin Ye",
		},
		{
			type:"paperTitle",
			text:"1259 <b>Towards Adversarial Patch Analysis and Certified Defense against Crowd Counting<b>",
		},
		{
			type:"paperAuthor",
			text:"Qiming Wu*; Zhikang Zou; Pan Zhou; Xiaoqing Ye; Binghui Wang; Ang Li",
		},
		{
			type:"paperTitle",
			text:"1265 <b>Conceptual and Syntactical Cross-modal Alignment with Cross-level Consistency for Image-Text Matching<b>",
		},
		{
			type:"paperAuthor",
			text:"Pengpeng Zeng; Lianli Gao; Xinyu Lyu; Shuaiqi Jing; Jingkuan Song*",
		},
		{
			type:"paperTitle",
			text:"1270 <b>SSPU-Net: Self-Supervised Point Cloud Upsampling via Differentiable Rendering<b>",
		},
		{
			type:"paperAuthor",
			text:"YiFan Zhao; Le Hui; Jin Xie*",
		},
		{
			type:"paperTitle",
			text:"1275 <b>VmAP: A Fair Metric for Video Object Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Anupam Sobti*; Vaibhav Mavi; M Balakrishnan; Chetan Arora",
		},
		{
			type:"paperTitle",
			text:"1277 <b>Source Data-free Unsupervised Domain Adaptation for Semantic Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Mucong Ye; Jing Zhang*; Jinpeng Ouyang; Yuan Ding",
		},
		{
			type:"paperTitle",
			text:"1280 <b>Yes, ¡°Attention Is All You Need¡±, for Exemplar based Colorization<b>",
		},
		{
			type:"paperAuthor",
			text:"Wang Yin*; Peng Lu; Zhaoran Zhao; Xujun Peng",
		},
		{
			type:"paperTitle",
			text:"1283 <b>Heuristic Depth Estimation via Recurrent Prediction with Confidence-Aware Loss<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiehua Zhang*; Liang Li; Chenggang Yan; Yaoqi Sun; Tao Shen; Jiyong Zhang; Zhan Wang",
		},
		{
			type:"paperTitle",
			text:"1291 <b>Unsupervised Cross-Modal Distillation for Thermal Infrared Tracking<b>",
		},
		{
			type:"paperAuthor",
			text:"Jingxian Sun; Lichao Zhang; Yufei Zha*; Abel Gonzalez-Garcia; Peng Zhang; Wei Huang; Yanning Zhang",
		},
		{
			type:"paperTitle",
			text:"1302 <b>ABPNet: Adaptive Background Modeling for Generalized Few Shot Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Kaiqi Dong*; Wei Yang; Zhenbo Xu; Liusheng Huang; Zhidong Yu",
		},
		{
			type:"paperTitle",
			text:"1319 <b>Towards Reasoning Ability in Scene Text Visual Question Answering<b>",
		},
		{
			type:"paperAuthor",
			text:"Qingqing Wang*; Liqiang Xiao; Yue Lu; Yaohui Jin; Hao He",
		},
		{
			type:"paperTitle",
			text:"1322 <b>Multi-caption Text-to-Face Synthesis: Dataset and Algorithm<b>",
		},
		{
			type:"paperAuthor",
			text:"Jianxin Sun*; Qi Li; Weining Wang; Jian Zhao; Zhenan Sun",
		},
		{
			type:"paperTitle",
			text:"1323 <b>Multimodal Compatibility Modeling via Exploring the Consistent and Complementary Correlations<b>",
		},
		{
			type:"paperAuthor",
			text:"Weili Guan*; Haokun Wen; Xuemeng Song; Chung-Hsing Yeh; Xiaojun Chang; Liqiang Nie",
		},
		{
			type:"paperTitle",
			text:"1325 <b>CDD: Multi-view Subspace Clustering via Cross-view Diversity Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Shudong Huang; Ivor Tsang; Zenglin Xu ; Jiancheng Lv; Quan-Hui Liu*",
		},
		{
			type:"paperTitle",
			text:"1330 <b>Learning Spatio-temporal Representation by Channel Aliasing Video Perception<b>",
		},
		{
			type:"paperAuthor",
			text:"YiQi Lin; Jinpeng Wang; Manlin Zhang; Andy J Ma*",
		},
		{
			type:"paperTitle",
			text:"1334 <b>Efficient Sparse Attacks on Videos using Reinforcement Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Huanqian Yan; Xingxing Wei*",
		},
		{
			type:"paperTitle",
			text:"1336 <b>AdvHash: Set-to-set Targeted Attack on Deep Hashing with One Single Adversarial Patch<b>",
		},
		{
			type:"paperAuthor",
			text:"Shengshan Hu*; Yechao Zhang; Xiaogeng Liu; Leo Yu Zhang; Minghui Li; Hai Jin",
		},
		{
			type:"paperTitle",
			text:"1343 <b>TransRefer3D: Entity-and-Relation Aware Transformer for Fine-Grained 3D Visual Grounding<b>",
		},
		{
			type:"paperAuthor",
			text:"Dailan He*; Yusheng Zhao; Junyu Luo; Tianrui Hui; Shaofei Huang; Aixi Zhang; Si Liu",
		},
		{
			type:"paperTitle",
			text:"1344 <b>Single Image 3D Object Estimation with Primitive Graph Networks<b>",
		},
		{
			type:"paperAuthor",
			text:"Qian He*; Desen Zhou; Bo Wan; Xuming He",
		},
		{
			type:"paperTitle",
			text:"1346 <b>Boosting Mobile CNN Inference through Semantic Memory<b>",
		},
		{
			type:"paperAuthor",
			text:"Yun Li*; Chen Zhang; Shihao Han; Li Lyna Zhang; Baoqun Yin; Yunxin Liu; Mengwei Xu",
		},
		{
			type:"paperTitle",
			text:"1358 <b>Knowing When to Quit: Selective Cascaded Regression with Patch Attention for Real-Time Face Alignment<b>",
		},
		{
			type:"paperAuthor",
			text:"Gil Shapira*; Noga Levy; Ishay Goldin; Roy J Jevnisek",
		},
		{
			type:"paperTitle",
			text:"1371 <b>End-to-end Boundary Exploration for Weakly-supervised Semantic Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Jianjun Chen*; Shancheng Fang; Hongtao Xie; Zheng-Jun Zha; Yue Hu; Jianlong Tan",
		},
		{
			type:"paperTitle",
			text:"1374 <b>SFE-Net: EEG-based emotion recognition with symmetrical spatial feature extraction<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiangwen Deng; Junlin Zhu; Shangming Yang*",
		},
		{
			type:"paperTitle",
			text:"1379 <b>Bridging the Gap between Low-Light Scenes: Bilevel Learning for Fast Adaptation<b>",
		},
		{
			type:"paperAuthor",
			text:"Dian Jin; Long Ma; Risheng Liu*; Xin Fan",
		},
		{
			type:"paperTitle",
			text:"1391 <b>Handling Difficult Labels for Multi-label Image Classification via Uncertainty Distillation<b>",
		},
		{
			type:"paperAuthor",
			text:"Liangchen Song*; Jialian Wu; Ming Yang; Qian Zhang; Yuan Li; Junsong Yuan",
		},
		{
			type:"paperTitle",
			text:"1393 <b>Perception-Oriented Stereo Image Super-Resolution<b>",
		},
		{
			type:"paperAuthor",
			text:"Chenxi Ma; Bo Yan*; Weimin Tan; Xuhao Jiang",
		},
		{
			type:"paperTitle",
			text:"1403 <b>ReLLIE: Deep Reinforcement Learning for Customized Low-Light Image Enhancement<b>",
		},
		{
			type:"paperAuthor",
			text:"Rongkai Zhang*; Lanqing Guo; Siyu Huang; Bihan Wen",
		},
		{
			type:"paperTitle",
			text:"1408 <b>Intrinsic Temporal Regularization for High-resolution Human Video Synthesis<b>",
		},
		{
			type:"paperAuthor",
			text:"Lingbo Yang*; Zhanning Gao; Siwei Ma; Wen Gao",
		},
		{
			type:"paperTitle",
			text:"1420 <b>A2W: Context-Aware Recommendation System for Mobile Augmented Reality Web Browser<b>",
		},
		{
			type:"paperAuthor",
			text:"Kit Yung Lam*; Lik Hang Lee; Pan Hui",
		},
		{
			type:"paperTitle",
			text:"1439 <b>Cross-modal Self-Supervised Learning for Lip Reading: When Contrastive Learning meets Adversarial Training<b>",
		},
		{
			type:"paperAuthor",
			text:"Changchong Sheng; Matti Pietikäinen; Qi Tian; Li Liu*",
		},
		{
			type:"paperTitle",
			text:"1450 <b>OsGG-Net: One-step Graph Generation Network for Unbiased Head Pose Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Shentong Mo; Miao Xin*",
		},
		{
			type:"paperTitle",
			text:"1452 <b>Multi-Modal Multi-Instance Learning for Retinal Disease Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Xirong Li*; Yang Zhou; Jie Wang; Hailan Lin; Jianchun Zhao; Dayong Ding; Weihong Yu; Youxin Chen",
		},
		{
			type:"paperTitle",
			text:"1456 <b>Locally Adaptive Structure and Texture Similarity for Image Quality Assessment<b>",
		},
		{
			type:"paperAuthor",
			text:"Keyan Ding*; Yi Liu; Xueyi Zou; Shiqi Wang; Kede Ma",
		},
		{
			type:"paperTitle",
			text:"1458 <b>CALLip: Lipreading using Contrastive and Attribute Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Yiyang Huang; Xuefeng Liang*; Chaowei Fang",
		},
		{
			type:"paperTitle",
			text:"1466 <b>Cross-Modal Recipe Embeddings by Disentangling Recipe Contents and Dish Styles<b>",
		},
		{
			type:"paperAuthor",
			text:"Yu Sugiyama; Keiji Yanai*",
		},
		{
			type:"paperTitle",
			text:"1473 <b>TDI TextSpotter: Taking Data Imbalance into Account in Scene Text Spotting<b>",
		},
		{
			type:"paperAuthor",
			text:"Yu Zhou*; Hongtao Xie; Shancheng Fang; Jing Wang; Zheng-Jun Zha; Yongdong Zhang",
		},
		{
			type:"paperTitle",
			text:"1477 <b>Position-Augmented Transformers with Entity-Aligned Mesh for TextVQA<b>",
		},
		{
			type:"paperAuthor",
			text:"Xuanyu Zhang*; Ching Yang",
		},
		{
			type:"paperTitle",
			text:"1479 <b>Learning Contextual Transformer Network for Image Inpainting<b>",
		},
		{
			type:"paperAuthor",
			text:"Ye Deng*; Siqi Hui; Sanping Zhou; Deyu Meng; Jinjun Wang",
		},
		{
			type:"paperTitle",
			text:"1482 <b>Milliseconds Color Stippling<b>",
		},
		{
			type:"paperAuthor",
			text:"Lei Ma; Jian Shi*; Yanyun Chen",
		},
		{
			type:"paperTitle",
			text:"1485 <b>AFD-Net: Adaptive Fully-Dual Network for Few-Shot Object Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Longyao Liu; Bo Ma*; Yulin Zhang; Xin Yi; Haozhi Li",
		},
		{
			type:"paperTitle",
			text:"1487 <b>Missing Data Imputation for Solar Yield Prediction using Temporal Multi-Modal Variational Auto-Encoder<b>",
		},
		{
			type:"paperAuthor",
			text:"Meng Shen*; Huaizheng Zhang; Yixin Cao; Fan Yang; Yonggang Wen",
		},
		{
			type:"paperTitle",
			text:"1488 <b>Understanding Chinese Video and Language via Contrastive Multimodal Pre-Training<b>",
		},
		{
			type:"paperAuthor",
			text:"Chenyi Lei*; Shixian Luo; Yong Liu; Wanggui He; Jiamang Wang; Guoxin Wang; Haihong Tang; Chunyan Miao; Houqiang Li",
		},
		{
			type:"paperTitle",
			text:"1489 <b>DehazeFlow: Multi-scale Conditional Flow Network for Single Image Dehazing<b>",
		},
		{
			type:"paperAuthor",
			text:"HongYu Li*; Jia Li; Dong Zhao; Long Xu",
		},
		{
			type:"paperTitle",
			text:"1493 <b>GCM-Net: Towards Effective Global Context Modeling for Image Inpainting<b>",
		},
		{
			type:"paperAuthor",
			text:"Zheng Huan; Zhao Zhang*; Yang Wang; Zheng Zhang; Mingliang Xu; Yi Yang; Meng Wang",
		},
		{
			type:"paperTitle",
			text:"1496 <b>Embracing the Dark Knowledge: Domain Generalization Using Regularized Knowledge Distillation<b>",
		},
		{
			type:"paperAuthor",
			text:"Yufei Wang*; Haoliang Li; Lap-Pui Chau; Alex Kot",
		},
		{
			type:"paperTitle",
			text:"1499 <b>Cluster and Scatter: A Multi-grained Active Semi-supervised Learning Framework for Scalable Person Re-identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Bingyu Hu*; Zheng-Jun Zha; Jiawei Liu; Xierong Zhu; Hongtao Xie",
		},
		{
			type:"paperTitle",
			text:"1516 <b>Dual Graph Convolutional Networks with Transformer and Curriculum Learning for Image Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Xinzhi Dong; Chengjiang Long; Wenju Xu; Chunxia Xiao*",
		},
		{
			type:"paperTitle",
			text:"1528 <b>Build Your Own Bundle - A Neural Combinatorial Optimization Method<b>",
		},
		{
			type:"paperAuthor",
			text:"Qilin Deng*; Kai Wang; Minghao Zhao; Runze Wu; Yu Ding; Zhene Zou; Yue Shang; Jianrong Tao; Changjie Fan",
		},
		{
			type:"paperTitle",
			text:"1534 <b>Unsupervised Image Deraining: Optimization Model Driven Deep CNN<b>",
		},
		{
			type:"paperAuthor",
			text:"Changfeng Yu; Yi Chang; Yi Li; Xi-Le Zhao; Luxin Yan*",
		},
		{
			type:"paperTitle",
			text:"1539 <b>An EM Framework for Online Class Incremental Semantic Segmentation with Dynamic Sampling<b>",
		},
		{
			type:"paperAuthor",
			text:"Shipeng Yan*; Jiale Zhou; Jiangwei Xie; Songyang Zhang; Xuming He",
		},
		{
			type:"paperTitle",
			text:"1548 <b>I2V-GAN: Unpaired Infrared-to-Visible Video Translation<b>",
		},
		{
			type:"paperAuthor",
			text:"Shuang Li*; Bingfeng Han; Zhenjie Yu; Chi Harold Liu; Kai Chen; Shuigen Wang",
		},
		{
			type:"paperTitle",
			text:"1549 <b>Implicit feedbacks are not always favorable: Iterative Relabeled One-Class Collaborative Filtering against Noisy Interactions<b>",
		},
		{
			type:"paperAuthor",
			text:"Zitai Wang*; Qianqian Xu; Zhiyong Yang; Xiaochun Cao; Qingming Huang",
		},
		{
			type:"paperTitle",
			text:"1555 <b>InsPose: Instance-Aware Networks for Single-Stage Multi-Person Pose Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Dahu Shi*; Xing Wei; Xiaodong Yu; Wenming Tan; Ye Ren; Shiliang Pu",
		},
		{
			type:"paperTitle",
			text:"1566 <b>Implicit Feature Refinement for Instance Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Lufan Ma*; Tiancai Wang; Bin Dong; Jiangpeng Yan; Xiu Li; Xiangyu Zhang",
		},
		{
			type:"paperTitle",
			text:"1598 <b>Question-controlled Text-aware Image Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Anwen Hu*; Shizhe Chen; Qin Jin",
		},
		{
			type:"paperTitle",
			text:"1599 <b>Style-Aware Image Recommendation for Social Media Marketing<b>",
		},
		{
			type:"paperAuthor",
			text:"Yiwei Zhang*; Toshihiko Yamasaki",
		},
		{
			type:"paperTitle",
			text:"1609 <b>WePerson: Learning a Generalized Re-identification Model from All-weather Virtual Data<b>",
		},
		{
			type:"paperAuthor",
			text:"He Li; Mang Ye*; Bo Du",
		},
		{
			type:"paperTitle",
			text:"1622 <b>Polar Ray: A Single-stage Angle-free Detector for Oriented Object Detection in Aerial Images<b>",
		},
		{
			type:"paperAuthor",
			text:"Shuai Liu; Lu Zhang; Shuai Hao; Huchuan Lu*; You He",
		},
		{
			type:"paperTitle",
			text:"1623 <b>Self-Contrastive Learning with Hard Negative Sampling for Self-supervised Point Cloud Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Bi'an Du; Xiang Gao; Wei Hu*; Xin Li",
		},
		{
			type:"paperTitle",
			text:"1626 <b>Generally Boosting Few-Shot Learning with HandCrafted Features<b>",
		},
		{
			type:"paperAuthor",
			text:"Yi Zhang; Sheng Huang*; Fengtao Zhou",
		},
		{
			type:"paperTitle",
			text:"1640 <b>ROECS: A Robust Semi-direct Pipeline Towards Online Extrinsics Correction of the Surround-view System<b>",
		},
		{
			type:"paperAuthor",
			text:"Tianjun Zhang; Nlong Zhao; Ying Shen; Xuan Shao; Lin Zhang*; Yicong Zhou",
		},
		{
			type:"paperTitle",
			text:"1643 <b>Pseudo Graph Convolutional Network for Vehicle ReID<b>",
		},
		{
			type:"paperAuthor",
			text:"Wen Qian*; Zhiqun He; Silong Peng; Chen Chen; Wei Wu",
		},
		{
			type:"paperTitle",
			text:"1658 <b>Towards Fast and High-Quality Sign Language Production<b>",
		},
		{
			type:"paperAuthor",
			text:"Wencan Huang*; Wenwen Pan; Zhou Zhao; Qi Tian",
		},
		{
			type:"paperTitle",
			text:"1660 <b>Effective De-identification Generative Adversarial Network for Face Anonymization<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhenzhong Kuang*; Huigui Liu; Jun Yu; Aikui Tian; Lei Wang; Jianping Fan; Noboru Babaguchi",
		},
		{
			type:"paperTitle",
			text:"1666 <b>Cross-modal Retrieval and Synthesis (X-MRS): Closing the modality gap in shared subspace learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Ricardo Guerrero *; Hai X Pham; Vladimir Pavlovic",
		},
		{
			type:"paperTitle",
			text:"1669 <b>When Face Completion Meets Irregular Holes: An Attributes Guided Deep Inpainting Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Jie Xiao; Dandan Zhan; Haoran Qi; Zhi Jin*",
		},
		{
			type:"paperTitle",
			text:"1691 <b>Non-Linear Fusion for Self-Paced Multi-View Clustering<b>",
		},
		{
			type:"paperAuthor",
			text:"Zongmo Huang; Yazhou Ren*; Xiaorong Pu; Lifang He",
		},
		{
			type:"paperTitle",
			text:"1694 <b>Counterfactual Debiasing Inference for Compositional Action Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Pengzhan Sun*; Bo Wu; Xunsong Li; Wen Li; Lixin Duan; Chuang Gan",
		},
		{
			type:"paperTitle",
			text:"1696 <b>STST: Spatial-Temporal Specialized Transformer for Skeleton-based Action Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuhan Zhang*; Bo Wu; Wen Li; Lixin Duan; Chuang Gan",
		},
		{
			type:"paperTitle",
			text:"1700 <b>Exploring Gradient Flow Based Saliency for DNN Model Compression<b>",
		},
		{
			type:"paperAuthor",
			text:"Xinyu Liu; Baopu Li*; Zhen Chen; Yixuan Yuan",
		},
		{
			type:"paperTitle",
			text:"1701 <b>An adaptive iterative inpainting method with more information exploration<b>",
		},
		{
			type:"paperAuthor",
			text:"Shengjie Chen*; Zhenhua Guo; Bo Yuan",
		},
		{
			type:"paperTitle",
			text:"1702 <b>Assisting News Media Editors with Cohesive Visual Storylines<b>",
		},
		{
			type:"paperAuthor",
			text:"Gonalo Marcelino; David Semedo; Andre Mourao; Saverio Blasi; Joao Magalhaes*; Marta Mrak",
		},
		{
			type:"paperTitle",
			text:"1713 <b>MM-Flow: Multi-modal Flow Network for Point Cloud Completion<b>",
		},
		{
			type:"paperAuthor",
			text:"Yiqiang Zhao; Yiyao Zhou*; Rui Chen; Bin Hu; Arxidin Akbar",
		},
		{
			type:"paperTitle",
			text:"1722 <b>Long-tailed Distribution Adaptation<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhiliang Peng; Wei Huang; Zonghao Guo; Xiaosong Zhang; Jianbin Jiao; Qixiang Ye*",
		},
		{
			type:"paperTitle",
			text:"1727 <b>Lesion-Inspired Denoising Network: Connecting Medical Image Denoising and Lesion Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Kecheng Chen*; Kun Long; Yazhou Ren; Jiayu Sun; Xiaorong Pu",
		},
		{
			type:"paperTitle",
			text:"1736 <b>Domain Adaptive Semantic Segmentation without Source Data<b>",
		},
		{
			type:"paperAuthor",
			text:"Fuming You; Jingjing Li*; Lei Zhu; Zhi Chen; Zi Huang",
		},
		{
			type:"paperTitle",
			text:"1738 <b>Cross-modal Joint Prediction and Alignment for Composed Query Image Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuchen Yang*; Min Wang; Wengang Zhou; Houqiang Li",
		},
		{
			type:"paperTitle",
			text:"1743 <b>JDMAN: Joint Discriminative and Mutual Adaptation Networks for Cross-Domain Facial Expression Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Yingjian Li; Yingnan Gao; Bingzhi Chen; Zheng Zhang; Lei Zhu; Guangming Lu*",
		},
		{
			type:"paperTitle",
			text:"1746 <b>Improving Weakly Supervised Object Localization via Causal Intervention<b>",
		},
		{
			type:"paperAuthor",
			text:"Feifei Shao; Yawei Luo*; Li Zhang; Lu Ye; Siliang Tang; Yi Yang; Jun Xiao",
		},
		{
			type:"paperTitle",
			text:"1754 <b>Imbalanced Source-free Domain Adaptation<b>",
		},
		{
			type:"paperAuthor",
			text:"Li Xinhao; Jingjing Li*; Lei Zhu; Guoqing Wang; Zi Huang",
		},
		{
			type:"paperTitle",
			text:"1757 <b>Learning Transferrable and Interpretable Representations for Domain Generalization<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhekai Du; Jingjing Li*; Ke Lu; Lei Zhu; Zi Huang",
		},
		{
			type:"paperTitle",
			text:"1768 <b>WAS-VTON: Warping Architecture Search for Virtual Try-on Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhenyu Xie; Xujie Zhang; Fuwei Zhao; Haoye Dong; Michael C. Kampffmeyer; Haonan Yan; Xiaodan Liang*",
		},
		{
			type:"paperTitle",
			text:"1777 <b>DFR-Net: A Novel Multi-Task Learning Network for Real-Time Multi-Instrument Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Yan-Jie Zhou*; Shiqi Liu; Xiaoliang Xie; Zengguang Hou",
		},
		{
			type:"paperTitle",
			text:"1779 <b>From Superficial to Deep: Language Bias driven Curriculum Learning for Visual Question Answering<b>",
		},
		{
			type:"paperAuthor",
			text:"Mingrui Lao; Yanming Guo*; Yu Liu; Wei Chen; Nan Pu; Michael S Lew",
		},
		{
			type:"paperTitle",
			text:"1780 <b>Pairwise Emotional Relationship Recognition in Drama Videos: Dataset and Benchmark<b>",
		},
		{
			type:"paperAuthor",
			text:"Xun Gao; Yin Zhao*; Jie Zhang; LongJun Cai",
		},
		{
			type:"paperTitle",
			text:"1789 <b>Block Popularity Prediction for Multimedia Storage Systems Using Spacial-Temporal-Sequential Neural Networks<b>",
		},
		{
			type:"paperAuthor",
			text:"Yingying Cheng*; Fan Zhang; Gang Hu; Yiwen Wang; Hanhui Yang; Gong Zhang; Zhuo Cheng",
		},
		{
			type:"paperTitle",
			text:"1790 <b>Transferrable Contrastive Learning for Visual Domain Adaptation<b>",
		},
		{
			type:"paperAuthor",
			text:"Yang Chen; Yingwei Pan*; Yu Wang; Ting Yao; Xinmei Tian; Tao Mei",
		},
		{
			type:"paperTitle",
			text:"1799 <b>Weighted Gaussian Loss based Hamming Hashing<b>",
		},
		{
			type:"paperAuthor",
			text:"Rong-Cheng Tu*; Xian-Ling Mao; Cihang Kong; Zihang Shao; Ze-Lin Li; Wei Wei; Heyan Huang",
		},
		{
			type:"paperTitle",
			text:"1800 <b>Domain-Aware SE Network for Sketch-based Image Retrieval with Multiplicative Euclidean Margin Softmax<b>",
		},
		{
			type:"paperAuthor",
			text:"Peng Lu; Gao Huang; Hangyu Lin; Wenming Yang*; Guodong Guo; Yanwei Fu",
		},
		{
			type:"paperTitle",
			text:"1803 <b>FTAFace: Context-enhanced Face Detector with Fine-grained Task Attention<b>",
		},
		{
			type:"paperAuthor",
			text:"Deyu Wang*; Dongchao Wen; Wei Tao; Lingxiao Yin; Tse-Wei Chen; Tadayuki Ito; Kinya Osa; Masami Kato",
		},
		{
			type:"paperTitle",
			text:"1820 <b>Identity-aware Graph Memory Network for Action Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"JingCheng Ni*; Jie Qin; Di Huang",
		},
		{
			type:"paperTitle",
			text:"1822 <b>Improving Robustness and Accuracy via Relative Information Encoding in 3D Human Pose Estimation<b>",
		},
		{
			type:"paperAuthor",
			text:"Wenkang Shan*; Haopeng Lu; Shanshe Wang; Xinfeng Zhang; Wen Gao",
		},
		{
			type:"paperTitle",
			text:"1824 <b>Deep Neural Network Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Nan Zhong; Zhenxing Qian; Xinpeng Zhang*",
		},
		{
			type:"paperTitle",
			text:"1827 <b>Adversarial Learning with Mask Reconstruction for Text-Guided Image Inpainting<b>",
		},
		{
			type:"paperAuthor",
			text:"Xingcai Wu*; Yucheng Xie; Jiaqi Zeng; Zhenguo Yang; Yi Yu; Qing Li; Wenyin Liu",
		},
		{
			type:"paperTitle",
			text:"1835 <b>Spatiotemporal Inconsistency Learning for DeepFake Video Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhihao Gu*; Yang Chen; Taiping Yao; Shouhong Ding; Jilin Li; Feiyue Huang; Lizhuang Ma",
		},
		{
			type:"paperTitle",
			text:"1838 <b>VeloCity: Using Voice Assistants for Cyclists to Provide Traffic Reports<b>",
		},
		{
			type:"paperAuthor",
			text:"Gian-Luca Savino*; Jesse Moraes Braga; Johannes Schoening",
		},
		{
			type:"paperTitle",
			text:"1845 <b>Edit Like A Designer: Modeling Design Workflows for Unaligned Fashion Editing<b>",
		},
		{
			type:"paperAuthor",
			text:"Qiyu Dai; Shuai Yang; Wenjing Wang; Wei Xiang; Jiaying Liu*",
		},
		{
			type:"paperTitle",
			text:"1848 <b>Privacy-Preserving Portrait Matting<b>",
		},
		{
			type:"paperAuthor",
			text:"Jizhizi Li; Sihan Ma; Jing Zhang*; Dacheng Tao",
		},
		{
			type:"paperTitle",
			text:"1858 <b>A Transformer based Approach for Image Manipulation Chain Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiaxiang You; Yuanman Li*; Jiantao Zhou; Zhongyun Hua; Weiwei Sun; Xia Li",
		},
		{
			type:"paperTitle",
			text:"1862 <b>HANet: Hierarchical Alignment Networks for Video-Text Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Peng Wu*; Xiangteng He; Mingqian Tang; Yiliang Lv; Jing Liu",
		},
		{
			type:"paperTitle",
			text:"1866 <b>Scalable Multi-view Subspace Clustering with Unified Anchors<b>",
		},
		{
			type:"paperAuthor",
			text:"Mengjing Sun; Pei Zhang*; Siwei Wang; Sihang Zhou; Wenxuan Tu; Xinwang Liu; En Zhu; Changjian Wang",
		},
		{
			type:"paperTitle",
			text:"1868 <b>PRNet: A Progressive Recovery Network for Revealing Perceptually Encrypted Images<b>",
		},
		{
			type:"paperAuthor",
			text:"Tao Xiang; Ying Yang; Shangwei Guo*; Hangcheng Liu; Hantao Liu",
		},
		{
			type:"paperTitle",
			text:"1881 <b>FakeTagger: Robust Safeguards against DeepFake Dissemination via Provenance Tracking<b>",
		},
		{
			type:"paperAuthor",
			text:"Run Wang*; Felix Juefei-Xu; Meng Luo; Yang Liu; Lina Wang",
		},
		{
			type:"paperTitle",
			text:"1885 <b>Discriminative Latent Semantic Graph for Video Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Yang Bai*; Junyan Wang; Yang Long; Bingzhang Hu; Yang Song; Maurice Pagnucco; Yu Guan",
		},
		{
			type:"paperTitle",
			text:"1888 <b>From Image to Imuge: Immunized Image Generation<b>",
		},
		{
			type:"paperAuthor",
			text:"Qichao Ying; Zhenxing Qian*; Hang Zhou; Haisheng Xu; Xinpeng Zhang; Siyi Li",
		},
		{
			type:"paperTitle",
			text:"1894 <b>Wisdom of (Binned) Crowds: A Bayesian Stratification Paradigm for Crowd Counting<b>",
		},
		{
			type:"paperAuthor",
			text:"Sravya V Shivapuja; Mansi P Khamkar; Divij Bajaj; Ganesh Ramakrishnan; Ravi Kiran Sarvadevabhatla*",
		},
		{
			type:"paperTitle",
			text:"1896 <b>Demystifying Commercial Video Conferencing Applications<b>",
		},
		{
			type:"paperAuthor",
			text:"Insoo Lee*; Jinsung Lee; Kyunghan Lee; Dirk Grunwald; Sangtae Ha",
		},
		{
			type:"paperTitle",
			text:"1918 <b>LightFEC: Network Adaptive FEC with A Lightweight Deep-Learning Approach<b>",
		},
		{
			type:"paperAuthor",
			text:"Han Hu*; Sheng Cheng; Xinggong Zhang; Zongming Guo",
		},
		{
			type:"paperTitle",
			text:"1935 <b>SOGAN: 3D-Aware Shadow and Occlusion Robust GAN for Makeup Transfer<b>",
		},
		{
			type:"paperAuthor",
			text:"Yueming Lyu; Jing Dong*; Bo Peng; Wei Wang; Tieniu Tan",
		},
		{
			type:"paperTitle",
			text:"1954 <b>Semantic Scalable Image Compression with Cross-Layer Priors<b>",
		},
		{
			type:"paperAuthor",
			text:"Hanyue Tu*; Li Li; Wengang Zhou; Houqiang Li",
		},
		{
			type:"paperTitle",
			text:"1966 <b>Cascade Cross-modal Attention Network for Video Actor and Action Segmentation from a Sentence<b>",
		},
		{
			type:"paperAuthor",
			text:"Weidong Chen*; Guorong Li; Xinfeng Zhang; Hongyang Yu; Shuhui Wang; Qingming Huang",
		},
		{
			type:"paperTitle",
			text:"1971 <b>Extracting Useful Knowledge form Noisy Web Images via Data Purification for Fine-Grained Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Chuanyi Zhang; Yazhou Yao*; Xing Xu; Jie Shao; Jingkuan Song; Zechao Li; Zhenmin Tang",
		},
		{
			type:"paperTitle",
			text:"1974 <b>Complementary Factorization towards Outfit Compatibility Modeling<b>",
		},
		{
			type:"paperAuthor",
			text:"Tianyu Su*; Xuemeng Song; Na Zheng; Weili Guan; Yan Li; Liqiang Nie",
		},
		{
			type:"paperTitle",
			text:"1983 <b>Open Set Face Anti-Spoofing in Unseen Attacks<b>",
		},
		{
			type:"paperAuthor",
			text:"Xin Dong; Hao Liu*; Weiwei Cai; Pengyuan Lv; Zekuan Yu",
		},
		{
			type:"paperTitle",
			text:"1990 <b>Interventional Video Relation Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Yicong Li*; Xun Yang; Xindi Shang; Tat-Seng Chua",
		},
		{
			type:"paperTitle",
			text:"1997 <b>CanvasEmb: Learning Layout Representationwith Large-scale Pre-training for Graphic Design<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuxi Xie*; Danqing Huang; Jinpeng Wang; Chin-Yew Lin",
		},
		{
			type:"paperTitle",
			text:"2005 <b>Augmenting TV Shows via Uncalibrated Camera Small Motion Tracking in Dynamic Scene<b>",
		},
		{
			type:"paperAuthor",
			text:"Yizhen Lao*; Jie Yang; Xinying Wang; Jianxin Lin; Cao Yu; Song shien",
		},
		{
			type:"paperTitle",
			text:"2007 <b>SimulSLT: End-to-End Simultaneous Sign Language Translation<b>",
		},
		{
			type:"paperAuthor",
			text:"Aoxiong Yin; Zhou Zhao*; Jinglin Liu; Weike Jin; Meng Zhang; Xingshan Zeng; Xiaofei He",
		},
		{
			type:"paperTitle",
			text:"2009 <b>Mask and Predict: Multi-step Reasoning for Scene Graph Generation<b>",
		},
		{
			type:"paperAuthor",
			text:"Hongshuo Tian; Ning Xu*; An-An Liu; Chenggang Yan; Zhendong Mao; Quan Zhang; Yongdong Zhang",
		},
		{
			type:"paperTitle",
			text:"2022 <b>Heterogeneous Face Recognition with Attention-guided Feature Disentangling<b>",
		},
		{
			type:"paperAuthor",
			text:"Shanming Yang; Xiao Yang*; Yi Lin; Peng Cheng; Yi Zhang; Jianwei Zhang",
		},
		{
			type:"paperTitle",
			text:"2026 <b>Exploring the Quality of GAN Generated Images for Person Re-Identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Yiqi Jiang*; Weihua Chen; Xiuyu Sun; Xiaoyu Shi; Fan Wang; Hao Li",
		},
		{
			type:"paperTitle",
			text:"2033 <b>Multi-view Clustering via Deep Matrix Factorization and Partition Alignment<b>",
		},
		{
			type:"paperAuthor",
			text:"Chen Zhang*; Siwei Wang; Jiyuan Liu; Sihang Zhou; Pei Zhang; Xinwang Liu; En Zhu; Changwang Zhang",
		},
		{
			type:"paperTitle",
			text:"2035 <b>Video Similarity and Alignment Learning on Partial Video Copy Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhen Han*; Xiangteng He; Mingqian Tang; Yiliang Lv",
		},
		{
			type:"paperTitle",
			text:"2048 <b>No-Reference Video Quality Assessment with Heterogeneous Knowledge Ensemble<b>",
		},
		{
			type:"paperAuthor",
			text:"Jinjian Wu*; Yongxu Liu; Leida Li; Weisheng Dong; Guangming Shi",
		},
		{
			type:"paperTitle",
			text:"2061 <b>Seeing is Believing? Effects of Visualization on Smart Device Privacy Perceptions<b>",
		},
		{
			type:"paperAuthor",
			text:"Carlos Bermejo Fernandez*; Petteri Nurmi; Pan Hui",
		},
		{
			type:"paperTitle",
			text:"2062 <b>MHFC: Multi-Head Feature Collaboration for Few-Shot Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Shuai Shao*; Lei Xing; Yan Wang; Rui Xu; Chunyan Zhao; Yanjiang Wang; Baodi Liu",
		},
		{
			type:"paperTitle",
			text:"2072 <b>Vision-guided Music Source Separation via a Fine-grained Cycle-Separation Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Ma Shuo; Yanli Ji*; Xing Xu; Xiaofeng Zhu",
		},
		{
			type:"paperTitle",
			text:"2086 <b>GLM-Net : Global and Local Motion Estimation via Task-Oriented Encoder-Decoder Structure<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuchen Yang; Ye Xiang; Shuaicheng Liu; Megvii; Lifang Wu*; Boxuan Zhao; Bing Zeng",
		},
		{
			type:"paperTitle",
			text:"2089 <b>Sensor-Augmented Egocentric-Video Captioning with Dynamic Modal-Attention<b>",
		},
		{
			type:"paperAuthor",
			text:"Katsuyuki Nakamura*; Hiroki Ohashi; Mitsuhiro Okada",
		},
		{
			type:"paperTitle",
			text:"2091 <b>Cross Modal Compression: Towards Human-comprehensible Semantic Compression<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiguo Li*; Chuanmin Jia; Xinfeng Zhang; Siwei Ma; Wen Gao",
		},
		{
			type:"paperTitle",
			text:"2100 <b>RAMS-Trans: Recurrent Attention Multi-scale Transformer for Fine-grained Image Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Yunqing Hu; Xuan Jin; Yin Zhang*; Haiwen Hong; Jingfeng Zhang; Yuan He; hui xue",
		},
		{
			type:"paperTitle",
			text:"2103 <b>Memory-Augmented Deep Unfolding Network for Compressive Sensing<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiechong Song; Bin Chen; Jian Zhang*",
		},
		{
			type:"paperTitle",
			text:"2105 <b>Underwater Species Detection using Channel Sharpening Attention<b>",
		},
		{
			type:"paperAuthor",
			text:"Lihao Jiang; Yi Wang*; Qi Jia; Shengwei Xu; Yu Liu; Xin Fan; Haojie Li; Risheng Liu; Xinwei Xue; Ruili Wang",
		},
		{
			type:"paperTitle",
			text:"2110 <b>Self-Supervised Pre-training on the Target Domain for Cross-Domain Person Re-identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Junyin Zhang; Yongxin Ge*; Xinqian Gu; Boyu Hua; Tao Xiang",
		},
		{
			type:"paperTitle",
			text:"2115 <b>Exploring Graph-Structured Semantics for Cross-Modal Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Lei Zhang; Leiting Chen; Chuan Zhou*; Fan Yang; Xin Li",
		},
		{
			type:"paperTitle",
			text:"2130 <b>Text is NOT Enough: Integrating Visual Impressions into Open-domain Dialogue Generation<b>",
		},
		{
			type:"paperAuthor",
			text:"Lei Shen*; Haolan Zhan; Xin Shen; Yonghao Song; Xiaofang Zhao",
		},
		{
			type:"paperTitle",
			text:"2143 <b>Quality Assessment of End-to-End Learned Image Compression: The Benchmark and Objective Measure<b>",
		},
		{
			type:"paperAuthor",
			text:"Yang Li*; Shiqi Wang; Xinfeng Zhang; Shanshe Wang; Siwei Ma; Yue Wang",
		},
		{
			type:"paperTitle",
			text:"2144 <b>A Statistical Approach to Mining Semantic Similarity for Deep Unsupervised Hashing<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiao Luo; Daqing Wu; Zeyu Ma; Chong Chen*; Minghua Deng; Jianqiang Huang; Xian-Sheng Hua",
		},
		{
			type:"paperTitle",
			text:"2153 <b>BAM: Bilateral Activation Mechanism for Image Fusion<b>",
		},
		{
			type:"paperAuthor",
			text:"Zi-Rong Jin; Liang-Jian Deng*; Tian-Jing Zhang; Xiaoxu Jin",
		},
		{
			type:"paperTitle",
			text:"2161 <b>Hallucinating Statistical Moment and Subspace Descriptors for Action Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Lei Wang*; Piotr Koniusz",
		},
		{
			type:"paperTitle",
			text:"2168 <b>Learning Multi-Granular Spatio-Temporal Graph Network for Skeleton-based Action Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Tailin Chen*; Desen Zhou; Jian Wang; Shidong Wang; Yu Guan; Xuming He; Errui Ding",
		},
		{
			type:"paperTitle",
			text:"2179 <b>ION: Instance-level Object Navigation<b>",
		},
		{
			type:"paperAuthor",
			text:"Weijie Li*; Xinhang Song; Yubing Bai; Sixian Zhang; Shuqiang Jiang",
		},
		{
			type:"paperTitle",
			text:"2187 <b>Skeleton-Aware Neural Sign Language Translation<b>",
		},
		{
			type:"paperAuthor",
			text:"Shiwei Gan; Yafeng Yin*; Zhiwei Jiang; Lei Xie; Sanglu Lu",
		},
		{
			type:"paperTitle",
			text:"2210 <b>Fingerspelling Recognition in the Wild with Fixed-Query based Visual Attention<b>",
		},
		{
			type:"paperAuthor",
			text:"Srinivas Kruthiventi S S*; George Jose; Nitya Tandon; Rajesh Biswal; AASHISH KUMAR",
		},
		{
			type:"paperTitle",
			text:"2219 <b>Deep Human Dynamics Prior<b>",
		},
		{
			type:"paperAuthor",
			text:"Qiongjie Cui*; Huaijiang Sun; Yue Kong; Xiaoning Sun",
		},
		{
			type:"paperTitle",
			text:"2231 <b>Exploiting Invariance of Mining Facial Landmarks<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiangming Shi; Zixian Gao; Hao Liu*; Zekuan Yu; Fengjun Li",
		},
		{
			type:"paperTitle",
			text:"2240 <b>Joint Implicit Image Function for Guided Depth Super-Resolution<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiaxiang Tang*; Xiaokang Chen; Gang Zeng",
		},
		{
			type:"paperTitle",
			text:"2260 <b>Transformer-based Feature Reconstruction Network for Robust Multimodal Sentiment Analysis<b>",
		},
		{
			type:"paperAuthor",
			text:"Ziqi Yuan; Wei Li; Hua Xu*; Wenmeng Yu",
		},
		{
			type:"paperTitle",
			text:"2287 <b>Self-feature learning: an efficient deep lightweight network for image super-resolution<b>",
		},
		{
			type:"paperAuthor",
			text:"Jun Xiao*; Qian Ye; Rui Zhao; Kin-Man Lam; Kao Wan",
		},
		{
			type:"paperTitle",
			text:"2298 <b>DAWN: Dynamic Adversarial Watermarking of Neural Networks<b>",
		},
		{
			type:"paperAuthor",
			text:"Sebastian Szyller*; Buse Gul Atli; Samuel Marchal; N. Asokan",
		},
		{
			type:"paperTitle",
			text:"2325 <b>Visible Watermark Removal via Self-calibrated Localization and Background Refinement<b>",
		},
		{
			type:"paperAuthor",
			text:"Jing Liang; Li Niu*; Fengjun Guo; Teng Long; Liqing Zhang",
		},
		{
			type:"paperTitle",
			text:"2332 <b>Learning to Decode Contextual Information for Efficient Contour Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Ruoxi Deng*; Shengjun Liu; Jinxin Wang; Huibing Wang; Hanli Zhao; Xiaoqin Zhang",
		},
		{
			type:"paperTitle",
			text:"2336 <b>Fast, High-Quality Hierarchical Depth-Map Super-Resolution<b>",
		},
		{
			type:"paperAuthor",
			text:"Yiguo Qiao*; Licheng Jiao; Wenbin Li; Christian Richardt; Darren Cosker",
		},
		{
			type:"paperTitle",
			text:"2363 <b>TsFPS: An Accurate and Flexible 6DoF Tracking System with Fiducial Platonic Solids<b>",
		},
		{
			type:"paperAuthor",
			text:"Nan Xiang*; Xiaosong Yang; Jian J Zhang",
		},
		{
			type:"paperTitle",
			text:"2367 <b>Consistency-Constancy Bi-Knowledge Learning for Pedestrian Detection in Night Surveillance<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiao Wang; Zheng Wang*; Wu Liu; Xin Xu; Jing Chen; Chia-Wen Lin",
		},
		{
			type:"paperTitle",
			text:"2371 <b>SSconv: Explicit Spectral-to-Spatial Convolution for Pansharpening<b>",
		},
		{
			type:"paperAuthor",
			text:"Yudong Wang; Liang-Jian Deng*; Tian-Jing Zhang; Xiao Wu",
		},
		{
			type:"paperTitle",
			text:"2374 <b>TriTransNet:RGB-D salient object detection with a triplet transformer embedding network<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhengyi Liu*; Yuan Wang; Zhengzheng Tu; Yun Xiao; Bin Tang",
		},
		{
			type:"paperTitle",
			text:"2377 <b>Learning Sample-Specific Policies for Sequential Image Augmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Pu Li*; Xiaobai Liu; Xiaohui Xie",
		},
		{
			type:"paperTitle",
			text:"2378 <b>Image Quality Caption with Attentive and Recurrent Semantic Attractor Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Wen Yang; Jinjian Wu*; Leida Li; Weisheng Dong; Guangming Shi",
		},
		{
			type:"paperTitle",
			text:"2390 <b>Triangle-Reward Reinforcement Learning: A Visual-Linguistic Semantic Alignment for Image Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Weizhi Nie; Jiesi Li; Ning Xu*; An-An Liu; Xuanya Li; Yongdong Zhang",
		},
		{
			type:"paperTitle",
			text:"2429 <b>Stacked Semantically-Guided Learning for Image De-distortion<b>",
		},
		{
			type:"paperAuthor",
			text:"Huiyuan Fu*; Changhao Tian; Xin Wang; Huadong Ma",
		},
		{
			type:"paperTitle",
			text:"2438 <b>Focal and Composed Vision-semantic Modeling for Visual Question Answering<b>",
		},
		{
			type:"paperAuthor",
			text:"Yudong Han*; Yangyang Guo; Jianhua Yin; Meng Liu; Yupeng Hu; Liqiang Nie",
		},
		{
			type:"paperTitle",
			text:"2444 <b>Pose-Guided Feature Learning with Knowledge Distillation for Occluded Person Re-Identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Kecheng Zheng*; Cuiling Lan; Wenjun Zeng; Jiawei Liu; Zhizheng Zhang; Zheng-Jun Zha",
		},
		{
			type:"paperTitle",
			text:"2450 <b>Multiple Objects-Aware Visual Question Generation<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiayuan Xie; Yi Cai*; Qingbao Huang; Tao Wang",
		},
		{
			type:"paperTitle",
			text:"2454 <b>VASTile: Viewport Aware Scalable 360-Degree Video Frame Tiling.<b>",
		},
		{
			type:"paperAuthor",
			text:"Chamara Manoj Madarasingha Kattadige*; Kanchana Thilakarathna",
		},
		{
			type:"paperTitle",
			text:"2462 <b>Delving into Deep Image Prior for Adversarial Defense: A Novel Reconstruction-based Defense Framework<b>",
		},
		{
			type:"paperAuthor",
			text:"Li Ding*; Yongwei Wang; Xin Ding; Kaiwen Yuan; Ping Wang; Hua Huang; Z. Jane Wang",
		},
		{
			type:"paperTitle",
			text:"2464 <b>Fine-Grained Language Identification in Scene Text Images<b>",
		},
		{
			type:"paperAuthor",
			text:"Yongrui Li*; Shilian Wu; Jun Yu; Zengfu Wang",
		},
		{
			type:"paperTitle",
			text:"2472 <b>CARE: CloudIfied Android OSes on the Cloud Rendering<b>",
		},
		{
			type:"paperAuthor",
			text:"Dongjie Tang; Cathy Bao; Yong Yao; Chao Xie; Qiming Shi; Marc Mao; Randy Xu; Linsheng Li; Mohammad Reza R Haghighat; Zhengwei Qi*; Haibing Guan",
		},
		{
			type:"paperTitle",
			text:"2474 <b>Context-Aware Selective Label Smoothing for Calibrating Sequence Recognition Models<b>",
		},
		{
			type:"paperAuthor",
			text:"Shuangping Huang; Yu Luo*; Zhenzhou Zhuang; Jin-Gang Yu; Mengchao He; Yongpan Wang",
		},
		{
			type:"paperTitle",
			text:"2476 <b>Image Search with Text Feedback by Deep Hierarchical Attention Mutual Information Maximization<b>",
		},
		{
			type:"paperAuthor",
			text:"Chunbin Gu*; Jiajun Bu; Zhen Zhang; Zhi Yu; Dongfang Ma; Wei Wang",
		},
		{
			type:"paperTitle",
			text:"2481 <b>Pairwise VLAD Interaction Network for Video Question Answering<b>",
		},
		{
			type:"paperAuthor",
			text:"Hui Wang; Dan Guo*; Xian-Sheng Hua; Meng Wang",
		},
		{
			type:"paperTitle",
			text:"2487 <b>Attention-guided Temporally Coherent Video Object Matting<b>",
		},
		{
			type:"paperAuthor",
			text:"Yunke Zhang; Chi Wang; Miaomiao Cui; Peiran Ren; Xuansong Xie; Xian-Sheng Hua; Hujun Bao; Qixing Huang; Weiwei Xu*",
		},
		{
			type:"paperTitle",
			text:"2492 <b>Disentangling Hate in Online Memes<b>",
		},
		{
			type:"paperAuthor",
			text:"Roy Ka-Wei Lee*; Rui Cao; Fan Ziqing; Jing Jiang; Wen Haw Chong",
		},
		{
			type:"paperTitle",
			text:"2496 <b>Robust Real-World Image Super-Resolution against Adversarial Attacks<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiutao Yue; Haofeng Li; Pengxu Wei; Guanbin Li*; Liang Lin",
		},
		{
			type:"paperTitle",
			text:"2500 <b>Towards Robust Deep Hiding Under Non-Differentiable Distortions for Practical Blind Watermarking<b>",
		},
		{
			type:"paperAuthor",
			text:"Chaoning Zhang*; Adil Karjauv; Philipp Benz; In So Kweon",
		},
		{
			type:"paperTitle",
			text:"2502 <b>Bottom-Up and Bidirectional Alignment for Referring Expression Comprehension<b>",
		},
		{
			type:"paperAuthor",
			text:"Liuwu Li; Yuqi Bu; Yi Cai*",
		},
		{
			type:"paperTitle",
			text:"2527 <b>SalS-GAN: Spatially-Adaptive Latent Space in StyleGAN for Real Image Embedding<b>",
		},
		{
			type:"paperAuthor",
			text:"Lingyun Zhang; Xiuxiu Bai*; Yao Gao",
		},
		{
			type:"paperTitle",
			text:"2531 <b>Structured Multi-modal Feature Embedding and Alignment for Image-Sentence Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Xuri Ge*; Fuhai Chen; Joemon Jose; Zhilong Ji; Zhongqin Wu; Xiao Liu",
		},
		{
			type:"paperTitle",
			text:"2537 <b>Keyframe Extraction from Motion Capture Sequences with Graph based Deep Reinforcement Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Clinton A Mo*; Kun Hu; Shaohui Mei; Zebin Chen; Zhiyong Wang",
		},
		{
			type:"paperTitle",
			text:"2547 <b>Dense Contrastive Visual-Linguistic Pretraining<b>",
		},
		{
			type:"paperAuthor",
			text:"Lei Shi*; Kai Shuang; Shijie Geng; Peng Gao; Zuohui Fu; Gerard de Melo; Yunpeng Chen; Sen Su",
		},
		{
			type:"paperTitle",
			text:"2550 <b>Hybrid Reasoning Network for Video-based Commonsense Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Weijiang Yu*; Jian Liang; Lei Ji; Lu Li; Yuejian Fang; Nong Xiao; Nan Duan",
		},
		{
			type:"paperTitle",
			text:"2563 <b>Learning Regularizer for Monocular Depth Estimation with Adversarial Guidance<b>",
		},
		{
			type:"paperAuthor",
			text:"Guibao Shen; Yingkui Zhang; Jialu Li; Mingqiang Wei; Qiong Wang*; Guangyong Chen; Pheng-Ann Heng",
		},
		{
			type:"paperTitle",
			text:"2568 <b>Pixel-wise Graph Attention Networks For Person Re-identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Wenyu Zhang; Qing Ding*; Jian Hu; Yi Ma; Mingzhe Lu",
		},
		{
			type:"paperTitle",
			text:"2571 <b>Neighbor-Vote: Improving Monocular 3D Object Detection through Neighbor Distance Voting<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiaomeng Chu; Jiajun Deng; Yao Li; Zhenxun Yuan; Yanyong Zhang*; Jianmin Ji; Yu Zhang",
		},
		{
			type:"paperTitle",
			text:"2574 <b>Remember and Reuse: Cross-Task Blind Image Quality Assessment via Relevance-aware Incremental Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Rui Ma; Hanxiao Luo; Qingbo Wu*; King Ngi Ngan; Hongliang Li; Fanman Meng; LinFeng Xu",
		},
		{
			type:"paperTitle",
			text:"2584 <b>MSO: Multi-Feature Space Joint Optimization Network for RGB-Infrared Person Re-Identification<b>",
		},
		{
			type:"paperAuthor",
			text:"Yajun Gao; Tengfei Liang; Yi Jin*; Xiaoyan Gu; Wu Liu; Yidong Li; Congyan Lang",
		},
		{
			type:"paperTitle",
			text:"2591 <b>Point Cloud Projection and Multi-Scale Feature Fusion Network Based Blind Quality Assessment for Colored Point Clouds<b>",
		},
		{
			type:"paperAuthor",
			text:"Wen-xu Tao; Gang-yi Jiang*; Zhi-di Jiang; Mei Yu",
		},
		{
			type:"paperTitle",
			text:"2592 <b>Multi-branch Channel-wise Enhancement Network for Fine-grained Visual Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Guangjun Li*; Yongxiong Wang; Fengting Zhu",
		},
		{
			type:"paperTitle",
			text:"2626 <b>General Approximate Cross Validation for Model Selection: Supervised, Semi-supervised and Pairwise Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Bowei Zhu; Yong Liu*",
		},
		{
			type:"paperTitle",
			text:"2637 <b>Progressive and Selective Fusion Network for High Dynamic Range Imaging<b>",
		},
		{
			type:"paperAuthor",
			text:"Qian Ye*; Jun Xiao; Kin-Man Lam; Takayuki Okatani",
		},
		{
			type:"paperTitle",
			text:"2639 <b>Multimodal Relation Extraction with Efficient Graph Alignment<b>",
		},
		{
			type:"paperAuthor",
			text:"Changmeng Zheng; Junhao Feng; Ze Fu; Yi Cai*; Qing Li; Tao Wang",
		},
		{
			type:"paperTitle",
			text:"2640 <b>Legitimate Adversarial Patches: Evading Human Eyes and Detection Models in the Physical World<b>",
		},
		{
			type:"paperAuthor",
			text:"Jia Tan*; Nan Ji; Haidong Xie; Xueshuang Xiang",
		},
		{
			type:"paperTitle",
			text:"2645 <b>Unsupervised Vehicle search in the Wild: A New Benchmark<b>",
		},
		{
			type:"paperAuthor",
			text:"Xian Zhong; Shilei Zhao; Xiao Wang*; Kui Jiang; Wenxuan Liu; Wenxin Huang; Zheng Wang",
		},
		{
			type:"paperTitle",
			text:"2650 <b>Meta-FDMixup: Cross-Domain Few-Shot Learning Guided by Labeled Target Data<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuqian Fu*; Yanwei Fu; Yu-Gang Jiang",
		},
		{
			type:"paperTitle",
			text:"2651 <b>Target-guided Adaptive Base Class Reweighting for Few-Shot Learning<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiliang Yan; Deming Zhai; Junjun Jiang; Xianming Liu*",
		},
		{
			type:"paperTitle",
			text:"2671 <b> Dynamic Reasoning Network for Few-shot Semantic Segmentation <b>",
		},
		{
			type:"paperAuthor",
			text:"Yunzhi Zhuge; Chunhua Shen*",
		},
		{
			type:"paperTitle",
			text:"2680 <b>Heterogeneous Feature Fusion and Cross-modal Alignment for Composed Image Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Gangjian Zhang; Shikui Wei*; Huaxin Pang; Yao Zhao",
		},
		{
			type:"paperTitle",
			text:"2702 <b>Similar Scenes arouse Similar Emotions: Parallel Data Augmentation for Stylized Image Captioning<b>",
		},
		{
			type:"paperAuthor",
			text:"Guodun Li; Yuchen Zhai; Zehao Lin; Yin Zhang*",
		},
		{
			type:"paperTitle",
			text:"2705 <b>Trajectory is not Enough: Hidden Following Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Danni Xu*; Ruimin Hu; Zixiang Xiong; Zheng Wang; Linbo Luo; Dengshi Li",
		},
		{
			type:"paperTitle",
			text:"2707 <b>Contrastive Learning for Cold-Start Recommendation<b>",
		},
		{
			type:"paperAuthor",
			text:"Yinwei Wei*; Xiang Wang; Qi Li; Liqiang Nie; Yan Li; Xuanping Li; Tat-Seng Chua",
		},
		{
			type:"paperTitle",
			text:"2708 <b>CG-GAN: Class-Attribute Guided Generative Adversarial Network for Old Photo Restoration<b>",
		},
		{
			type:"paperAuthor",
			text:"Jixin Liu*; Rui Chen; Shipeng An; Heng Zhang",
		},
		{
			type:"paperTitle",
			text:"2726 <b>Get The Best of the Three Worlds: Real-Time Neural Image Compression in a Non-GPU Environment<b>",
		},
		{
			type:"paperAuthor",
			text:"Zekun Zheng*; Xiaodong Wang; Xinye Lin; Shaohe Lv",
		},
		{
			type:"paperTitle",
			text:"2728 <b>Visual Language Based Succinct Zero-Shot Object Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Ye Zheng*; Xi Huang; Li Cui",
		},
		{
			type:"paperTitle",
			text:"2733 <b>GAMnet: Robust Feature Matching via Graph Adversarial-Matching Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Bo Jiang*; Pengfei Sun; Ziyan Zhang; Jin Tang; Bin Luo",
		},
		{
			type:"paperTitle",
			text:"2734 <b>MCCN: Multimodal Coordinated Clustering Network for Large-Scale Cross-modal Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhixiong Zeng*; Ying Sun; Wenji Mao",
		},
		{
			type:"paperTitle",
			text:"2742 <b>AFEC: Adaptive Feature Extraction Modules For Learned Image Compression<b>",
		},
		{
			type:"paperAuthor",
			text:"Yi Ma*; Yongqi Zhai; Jiayu Yang; Chunhui Yang; Ronggang Wang",
		},
		{
			type:"paperTitle",
			text:"2768 <b>How Video Super-Resolution and Frame Interpolation Mutually Benefit<b>",
		},
		{
			type:"paperAuthor",
			text:"Chengcheng Zhou*; Zongqing Lu; Linge Li; Qiangyu Yan; Jing-Hao Xue",
		},
		{
			type:"paperTitle",
			text:"2772 <b>FOCAS: Practical Video Super Resolution using Foveated Rendering<b>",
		},
		{
			type:"paperAuthor",
			text:"Lingdong Wang*; Mohammad Hajiesmaili; Ramesh K. Sitaraman",
		},
		{
			type:"paperTitle",
			text:"2778 <b>Adaptive Affinity Loss and Erroneous Pseudo-Label Refinement for Weakly Supervised Semantic Segmentation<b>",
		},
		{
			type:"paperAuthor",
			text:"Xiangrong Zhang*; Zelin Peng; Peng Zhu; Tianyang Zhang; Chen Li; Huiyu Zhou; Licheng Jiao",
		},
		{
			type:"paperTitle",
			text:"2782 <b>Relationship-Preserving Knowledge Distillation for Zero-Shot Sketch Based Image Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Jialin Tian; Xing Xu*; Zheng Wang; Fumin Shen; Xin Liu",
		},
		{
			type:"paperTitle",
			text:"2818 <b>Partially fake it till you make it: mixing real and fake thermal images for improved object detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Francesco Bongini; Lorenzo Berlincioni; Marco Bertini*; Alberto Del Bimbo",
		},
		{
			type:"paperTitle",
			text:"2826 <b>CDP: Towards Optimal Filter Pruning via Class-wise Discriminative Power<b>",
		},
		{
			type:"paperAuthor",
			text:"Tianshuo Xu; Yuhang Wu; Xiawu Zheng; Teng Xi; Gang Zhang; Errui Ding; Fei Chao*; Rongrong Ji",
		},
		{
			type:"paperTitle",
			text:"2834 <b>Face Hallucination via Split-Attention in Split-Attention Network<b>",
		},
		{
			type:"paperAuthor",
			text:"Tao Lu; Yuanzhi Wang*; Yanduo Zhang; Yu Wang; Liu Wei; Zhongyuan Wang; Junjun Jiang",
		},
		{
			type:"paperTitle",
			text:"2858 <b>Fake Gradient: A Security and Privacy Protection Framework for DNN-based Image Classification<b>",
		},
		{
			type:"paperAuthor",
			text:"Xianglong Feng; Yi Xie; Mengmei Ye; Zhongze Tang; Bo Yuan; Sheng Wei*",
		},
		{
			type:"paperTitle",
			text:"2877 <b>Integrating Semantic and Temporal Relationships in Facial Action Unit Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhihua Li*; Xiang Deng; Xiaotian Li; Lijun Yin",
		},
		{
			type:"paperTitle",
			text:"2887 <b>Sparse to Dense Depth Completion using a Generative Adversarial Network with Intelligent Sampling Strategies<b>",
		},
		{
			type:"paperAuthor",
			text:"Md Fahim Faysal Khan*; Nelson D Troncoso Aldas; Abhishek Kumar; Siddharth Advani; Vijaykrishnan Narayanan",
		},
		{
			type:"paperTitle",
			text:"2898 <b>How does Color Constancy Affect Target Recognition and Instance Segmentation ?<b>",
		},
		{
			type:"paperAuthor",
			text:"Siyan Xue; Shaobing Gao*; Minjie Tan; Zhen He; Liangtian He",
		},
		{
			type:"paperTitle",
			text:"2911 <b>Convolutional Transformer based Dual Discriminator Generative Adversarial Networks for Video Anomaly Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Xinyang Feng; Dongjin Song*; Yuncong Chen; Zhengzhang Chen; Jingchao Ni; Haifeng Chen",
		},
		{
			type:"paperTitle",
			text:"2921 <b>Salient Error Detection based Refinement for Wide-baseline Image Interpolation<b>",
		},
		{
			type:"paperAuthor",
			text:"Yuan Chang*; Yisong Chen; Guoping Wang",
		},
		{
			type:"paperTitle",
			text:"2936 <b>A Multi-Domain Adaptive Graph Convolutional Network for EEG-based Emotion Recognition<b>",
		},
		{
			type:"paperAuthor",
			text:"Rui Li*; Yiting Wang; Bao-Liang Lu",
		},
		{
			type:"paperTitle",
			text:"2939 <b>Interpolation Variable Rate Image Compression<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhenhong Sun; Zhiyu Tan; Xiuyu Sun*; Fangyi Zhang; Yichen Qian; Dongyang Li; Hao Li",
		},
		{
			type:"paperTitle",
			text:"2959 <b>Armor: A Benchmark for Testing the Effectiveness of Artificial Music Objective Evaluation Method<b>",
		},
		{
			type:"paperAuthor",
			text:"Songhe Wang*; Zheng Bao; Jingtong E",
		},
		{
			type:"paperTitle",
			text:"2974 <b>DRDF: Determining the Importance of Different Multimodal Information with Dual-Router Dynamic Framework<b>",
		},
		{
			type:"paperAuthor",
			text:"Haiwen Hong; Xuan Jin; Yin Zhang*; Yunqing Hu; Jingfeng Zhang; Yuan He; hui xue",
		},
		{
			type:"paperTitle",
			text:"2977 <b>CoCo-BERT: Improving Video-Language Pre-training with Contrastive Cross-modal Matching and Denoising<b>",
		},
		{
			type:"paperAuthor",
			text:"Jianjie Luo; Yehao Li; Yingwei Pan*; Ting Yao; Hongyang Chao; Tao Mei",
		},
		{
			type:"paperTitle",
			text:"3000 <b>DLA-Net for FG-SBIR: Dynamic Local Aligned Network for Fine-Grained Sketch-Based Image Retrieval<b>",
		},
		{
			type:"paperAuthor",
			text:"Jiaqing Xu*; Haifeng Sun; Qi Qi; Jingyu Wang; Ce Ge; Lejian Zhang; Jianxin Liao",
		},
		{
			type:"paperTitle",
			text:"3004 <b>Pareto Optimality for Fairness-constrained Collaborative Filtering<b>",
		},
		{
			type:"paperAuthor",
			text:"Qianxiu Hao*; Qianqian Xu; Zhiyong Yang; Qingming Huang",
		},
		{
			type:"paperTitle",
			text:"3005 <b>Decoupled IoU Regression for Object Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Yan Gao*; Qimeng Wang; Xu Tang; Haochen Wang; Fei Ding; Jing Li; Yao Hu",
		},
		{
			type:"paperTitle",
			text:"3018 <b>RCNet: Reverse Feature Pyramid and Cross-scale Shift Network for Object Detection<b>",
		},
		{
			type:"paperAuthor",
			text:"Zhuofan Zong; Qianggang Cao; Biao Leng*",
		},
		{
			type:"paperTitle",
			text:"3026 <b>Recursive Fusion and Deformable Spatiotemporal Attention for Video Compression Artifact Reduction<b>",
		},
		{
			type:"paperAuthor",
			text:"Minyi Zhao; Yi Xu; Shuigeng Zhou*",
		},
		{
			type:"paperTitle",
			text:"3084 <b>JokerGAN: Memory-Efficient Model for Handwritten Text Generation with Text Line Awareness<b>",
		},
		{
			type:"paperAuthor",
			text:"Jan Zdenek*; Hideki Nakayama",
		},

        

      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Workshops'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>