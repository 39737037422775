<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'retract_policies'">
          <el-col class="retract_policies">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "volunteer AC",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Volunteer Area Chairs and Reviewers',
      contents:[
        {
          type:'text',
          text:'Closing date: <b>January 3, 2024 (AoE)</b>'
        },
        {
          type:'text',
          text:`We welcome applications to become part of the ACM Multimedia 2024 (<a href="https://2024.acmmm.org">https://2024.acmmm.org</a>) Technical Program Committee (TPC). TPC members (AC and reviewers) play a key role in the review process, helping the TPC chairs to shape the technical program of the conference. The application is open to all members of the community, from both industry and academia, who believe they can contribute to this team. Our aims are to broaden participation, ensure a diverse and representative Technical Program Committee, and help widen the ACM Multimedia community to include a full range of perspectives.
          `,
        },
        {
          type:'text',
          text:`To be selected as an AC or a reviewer, you must be an active researcher having published regularly in peer-reviewed, reputable conferences or journals over the past years, and have demonstrated expertise in one or more research areas in our call for papers.
          `,
        },
        {
          type:'text',
          text:`If you are interested in serving as Area Chairs and Reviewers of ACM Multimedia 2024, please fill out this <a href="https://forms.gle/PdwH2wEFPTZvms4d9" target="_blank" class="demo-link" >Google form</a>.
          `,
        },
        {
          type: 'text',
          text: 'The timeline of the review process is as follows:',
        },
        {
          type:'noList',
          text:'Regular paper submission deadline: <b>April 12, 2024</b>',
        },
        {
          type:'noList',
          text:'Paper review process: <b>April 12 - June 10, 2024</b>',
        },
        {
          type:'noList',
          text:'Reviews returned to authors: <b>June 10, 2024</b>',
        },
        {
          type:'noList',
          text:'Author rebuttal period: <b>June 10 - June 17, 2024</b>',
        },
        {
          type:'noList',
          text:'Paper discussion phase: <b>June 17 - July 8, 2024</b>',
        },
        
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Call for Volunteer Area Chairs and Reviewers'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.retract_policies {
    /* padding-left: 2.5rem; */
    text-align: left;
    /*color: #202020;*/
}

</style>