<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Doctoral Symposium',
      contents:[
        {
          type:'primaryTitle',
          text:'Overview',
        },
        {
          type:'text',
          text:'The ACM Multimedia 2024 Doctoral Symposium will provide PhD students working in all areas of multimedia with a unique opportunity to share their ongoing research work and ideas with, and get suggestions and feedback from renowned and experienced researchers in the field. The Doctoral Symposium also aims at establishing a supportive worldwide community of doctoral students working on multimedia, and promoting social and intellectual interactions among students and researchers from academia, industry and government.' 
        },
        {
          type:'text',
          text:'PhD students are invited to send a short paper (up to 4 pages for technical content and 1 page for references) summarizing their dissertation research. A program committee will review the submissions. Accepted papers will be presented by the student and discussed in an oral or poster session. ' 
        },
        {
          type:'text',
          text:'Submission website: <a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Doctoral_Symposium">https://openreview.net/group?id=acmmm.org/ACMMM/2024/Doctoral_Symposium</a>.',
        },
        {
          type:'primaryTitle',
          text:'Description of the Event',
        },
        {
          type:'text',
          text:'The Doctoral Symposium will take place during the main conference in oral and poster presentation formats. For each accepted oral paper, a panel of senior experts (expressly chosen by the Doctoral Symposium co-chairs to match at best the topics to be covered) will interact with the author and the audience during the presentation, providing constructive advice and suggestions. All accepted PhD students will also present their work at the conference Poster Session, to enable interaction with a broader audience of the main conference.',
        },
        {
          type:'primaryTitle',
          text:'Eligibility',
        },
        {
          type:'text',
          text:'Prospective student authors should already have a clear research direction and possibly have published some results. Preference will be given to students who are about halfway through their PhD work. Students who have just started their doctoral program are advised to consult with their local advisor/supervisor before submitting a paper.',
        },
        {
          type:'text',
          text:'Students who are approaching the end of their PhD should rather strive for submitting a paper at the main conference or at a workshop. The Doctoral Symposium should not be used for placing just another publication.',
        },
        {
          type:'primaryTitle',
          text:'Submission Guidelines',
        },
        {
          type:'text',
          text:'Submission website: <a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Doctoral_Symposium">https://openreview.net/group?id=acmmm.org/ACMMM/2024/Doctoral_Symposium</a>.',
        },
        {
          type:'text',
          text:'Each doctoral student who wants to submit a paper for the Doctoral Symposium should prepare:',
        },
        {
          type:'text',
          text:'<b>1.Research Abstract:</b>',
        },
        {
          type:'text',
          text:'A short paper (up to 4 pages for technical content and 1 page for reference) summarizing their dissertation research, singly authored by themself. The use of figures, diagrams or illustrations is encouraged. The abstract should include:',
        },
        {
          type:'noList',
          text:'Motivation, problem description',
        },
        {
          type:'noList',
          text:'Background and related work',
        },
        {
          type:'noList',
          text:'Novelty and significance relative to the state of the art',
        },
        {
          type:'noList',
          text:'Approach and proposed experiments (where appropriate)',
        },
        {
          type:'noList',
          text:'Results obtained and work in progress',
        },
        {
          type:'noList',
          text:'Keywords and key references of the presented work',
        },
        {
          type:'noList',
          text:'Discussion of legal, ethical and privacy implications of the work',
        },
        {
          type:'noList',
          text:'Discussion of the measures taken to ensure the reproducibility of the work',
        },
        {
          type:'noList',
          text:'Keywords and key references of the presented work',
        },
        {
          type:'text',
          text:'The submission must comply with the two-column ACM proceedings format. The Template is available at:'+
          '<br><a class="demo-link" href=" https://www.acm.org/publications/proceedings-template/"> https://www.acm.org/publications/proceedings-template/</a>.',
        },
        {
          type:'text',
          text:'<br><b>2. Recommendation Letter:</b>',
        },
        {
          type:'text',
          text:'A letter of recommendation from the student\'s PhD advisor, verifying:',
        },
        {
          type:'noList',
          text:'The student status of the applicant',
        },
        {
          type:'noList',
          text:'Date of advancement to PhD candidacy',
        },
        {
          type:'noList',
          text:'The expected date of thesis defence or graduation',
        },
        {
          type:'text',
          text:'<br><b>3. Personal document:</b>',
        },
        {
          type:'noList',
          text:'A short CV, including the list of all publications.',
        },
        {
          type:'noList',
          text:'A short (~200 words) statement from the student on why they are interested in participating in the Doctoral Symposium. At the end of the statement, the student should state suggestions of multimedia experts that they would be interested in discussing their work with and elements of their dissertation research that they would like to get feedback on (There is no guarantee that these experts are available, but we will try to take each student\'s "wish list" into account.) ',
        },
        {
          type:'text',
          text:'<br>All three documents must be written in English.',
        },
        {
          type:'primaryTitle',
          text:'Review Process and Publication',
        },
        {
          type:'text',
          text:'Review is single-blind. Selections will be based on the research abstract, singly authored by the student wishing to participate. Selected abstracts will be published in the ACM Multimedia Conference Proceedings. Doctoral students who submit to the Doctoral Symposium are encouraged to submit a paper on their research to the main conference. However, acceptance for participation in the Symposium will be based solely on the abstract written for the event. All submissions will be reviewed with respect to the overall quality of presentation, potential for future impact of the research on the field, and expected benefit to the other doctoral students attending the Doctoral Symposium.'
        },
        {
          type:'text',
          text:'<b>Important note for the authors:</b> The official publication date is the date the proceedings are made available in the ACM Digital Library. This date may be up to two weeks prior to the first day of the conference. The official publication date affects the deadline for any patent filings related to published work.'
        },
        {
          type:'text',
          text:'<b>Registration:</b> All accepted students are required to register for the conference in order to participate in the conference. Please note that the student registration is sufficient to cover a doctoral symposium accepted article. Student travel grants are available.'
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Submission Deadline: <b>June 3, 2024</b>'
        },
        {
          type:'noList',
          text:'Notification of acceptance: <b>July 15, 2024</b>'
        },
        {
          type: 'noList',
          text: 'Camera-ready Submission: <b>August 7, 2024</b>',
        },
        // {
        //   type:'text',
        //   text:'<b>Submission details:</b> to be announced.'
        // },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions regarding tutorials please email the Doctoral Symposium Chairs:'
        },
        {
          type:'noList',
          text:'<b>Stefan Winkler</b>, National University of Singapore, Singapore, (winkler@comp.nus.edu.sg).'
        },
        {
          type:'noList',
          text:'<b>Ajmal Mian</b>, University of Western Australia, Australia, (ajmal.mian@uwa.edu.au).'
        },
        {
          type:'noList',
          text:'<b>Qin Jin</b>, Renmin University of China, China, (qjin@ruc.edu.cn).'
        },
        {
          type:'noList',
          text:'<b>Reza Haffari</b>, Monash University, Australia, (Gholamreza.Haffari@monash.edu).'
        },
        {
          type:'noList',
          text:'<b>Changsheng Xu</b>, Chinese Academy of Sciences, China, (csxu@nlpr.ia.ac.cn).'
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Doctoral Symposium'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>