<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryMiddleTitle'">
          <el-col class="secondaryMiddleTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'retract_policies'">
          <el-col class="retract_policies">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "reviewer_guidelines",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Reviewer Guidelines',
      contents:[
        {
          type:'primaryTitle',
          text:'Reviewer Guidelines',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Contacting the program chairs',
        },
        {
          type:'text',
          text:`If you encounter a situation that you are unable to resolve with your AC, please contact the program chairs at acmmm2024pc@gmail.com. Please refrain from writing to the program chairs at their personal/official email addresses.
          `,
        },
        {
          type:'secondaryMiddleTitle',
          text:'Introduction',
        },
        {
          type:'text',
          text:'Thank you for agreeing to serve for ACM Multimedia 2024!'
        },
        {
          type:'text',
          text:'This page provides an overview of reviewer responsibilities and key dates.'
        },
        {
          type:'secondaryMiddleTitle',
          text:'Key dates',
        },
        {
          type:'retract_policies',
          text:'April 12, 2024 - Paper submission deadline',
        },
        {
          type:'retract_policies',
          text:'April 26, 2024 - Papers assigned to reviewers',
        },
        {
          type:'retract_policies',
          text:'May 25, 2024 - Reviews due',
        },
        {
          type:'retract_policies',
          text:'June 10-17, 2024 - Author rebuttal',
        },
        {
          type:'retract_policies',
          text:'June 18-24, 2024 - ACs discuss papers with reviewers; reviewers enter final recommendation',
        },
        {
          type:'secondaryMiddleTitle',
          text:'General information',
        },
        {
          type:'retract_policies',
          text:'Please respect deadlines and respond to emails as promptly as possible!',
        },
        {
          type:'retract_policies',
          text:'It is crucial that we are able to reach you in a timely manner. We will send most emails from OpenReview (i.e., noreply@openreview.net). Such emails are sometimes accidentally marked as spam. Please check your spam folder regularly. If you find such an email in there, please whitelist the OpenReview email address so that you will receive future emails from OpenReview.',
        },
        {
          type:'retract_policies',
          text:'If you have changed or plan to change your email address, please update OpenReview accordingly. We have no way of knowing whether an email sent to you from OpenReview has bounced, so it is crucial that you make sure that OpenReview has the correct email address for you at all times. You should also make sure that your domain conflicts in OpenReview are up to date; these are important for preventing conflicts-of-interest during the review process.',
        },
        {
          type:'retract_policies',
          text:'Please use these <a href="https://docs.google.com/document/d/1nhI4qKfpU4PNIsIjQgfVKF9iR6nfdTUs8PZA7CkFi2w/edit?usp=sharing">guidelines to evaluate Conflict of Interest</a>.',
        },
        {
          type:'retract_policies',
          text:'Please log into OpenReview to make sure that your profile is up to date. Using an old/stale profile causes poor assignments, outside your area of expertise.',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Responsibilities',
        },
        {
          type:'retract_policies',
          text:'Each reviewer will be assigned 5-7 submissions. Reviewers are responsible for reviewing submissions, reading author rebuttal, discussing submissions and author rebuttal with other reviewers and area chairs (ACs), and helping make decisions. The reviewing process is double blind at the level of reviewers and ACs. There are no physical meetings; discussions with other reviewers and ACs will take place via OpenReview. Reviewer identities are visible to other reviewers, ACs and SACs. After decisions have been made, reviews and meta-reviews for the accepted papers will be made public (but reviewer and SAC/AC identities will remain anonymous). <b>Also, the discussions between the reviewers and AC/SAC will remain private. The public can see the reviews but not add or make changes to them.</b>',
        },
        {
          type:'retract_policies',
          text:'As soon as reviewers are notified of papers to review, reviewers are expected to log in to OpenReview to check for conflicts and to check that papers fall within the area of expertise. If reviewers are not comfortable reviewing a paper, they need to fill out a note in OpenReview and communicate to their AC immediately, because it takes time to find an alternate qualified reviewer.',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Reviewer best practices',
        },
        {
          type:'retract_policies',
          text:'It is okay to be unavailable for part of the review process (e.g., on vacation for a few days), but if you will be unavailable for more than that -- especially during important windows (e.g., discussion) -- you must let your ACs know.',
        },
        {
          type:'retract_policies',
          text:'With great power comes great responsibility! Take your job seriously and be fair.',
        },
        {
          type:'retract_policies',
          text:'Write thoughtful and constructive reviews. Although the double-blind review process reduces the risk of discrimination, reviews can inadvertently contain subtle discrimination, which should be actively avoided. <br>Example: avoid comments regarding English style or grammar that may be interpreted as implying the author is "foreign" or "non-native". So, instead of "Please have your submission proof-read by a native English speaker,” use a neutral formulation such as "Please have your submission proof-read for English style and grammar issues.”',
        },
        {
          type:'retract_policies',
          text:'Example: avoid comments regarding English style or grammar that may be interpreted as implying the author is "foreign" or "non-native". So, instead of "Please have your submission proof-read by a native English speaker,” use a neutral formulation such as "Please have your submission proof-read for English style and grammar issues.”',
        },
        {
          type:'retract_policies',
          text:'DO NOT talk to other reviewers, ACs, or SACs about submissions that are assigned to you without prior approval from your AC; other reviewers, ACs, and SACs may have conflicts with these submissions. In general, your primary point of contact for any discussions should be the corresponding AC for that submission.',
        },
        {
          type:'retract_policies',
          text:'DO NOT talk to other reviewers, ACs, or SACs about your own submissions (i.e., submissions you are an author on) or submissions with which you have a conflict.',
        },
        {
          type:'retract_policies',
          text:'Keep papers assigned to you in absolute confidentiality.',
        },
        {
          type:'retract_policies',
          text:'Be professional, polite, and listen to the other reviewers, but do not give in to undue influence.',
        },
        {
          type:'retract_policies',
          text:'Engage actively in the discussion phase for each of the submissions that you are assigned, even if you are not specifically prompted to do so by the AC.',
        },
        {
          type:'retract_policies',
          text:'It is not fair to dismiss any submission without having thoroughly read it. Think about the times when you received an unfair, unjustified, short, or dismissive review. Try not to be that reviewer! Always be constructive and help the authors understand your viewpoint, without being dismissive or using inappropriate language. If you need to cite existing work to justify one of your comments, please be as precise as possible and give a complete citation.',
        },
        {
          type:'retract_policies',
          text:'If you would like the authors to clarify something during the author response phase, please articulate this clearly in your review (e.g., “I would like to see results of experiment X” or “Can you please include details about the parameter settings used for experiment Y”).',
        },
        {
          type:'primaryTitle',
          text:'Reviewer Instructions',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Online submission system (OpenReview)',
        },
        {
          type:'text',
          text:'All reviews must be submitted via the MM’24 <a href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Conference">OpenReview site</a>. You may visit the site multiple times and revise your reviews as often as necessary. If you are both an author and a reviewer, please use the same email address for both roles in OpenReview. During the reviewing process, you will receive many emails from OpenReview (i.e., noreply@openreview.net). Such emails are sometimes accidentally marked as spam. Please check your spam folder regularly and if you find such an email in there, please whitelist the OpenReview email address so that you will receive future emails from OpenReview.',
        },
        {
          type:'text',
          text:'Never reply directly to emails sent by OpenReview.',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Confidentiality',
        },
        {
          type:'text',
          text:'You must keep everything relating to the review process confidential. Do not use ideas, code and results from submissions in your own work until they become publicly available (e.g., via a technical report or a published paper for ideas/results, via open source for code, etc.). Do not talk about or distribute submissions (whether it is the code or the ideas and results described in them) to anyone without prior approval from the program chairs. <i>Code submitted for reviewing cannot be distributed.</i>',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Double-blind reviewing',
        },
        {
          type:'text',
          text:'The reviewing process will be double blind at the level of reviewers and ACs (i.e., reviewers and ACs cannot see author identities) but not at the level of SACs and program chairs. Authors are responsible for anonymizing their submissions. In particular, they should not include author names, author affiliations, or acknowledgements in their submissions and they should avoid providing any other identifying information (even in the supplementary material). If you are assigned a submission that is not adequately anonymized (e.g., includes author names, author affiliations, acknowledgements, or other identifying information) then please contact the corresponding AC. Under no circumstances should you attempt to find out the identities of the authors for any of your assigned submissions (e.g., by searching on Google or arXiv). If you accidentally find out, please do not divulge the identities to anyone. You should not let the authors’ identities influence your decision in any way.',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Supplementary material',
        },
        {
          type:'text',
          text:'Authors may submit up to 50MB of supplementary material, such as proofs, derivations, data, source code or videos describing your work; all supplementary material must be in ZIP format. Your responsibility as a reviewer is to read and review the submission itself; looking at supplementary material is optional, and at your discretion. That said, you may wish to look at supplementary material before criticizing a submission for insufficient details, proofs, or experimental results.',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Executing Code & Clicking on Links',
        },
        {
          type:'text',
          text:'Please remember that just like any other untrusted code, any submitted code may contain security vulnerabilities. If you are planning to run the supplementary source code, please make sure you are doing this in a secure environment because this code is not vetted by our submission system. We recommend running source code (1) inside a Docker container, or (2) a Virtual Machine image (using VirtualBox or VMWare), or (3) on a network-isolated cloud instance.',
        },
        {
          type:'text',
          text:'You may wish to also be cautious about accessing other web links provided from the paper, as these may contain vulnerabilities or may log visitor IP addresses.',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Formatting instructions',
        },
        {
          type:'text',
          text:'Submissions are limited to eight content pages plus up to two additional pages for references. Formatting guidelines are available at <a href="https://2024.acmmm.org/regular-papers">https://2024.acmmm.org/regular-papers</a>. If you are assigned any submissions that violate the formatting guidelines (e.g., by decreasing margins or font size) or page limits, please notify the AC immediately.',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Dual submissions',
        },
        {
          type:'text',
          text:'Submissions that are identical or substantially similar to papers that are in submission to, have been accepted to, or have been published in other archival conferences, journals, workshops, etc. should be deemed dual submissions. Submissions that are identical or substantially similar to other ACM MM submissions should also be deemed dual submissions; submissions should be distinct and sufficiently substantial. Slicing contributions too thinly may be sufficient for submissions to be deemed dual submissions. If you suspect that a submission that has been assigned to you is a dual submission or if you require further clarification, please contact the corresponding AC.',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Review content',
        },
        {
          type:'text',
          text:'We know that serving as a reviewer for ACM MM is time consuming, but the community needs outstanding people like yourself to uphold the scientific quality of ACM MM. Review content is the primary means by which ACs, SACs, and program chairs make decisions about submissions. Please make your review as detailed and informative as possible; short, superficial reviews that venture uninformed opinions or guesses are worse than no review since they may result in the rejection of a high-quality submission.',
        },
        {
          type:'text',
          text:'Review content is also the primary means by which authors understand their submissions’ decisions. Reviews for rejected submissions help authors understand how to improve their work for other conferences or journals. Reviews for accepted submissions help authors understand how to improve their work for the camera-ready versions.',
        },
        {
          type:'text',
          text:'You will need to enter the following information in the <b>review form:</b>',
        },
        {
          type:'text',
          text:'<b>1. Paper Summary:</b> Briefly summarize the paper and its contributions',
        },
        {
          type:'text',
          text:'Summarize the paper motivation, key contributions and achievements in a paragraph of about 5 sentences.',
        },
        {
          type:'text',
          text:'Although this part of the review may not provide much new information to authors, it is invaluable to ACs, SACs, and program chairs, and it can help the authors determine whether there are misunderstandings that need to be addressed in their author response.',
        },
        {
          type:'text',
          text:'There are many examples of contributions that warrant publication at ACM MM. These contributions may be theoretical, methodological, algorithmic, empirical, connecting ideas in disparate fields (“bridge papers”), or providing a critical analysis (e.g., principled justifications of why the community is going after the wrong outcome or using the wrong types of approaches.).',
        },
        {
          type:'text',
          text:'<b>2. Suitability:</b> Is this paper suited to the MM conference (should demonstrate processing of multimedia or multimodal cues)?',
        },
        {
          type:'text',
          text:'Please rate the paper as a) unsuitable for the MM venue as it involves unimodal/unimedia processing, b) unimodal/unimedia in nature but of sufficient interest to the multimedia community, or c) definitely suitable for MM as the work demonstrates multimedia processing. With an increasing number of paper submissions every year, it is critical that reviewers identify and promote those papers that align with the community’s goals and objectives.',
        },
        {
          type:'text',
          text:'<b>3. Paper Summary & Strengths:</b> Summarize the paper and describe the strengths of the work. Typical criteria include: soundness of the claims (theoretical grounding, empirical evaluation), significance and novelty of the contribution, and relevance to the ACM MM community.',
        },
        {
          type:'text',
          text:'Briefly summarize the paper in 3-5 sentences and list the strengths of the submission. For instance, it could be about the soundness of the theoretical claim or the soundness of empirical methodology used to validate an empirical approach. Another important axis is the significance and the novelty of the contributions relative to what has been done already in the literature, and here you may want to cite these relevant prior works. One measure of the significance of a contribution is (your belief about) the level to which researchers or practitioners will make use of or be influenced by the proposed ideas. Solid, technical papers that explore new territory or point out new directions for research are preferable to papers that advance the state of the art, but only incrementally. Finally, a possible strength is the relevance of the line of work for the ACM Multimedia community. Strong multimedia papers often combine multiple modalities of data in interesting novel ways.',
        },
        {
          type:'text',
          text:'<b>4. Weaknesses:</b> Explain the limitations of this work along the same axes as above.',
        },
        {
          type:'text',
          text:'This is like above, but now focusing on the limitations of this work.',
        },
        {
          type:'text',
          text:'Your comments should be detailed, specific, and polite. Please avoid vague, subjective complaints. Think about the times when you received an unfair, unjustified, short, or dismissive review. Try not to be that reviewer! Always be constructive and help the authors understand your viewpoint, without being dismissive or using inappropriate language. Remember that you are not reviewing your level of interest in the submission, but its scientific contribution to the field!',
        },
        {
          type:'text',
          text:'<b>5. Preliminary Rating:</b>',
        },
        {
          type:'text',
          text:'Rate the paper outcome as one among the following:',
        },
        {
          type:'text',
          text:'Reject: This submission should definitely not be presented at ACM Multimedia 2024.',
        },
        {
          type:'text',
          text:'Weak Reject: I would need strong arguments to accept this submission.',
        },
        {
          type:'text',
          text:'Borderline Reject - I lean towards rejection, but I could be convinced otherwise.',
        },
        {
          type:'text',
          text:'Borderline Accept - I lean towards acceptance, but I could be convinced otherwise.',
        },
        {
          type:'text',
          text:'Weak Accept - I would need strong arguments to reject this submission.',
        },
        {
          type:'text',
          text:'Accept - This submission should definitely be presented at ACM Multimedia 2024.',
        },
        {
          type:'text',
          text:'<b>6. Confidence score:</b>',
        },
        {
          type:'text',
          text:'Not Confident: Your assessment is an educated guess. The submission is not in your area or the submission was difficult to understand.',
        },
        {
          type:'text',
          text:'Somewhat Confident: You are confident in your assessment, but not absolutely certain. It is not impossible that you did not understand some parts of the submission or that you are unfamiliar with some pieces of related work.',
        },
        {
          type:'text',
          text:'Confident: You are fairly confident in your assessment.  It is possible that you did not understand some parts of the submission or that you are unfamiliar with some pieces of related work.',
        },
        {
          type:'text',
          text:'Very Confident: You are absolutely certain about your assessment. You are very familiar with the related work.',
        },
        {
          type:'text',
          text:'<b>7. Award</b>',
        },
        {
          type:'text',
          text:'Yes: This paper should be considered for an outstanding paper award.',
        },
        {
          type:'text',
          text:'No: This paper should not be considered for an outstanding paper award.',
        },
        {
          type:'text',
          text:'<b>8. Confidential Comments (optional) to Area and Program Chairs</b>',
        },
        {
          type:'text',
          text:'If you have comments that you wish to be kept confidential from the authors, you can use the “Confidential Comments to Area Chair” text field. Such comments might include explicit comparisons of the submission to other submissions and criticisms that are more bluntly stated. If you accidentally find out the identities of the authors, please do not divulge the identities to anyone.',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Author rebuttal',
        },
        {
          type:'text',
          text:'Authors will be given the opportunity to respond to their reviews before decisions are made. This is to enable them to address misunderstandings, point out parts of their submissions that were overlooked, or disagree with the reviewers\' assessments. In previous years, some authors felt that their responses were ignored. As a reviewer, it is your responsibility to read and (if appropriate) respond to the rebuttal. It is not fair to ignore any author response, even for submissions that you think should be rejected. As you read the rebuttal, keep an open mind. Have you overlooked something? Please update each review to indicate that you have read the author\'s rebuttal and whether you agree or disagree with it. You should be more specific than “I have read it and my opinion remains the same.” If that is the case, you should explain why it remains the same, what the rebuttal failed to address, etc.'
        },
        {
          type:'secondaryMiddleTitle',
          text:'Discussion',
        },
        {
          type:'text',
          text:'After the author response phase, the AC for each submission will initiate a discussion via OpenReview to encourage the reviewers to come to a consensus. If the reviewers do come to a consensus, the program chairs will take it seriously; only rarely are unanimous assessments overruled. The discussion phase is especially important for borderline submissions and submissions where the reviewers’ assessments differ; most submissions fall into one or another of these categories, so please take this phase seriously. When discussing a submission, try to remember that different people have different backgrounds and different points of view. Ask yourself, “Do the other reviewers\' comments make sense?" and do consider changing your mind in light of their comments, if appropriate. That said, if you think the other reviewers are not correct, you are not required to change your mind. Reviewer consensus is valuable, but it is not mandatory.',
        },
        {
          type:'secondaryTitle',
          text:'<span style="color:black">Acknowledgment for these guidelines</span>',
        },
        {
          type:'text',
          text:'These guidelines build upon those shared by NeurIPS <a href="https://nips.cc/Conferences/2020/PaperInformation/AC-SACGuidelines">https://nips.cc/Conferences/2020/PaperInformation/AC-SACGuidelines</a>.',
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Multimedia Reviewer Guidelines'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.retract_policies {
    /* padding-left: 2.5rem; */
    text-align: left;
    /*color: #202020;*/
}

</style>