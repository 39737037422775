<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{ mainTitle }}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryMiddleTitle'">
        <el-col class="secondaryMiddleTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
          <!-- <li class="demo-text"> -->
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Regular Papers',
      contents: [
        {
          type: 'primaryTitle',
          text: 'Introduction',
        },
        {
          type: 'text',
          text: 'ACM Multimedia is the premier conference in multimedia, a research field that discusses emerging computing methods from a perspective in which each medium — e.g. images, text, audio — is a strong component of the complete, integrated exchange of information. The multimedia community has a tradition of being able to handle big data, has been a pioneer in large-scale evaluations and dataset creations, and is uniquely angled towards novel applications and cutting-edge industrial challenges. As such the conference openly embraces new intellectual angles from both industry as well as academia and welcomes submissions from related fields, such as data science, HCI, and signal processing.',
        },
        {
          type: 'text',
          text: 'ACM Multimedia 2024 calls for research papers presenting novel theoretical and algorithmic solutions to address problems across multimedia and related application fields. The conference also calls for papers presenting novel, thought-provoking ideas, and promising (preliminary) results in realizing these ideas. Topics of interest include but are not limited to four major themes of multimedia: Engagement, Experience, Systems, and Understanding.',
        },
        {
          type: 'text',
          text: 'The conference invites research papers of varying lengths from 6 to 8 pages, plus additional pages for the reference pages; i.e., the reference page(s) are not counted towards the page limit of 6 to 8 pages. Please note that there is no longer a distinction between long and short papers, but the authors may themselves decide on the appropriate length of the paper. All papers will undergo the same review process and review period.',
        },
        {
          type: 'text',
          text: 'Submit your paper to Open Review: <a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Conference">https://openreview.net/group?id=acmmm.org/ACMMM/2024/Conference</a>.',
        },
        {
          type: 'secondaryMiddleTitle',
          text: 'Theme: Engaging Users with Multimedia',
        },
        {
          type: 'text',
          text: 'The engagement of multimedia with society as a whole requires research that addresses how multimedia can be used to connect people with multimedia artifacts that meet their needs in a variety of contexts. The topic areas included under this theme include:',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Emotional and Social Signals</span>',
        },
        {
          type: 'text',
          text: 'This area focuses on the analysis of emotional, cognitive (e.g., brain-based) and interactive social behavior in the spectrum of individual to small group settings. It calls for novel contributions with a strong human-centered focus specializing in supporting or developing automated techniques for analyzing, processing, interpreting, synthesizing, or exploiting human social, affective and cognitive signals for multimedia applications.)',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Multimedia Search and Recommendation</span>',
        },
        {
          type: 'text',
          text: 'To engage user in information access, search and recommendation requires not only understanding of data but also user and context. This area calls for novel solutions for user-centric multimedia search and recommendations, in either automatic or interactive mode, with topics ranging from optimization, user intent prediction, to personalized, collaborative or exploratory algorithms. (Note: Topics focusing primarily on indexing and scalability should be submitted to <b>"Multimedia systems: Data Systems indexing and management"</b>)',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Summarization, Analytics, and Storytelling</span>',
        },
        {
          type: 'text',
          text: 'The information underlying multimedia is by nature multi-perspective. Allowing efficient multi-perspective and context-adaptive information access remains an open problem. This area calls for new and novel solutions that can compose, link, edit and summarize multimedia data into a compact but insightful, enjoyable and multi-perspective presentation to facilitate tasks such as multimedia analytics, decision making, searching and browsing.',
        },
        {
          type: 'secondaryMiddleTitle',
          text: 'Theme: Experience',
        },
        {
          type: 'text',
          text: 'One of the core tenants of our research community is that multimedia contributes to the user experience in a rich and meaningful manner. The topics organized under this theme are concerned with innovative uses of multimedia to enhance the user experience, how this experience is manifested in specific domains, and metrics for qualitatively and quantitatively measuring that experience in useful and meaningful ways. Specific topic areas addressed this year include:',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Interactions and Quality of Experience</span>',
        },
        {
          type: 'text',
          text: 'Papers under this topic area should address human-centered issues. Topics include (i) novel interaction techniques and modalities for accessing, authoring, and consuming multimedia data, (ii) design and implementation of novel interactive media (iii) new methodologies, models, and metrics to understand and/or measure multimedia quality of experience.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Art and Culture</span>',
        },
        {
          type: 'text',
          text: 'Papers under this topic area should develop techniques that enable effective engagement of the public with art and other forms of cultural expression, balancing between sophisticated computational/engineering techniques and artistic / cultural purposes. Topics include (i) digital artworks, including hybrid physical digital installations; dynamic, generative, and interactive multimedia artworks; (ii) computational tools to support creativity, cultural preservation, and curation.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Multimedia Applications</span>',
        },
        {
          type: 'text',
          text: 'Papers under this topic area should push the envelope of how multimedia can be used to improve the user experience in a rich and meaningful manner. We solicit papers that design, implement, and evaluate applications that employ multimedia data in surprising new ways or in application scenarios that user experience remains challenging based on today\'s start-of-the-art, such as immersive telepresence, distance education, and metaverse.',
        },
        {
          type: 'secondaryMiddleTitle',
          text: 'Theme: Multimedia Systems',
        },
        {
          type: 'text',
          text: 'Research in multimedia systems is generally concerned with understanding fundamental trade-offs between competing resource requirements, developing practical techniques and heuristics for realizing complex optimization and allocation strategies, and demonstrating innovative mechanisms and frameworks for building large-scale multimedia applications. Within this theme, we have focused on three target topic areas:',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Systems and Middleware</span>',
        },
        {
          type: 'text',
          text: 'This area seeks novel contributions that address performance issues in one of the systems components. Topics include operating systems, mobile systems, storage systems, distributed systems, programming systems and abstractions, and embedded systems. Papers must establish performance improvement or non-trivial trade-offs through integration of multiple systems components or enhancing one of the system components.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Transport and Delivery</span>',
        },
        {
          type: 'text',
          text: 'Papers under this topic area should address improvement to multimedia transport and delivery mechanisms over a computer network. Topics include network protocol enhancement, supporting multimedia data with network mechanisms such as SDN and NFV, in-network content placement.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Data Systems Management and Indexing</span>',
        },
        {
          type: 'text',
          text: 'Papers under this topic area should address performance issues related to data management and indexing to support multimedia access at a large scale, including browsing, searching, recommendation, analysis, processing, and mining. Topics include scalable systems and indexing techniques that support multimedia access and analytics.',
        },
        {
          type: 'secondaryMiddleTitle',
          text: 'Theme: Multimedia Content Understanding',
        },
        {
          type: 'text',
          text: 'Multimedia data types by their very nature are complex and often involve intertwined instances of different kinds of information. We can leverage this multi-modal perspective in order to extract meaning and understanding of the world, often with surprising results. Specific topics addressed this year include:',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Multimodal Fusion</span>',
        },
        {
          type: 'text',
          text: 'In the real world, some problems are addressable only through a combination of multiple media and/or modalities. This area seeks new insights and solutions of how multi-perspective media information should be fused and embedded for novel problems as well as innovative systems.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Vision and Language</span>',
        },
        {
          type: 'text',
          text: 'Recent research has driven the merging of vision and language in different ways, for example, captioning, question-answering, multi-modal chatbots. This area seeks new solutions and results that are specific to the problems of combining or bridging vision and language.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Multimedia Interpretation</span>',
        },
        {
          type: 'text',
          text: 'This area seeks novel processing of media-related information in any form that can lead to new ways of interpreting or creating multimedia content. Examples include processing of visual, audio, music, language, speech, or other modalities, for interpretation, knowledge inference, understanding and generation.',
        },
        {
          type: 'secondaryMiddleTitle',
          text: 'Theme: Multimedia in the Generative AI Era',
        },
        {
          type: 'text',
          text: 'Generative AI empowered by foundation models and multi-modal training data have made exciting advancements in the past few years. This theme collects the latest techniques and applications in this generative AI era that benefit multimedia applications. Topics of interest are listed below. ',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Multimedia Foundation Models</span>',
        },
        {
          type: 'text',
          text: 'Large language models (LLMs) are representatives of foundation models. There are numerous improvements marrying LLMs with other diverse modalities such as images, videos, and audios. This topic seeks state-of-the-art techniques in multimedia alignment, architecture design, new applications and fundamental insights.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Generative Multimedia</span>',
        },
        {
          type: 'text',
          text: 'This topic focuses on generative models such as Diffusion models and Generative Adversarial Networks that allow multimedia systems to generate content with unparalleled realism and diversity. Emphasis is also placed on interactive and personalized systems that allow for better user experience.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Social Aspects of Generative AI</span>',
        },
        {
          type: 'text',
          text: 'The success of generative AI requires deep thinking into its social impact. This topic calls for research works in promoting privacy, security fairness and transparency of generative AI models. New applications to improve social well-being and AI interpretability are also a focus.',
        },
        {
          type: 'primaryTitle',
          text: 'Important Dates',
        },
        {
          type: 'text',
          text: 'Please note: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type: 'noList',
          text: 'Abstract submission deadline: <b>April 8, 2024</b>',
        },
        {
          type: 'noList',
          text: 'Full paper submission deadline: <b>April 12, 2024</b>',
        },
        {
          type: 'noList',
          text: 'Regular Paper Reviews to Author: <b>June 10, 2024</b>',
        },
        {
          type: 'noList',
          text: 'Regular Paper Rebuttal Deadline: <b>June 17, 2024</b>',
        },
        {
          type: 'noList',
          text: 'Notification: <b>July 15, 2024</b>',
        },
        {
          type: 'noList',
          text: 'Regular Paper camera-ready submission: <b>August 7, 2024</b>',
        },
        {
          type: 'primaryTitle',
          text: 'Submission Instructions',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Submission System:</span>',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Conference">https://openreview.net/group?id=acmmm.org/ACMMM/2024/Conference</a>.'
        },
        {
          type: 'text',
          text: 'ACM MM24 uses OpenReview for paper submission and peer review. To handle conflict of interest, we require every author to (1) have an OpenReview profile and (2) keep it update with publications, employment and email addresses.'
        },
        {
          type: 'text',
          text: 'Important Note: We may desk-reject papers which has authors without a valid OpenReview profile after the full paper submission deadline <b>(April 12, 2024)</b>.'
        },
        {
          type: 'text',
          text: 'For any question or inquiry, please contact <a class="demo-link" href="mailto:acmmm2024pc@gmail.com"> &lt;acmmm2024pc@gmail.com&gt;</a>'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:red">(New & Important)</span> <span style="color:black">Multimedia/multimodality statement for paper submissions:</span>',
        },
        {
          type: 'text',
          text: 'As the volume of submissions to the MM conference continues to grow annually, the SIGMM community seeks to distinguish itself from other communities such as NeurIPS, CVPR, and ECCV. Our focus lies in promoting research that is inherently multimedia or multimodal in nature. To achieve this, all paper authors are required to submit a concise 200-word statement outlining how their work contributes to the advancement of multimedia and multimodal processing. While papers that involve unimedia/unimodal processing will not necessarily be rejected, papers that make multimedia/multimodal research contributions will be preferred for publication in the conference proceedings.'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:red">(New & Important)</span> <span style="color:black"> Paper Format:</span>',
        },
        {
          type: 'text',
          text: 'Submitted papers (.pdf format) must use the ACM Article Template: <a class="demo-link" href="/files/ACM-MM24-paper-templates.zip">paper template</a>. Please remember to add Concepts and Keywords. Please use the template in traditional double-column format to prepare your submissions. For example, word users may use <b>Word Interim Template</b>, and latex users may use <b>sample-sigconf-authordraft</b> template. When using <b>sample-sigconf-authordraft</b> template, please comment all the author information for submission and review of manuscript, instead of changing the documentclass command to <b>\'\\documentclass[manuscript, screen, review]{acmart}\'</b> as told by instructions.'
        },
        {
          type: 'text',
          text: 'Pls ensure that you submit your papers subscribing to <b>this format</b> for full consideration during the review process.'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:red">(New & Important)</span> <span style="color:black">Policy on authorship and generative AI tools:</span>',
        },
        {
          type: 'text',
          text: 'Generative AI tools and technologies, such as ChatGPT, may not be listed as authors of an ACM published Work. The use of generative AI tools and technologies to create content is permitted but must be fully disclosed in the Work. If generative AI language tools generate inappropriate language, plagiarized content, errors, mistakes, incorrect references, or misleading content, and that output is included in scientific works, author(s) will take full responsibility for the same. For details and more polices, please see <a class="demo-link" href="https://2024.acmmm.org/policies">here</a>.'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Length:</span>',
        },
        {
          type: 'text',
          text: 'Submitted papers may consist of up to 8 pages. Up to two additional pages may be added for references. The reference pages must only contain references. Overlength papers will be rejected without review. Optionally, you may upload supplementary material that complements your submission (<b>50Mb</b> limit).'
        },
        {
          type: 'text',
          text: 'All the content other than that in the main paper should be written in the separate supplementary material. We do not allow appendix that follow right after the main paper in the main submission file.'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Blinding:</span>',
        },
        {
          type: 'text',
          text: 'Paper submissions must conform with the “double-blind” review policy. This means that the authors should not know the names of the reviewers of their papers, and reviewers should not know the names of the authors. Please prepare your paper in a way that preserves anonymity of the authors.'
        },
        {
          type: 'noList',
          text: 'Do not put the authors’ names under the title.',
        },
        {
          type: 'noList',
          text: 'Avoid using phrases such as “our previous work” when referring to earlier publications by the authors.',
        },
        {
          type: 'noList',
          text: 'Remove information that may identify the authors in the acknowledgments (e.g., co-workers and grant IDs).',
        },
        {
          type: 'noList',
          text: 'Check supplemental material (e.g., titles in the video clips, or supplementary documents) for information that may identify the authors’ identities.',
        },
        {
          type: 'noList',
          text: 'Avoid providing links to websites that identify the authors.',
        },
        {
          type: 'text',
          text: 'Papers without appropriate blinding will be rejected without review.'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Originality:</span>',
        },
        {
          type: 'text',
          text: 'Papers submitted to ACM Multimedia must be the original work of the authors. The may not be simultaneously under review elsewhere. Publications that have been peer-reviewed and have appeared at other conferences or workshops may not be submitted to ACM Multimedia (see also the arXiv/Archive policy below). Authors should be aware that ACM has a strict policy with regard to plagiarism and self-plagiarism (<a class="demo-link" href="https://www.acm.org/publications/policies/plagiarism">https://www.acm.org/publications/policies/plagiarism</a>). The authors’ prior work must be cited appropriately.'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Author List:</span>',
        },
        {
          type: 'text',
          text: 'Please ensure that you submit your papers with the full and final list of authors in the correct order. The author list registered for each submission is not allowed to change in any way after the <b>full paper submission deadline</b>. (Note that this rule regards the identity of authors, e.g., typos are correctable.)'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Paper title:</span>',
        },
        {
          type: 'text',
          text: 'As per MM Policy, paper titles should remain the same between the camera-ready version and submission.'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Proofreading:</span>',
        },
        {
          type: 'text',
          text: 'Please proofread your submission carefully. It is essential that the language used in the paper is clear and correct so that it is easily understandable. (Either US English or UK English spelling conventions are acceptable.)'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">ArXiv/Archive Policy:</span>',
        },
        {
          type: 'text',
          text: 'In accordance with ACM guidelines, all SIGMM-sponsored conferences adhere to the following policy regarding arXiv papers:'
        },
        {
          type: 'noList',
          text: 'We define a publication as a written piece documenting scientific work that was submitted for review by peers for either acceptance or rejection, and, after review, has been accepted.',
        },
        {
          type: 'noList',
          text: 'Documentation of scientific work that is published in a not-for-profit archive without any form of peer-review (departmental Technical Report, arXiv.org, etc.) is <b>not</b> considered a publication.',
        },
        {
          type: 'noList',
          text: 'However, this definition of publication does include peer-reviewed workshop papers, even if they do not appear in formal proceedings. Any submission to ACM Multimedia must not have substantial overlap with prior publications or other work currently undergoing peer review anywhere.',
        },
        {
          type: 'text',
          text: 'Note that documents published on website archives are subject to change. Citing such documents is discouraged. Furthermore, ACM Multimedia will review the documents formally submitted and any additional information in a web archive version will not affect the review.'
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Rebuttal Policies:</span>',
        },
        {
          type: 'text',
          text: 'After receiving the reviews, the authors may optionally submit a rebuttal to address the reviewers\' comments. The rebuttal is limited to a One page PDF file using the <a class="demo-link" href="/files/template-for-rebuttal-acmmm24.zip">rebuttal template</a>. Responses longer than one page will simply not be reviewed. This includes responses where the margins and formatting are deemed to have been significantly altered from those specified by the style guide (deleting the title to get some space is acceptable).'
        },
        {
          type: 'text',
          text: 'The rebuttal must maintain anonymity. It cannot include links to external material such as code, videos, etc.'
        },
        {
          type: 'text',
          text: 'Authors may optionally contact the <a class="demo-link" href="/authors-advocate">Author’s Advocate</a>, whose role is to listen to the authors, and to help them if reviews are clearly below average quality. The Author’s Advocate operates independently from the Technical Program Committee.'
        },
        {
          type: 'primaryTitle',
          text: 'Contacts',
        },
        {
          type: 'text',
          text: 'For any questions, please contact the Technical Program Chairs: <a class="demo-link" href="mailto:acmmm2024pc@gmail.com"> &lt;acmmm2024pc@gmail.com&gt;</a>:'
        },
        {
          type: 'noList',
          text: '<b>Ramanathan Subramanian</b>, University of Canberra, Australia',
        },
        {
          type: 'noList',
          text: '<b>Vivek K. Singh</b>, Rutgers University, USA',
        },
        {
          type: 'noList',
          text: '<b>Lexing Xie</b> , Australian National University, Australia',
        },
        {
          type: 'noList',
          text: '<b>Pablo Cesar</b> , Centrum Wiskunde & Informatica, Netherlands',
        },
        {
          type: 'noList',
          text: '<b>Liang Zheng</b> , Australian National University, Australia',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Regular Papers'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>