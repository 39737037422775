<template>
  <div >
    <el-row class="acmfooter_top">
      <p> Sponsors: </p>
      <el-col span="8" v-for="(item,index) in logoImage" :key="index">
        <div class="sponsors">
            <img class="sponsorImage" @click="openLink(item.link)" :src="item.src" alt="Sponsors" >
        </div>
      </el-col>
      <!-- <el-col>
        <table>
          <tr>
            <th class="sponsors" v-for="(item,index) in logoImage" :key="index">
                <img class="sponsorImage" @click="openLink(item.link)" :src="item.src" alt="Sponsors" >
            </th>
          </tr>
        </table>
      </el-col> -->
      
    </el-row>
    <el-row class="acmfooter_bottom">
      <el-col>
        <p>© ACM Multimedia 2024</p>
      </el-col>
    </el-row>
    
  </div>
</template>

<script>
export default {
  name: "acmfooter",
  data() {
    return {
      logoImage: [
        {
          src: require("@/assets/sponsors/Melbourne_Convention_Bureau_logo.png"),
          link: 'https://www.melbournecb.com.au/',
        },
        {
          src: require("@/assets/sponsors/business_event_aus.png"),
          link: 'https://businessevents.australia.com/en',
        },
        {
          src: require("@/assets/sponsors/Victorian_State_Government_logo.png"),
          link: 'https://djsir.vic.gov.au/',
        },
        
        
      ],
    }
  },
  methods:{
    openLink: function(link){
      window.open(link, '_blank');
    }
  }
}
</script>

<style scoped>
.acmfooter_bottom{
  height: 80px;
  /*background: #0054B0;*/
  /* background: black; */
  background: #2C9370;
  text-align: center;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* color: #05cedd; */
  color: #FFFFFF;
  line-height: 80px;
}

.acmfooter_top{
  /* height: 100px; */
  /* background: #05cedd; */
  /* background: black; */
  text-align: center;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: black;
  /* line-height: 80px; */
}

.acmfooter_top > p{
  margin: auto;
}

.sponsors {
  /* display: inline-block; */
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content:center;
  /* align-content: center; */
  align-items: center;
}

.sponsors > img{
  width: 30%;
  height: 30%;
  margin-right: 1.2rem;
  margin-left: 0.2rem;
}

/* .sponsors:first-child{
  justify-content: right;
}

.sponsors:last-child{
  justify-content: left;
} */

@media screen and (max-width: 750px){
  .acmfooter{
    font-size: 20px;
  }

}
</style>