<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'retract_policies'">
          <el-col class="retract_policies">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "policies",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'ACM Multimedia Policies',
      contents:[
        {
          type:'text',
          text:'ACM MM 2024 follows the ACM’s policies regarding <b>harassment, publishing,</b> and <b>ethics</b>:',
        },
        {
          type:'primaryTitle',
          text:'Harassment',
        },
        {
          type:'text',
          text:`<a class="demo-link" href="https://www.acm.org/special-interest-groups/volunteer-resources/officers-manual/policy-against-discrimination-and-harassment">ACM Policy Against Harassment at ACM Events</a> for issues related to harassment and discrimination. Reporting of incidents should be done via the online form available from the website FAQ about <a class="demo-link" href="https://www.acm.org/about-acm/reporting-unacceptable-behavior">Reporting Unacceptable Behavior at ACM Activities</a>.
          `,
        },
        {
          type:'primaryTitle',
          text:'Publishing',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://www.acm.org/publications/policies">ACM Publications Board Policies and Procedures</a> for issues related to review processes and publication. Of specific relevance to the SIGs are:'
        },
        {
          type:'retract_policies',
          text:'policy on roles and responsibilities in ACM publishing <br> <a class="demo-link" href="https://www.acm.org/publications/policies/roles-and-responsibilities">https://www.acm.org/publications/policies/roles-and-responsibilities</a>.',
        },
        {
          type:'retract_policies',
          text:'policy on coercion and abuse in the ACM publications process <br> <a class="demo-link" href="https://www.acm.org/publications/policies/coercion-and-abuse">https://www.acm.org/publications/policies/coercion-and-abuse</a>.',
        },
        {
          type:'retract_policies',
          text:'policy on plagiarism, misrepresentation, and falsification <br> <a class="demo-link" href="https://www.acm.org/publications/policies/plagiarism-overview">https://www.acm.org/publications/policies/plagiarism-overview</a>.',
        },
        {
          type:'retract_policies',
          text:'<b>(New) policy on authorship and generative AI tools, such as ChatGPT.</b> Generative AI tools and technologies, such as ChatGPT, may not be listed as authors of an ACM published Work. The use of generative AI tools and technologies to create content is permitted but must be fully disclosed in the Work. If generative AI language tools generate inappropriate language, plagiarized content, errors, mistakes, incorrect references, or misleading content, and that output is included in scientific works, author(s) will take full responsibility for the same. <br> <a class="demo-link" href="https://www.acm.org/publications/policies/new-acm-policy-on-authorship">https://www.acm.org/publications/policies/new-acm-policy-on-authorship</a>.',
        },
        {
            type: 'text',
            text: 'Reporting of violations of these publications policies should be done via the online form for <a class="demo-link" href="https://services.acm.org/policy_violations/policy.cfm">Reporting Violations of ACM Publications Policies</a> and/or reported to the conference general chairs.',
        },
        {
          type:'primaryTitle',
          text:'Ethics',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://www.acm.org/code-of-ethics">ACM Code of Ethics and Professional Conduct</a> for other ethics issues. Reporting of ethics violations should be done via email as indicated on the website describing the <a class="demo-link" href="https://www.acm.org/code-of-ethics/enforcement-procedures">ACM Code of Ethics Enforcement Procedures</a>.'
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Multimedia Policies'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.retract_policies {
    /* padding-left: 2.5rem; */
    text-align: left;
    /*color: #202020;*/
}

</style>