<template>
  <div id="app">
    <div v-show="iftop" class="totop flexCenter" @click="backTop">
      <i class="el-icon-top" style="color: #FFFFFF"></i>
    </div>
    <router-view/>
  </div>
</template>


<script>
export default {
  data() {
    return {
      iftop: false
    }
  },
  mounted() { //监听scroll方法
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() { //及时释放
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.iftop = window.pageYOffset > 400
    },
    backTop() {
      window.scrollTo({top: 0, behavior: 'smooth',})
    }
  }
}
</script>
<style>
/*.el-carousel__item.is-animating {*/

/*  transition: all 1s ease-in-out !important;*/
/*}*/
/*.el-carousel__indicators{*/
/*  transition: all 1s ease-in-out !important;*/

/*}*/
.el-backtop {
  bottom: 20px !important;
}

* {
  font-family: "Roboto", sans-serif !important;
}

body {
  line-height: 0;
}

.el-carousel__indicators--horizontal {
  bottom: 150px !important;
}

.el-carousel__arrow {
  top: 43% !important;
  width: 5rem !important;
  height: 5rem !important;
}

.el-carousel__arrow i {
  font-size: 3rem;
  line-height: 5rem;
}
</style>
<style scoped>

/* .el-icon-top {
} */

.totop {
  text-align: center;
  position: fixed;
  bottom: 100px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  background-color: #2C9370;
  color: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 0 6px rgba(0, 0, 0, .12);
  cursor: pointer;
  z-index: 5;
}
</style>
