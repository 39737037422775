<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>

    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryMiddleTitle'">
        <el-col class="secondaryMiddleTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'image'">
        <el-col>
          <div class="contact_image">
            <img :src="content.src">
          </div>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'retract_policies'">
        <el-col class="retract_policies">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'button'">
        <el-col>
          <div class="vic_button" >
              <button @click="openLink(content.text)">Register now</button>
          </div>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'dateText'">
        <el-col class="dateText">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'feesTable'">
        <el-row class="fees-header">
          <el-col :span="13">
            <span class="first-header-text">Fee Types</span>
          </el-col>
          <el-col :span="6">
            <span class="header-text">Early Bird Rate<br>Closes 29 August 2024</span>
          </el-col>
          <el-col :span="5">
            <span class="header-text">Standard Rate<br>From 30 August 2024</span>
          </el-col>
        </el-row>

        <div class="table-context">
          <el-row class="table-row" v-for="(item ,index) in content.table" :key="index">
            <el-col :span="13">
              <span class="first-fees-text">{{ item.subject }}</span>
            </el-col>
            <el-col :span="6">
              <span class="fees-text">{{ item.early }}</span>
            </el-col>
            <el-col :span="5">
              <span class="fees-text">{{ item.standard }}</span>
            </el-col>
          </el-row>
        </div>
      </el-row>

      <el-row v-else-if="content.type === 'feesTable2'">
        <el-row class="fees-header">
          <el-col :span="24">
            <span class="first-header-text">Fee Types</span>
          </el-col>
        </el-row>

        <div class="table-context">
          <el-row class="table-row" v-for="(item ,index) in content.table" :key="index">
            <el-col :span="15">
              <span class="first-fees-text">{{ item.subject }}</span>
            </el-col>
            <el-col :span="9">
              <span class="fees-text">{{ item.price }}</span>
            </el-col>
          </el-row>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dates",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: "Registration",
      contents: [
        {
          type: 'text',
          text: 'Registration for the 32nd ACM International Conference on Multimedia (MM 24) is now open. Please read the registration information carefully before beginning the online registration.',
        },
        {
          type: 'text',
          text: 'The registration system has been opened earlier than in previous years to give those travelling from overseas ample time to complete the online registration form and receive the necessary visa support documents. It is recommended to apply for a visa at least three months before your intended departure date, and five months prior if there are any character or health concerns.  For more information on visa processing times, please visit the department\'s <a href="http://www.homeaffairs.gov.au/about/access-accountability/service-standards">website</a>.',
        },
        {
          type: 'button',
          text: 'https://icmsaust.eventsair.com/acmmm-2024---32nd-acm-international-conference-on-multimedia/registration-form'
        },
        {
          type:'primaryTitle',
          text:'Authors please note: each paper requires a registration',
        },
        {
          type: 'text',
          text: 'Each paper at the conference must be covered by at least one author registration according to the following rules:'
        },
        {
          type: 'noList',
          // text: 'One Full registration (non-student) covers only: <b>one</b> Full paper, or <b>one</b> Brave New Idea paper, or <b>one</b> Industry Demo',
          text: 'One Full registration (non-student) covers <b>only</b>: one Full paper, or one Brave New Idea paper, or one Industry Demonstration, plus one workshop/short paper',
        },
        {
          type: 'noList',
          // text: 'One Full registration (non-student) covers <b>two</b> of the following papers: Technical Demo, Doctoral Symposium, Open-source paper, Workshop paper, or Grand Challenge paper',
          text: 'One Full registration (non-student) covers <b>two</b> of the following papers: Technical Demonstration, Doctoral Symposium, Open-source paper, Workshop paper, or Grand Challenge paper ',
        },
        {
          type: 'noList',
          // text: 'One Student registration covers: <b>one</b> Technical Demo, or <b>one</b> Doctoral Symposium, or <b>one</b> Open-source paper, or <b>one</b> Workshop Paper',
          text: 'One Student registration covers <b>one</b> of the following papers: Technical Demonstration, or Doctoral Symposium, or  Open-source paper, or Workshop Paper, or one Grand Challenge paper',
        },
        {
          type: 'noList',
          // text: 'One Tutorial and Workshops or One Day Only registration (student or full) covers <b>one</b> Workshop paper',
          text: 'One Tutorial and Workshops or One Day Only registration (student or full) covers one of the following papers: Workshop paper or Grand Challenge paper',
        },
        {
          type: 'text',
          // text: 'One Tutorial and Workshops or One Day Only registration (student or full) covers <b>one</b> Workshop paper',
          text: '<b>Regular Paper authors must register before midnight (AEST) 7 August and Workshop paper authors must register before midnight (AEST) 22 August.</b>',
        },
        {
          type:'primaryTitle',
          text:'Full Conference Registration',
        },
        {
          type: 'text',
          text: '<b>Full Conference Registration entitlements include:</b>',
        },
        {
          type: 'noList',
          text: 'Access to all main conference sessions',
        },
        {
          type: 'noList',
          text: 'Access to any tutorial or workshop on Days 1 & 5',
        },
        {
          type: 'noList',
          text: 'Daily access to the MM 24 Exhibition',
        },
        {
          type: 'noList',
          text: 'Morning tea, lunch and afternoon tea daily',
        },
        {
          type: 'noList',
          text: 'A ticket to the Welcome Reception on Tuesday 29 October',
        },
        {
          type: 'noList',
          text: 'A ticket to the Conference Dinner on Wednesday 30 October for full delegate registrants',
        },
        {
          type: 'noList',
          text: 'The opportunity to purchase a ticket to the Conference Dinner on Wednesday 30 October for student registrants',
        },
        {
          type: 'text',
          text: '<b>Full Conference Registration fees</b>',
        },
        {
          type: 'text',
          text: 'All fees are quoted in Australian dollars ($AUD) and are inclusive of 10% GST (Goods and Services Tax).',
        },
        // Fee Types	Early Bird Rate 
        // Closes 15 August 2024	Standard Rate 
        // From 16 August 2024
        // ACM Member Full Delegate Registration*	$1350	$1500
        // Non Member Full Delegate Registration	$1500	$1700
        // ACM Member Student Registration**	$680	$800
        // Non Member Student Registration	$780	$900

        {
          type: 'feesTable',
          table: [
            {
              subject: 'ACM Member Full Delegate Registration*',
              early: '$1500',
              standard: '$1700',
            },
            {
              subject: 'Non Member Full Delegate Registration',
              early: '$1650',
              standard: '$1850',
            },
            {
              subject: 'ACM Member Student Registration**',
              early: '$800',
              standard: '$900',
            },
            {
              subject: 'Non Member Student Registration',
              early: '$900',
              standard: '$1000',
            },
          ]
        },
        // *Only current ACM members are eligible for the member rate. Members will be asked to provide their membership number during the registration process. 
        {
          type: 'text',
          text: '<i>*Only current ACM members are eligible for the member rate. Members will be asked to provide their membership number during the registration process.</i>'
        },
        // ** A student is defined as a holder of a student identification card from a recognised tertiary or secondary educational institution or international student card. Students must be currently enrolled in part or full time
        {
          type: 'text',
          text: '<i>** A student is defined as a holder of a student identification card from a recognised tertiary or secondary educational institution or international student card. Students must be currently enrolled in part or full time</i>'
        },
        // Tutorial/ Workshop Registration
        {
          type: 'primaryTitle',
          text: 'Tutorial/ Workshop Registration',
        },
        // Tutorial/ Workshop (Day 1 & Day 5) registration entitlements include:
        {
          type: 'text',
          text: '<b>Tutorial/ Workshop (Day 1 & Day 5) registration entitlements include:</b>'
        },
        // •	Access to any tutorial or workshop on Monday 28 October and Friday 1 November
        // •	Morning tea, lunch and afternoon tea on Monday 28 October and Friday 1 November
        // •	Opportunity to purchase a ticket to the Welcome Reception on Tuesday 29 October
        // •	Opportunity to purchase a ticket to the Conference Dinner on Wednesday 30 October
        {
          type: 'noList',
          text: 'Access to any tutorial or workshop on Monday 28 October and Friday 1 November'
        },
        {
          type: 'noList',
          text: 'Morning tea, lunch and afternoon tea on Monday 28 October and Friday 1 November'
        },
        {
          type: 'noList',
          text: 'Opportunity to purchase a ticket to the Welcome Reception on Tuesday 29 October'
        },
        {
          type: 'noList',
          text: 'Opportunity to purchase a ticket to the Conference Dinner on Wednesday 30 October'
        },
        // Tutorial/ Workshop (Day 1 & Day 5) Registrations fees
        {
          type: 'text',
          text: '<b>Tutorial/ Workshop (Day 1 & Day 5) Registrations fees</b>'
        },
        // All fees are quoted in Australian dollars ($AUD) and are inclusive of 10% GST (Goods and Services Tax).
        {
          type: 'text',
          text: 'All fees are quoted in Australian dollars ($AUD) and are inclusive of 10% GST (Goods and Services Tax).'
        },
        // Fee Types	Early Bird Rate
        // Closes 15 August 2024	Standard Rate
        // From 16 August 2024
        // ACM Member Day 1 & 5 Tutorial/Workshop Registration*	$900	$1050
        // Non Member Day 1 & 5 Tutorial/Workshop Registration	$1000	$1150
        // ACM Member Day 1 & 5 Student Tutorial/Workshop Registration*	$550	$700
        // Non Member Day 1 & 5 Student Tutorial/Workshop Registration	$650	$800
        {
          type: 'feesTable',
          table: [
            {
              subject: 'ACM Member Day 1 & 5 Tutorial/Workshop Registration*',
              early: '$900',
              standard: '$1050',
            },
            {
              subject: 'Non Member Day 1 & 5 Tutorial/Workshop Registration',
              early: '$1000',
              standard: '$1150',
            },
            {
              subject: 'ACM Member Day 1 & 5 Student Tutorial/Workshop Registration*',
              early: '$550',
              standard: '$700',
            },
            {
              subject: 'Non Member Day 1 & 5 Student Tutorial/Workshop Registration',
              early: '$650',
              standard: '$800',
            },
          ]
        },
        // *Only current ACM members are eligible for the member rate. Members will be asked to provide their membership number during the registration process. 
        {
          type: 'text',
          text: '<i>*Only current ACM members are eligible for the member rate. Members will be asked to provide their membership number during the registration process.</i>'
        },
        // ** A student is defined as a holder of a student identification card from a recognised tertiary or secondary educational institution or international student card. Students must be currently enrolled in part or full time
        {
          type: 'text',
          text: '<i>** A student is defined as a holder of a student identification card from a recognised tertiary or secondary educational institution or international student card. Students must be currently enrolled in part or full time</i>'
        },
        // One Day Only Tutorial/ Workshop registration entitlements include:
        // •	Access to any tutorial or workshop on your selected day of Monday 28 October or Friday 1 November
        // •	Morning tea, lunch and afternoon tea on your selected Tutorial/Workshop day
        // •	Opportunity to purchase a ticket to the Welcome Reception on Tuesday 29 October 
        // •	Opportunity to purchase a ticket to the Conference Dinner on Wednesday 30 October
        {
          type: 'text',
          text: '<br><b>One Day Only Tutorial/ Workshop registration entitlements include:</b>'
        },
        {
          type: 'noList',
          text: 'Access to any tutorial or workshop on your selected day of Monday 28 October or Friday 1 November'
        },
        {
          type: 'noList',
          text: 'Morning tea, lunch and afternoon tea on your selected Tutorial/Workshop day'
        },
        {
          type: 'noList',
          text: 'Opportunity to purchase a ticket to the Welcome Reception on Tuesday 29 October'
        },
        {
          type: 'noList',
          text: 'Opportunity to purchase a ticket to the Conference Dinner on Wednesday 30 October'
        },
        // One Day Only Tutorial/ Workshop Registration fees
        {
          type: 'text',
          text: '<b>One Day Only Tutorial/ Workshop Registration fees</b>'
        },
        // Fee types
        // One Day ACM Member Tutorial/Workshop Registration*	$550
        // One Day Non Member Tutorial/Workshop Registration	$650
        // One Day ACM Member Student Tutorial/Workshop Registration*	$300
        // One Day Non Member Student Tutorial/Workshop Registration	$350
        {
          type: 'feesTable2',
          table: [
            {
              subject: 'One Day ACM Member Tutorial/Workshop Registration*',
              price: '$550',
            },
            {
              subject: 'One Day Non Member Tutorial/Workshop Registration',
              price: '$650',
            },
            {
              subject: 'One Day ACM Member Student Tutorial/Workshop Registration*',
              price: '$300',
            },
            {
              subject: 'One Day Non Member Student Tutorial/Workshop Registration',
              price: '$350',
            },
          ]
        },
        // *Only current ACM members are eligible for the member rate. Members will be asked to provide their membership number during the registration process. 
        {
          type: 'text',
          text: '<i>*Only current ACM members are eligible for the member rate. Members will be asked to provide their membership number during the registration process.</i>'
        },
        // ** A student is defined as a holder of a student identification card from a recognised tertiary or secondary educational institution or international student card. Students must be currently enrolled in part or full time
        {
          type: 'text',
          text: '<i>** A student is defined as a holder of a student identification card from a recognised tertiary or secondary educational institution or international student card. Students must be currently enrolled in part or full time</i>'
        },
        // Social Functions
        {
          type: 'primaryTitle',
          text: 'Social Functions',
        },
        // Welcome Reception
        // Tuesday 29 October
        // Location: Foyer 2 and 3 at Melbourne Convention and Exhibition Centre (MCEC)
        {
          type: 'text',
          text: '<b>Welcome Reception</b>'+'<br><b>Tuesday 29 October</b>'+'<br><b>Location:</b> Foyer 2 and 3 at Melbourne Convention and Exhibition Centre (MCEC)',
        },
        // Take this opportunity to unwind and meet your peers, in a social environment, at the MM 24 Welcome Reception. One ticket to the Welcome Reception is included in each full delegate registration with additional tickets available to purchase in the online registration form. Workshop and Tutorial delegates have the opportunity to purchase a ticket through the online registration form.
        {
          type: 'text',
          text: 'Take this opportunity to unwind and meet your peers, in a social environment, at the MM 24 Welcome Reception. One ticket to the Welcome Reception is included in each full delegate registration with additional tickets available to purchase in the online registration form. Workshop and Tutorial delegates have the opportunity to purchase a ticket through the online registration form.',
        },
        // Conference Dinner
        // Wednesday 30 October
        // Location: Melbourne Room, MCEC
        {
          type: 'text',
          text: '<b>Conference Dinner</b>'+'<br><b>Wednesday 30 October</b>'+'<br><b>Location:</b> Melbourne Room, MCEC',
        },
        // Celebrate the Conference with your industry peers at the MM 24 Conference Dinner. It will be a night of delicious food, drinks entertainment and networking not to be missed!
        {
          type: 'text',
          text: 'Celebrate the Conference with your industry peers at the MM 24 Conference Dinner. It will be a night of delicious food, drinks entertainment and networking not to be missed!',
        },
        // A ticket to the Conference Dinner is included with a full conference registration. Tickets for student, workshop/tutorial registrants are available to purchase through the registration form for $165 each.
        {
          type: 'text',
          text: 'A ticket to the Conference Dinner is included with a full conference registration. Tickets for student, workshop/tutorial registrants are available to purchase through the registration form for $165 each.',
        },
        {
          type: 'primaryTitle',
          text: 'Payment Details',
        },
        // Payment Deadlines:
        // Registrations must be paid in full by Thursday 15 August 2024 in order to receive the Early Bird registration rate.
        {
          type: 'text',
          text: '<b>Payment Deadlines:</b>'+'<br>Registrations must be paid in full by Thursday 29 August 2024 in order to receive the Early Bird registration rate.',
        },
        // Method of Payment 
        // Registration can only be confirmed once payment is received
        {
          type: 'text',
          text: '<b>Method of Payment</b>'+'<br>Registration can only be confirmed once payment is received',
        },
        // Payment can be made by any of the following methods:
        // •	Credit card: Visa, MasterCard and American Express are accepted. If you are making a credit card payment from a country outside Australia, for security reasons you must first advise your banking institution of the impending charge and authorise them to allow the charge to Australia to go through. Please note that charges to your credit card will appear as being from "ICMS Australasia Pty Ltd" on your bank statement.
        // •	Bank transfer: Please include your surname in the transfer and email the transfer receipt (remittance) to the [Conference Secretariat](mailto:acmmm24@icmsaust.com.au). 
        {
          type: 'text',
          text: 'Payment can be made by any of the following methods:'
        },
        {
          type: 'noList',
          text: 'Credit card: Visa, MasterCard and American Express are accepted. If you are making a credit card payment from a country outside Australia, for security reasons you must first advise your banking institution of the impending charge and authorise them to allow the charge to Australia to go through. Please note that charges to your credit card will appear as being from "ICMS Australasia Pty Ltd" on your bank statement.'
        },
        {
          type: 'noList',
          text: 'Bank transfer: Please include your surname in the transfer and email the transfer receipt (remittance) to the <a href="mailto:acmmm24@icmsaust.com.au">Conference Secretariat</a>.'
        },
        // Cancellation & Refund Policy
        // Cancellation of Registration Deadline: Thursday 29 August 2024
        // Cancellations must be notified in writing (e-mail is sufficient) to the Conference Secretariat. Cancellations for registrations made by 29 August 2024 will result in a full refund less $150 to cover administration costs. As an alternative to cancellation, your registration may be transferred to another person by 25 October 2024 without incurring any cost penalty. The Conference Secretariat must be advised in writing of any alterations or transfers. The Organising Committee reserves final decision on a case-by-case basis for granting a cancellation in extenuating circumstances.
        {
          type:'primaryTitle',
          // color:#2C9370;
          text:'<b style="color:#2C9370;">Cancellation & Refund Policy</b>',
        },
        {
          type: 'text',
          text: '<br><b>Cancellation of Registration Deadline: Thursday 29 August 2024</b>'+'<br>Cancellations must be notified in writing (e-mail is sufficient) to the Conference Secretariat. Cancellations for registrations made by 29 August 2024 will result in a full refund less $150 to cover administration costs. As an alternative to cancellation, your registration may be transferred to another person by 25 October 2024 without incurring any cost penalty. The Conference Secretariat must be advised in writing of any alterations or transfers. The Organising Committee reserves final decision on a case-by-case basis for granting a cancellation in extenuating circumstances.',
        },
        // Registration Alterations
        // Delegates must note that some social functions may reach their capacity limits and tickets are only guaranteed on a first come first served basis. From 29 August 2024, no refunds will be provided for a transfer of registration. There will be no cancellation allowance for failure to secure a visa to Australia. It is recommended that travel insurance be secured personally upon registration. The Conference Secretariat must be advised in writing of any alterations or transfers. 
        {
          type: 'text',
          text: '<b>Registration Alterations</b>' + '<br>Delegates must note that some social functions may reach their capacity limits and tickets are only guaranteed on a first come first served basis. From 29 August 2024, no refunds will be provided for a transfer of registration. There will be no cancellation allowance for failure to secure a visa to Australia. It is recommended that travel insurance be secured personally upon registration. The Conference Secretariat must be advised in writing of any alterations or transfers.',
        },
        // Privacy Disclaimer
        // In registering for this Conference relevant details (name/organisation/country) will be incorporated into a delegate list and may be made available (if necessary) to parties directly related to the Conference including, but not limited to; venues, accommodation providers, sponsors, key suppliers and future Conferences. However, to ensure your privacy, your email address and personal information will not be shared outside of ACM and the Conference Secretariat (ICMS Australasia).
        {
          type: 'text',
          text: '<b>Privacy Disclaimer</b>' + '<br>In registering for this Conference relevant details (name/organisation/country) will be incorporated into a delegate list and may be made available (if necessary) to parties directly related to the Conference including, but not limited to; venues, accommodation providers, sponsors, key suppliers and future Conferences. However, to ensure your privacy, your email address and personal information will not be shared outside of ACM and the Conference Secretariat (ICMS Australasia).',
        },
        // Enquiries 
        // Please direct all registration enquiries to: 
        // MM 24 Conference Secretariat
        // Managed by ICMS Australasia Pty Ltd
        // P: +61 3 9682 0500
        // E: acmmm24@icmsaust.com.au 
        {
          type: 'text',
          text: '<b>Enquiries</b>' + '<br>Please direct all registration enquiries to:' + '<br><b style="color:#2C9370;">MM 24 Conference Secretariat</b>' + '<br>Managed by ICMS Australasia Pty Ltd' + '<br>P: +61 3 9682 0500' + '<br>E: <a href="mailto:acmmm24@icmsaust.com.au">acmmm24@icmsaust.com.au</a>',
        },
      ],
    }
  },
  methods:{
    openLink: function(link){
      window.open(link, '_blank');
    }
  },
}
</script>

<style scoped>

@import "../../css/responsive.css";


.icon {
  margin-top: .6rem;
  margin-right: 1rem;
  width: 3.6rem;
}

.title {
  margin-left: -1.8rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* color: #202020; */
  color: black;
}

.head_title {
  margin-left: 0rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /*color: black;*/
  /* color: #202020; */
  color: black;
}

.fees-header {
  display: flex;
  align-items: center;
  height: auto;
  /*background-color: #5EAAFB;*/
  /* background-color: #05cedd; */
  background-color: #2C9370;
}

.first-header-text {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-left: 1rem;
  line-height: 3rem;
  color: #FFFFFF;
  /* color: #05cedd; */
}

.header-text {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  /* margin-left: 1rem; */
  line-height: 3rem;
  color: #FFFFFF;
  /* color: #05cedd; */
}

.first-fees-text {
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #666666;
  margin-left: 1rem;
  line-height: 3rem;
}

.fees-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #666666;
  /* margin-left: 1rem; */
  line-height: 3rem;
}

.table-row {
  height: 3rem;
}

.table-context {
  /* min-height: 50rem; */
  border: 0.1rem solid #979797;
}

.black * {
  color: black !important;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  .icon {
    margin-top: 0rem;
  }

  .title {
    margin-left: 0;
  }
}

.vic_button{
    /* display: inline-block; */
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content:center;
    /* align-content: center; */
    align-items: center;
    /* background: #05cedd; */
    text-align: center;
    /* color: #FFFFFF; */
}

.vic_button > button{
    border: none;
    padding: 1.5rem 6rem;
    text-align: center;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    display: inline-block;
    font-size: 2rem;
    background: #2C9370;
    color: #FFFFFF;
    border-radius: 1.2rem; 
}

</style>
