<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>

    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryMiddleTitle'">
        <el-col class="secondaryMiddleTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'image'">
        <el-col>
          <div class="contact_image">
            <img :src="content.src" style="width: 100%;">
          </div>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'retract_policies'">
        <el-col class="retract_policies">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'dateText'">
        <el-col class="dateText">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  name: "Onsite Childcare",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: "Onsite Childcare at MM24",
      contents: [
        // The local organising committee is proud to announce that MM24 will be offering onsite childcare to registered attendees for the duration of the Conference.

        // Delegates who are interested in registering their children for the childcare services can book this in during the registration process for $25 AUD per child per day. Delegates who have already registered can contact the [Conference Secretariat](mailto:acmmm24@icmsaust.com.au) to have the service added to their booking.

        // Please note the capacity for the childcare is strictly limited and only available for children’s ages 1 – 10. Spaces will be allocated based on a first-come-first served basis upon confirmation of payment.

        {
          type: "text",
          text: "The local organising committee is proud to announce that MM24 will be offering onsite childcare to registered attendees for the duration of the Conference.",
        },
        {
          type: "text",
          text: "Delegates who are interested in registering their children for the childcare services can book this in during the registration process for $25 AUD per child per day. Delegates who have already registered can contact the <a href='mailto:acmmm24@icmsaust.com.au'>Conference Secretariat</a> to have the service added to their booking.",
        },
        {
          type: "text",
          text: "Please note the capacity for the childcare is strictly limited and only available for children’s ages 1 – 10. Spaces will be allocated based on a first-come-first served basis upon confirmation of payment.",
        },
        {
          type: "image",
          src: require("@/assets/images/onsite_childcare.jpg"),
        },
      ],
    }
  },
  methods:{
    openLink: function(link){
      window.open(link, '_blank');
    }
  },
}
</script>

<style scoped>

@import "../../css/responsive.css";


.icon {
  margin-top: .6rem;
  margin-right: 1rem;
  width: 3.6rem;
}

.title {
  margin-left: -1.8rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* color: #202020; */
  color: black;
}

.head_title {
  margin-left: 0rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /*color: black;*/
  /* color: #202020; */
  color: black;
}

.black * {
  color: black !important;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  .icon {
    margin-top: 0rem;
  }

  .title {
    margin-left: 0;
  }
}

.mel_banner{
    /* width: 40rem;
    height: 40rem; */
    position:relative;
    padding-bottom: 35%;
    /* position: relative; */
    /* text-align: center; */
    /* width: 200px;
    height: 200px; */
    /* overflow: hidden; */
}

.mel_banner > img{
  position:absolute;
  /* object-fit: cover; */
  top:0;left:0;
  width:100%;
  /* height:100%; */
}

</style>
