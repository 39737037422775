<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'image'">
          <el-col class="qr_text">
            <p>QR code to the EOI form:</p>
          </el-col>
          <el-col>
            <div class="contact_image">
              <img :src="content.src" alt="qr image">
            </div>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "Contact Us",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Contact Us',
      contents:[
        // {
        //   type:'primaryTitle',
        //   text:'Overview',
        // },
        {
          type:'text',
          text:'If you have any question related to ACM Multimedia 2024, please find below the appropriate contact details.',
        },
        {
          type:'retract',
          text:'General enquiries (registration, accommodation, travel information and others), please contact: <a class="demo-link" href="mailto:acmmm24@icmsaust.com.au">acmmm24@icmsaust.com.au</a>',
        },
        {
          type:'retract',
          text:'For any questions regarding the camera-ready submission, please contact us, the <b>Publication</b> Chairs at: <a class="demo-link" href="mailto:acmmm24pubchairs@gmail.com">acmmm24pubchairs@gmail.com</a>',
        },
        {
          type:'retract',
          text:'Sponsor and Exhibitor enquiries please contact: <a class="demo-link" href="mailto:mm24sponsorship@gmail.com">mm24sponsorship@gmail.com</a>',
        },
        {
          type:'retract',
          text:'Website-related enquiries, please contact: <a class="demo-link" href="mailto:acmmm24adm@gmail.com">acmmm24adm@gmail.com</a>',
        },
        {
          type:'retract',
          text:'For any <span style="color:black;font-weight: 600">delegate, sponsor</span> or <span style="color:black;font-weight: 600">exhibitor</span> who are interested in ACM Multimedia 2024, please feel free to contact us by this <a class="demo-link" href="https://icmsaust.eventsair.com/acmmm-2024---32nd-acm-international-conference-on-multimedia/eoi-form/Site/Register" target="_blank">link</a>.',
        },
        {
          type:'image',
          text:'<img src="@/assets/images/register_qr.png" alt="register QR code">',
          src: require("@/assets/images/register_qr.png"),
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Contact'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";

.contact_image{

  display: flex;
  flex-direction: row;
  justify-content:center;
  /* align-content: center; */
  align-items: center;
  /* background: #05cedd; */
  text-align: center;
  /* height: 50%; */
}

.contact_image > img{
  /*padding-top: 2rem;*/
  width: 110px;
}

.qr_text{
  /* display: inline-block; */
/*    margin-top: 1rem;
    margin-bottom: 1rem;*/
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content:center;
    /* align-content: center; */
    align-items: center;
    /* background: #05cedd; */
    text-align: center;
    /* color: #FFFFFF; */
}

.qr_text > p{
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-decoration: none;
}

</style>