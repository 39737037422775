<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>

    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryMiddleTitle'">
        <el-col class="secondaryMiddleTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'image'">
        <el-col>
          <div class="contact_image">
            <img :src="content.src">
          </div>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'retract_policies'">
        <el-col class="retract_policies">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'dateText'">
        <el-col class="dateText">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  name: "Dates",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: "Visa Information",
      contents: [        
        // Letters of Invitation for Visa applications:
        // Please complete your registration online to receive your letter of invitation. Invitations will be issued automatically as part of the registration confirmation process.
        {
          type: "text",
          text: "<b>Letters of Invitation for Visa applications:</b>"+"<br>Please complete your registration online to receive your letter of invitation. Invitations will be issued automatically as part of the registration confirmation process."
        },
        // Visa options for Conferences, Congresses, Trade Fairs, Seminars or Expos
        {
          type: "secondaryMiddleTitle",
          text: "Visa options for Conferences, Congresses, Trade Fairs, Seminars or Expos"
        },
        // All visitors to Australia must have a valid visa to travel to and enter Australia (other than New Zealand passport holders who will normally be granted a Special Category visa on arrival, provided they meet health and character requirements; and permanent residents of Norfolk Island who may be granted a Permanent Resident of Norfolk Island visa on arrival).
        {
          type: "text",
          text: "All visitors to Australia must have a valid visa to travel to and enter Australia (other than New Zealand passport holders who will normally be granted a Special Category visa on arrival, provided they meet health and character requirements; and permanent residents of Norfolk Island who may be granted a Permanent Resident of Norfolk Island visa on arrival)."
        },
        // If you are planning to visit Australia for a business event such as a conference, trade fair, seminar or expo, there may be different visa options available to you. The appropriate visa will depend, amongst other things, on your purpose for visiting Australia.
        {
          type: "text",
          text: "If you are planning to visit Australia for a business event such as a conference, trade fair, seminar or expo, there may be different visa options available to you. The appropriate visa will depend, amongst other things, on your purpose for visiting Australia."
        },
        // Visa Options
        {
          type: "secondaryMiddleTitle",
          text: "Visa Options"
        },
        // The most common Visa’s that may be recommended to you for short travel to Australia to attend a conference or congress are;
        {
          type: "text",
          text: "The most common Visa’s that may be recommended to you for short travel to Australia to attend a conference or congress are;"
        },
        // 1. ETA (601)
        {
          type: "text",
          text: "1. ETA (601)"
        },
        // •	Online Application Process
        {
          type: "retract",
          text: "Online Application Process"
        },
        // •	Quick
        {
          type: "retract",
          text: "Quick"
        },
        // Eligibility for an eVisitor visa and ETA will be determined by the country of passport that the applicant holds. The ETA is a quick, online, charge free process, but incurs a AUD$20 service provider charge, and/or a further charge if applied for through a travel agency, airline agency or specialist service provider. Further information on the ETA can be found on the [website](https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/electronic-travel-authority-601).
        {
          type: "text",
          text: "Eligibility for an eVisitor visa and ETA will be determined by the country of passport that the applicant holds. The ETA is a quick, online, charge free process, but incurs a AUD$20 service provider charge, and/or a further charge if applied for through a travel agency, airline agency or specialist service provider. Further information on the ETA can be found on the <a href='https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/electronic-travel-authority-601' target='_blank'>website</a>."
        },
        // 2. eVisitor (651)
        {
          type: "text",
          text: "2. eVisitor (651)"
        },
        // •	Online Application Process
        // •	Quick
        // •	One working day processing time
        // •	No Application charge or service fee
        {
          type: "retract",
          text: "Online Application Process"
        },
        {
          type: "retract",
          text: "Quick"
        },
        {
          type: "retract",
          text: "One working day processing time"
        },
        {
          type: "retract",
          text: "No Application charge or service fee"
        },
        // Eligibility for an eVisitor visa and ETA will be determined by the country of passport that the applicant holds. The eVisitor visa is an online visa process, with a one working day processing time for low risk applications. There is no visa application charge or service fee. Further information on the eVisitor visa, including how to apply online, can be found on the [website](https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/evisitor-651).
        {
          type: "text",
          text: "Eligibility for an eVisitor visa and ETA will be determined by the country of passport that the applicant holds. The eVisitor visa is an online visa process, with a one working day processing time for low risk applications. There is no visa application charge or service fee. Further information on the eVisitor visa, including how to apply online, can be found on the <a href='https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/evisitor-651' target='_blank'>website</a>."
        },
        // 3. Visitor Visa (subclass 600) in the Business Visitor stream
        {
          type: "text",
          text: "3. Visitor Visa (subclass 600) in the Business Visitor stream"
        },
        // •	Online or Paper Application Process
        // Passport holders who are not eligible to apply for an eVisitor or an ETA should apply for a Visitor Visa (subclass 600) in the Business Visitor Stream. 
        // •	Online application process
        // •	Application fee of $140 AUD
        {
          type: "retract",
          text: "Online or Paper Application Process"
        },
        {
          type: "text",
          text: "Passport holders who are not eligible to apply for an eVisitor or an ETA should apply for a Visitor Visa (subclass 600) in the Business Visitor Stream."
        },
        {
          type: "retract",
          text: "Online application process"
        },
        {
          type: "retract",
          text: "Application fee of $140 AUD"
        },
        // Further information on the Subclass 600 Visa can be found on the [website](https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/visitor-600/business-visitor-stream).
        {
          type: "text",
          text: "Further information on the Subclass 600 Visa can be found on the <a href='https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/visitor-600/business-visitor-stream' target='_blank'>website</a>."
        },

        // Things to consider before lodging an application:
        {
          type: "secondaryMiddleTitle",
          text: "Things to consider before lodging an application:"
        },

        // Invitation and registration to attend the event
        {
          type: "text",
          text: "<b>Invitation and registration to attend the event</b>"
        },
        // Although this is a separate process to lodging your visa application, when assessing whether you meet the criteria for the grant of a visa, the department may take into account whether you are registered or were invited to attend the event.
        {
          type: "text",
          text: "Although this is a separate process to lodging your visa application, when assessing whether you meet the criteria for the grant of a visa, the department may take into account whether you are registered or were invited to attend the event."
        },
        // Confirmation of registration, or a copy of your letter of invitation, should accompany visa applications. For online subclass 400and subclass 600 applications, information about documents that need to be attached will be provided as part of the application process.
        {
          type: "text",
          text: "Confirmation of registration, or a copy of your letter of invitation, should accompany visa applications. For online subclass 400and subclass 600 applications, information about documents that need to be attached will be provided as part of the application process."
        },
        // To request a letter of invitation please contact the secretariat.
        {
          type: "text",
          text: "To request a letter of invitation please contact the secretariat."
        },

        // Cut-off date for lodging paper based applications
        {
          type: "text",
          text: "<b>Cut-off date for lodging paper based applications</b>"
        },
        // You should apply for a visa at least two months prior to the intended date of departure, and four months prior if there are any character or health concerns. More information about visa processing times is available on the department’s [website](http://www.homeaffairs.gov.au/about/access-accountability/service-standards).
        {
          type: "text",
          text: "You should apply for a visa at least two months prior to the intended date of departure, and four months prior if there are any character or health concerns. More information about visa processing times is available on the department’s <a href='http://www.homeaffairs.gov.au/about/access-accountability/service-standards' target='_blank'>website</a>."
        },

        // Health and character requirements
        {
          type: "text",
          text: "<b>Health and character requirements</b>"
        },
        // All visa applicants must be assessed against Australia’s health and character requirements. These requirements are designed to protect the safety and security of the Australian community.
        {
          type: "text",
          text: "All visa applicants must be assessed against Australia’s health and character requirements. These requirements are designed to protect the safety and security of the Australian community."
        },

        // Health requirements
        {
          type: "text",
          text: "<b>Health requirements</b>"
        },
        // As part of the visa application process you may be required to undertake a medical examination, chest x ray and/or other health checks.
        {
          type: "text",
          text: "As part of the visa application process you may be required to undertake a medical examination, chest x ray and/or other health checks."
        },
        // The Department of Immigration and Border Protection (the department) will advise you of the applicable health checks (if any), based on your individual circumstances. Information on which health checks may apply to you is available on the department’s [website](http://www.homeaffairs.gov.au/Trav/Visa/Heal).
        {
          type: "text",
          text: "The Department of Immigration and Border Protection (the department) will advise you of the applicable health checks (if any), based on your individual circumstances. Information on which health checks may apply to you is available on the department’s <a href='http://www.homeaffairs.gov.au/Trav/Visa/Heal' target='_blank'>website</a>."
        },

        // Character requirements
        {
          type: "text",
          text: "<b>Character requirements</b>"
        },
        // As part of the visa application assessment process the onus is on you to satisfy the character requirements set out in Section 501of the Migration Act 1958. Further information on the character requirement is available on the department’s [website](http://www.homeaffairs.gov.au/Trav/Visa/Char).
        {
          type: "text",
          text: "As part of the visa application assessment process the onus is on you to satisfy the character requirements set out in Section 501of the Migration Act 1958. Further information on the character requirement is available on the department’s <a href='http://www.homeaffairs.gov.au/Trav/Visa/Char' target='_blank'>website</a>."
        },
        // If you are unsure whether you satisfy the character requirements because of prior criminal convictions, you should lodge a paper based application at the Australian visa office nearest to your current place of residence, at least four months prior to the event start date.
        {
          type: "text",
          text: "If you are unsure whether you satisfy the character requirements because of prior criminal convictions, you should lodge a paper based application at the Australian visa office nearest to your current place of residence, at least four months prior to the event start date."
        },
        // Contact information for Australian visa offices is available on the department’s [website](http://www.homeaffairs.gov.au/about/contact/offices-locations).
        {
          type: "text",
          text: "Contact information for Australian visa offices is available on the department’s <a href='http://www.homeaffairs.gov.au/about/contact/offices-locations' target='_blank'>website</a>."
        },
        // Travel sanctions
        {
          type: "text",
          text: "<b>Travel sanctions</b>"
        },
        // For information on countries subject to travel sanctions, please refer to the Department of Foreign Affairs and Trade’s [website](http://www.dfat.gov.au/international-relations/security/sanctions/pages/sanctions.aspx). Nationals of these countries may not be permitted to travel to and enter Australia.
        {
          type: "text",
          text: "For information on countries subject to travel sanctions, please refer to the Department of Foreign Affairs and Trade’s <a href='http://www.dfat.gov.au/international-relations/security/sanctions/pages/sanctions.aspx' target='_blank'>website</a>. Nationals of these countries may not be permitted to travel to and enter Australia."
        },
        // Biometrics Programme
        {
          type: "text",
          text: "<b>Biometrics Programme</b>"
        },
        // Biometrics may need to be collected at an Australian Visa Application Centre for visitors and business people lodging a visa application from the countries listed on the department’s [website](http://www.homeaffairs.gov.au/Trav/Visa/Biom).
        {
          type: "text",
          text: "Biometrics may need to be collected at an Australian Visa Application Centre for visitors and business people lodging a visa application from the countries listed on the department’s <a href='http://www.homeaffairs.gov.au/Trav/Visa/Biom' target='_blank'>website</a>."
        },
        // If you lodge your application online, the department will advise you in writing if you need to provide your biometrics.
        {
          type: "text",
          text: "If you lodge your application online, the department will advise you in writing if you need to provide your biometrics."
        },
        // Obligations of visa holders
        {
          type: "text",
          text: "<b>Obligations of visa holders</b>"
        },
        // On arrival at Australia’s border you must identify yourself, present your travel documents, and complete an Incoming Passenger Card(IPC). The IPC requires visitors to declare their health status and any prior criminal convictions.
        {
          type: "text",
          text: "On arrival at Australia’s border you must identify yourself, present your travel documents, and complete an Incoming Passenger Card(IPC). The IPC requires visitors to declare their health status and any prior criminal convictions."
        },
        // If you do not meet immigration clearance requirements, you may be refused entry to Australia.
        {
          type: "text",
          text: "If you do not meet immigration clearance requirements, you may be refused entry to Australia."
        },
        // More information on immigration clearance processes at Australia’s borders is available on the department’s [website](https://www.abf.gov.au/entering-and-leaving-australia/crossing-the-border/at-the-border).
        {
          type: "text",
          text: "More information on immigration clearance processes at Australia’s borders is available on the department’s <a href='https://www.abf.gov.au/entering-and-leaving-australia/crossing-the-border/at-the-border' target='_blank'>website</a>."
        },
        // You must comply with your visa validity and visa conditions while in Australia. When you receive your Australian visa you are issued with a visa grant notice that explains the conditions of the visa including period of validity and entry requirements. You are advised to carry this visa grant notice when travelling for your own reference.
        {
          type: "text",
          text: "You must comply with your visa validity and visa conditions while in Australia. When you receive your Australian visa you are issued with a visa grant notice that explains the conditions of the visa including period of validity and entry requirements. You are advised to carry this visa grant notice when travelling for your own reference."
        },
        // Your visa may be cancelled if you breach any of the conditions.
        {
          type: "text",
          text: "Your visa may be cancelled if you breach any of the conditions."
        },
        // If your visa expires while you are still in Australia, you become an unlawful non-citizen. Unlawful non-citizens are liable to be detained and removed from Australia.
        {
          type: "text",
          text: "If your visa expires while you are still in Australia, you become an unlawful non-citizen. Unlawful non-citizens are liable to be detained and removed from Australia."
        },
        // Enquiries 
        // Please direct all registration enquiries to: 
        // MM 24 Conference Secretariat
        // Managed by ICMS Australasia Pty Ltd
        // P: +61 3 9682 0500
        // E: acmmm24@icmsaust.com.au 
        {
          type: 'text',
          text: '<b>Enquiries</b>' + '<br>Please direct all registration enquiries to:' + '<br><b style="color:#2C9370;">MM 24 Conference Secretariat</b>' + '<br>Managed by ICMS Australasia Pty Ltd' + '<br>P: +61 3 9682 0500' + '<br>E: <a href="mailto:acmmm24@icmsaust.com.au">acmmm24@icmsaust.com.au</a>',
        },

      ],
    }
  },
  methods:{
    openLink: function(link){
      window.open(link, '_blank');
    }
  },
}
</script>

<style scoped>

@import "../../css/responsive.css";


.icon {
  margin-top: .6rem;
  margin-right: 1rem;
  width: 3.6rem;
}

.title {
  margin-left: -1.8rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* color: #202020; */
  color: black;
}

.head_title {
  margin-left: 0rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /*color: black;*/
  /* color: #202020; */
  color: black;
}

.black * {
  color: black !important;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  .icon {
    margin-top: 0rem;
  }

  .title {
    margin-left: 0;
  }
}

.mel_banner{
    /* width: 40rem;
    height: 40rem; */
    position:relative;
    padding-bottom: 35%;
    /* position: relative; */
    /* text-align: center; */
    /* width: 200px;
    height: 200px; */
    /* overflow: hidden; */
}

.mel_banner > img{
  position:absolute;
  /* object-fit: cover; */
  top:0;left:0;
  width:100%;
  /* height:100%; */
}

</style>
