<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call For Industry Demonstrations',
      contents: [
        {
          type: 'text',
          text: 'ACM Multimedia 2024 invites proposals for industry showcase demos to be presented during the conference. The 32nd ACM International Conference on Multimedia (ACM MM 2024) will be held in Melbourne from October 28 to November 1, 2024. This flagship conference of the ACM SIGMM (Special Interest Group on Multimedia) will be held in-person.'
        },
        {
          type: 'text',
          text: 'This showcase is intended for research demonstrations by industrial practitioners and researchers. The demos should highlight technological or research aspects of the work, and should have an interaction element with the audience. The goal is to clarify to the audience how to bridge the gap between research and products in an engaging fashion. Demos advertising commercial products cannot be part of this as they are more appropriate for the category of industry exhibition.'
        },
        {
          type: 'primaryTitle',
          text: 'Topics',
        },
        {
          type: 'text',
          text: 'The scope includes (but is not limited to) the following subfields of multimedia:'
        },
        {
          type: 'noList',
          text: 'Emotional and Social Signals',
        },
        {
          type: 'noList',
          text: 'Multimedia Search and Recommendation',
        },
        {
          type: 'noList',
          text: 'Summarization, Analytics, and Storytelling',
        },
        {
          type: 'noList',
          text: 'Interactions and Quality of Experience',
        },
        {
          type: 'noList',
          text: 'Art and Culture',
        },
        {
          type: 'noList',
          text: 'Multimedia Applications',
        },
        {
          type: 'noList',
          text: 'Systems and Middleware',
        },
        {
          type: 'noList',
          text: 'Transport and Delivery',
        },
        {
          type: 'noList',
          text: 'Data Systems Management and Indexing',
        },
        {
          type: 'noList',
          text: 'Multimedia Fusion',
        },
        {
          type: 'noList',
          text: 'Vision and Language',
        },
        {
          type: 'noList',
          text: 'Multimedia Interpretation',
        },
        {
          type: 'noList',
          text: 'Multimedia Foundation Models',
        },
        {
          type: 'noList',
          text: 'Generative Multimedia',
        },
        {
          type: 'noList',
          text: 'Social Aspects of Generative AI',
        },
        {
          type: 'text',
          text: 'Participants are invited to submit industry demo proposals until the specified deadline. Each proposal must contain:'
        },
        {
          type: 'noList',
          text: 'Demonstration title.',
        },
        {
          type: 'noList',
          text: 'Name(s), affiliation(s) and email(s) of the demo contributors.',
        },
        {
          type: 'noList',
          text: '500-word abstract describing the technology being demonstrated, the equipment that will be used, and the demo experience. The abstract must clearly explain in what sense the proposed demo is innovative and how it will appeal to the ACM MM audience.',
        },
        {
          type: 'text',
          text: 'If the demo is associated with a paper submitted or accepted to ACM MM 2024, the participants are invited to provide the corresponding paper ID.'
        },
        {
          type: 'text',
          text: 'Demos should ideally have a presentation and an interactive component. In case the demo cannot be logistically transported, a video of the live demo being performed would be accepted if it conforms to the above requirement.'
        },
        {
          type: 'text',
          text: 'Each demo will be provided with a standard set composed of table and electrical power strip, possible display monitor (15"). Any additional equipment will be the responsibility of the participants. It is also the case of any shipping costs, delivery to and from the conference as well as care of the equipment during the conference.'
        },
        {
          type: 'text',
          text: 'Please note that at least one person needs to register to ACM MM 2024 in order to present a demo.'
        },
        {
          type: 'primaryTitle',
          text: 'Important Dates',
        },
        {
          type: 'text',
          text: 'Submission site opens: May 20, 2024 (AoE)',
        },
        {
          type: 'text',
          text: 'Abstract submission deadline (second round): September 6 (11:59pm AoE)',
        },
        {
          type: 'text',
          text: 'Notification of acceptance/rejection: September 13 (AoE)',
        },
        {
          type: 'text',
          text: 'Demo Presentation: Onsite at the conference',
        },
        {
          type: 'primaryTitle',
          text: 'Submission Details and Quick Review Process',
        },
        {
          type: 'text',
          text: '<b>Submission of Demo Abstract:</b> <a class="demo-link" href="https://t.ly/NbZ8x">https://t.ly/NbZ8x</a>',
        },
        {
          type: 'text',
          text: 'Demo proposals (abstracts) will be quickly peer-reviewed by the committee members responsible to select industry demos. The selection criteria include originality, innovation, applicability and potential benefit of the resulting system.'
        },
        {
          type: 'primaryTitle',
          text: 'Conflict of Interest Policy',
        },
        {
          type: 'text',
          text: 'All people involved in the ACM MM 2024 review process adhere to the ACM Multimedia Policies. Details can be found at <a class="demo-link" href="https://2024.acmmm.org/policies">https://2024.acmmm.org/policies</a>'
        },
        {
          type: 'primaryTitle',
          text: 'Enquiries',
        },
        {
          type: 'text',
          text: 'Please, send all enquiries about the Industry Demonstrations to chairs:'
        },
        {
          type: 'noList',
          text: 'Haiwei Dong, Huawei Canada (haiwei.dong@acm.org)'
        },
        {
          type: 'noList',
          text: 'Jianquan Liu, NEC Corporation (jqliu.japan@ieee.org)'
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Industrial Papers'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>