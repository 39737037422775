<template>
  <div style="width: 30rem;">
    
    <div class="context-twitter">
      <div>ACMMM24 on Twitter</div>
      <hr class="hr"/>
      <div>
        <iframe class="iframe"
                style="border:none;width: 100%; height: 45rem;"
                :src="reportUrl" frameborder="no"
        ></iframe>
      </div>
    </div>
    <div class="context-twitter">
      <div>ACMMM24 on <a href="https://www.linkedin.com/company/acmmm/about/" target="_blank">LinkedIn</a></div>
      <hr class="hr"/>
      <div>
        <!-- <p>Follow us on <a href="https://www.linkedin.com/company/acmmm/about/" target="_blank">LinkedIn</a></p> -->
        <iframe class="iframe" src="https://www.linkedin.com/embed/feed/update/urn:li:share:7160753168811757569" style="border:none;width: 100%; height: 45rem;" frameborder="no" title="Embedded post"></iframe>
        <hr class="hr"/>
        <iframe class="iframe" src="https://www.linkedin.com/embed/feed/update/urn:li:share:7135843017042657280" style="border:none;width: 100%; height: 45rem;" frameborder="no" title="Embedded post"></iframe>
        <hr class="hr"/>
        <iframe class="iframe" src="https://www.linkedin.com/embed/feed/update/urn:li:share:7135824895682240512" style="border:none;width: 100%; height: 45rem;" frameborder="no" title="Embedded post"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "twitter", methods: {},
  data() {
    return {
      reportUrl: 'https://twitframe.com/show?url=https://twitter.com/ACMMM24/status/1730059492371649010?ref_src=twsrc%5Etfw',
      linkedinUrl: 'https://www.linkedin.com/company/acmmm/',
    }
  },
  created() {
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Semibold, PingFang SC;
}

.Sponsors {
  margin-top: 6rem;
  width: 30rem;
  color: #444444;
}

.sp-title {
  color: #444444;
  width: 100%;
  font-size: 2.8rem;
  font-weight: 600;
}

.sponsors-title {
  margin-top: 3.5rem;
  font-size: 2.4rem;
  font-weight: 600;
}

.diamond{
  width: 81%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.platinum{
  float: left;
  width: 81%;
  margin-top: 1rem;
  margin-bottom: 2rem;
/*  margin-top: -2rem;
  margin-bottom: -4rem;*/
}
.gold{
  float: left;
  width: 81%;
  margin-top: 1rem;
  margin-bottom: 2rem;
/*  margin-top: -2rem;
  margin-bottom: -4.5rem;*/
}
.silver{
  float: left;
  width: 40%;
  margin-top: 1rem;
  margin-bottom: 2rem;
/*  margin-top: -1rem;
  margin-bottom: -4rem;*/
}

.img {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.context-twitter {
  margin-top: 10.5rem;
  width: 30rem;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 1.7rem;
}

.hr {
  width: 30rem;
  border: 1px solid #444;
  margin-bottom: 1rem;
}

.hr2 {
  padding-bottom: 1rem;
  width: 30rem;
  border-top: 2px solid #444;
}

.hr3 {
  padding-bottom: 1rem;
  width: 28rem;
  border-top: 2px solid #444;
}

.u-floatLeft {
  font-size: 1.2rem;
  float: left !important;
}

.u-floatRight {
  font-size: 1.2rem;
  float: right !important;
}

.iframe {
  margin-top: 1rem;
}
</style>
