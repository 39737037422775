<template>
  <div>
    <el-row class="el-rows">

      <p class="head_title"> Visit Victoria </p>
    </el-row>

    <div v-for="(content,index) in contents" :key="index">

      <el-row v-if="content.type === 'text'">
        <el-col class="demo-text">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>

    <hr>

    <div class="test_table">
        <el-row class="vic_image_row" v-for="(item ,index) in table" :key="index">
            <el-col span="10">
                <div class="vic_image_holder">
                    <img class="vic_image" :src="item.src" alt="victoria image">
                </div>
            </el-col>
            <el-col span="14">
                <div class="vic_text_holder">
                  <p class="main_title"> {{item.main_title}} </p>
                  <p class="second_title"> <em>{{item.second_title}}</em> </p>
                  <p class="demo-text"> {{item.main_text}} </p>

                  <div class="vic_button" >
                      <button @click="openLink(item.link)">FIND OUT MORE</button>
                  </div>
                </div>
                
                
                
                <!-- <p> <a href="item.link">{{item.main_text}}</a></p> -->
            </el-col>
        </el-row>
    </div>

    <hr>

    <div>
      <el-row class="west_image_holder">
        <img class="go_west" src="@/assets/visit_victoria/Go_West_logo.jpg" alt="go west tour">
      </el-row>
        
    </div>

    <hr>

    <div>
        <p class="main_title">Accessible Victoria</p>
        <p class="demo-text">Melbourne is one of Australia's oldest cities, but accessibility for people with special mobility needs is improving all the time. Find out about accessible activities and attractions and the best ways to get around Melbourne and Victoria!</p>
    </div>
    <div class="vic_button">
        <button onclick=" window.open('https://www.visitvictoria.com/practical-information/accessible-victoria','_blank')">FIND OUT MORE</button>
    </div>

  </div>
</template>

<script>
export default {
  name: "visit_victoria",
  data() {
    return {
      // iconImage: require("@/assets/content/bullet_point.png"),
      iconImage: "",
      contents: [
        {
          type: 'text',
          text: 'Take some time pre- or post-conference to explore Victoria in the spring. Whether you are visiting world-class wineries, immersing yourself in the atmosphere of the Spring Racing Carnival, or spending the day at one of Victoria’s sensational surf beaches, there’s something to explore for everyone.'
        },
        {
          type: 'text',
          text: 'Discover the diversity and beauty of regional Victoria, from natural springs to world-class wineries, coastal villages, peninsulas and alpine towns. Drive one of the many scenic routes, pull on your walking boots and follow tracks and trails, or wander shops and produce markets.'
        },
        {
          type: 'text',
          text: 'Book a trip before or after the conference from Go West Tours and use our promotional code <span style="font-weight: bold;">ACM24</span> upon checkout to get 10% off the normal booking price.'
        },
      ],
      table: [
        {
          src: require("@/assets/visit_victoria/Phillip_Island.jpeg"),
          main_title: 'Phillip Island',
          second_title: 'Parade with the Penguins',
          main_text: "Many consider Phillip Island’s iconic penguin parade an unmissable wildlife experience. With plenty of unique wildlife, coastal and nature experiences on offer, this is an experience the whole family will enjoy.",
          link: "https://gowest.com.au/our-tours/penguins-express-tour/",

        },
        {
          src: require("@/assets/visit_victoria/Yarra_Valley.jpg"),
          main_title: 'Yarra Valley',
          second_title: 'Enjoy the tastes of Victoria',
          main_text: "Sample some of the best wine and food the state of Victoria has to offer whilst traveling through the serene forest of the Blue Dandenong Ranges and the Yarra Valley, one of Australia’s most picturesque wine regions. Add on a Hot Air Balloon, Helicopter ride, or tour the region on horseback to make the experience truly once in a lifetime.",
          link: "https://gowest.com.au/our-tours/yarra-valley-gourmet-tour/",

        },
        {
          src: require("@/assets/visit_victoria/Great_Ocean_Road.jpg"),
          main_title: 'Great Ocean Road',
          second_title: 'Cruise the coastal chic',
          main_text: "An amazing coastline to travel and stunningly breathtaking, the Great Ocean Road is a photographer’s, surfers and travellers delight. The Great Ocean Road consistently features in the Top 10 of the World’s Greatest Scenic Coastal Drives.",
          link: "https://gowest.com.au/our-tours/great-ocean-road-sunset-tour/",

        },
        {
          src: require("@/assets/visit_victoria/Wilsons_Prom.jpg"),
          main_title: 'Wilsons Promontory',
          second_title: 'Take a walk on the wild side',
          main_text: "Explore one of Australia’s most pristine natural wilderness areas on a full day in this local’s favourite national park. Wander miles of virgin coastline and feel the white sand beaches ‘squeak’ between your toes. Get lost in native rainforests teeming with wildlife and cast your eyes across the great Southern Ocean from Mt. Oberon. This is not yet at the top of every visitor’s list but it should be.",
          link: "https://gowest.com.au/our-tours/wilsons-promontory-tour/",

        },
        
      ]
    }
  },
  methods:{
    openLink: function(link){
      window.open(link, '_blank');
    }
    

  }
}
</script>

<style scoped>

@import "../../css/responsive.css";

/* .test_table{
  border:1px solid black;
} */

.vic_image_row{
  padding-bottom: 4rem;
}

/* .vic_image{
    width: 40rem;
    height: 30rem;
} */

.go_west{
    /* width: 40rem; */
    height: 30rem;
}

.vic_text_holder{
  display: flex;
  flex-direction: column;
  /* justify-content:center; */
  /* align-content: center; */
  /* align-items: center; */
  vertical-align: middle;
  margin-left: 1rem;
}

/* .el-row {
  margin-bottom: 20px;
} */

.vic_image_holder {
  /* display: inline-block; */
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content:left;
  /* align-content: center; */
  align-items: left;
}

.vic_image_holder > img{
    width: 100%;
    height: 100%;
}

.west_image_holder {
  /* display: inline-block; */
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content:center;
  /* align-content: center; */
  align-items: center;
}

.main_title {
  /* margin-left: -1.8rem; */
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 2.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* color: #202020; */
  color: black;
}

.second_title {
  /* margin-left: -1.8rem; */
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* font-weight: ; */
  /* color: #202020; */
  color: black;
}

.head_title {
  margin-left: 0rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /*color: black;*/
  /* color: #202020; */
  color: black;
}

.vic_button{
    /* display: inline-block; */
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content:center;
    /* align-content: center; */
    align-items: center;
    /* background: #05cedd; */
    text-align: center;
    /* color: #FFFFFF; */
}

.vic_button > button{
    border: none;
    padding: 1rem 1rem;
    text-align: center;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    display: inline-block;
    font-size: 1.5rem;
    background: #2C9370;
    color: #FFFFFF;
    /* border-radius: 1rem; */
}


.black * {
  color: black !important;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  .icon {
    margin-top: 0rem;
  }

  .title {
    margin-left: 0;
  }
}
</style>
