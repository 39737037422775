<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{ mainTitle }}
      </el-col>
    </el-row>

    <div v-for="(item,index) in Keynote" :key="index" :id="item.id">
      <div v-for="(content,index) in item.contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 't_author'">
        <el-col>
          <p class="author-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>

    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tutorial",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Conference Tutorials',
      // personnel: [
      //   {
      //     person: [
      //       {
      //         //定位id
      //         id: "1",
      //         image: require('@/assets/keynotes/Wen_Gao.jpg'),
      //         keytitle: 'Video Coding for Machine',
      //         name: 'Wen Gao',
      //         address: 'Peking University & Peng-Cheng Laboratory',
      //       },
      //       {
      //         id: "2",
      //         image: require('@/assets/keynotes/Jagadish.jpg'),
      //         keytitle: 'Semantic Media Conversion: Possibilities and Limits',
      //         name: 'H. V. Jagadish',
      //         address: 'University of Michigan',
      //       },
      //       {
      //         id: "3",
      //         image: require('@//assets/keynotes/Cordelia_Schmid.jpg'),
      //         keytitle: 'Do you see what I see? Large-scale Learning from Multimodal Videos',
      //         name: 'Cordelia Schmid',
      //         address: 'Inria/Google',
      //       },
      //     ],
      //   }
      // ],
      // personnel2: [
      //   {
      //     person: [
      //       {
      //         id: "4",
      //         turn: 'photo_4',
      //         image: require('@/assets/keynotes/James_Lester.jpg'),
      //         keytitle: 'AI and the Future of Education',
      //         name: 'James Lester',
      //         address: 'North Carolina State University',
      //       },
      //       {
      //         id: "5",
      //         turn: 'photo_5',
      //         image: require('@/assets/keynotes/Zhengyou_Zhang.jpg'),
      //         keytitle: 'Digital Human in an Integrated Physical-Digital World (IPhD)',
      //         name: 'Zhengyou Zhang',
      //         address: 'Tencent AI Lab & Tencent Robotics X',
      //       },
      //       {
      //         id: "6",
      //         turn: 'photo_6',
      //         image: require('@/assets/keynotes/Jingren_Zhou.jpg'),
      //         keytitle: 'Large-scale Multi-Modality Pretrained Models: Applications and Experiences',
      //         name: 'Jingren Zhou',
      //         address: 'Ant Group',
      //       }
      //     ],
      //   }
      // ],
      // 定位ok
      Keynote: [
        // {
        //   // 定位id
        //   id: "1",
        //   contents: [
        //     {
        //       type: 'primaryTitle',
        //       text: 'Tutorial 1',
        //     },
        //     {
        //       type: 'textBlue',
        //       text: '<b>Title: Image Quality Assessment in the Modern Age</b>'
        //     },
        //     {
        //       type: 'text',
        //       text: '<b>Presenter: </b>'
        //     },
        //     {
        //       type: 't_author',
        //       text: 'Kede Ma, City University of Hong Kong'
        //     },
        //     {
        //       type: 't_author',
        //       text: 'Yuming Fang, Jiangxi University of Finance and Economics'
        //     },
        //     {
        //       type: 'text',
        //       text: '<b>Date: </b> 24 October, 2021 14:00-17:00 (GMT+8 time zone)'
        //     },
        //     {
        //       type: 'text',
        //       text: '<b>Abstract: </b>This tutorial provides the audience with the basic theories, methodologies, and current progresses of image quality assessment (IQA). From an actionable perspective, we will first revisit several subjective quality assessment methodologies, with emphasis on how to properly select visual stimuli. We will then present in detail the design principles of objective quality assessment models, supplemented by an in-depth analysis of their advantages and disadvantages. Both hand-engineered and (deep) learning-based methods will be covered. Moreover, the limitations with the conventional model comparison methodology for objective quality models will be pointed out, and novel comparison methodologies such as those based on the theory of "analysis by synthesis" will be introduced. We will last discuss the real-world multimedia applications of IQA, and give a list of open challenging problems, in the hope of encouraging more and more talented researchers and engineers devoting to this exciting and rewarding research field.'
        //     },
        //   ]
        // },

        // "From Multimodal LLM to Human-level AI: Modality, Instruction, Reasoning and Beyond
        // Hao Fei, Xiangtai Li, Haotian Liu, Fuxiao Liu, Zhuosheng Zhang, Hanwang Zhang, Shuicheng Yan
        // National University of Singapore, Nanyang Technological University"	28th Oct	Morning
        {
          // 定位id
          id: "1",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 1',
            },
            {
              type: 'textBlue',
              text: '<b>From Multimodal LLM to Human-level AI: Modality, Instruction, Reasoning and Beyond</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Hao Fei, Xiangtai Li, Haotian Liu, Fuxiao Liu, Zhuosheng Zhang, Hanwang Zhang, Shuicheng Yan <br> National University of Singapore, Nanyang Technological University'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 28 October, 2024 Morning'
            },
          ]
        },
        // "Curriculum Learning for Multimedia
        // Xin Wang, Yuwei Zhou, Hong Chen, Wenwu Zhu
        // Tsinghua University, China"	28th Oct	Afternoon
        {
          // 定位id
          id: "2",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 2',
            },
            {
              type: 'textBlue',
              text: '<b>Curriculum Learning for Multimedia</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Xin Wang, Yuwei Zhou, Hong Chen, Wenwu Zhu <br> Tsinghua University, China'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 28 October, 2024 Afternoon'
            },
          ]
        },
        // "Multimedia Information Retrieval in XR
        // Rahel Arnold, Werner Bailer, Ralph Gasser, Björn Þór Jónsson, Omar Shahbaz Khan, Heiko Schuldt, Florian Spiess, Lucia Vadicamo
        // University of Basel, Switzerland; Joanneum Research, Austria; Reykjavik University, Iceland; CNR-ISTI, Italy"	1st Nov	Morning
        {
          // 定位id
          id: "3",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 3',
            },
            {
              type: 'textBlue',
              text: '<b>Multimedia Information Retrieval in XR</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Rahel Arnold, Werner Bailer, Ralph Gasser, Björn Þór Jónsson, Omar Shahbaz Khan, Heiko Schuldt, Florian Spiess, Lucia Vadicamo <br> University of Basel, Switzerland; Joanneum Research, Austria; Reykjavik University, Iceland; CNR-ISTI, Italy'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 1 November, 2024 Morning'
            },
          ]
        },
        // "Multimodal Large Language Models and Tunings: Vision, Language, Sensors, Audio, and Beyond
        // Caren Han, Feiqi Cao, Josiah Poon, Roberto Navigli
        // University of Melbourne, University of Sydney"	1st Nov	Afternoon
        {
          // 定位id
          id: "4",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 4',
            },
            {
              type: 'textBlue',
              text: '<b>Multimodal Large Language Models and Tunings: Vision, Language, Sensors, Audio, and Beyond</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Caren Han, Feiqi Cao, Josiah Poon, Roberto Navigli <br> University of Melbourne, University of Sydney'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 1 November, 2024 Afternoon'
            }
          ]
        },
        // "Label-Efficient Emotion and Sentiment Analysis
        // Sicheng Zhao, Guoli Jia, Xiaopeng Hong, Yanyan Zhao, Jianhua Tao
        // Tsinghua University, China"	28th Oct	Morning
        {
          // 定位id
          id: "5",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 5',
            },
            {
              type: 'textBlue',
              text: '<b>Label-Efficient Emotion and Sentiment Analysis</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Sicheng Zhao, Guoli Jia, Xiaopeng Hong, Yanyan Zhao, Jianhua Tao <br> Tsinghua University, China'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 28 October, 2024 Morning'
            }
          ]
        },
        // "Learning Backward Compatible Representations
        // Niccolò Biondi, Simone Ricci, Federico Pernici, Alberto Del Bimbo
        // University of Florence, Italy"	28th Oct	Afternoon
        {
          // 定位id
          id: "6",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 6',
            },
            {
              type: 'textBlue',
              text: '<b>Learning Backward Compatible Representations</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Niccolò Biondi, Simone Ricci, Federico Pernici, Alberto Del Bimbo <br> University of Florence, Italy'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 28 October, 2024 Afternoon'
            }
          ]
        },
        // "Large Vision-Language Models in Society
        // Zhuang Shao, Dongfang Liu, Kaicheng Yu, Siyuan Qi
        // Newcastle University, UK; Westlake University, China"	1st Nov	Morning
        {
          // 定位id
          id: "7",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 7',
            },
            {
              type: 'textBlue',
              text: '<b>Large Vision-Language Models in Society</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Zhuang Shao, Dongfang Liu, Kaicheng Yu, Siyuan Qi <br> Newcastle University, UK; Westlake University, China'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 1 November, 2024 Morning'
            }
          ]
        },
        // "Point Cloud Compression, Enhancement, and Applications: From 3D Perception to Large Models
        // Wei Gao, Ge Li
        // Peking University, China

        // "	1st Nov	Afternoon
        {
          // 定位id
          id: "8",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 8',
            },
            {
              type: 'textBlue',
              text: '<b>Point Cloud Compression, Enhancement, and Applications: From 3D Perception to Large Models</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Wei Gao, Ge Li <br> Peking University, China'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 1 November, 2024 Afternoon'
            }
          ]
        },
      ],

    //   // //已经无效
    //   // contents: [

    //   ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Conference Tutorials'
  }
  ,
  methods: {
    goAnchor(id) {
      var anchor = document.getElementById(id);
      // chrome
      document.body.scrollTop = anchor.offsetTop;
      // firefox
      document.documentElement.scrollTop = anchor.offsetTop;
      // safari
      window.pageYOffset = anchor.offsetTop;
    }
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";

.photo {
  width: 28%;
  margin-top: 1.8rem;
  vertical-align: top;
  /*display: inline-block;*/
}

.parent {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: -3rem;
}

.personnel {
  float: left;
  width: 22rem;
  height: 36rem;
  background: #FFFFFF;
  border-radius: 1.8rem;
  border: 2px solid #EEEEEE;
  margin-right: 2rem;
  margin-left: 2rem;
  text-align: center;
  /*margin-bottom: -4rem;*/

}

.personnel:hover {
  box-shadow: 0 0.2rem 0.5rem 0 #B3B3B3;
}

.personnels {
  text-align: center;
  margin-bottom: 2rem;
}

.pre-header > div {
  width: 19rem;
  height: 19rem;
  /*background-color: red;*/
  margin: 1rem auto 1rem;
  border-radius: 1rem;
}

.pre-header > div > img {
  width: 100%;
}

.pre-title > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #0054B0;
  margin-top: 0.6rem;
  /*line-height: 19px;*/
}

.pre-middle > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #272727;
  margin-top: 0.6rem;
  /*line-height: 19px;*/
}

.pre-button {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-top: 0.6rem;

}

.pre-button > p {
  font-size: 1.4rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-family: Roboto-Medium, Roboto, sans-serif;
  font-weight: 500;

  color: #272727;
  /*line-height: 16px;*/
}

.pre {
  margin-top: 2rem;
}

@media screen  and (max-width: 750px) {
  .personnel p {
    margin: 0 0 .8rem !important;
  }

  .parent .personnel:first-child {
    margin-left: 2rem;
  }

  .personnel {
    margin-right: 1.2rem;
  }

  .personnels {
    width: 105%;
  }
}

.parent > div:first-child {
  margin-left: 2rem !important;
}


</style>
