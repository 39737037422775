<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{ mainTitle }}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "workshop-proposals",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: '',
      contents: [
        {
          type: 'primaryTitle',
          text: 'Reviewer Guidelines',
        },
        {
          type: 'text',
          text: '<b>Note: These guidelines may be subject to minor revisions before the submission deadline.</b>',
        },
        {
          type: 'text',
          text: 'Thank you for your work reviewing for ACM Multimedia 2021. We appreciate your service. Your time and effort directly contribute to maintaining the high quality of the conference and strengthening the multimedia research community.',
        },
        {
          type: 'text',
          text: 'As a Technical Program Committee (TPC) member, we expect that you are already experienced with writing excellent reviews. However, in practice we find that guidelines can help streamline the process. Starting this year, <b>ACM Multimedia will be announcing awards for the best reviewers of the conference.</b> The guidelines also serve as a basis for the best reviewer decisions.',
        },
        {
          type: 'text',
          text: 'The Golden Rule of reviewing: Write a review that you would like to receive yourself.',
        },
        {
          type: 'text',
          text: 'A review should be helpful to the authors, even if the review recommends the rejection of the paper.',
        },
        {
          type: 'text',
          text: 'The reviews are anonymous, but please make sure that you deliver your best work, and that you write reviews that you would be proud to associate with your name.',
        },
        {
          type: 'primaryTitle',
          text: 'Best practices for reviewing:',
        },
        {
          type: 'secondaryTitle',
          text: 'Check the paper topic:',
        },
        {
          type: 'retract',
          text: 'Confirm that the paper that you are reviewing falls into the topical scope of ACM Multimedia, as defined by the Call for Papers. Eventually, we rely on your judgement and the collective wisdom with your peers to decide if the paper aligns with multimedia topics.',
        },
        {
          type: 'retract',
          text: 'Remember that the problem addressed by an ACM Multimedia paper ideally involves multi-modal data, <b>or</b> is expected to be related to the challenge of how people interpret and use multimedia. Papers that focus on a narrow aspect of a single modality beyond the interest of the multimedia community, and also fail to contribute new knowledge on human use of multimedia may be rejected as out of scope for the conference.',
        },
        {
          type: 'retract',
          text: 'Although many submissions to ACM Multimedia make a technical contribution in the form of a new algorithm, not all do, nor is it a requirement of ACM Multimedia. Do not give less value to papers that carry out studies of new multimedia problems because they do not make a novel algorithmic contribution. Instead, judge these papers by the novelty of their insights and the value these insights could have for the community.',
        },
        {
          type: 'primaryTitle',
          text: 'Support your statements:',
        },
        {
          type: 'retract',
          text: 'Reviews should not just state, “It is well known that…”, but rather, they should include citations.',
        },
        {
          type: 'retract',
          text: 'Reviews should not just state, “Important references are missing…”, but rather, they should include examples; Reviewers should list their own references only in very rare cases that these are indeed the most relevant references for the authors to refer to.',
        },
        {
          type: 'retract',
          text: 'Reviews should not just state, “Authors should compare to the state of the art…”, but rather, they should cite specific work (i.e., peer-reviewed references) that they feel the authors should have considered and why.',
        },
        {
          type: 'retract',
          text: 'Authors appreciate if reviewers are generous with their feedback.',
        },
        {
          type: 'primaryTitle',
          text: 'Special regulations due to COVID-19:',
        },
        {
          type: 'retract',
          text: 'The Reviewers should take into account that certain types of experiments involving people, e.g., user studies, dance experiments, were more difficult to perform this year because of the social distancing measures in many locations. Therefore, user studies, etc., should be reviewed keeping this in mind.',
        },
        {
          type: 'primaryTitle',
          text: 'Respect the authors:',
        },
        {
          type: 'retract',
          text: 'Reviews should critique “the paper”, and not the authors.',
        },
        {
          type: 'retract',
          text: 'Reviews should try not address the authors directly, esp. not as “you”. (A direct address can be interpreted as an affront by the reader).',
        },
        {
          type: 'retract',
          text: 'During the review process, no attempt should be made to guess the identity of the authors. (If you discover it by accident, please complete your review, but notify your AC.)',
        },
        {
          type: 'primaryTitle',
          text: 'Please include in your review:',
        },
        {
          type: 'retract',
          text: 'Statement of novelty: What does the paper contribute? Is that contribution valuable for the multimedia research community? Does the paper cover all the relevant related work, and explain how its contribution builds on the related work?',
        },
        {
          type: 'retract',
          text: 'Statement of scientific rigor: Are the experiments well designed? Are the experiments sufficient to support the claims made by the paper? Are they reproducible? Have the authors released a resource, such as a data set or code?',
        },
        {
          type: 'retract',
          text: 'Fixes that the authors should make for the camera ready. We can trust the authors to correct minor errors. Authors generally also will state their commitment to correcting minor errors found during the review process during the rebuttal. However, major flaws must lead to rejection, since it is not possible to confirm that the authors have actually corrected major flaws successfully (i.e., the paper does not go back to the reviewers for checking).',
        },
        {
          type: 'primaryTitle',
          text: 'Ensuring review quality:',
        },
        {
          type: 'retract',
          text: 'When you finish a review, and before you submit it, please check it over to make sure that it follows these guidelines. Checking your review is good practice and will also save the ACs the effort of chasing you.',
        },
        {
          type: 'retract',
          text: 'Note that high-quality, accurate reviews will also ensure that the authors do not request your review to be referred to the Authors’ Advocate.',
        },
        {
          type: 'primaryTitle',
          text: 'Rebuttal:',
        },
        {
          type: 'retract',
          text: 'Reviewers should not ask for new results or experiments to be included in the rebuttals. The final recommendations should be based on the results in the original papers. Any new results (e.g., new experiments and theorems) in the rebuttals should not be considered.',
        },
        {
          type: 'retract',
          text: 'When the authors return their rebuttals, please read them carefully. The authors devoted a great deal of effort to writing rebuttals.',
        },
        {
          type: 'retract',
          text: 'Take the rebuttal into consideration by updating your review or otherwise responding to requests of the AC.',
        },
        {
          type: 'primaryTitle',
          text: 'Policy on arXiv papers:',
        },
        {
          type: 'text',
          text: 'We consider a “publication” to be a manuscript that has undergone peer review and has been accepted for publication. This means that the following points apply to arXiv papers (and any other papers available online that have not been peer reviewed):'
        },
        {
          type: 'retract',
          text: 'If the paper that you are reviewing is available on arXiv, and has not been published elsewhere, it is an acceptable submission to ACM Multimedia, since arXiv papers are not peer reviewed and are not publications;',
        },
        {
          type: 'retract',
          text: 'Please do not insist that the authors cite a paper that is only on arXiv and has not otherwise been published. Since arXiv papers are not all peer-reviewed publications, missing an arXiv paper does *not* count as missing related work;',
        },
        {
          type: 'retract',
          text: 'Likewise, if the authors do not compare their work with an approach described in an arXiv paper, it does *not* count as a weakness in their experimental evaluation of their own approach;',
        },
        {
          type: 'retract',
          text: 'If you know of an interesting arXiv paper relevant to the paper you are reviewing, you are more than welcome to tell the authors about it, but make sure you mark the reference as FYI “for your information” so that the authors know that you do not regard it as missing related work.',
        },
        {
          type: 'primaryTitle',
          text: 'Responsibilities of Area Chairs:',
        },
        {
          type: 'retract',
          text: 'Each paper is assigned to two Area Chairs (ACs).',
        },
        {
          type: 'retract',
          text: 'Both ACs take charge of soliciting reviews, summarizing the strengths and weaknesses of each paper, and making a recommendation.',
        },
        {
          type: 'retract',
          text: 'After both ACs make their recommendations, a consensus is expected to be reached. If there is a difference between their recommendations, the ACs will take a discussion that ideally leads to a consensus (although not required).',
        },
        {
          type: 'retract',
          text: 'The final decision (Oral/Poster/Reject) will be made during the TPC meeting. The ACs will prepare the final decision and the meta review by summarizing the discussion during the TPC meeting, and the inputs from both ACs before they are released to the authors.',
        },
        {
          type: 'retract',
          text: 'CMT3 is designed to manage COI papers (i.e., papers co-authored by an AC in the same track). Such a situation will only rarely happen (e.g., in smaller tracks). COI papers cannot be seen by the co-authoring AC, so the second AC will take care of it. If you have doubts about this, please contact the TPC chairs.',
        },
        {
          type: 'text',
          text: 'If you have any questions about the guidelines, please contact the Technical Program Chairs at ' +
              '<a href="mailto:dlyyang@gmail.com"> &lt;dlyyang@gmail.com&gt;</a> / ' +
              '<a href="mailto:P.S.CesarGarcia@tudelft.nl">&lt;P.S.CesarGarcia@tudelft.nl&gt;</a> / ' +
              '<a href="mailto:fmetze@cs.cmu.edu">&lt;fmetze@cs.cmu.edu&gt;</a> / ' +
              '<a href="mailto:bprabhakaran@utdallas.edu">&lt;bprabhakaran@utdallas.edu&gt; </a>'
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Workshop Proposals'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";
</style>