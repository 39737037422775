<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'button'">
          <el-col>
            <div class="vic_button" >
                <button @click="openLink(content.text)">Download the Prospectus</button>
            </div>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "Call for Sponsors",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Sponsors',
      contents:[
        {
          type:'text',
          text:'We invite your organisation to be part of the ACM Multimedia Conference 2024 (MM 24) being hosted in Melbourne, Australia at the Melbourne Convention and Exhibition Centre from 28 October – 1 November 2024.',
        },
        {
          type:'text',
          text:'ACM Multimedia is the premier international conference in the area of multimedia and attracts thousands of abstract submissions and interest from those throughout the media field around the world.',
        },
        {
          type:'text',
          text:'The 2024 conference is anticipated to attract over 850 attendees from around the globe and we have a wide array of partnership opportunities available to suit your budget and ensure your brand has significant options for exposure throughout the event.',
        },
        {
          type:'text',
          text:'As a sponsor you’ll have access to a captive audience of professionals from across the multimedia industries, providing you with a unique opportunity to showcase your products and services.',
        },
        {
          type:'text',
          text:'To ensure that you secure the highest levels of exposure, we encourage you to confirm your sponsorship as early as possible. Don’t wait any longer, join us at MM 24 in marvellous Melbourne and take your organisation to the next level!',
        },
        {
          type:'button',
          // text:'https://drive.google.com/file/d/1Qlfhyn8IWzyVov9CN2B_ZueFdOMZB1Qy/view?usp=sharing',
          text: '/files/MM24_Call_For_Sponsors_v3.pdf'
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        // {
        //   type:'text',
        //   text:'For questions regarding the sponsorship you can email the Sponsorship Chairs via <a class="demo-link" href="mailto:mm24sponsorship@gmail.com"> &lt;mm24sponsorship@gmail.com&gt;</a>:'
        // },
        {
          type:'text',
          text:'Sponsor and Exhibitor enquiries please contact: <a class="demo-link" href="changhu.wang@outlook.com"> &lt;changhu.wang@outlook.com&gt;</a> or <a class="demo-link" href="W.Xiang@latrobe.edu.au"> &lt;W.Xiang@latrobe.edu.au&gt;</a>'
        },
        {
          type:'noList',
          text:'<b>Changhu Wang</b>, AIsphere.',
        },
        {
          type:'noList',
          text:'<b>Wei Xiang</b>, La Trobe University, Australia.',
        },
      ],
    }
  },
  methods:{
    openLink: function(link){
      window.open(link, '_blank');
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Call for Sponsors'
  },
}

</script>

<style scoped>
@import "../../css/responsive.css";

.vic_button{
    /* display: inline-block; */
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content:center;
    /* align-content: center; */
    align-items: center;
    /* background: #05cedd; */
    text-align: center;
    /* color: #FFFFFF; */
}

.vic_button > button{
    border: none;
    padding: 1.5rem 6rem;
    text-align: center;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    display: inline-block;
    font-size: 2rem;
    background: #2C9370;
    color: #FFFFFF;
    border-radius: 1.2rem; 
}

</style>