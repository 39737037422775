<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{content.text}}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "multimedia-grand-challenges-submission",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Multimedia Grand Challenges',
      contents:[
        {
          type:'secondaryBlack',
          text:'1M-Deepfakes Detection Challenge',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://deepfakes1m.github.io/">https://deepfakes1m.github.io/</a>',
        },
        {
          type:'text',
          text:'The tremendous progress in generative AI has made the generation and manipulation of synthetic data easier and faster than before. To this end, multiple use cases are benefitting from it. The negative aspect of this progress and wide adoption of generative AI is deepfakes. Audio/image/video of an individual(s) is manipulated using generative methods without permission from the individual(s). This can make them be shown saying or doing something, which they may not have done in real life. These unethically manipulated videos, popularly known as deepfakes have wide repercussions and negative effects on society in the form of the deepfakes’ potential in spreading disinformation and misinformation. Deepfakes unfortunately are used for trolling online as well. Authentication systems such as video KYC (Know Your Customer) are also not resilient as often, face recognition and verification systems are deceived when high-quality deepfakes are used. To this end, it is important for platforms and systems to be able to identify if manipulation has been performed on a media. These systems, which detect and analyse the deepfakes are referred to as deepfakes detectors. This ACM Multimedia 2024 challenge proposes a novel audio-visual deepfakes detection benchmarking effort called The One Million Deepfakes Detection Challenge. The challenge along with focussing on detection also proposes the task of localisation. The latter deals with identifying the timestamps where a video is manipulated.',
        },
        {
          type:'secondaryBlack',
          text:'Artificial Intelligence Generated Image Detection',
        },
        // https://challenge.ai.mgtv.com/#/track/24
        {
          type:'text',
          text:'<a class="demo-link" href="https://challenge.ai.mgtv.com/#/track/24">https://challenge.ai.mgtv.com/#/track/24</a>',
        },
        {
          type:'text',
          text:'With the booming development of generative model technology, AI-generated images have significantly improved in visual quality, semantic complexity, runtime efficiency, and other aspects. On the one hand, AI-generated images are ubiquitous on various Internet platforms, and on the other hand, they are becoming increasingly difficult to distinguish by the naked eye. In addition, some judgment algorithms trained on datasets generated by GANs have been proven to perform poorly under the diffusion of model-generated images. Consequently, the central challenge of this competition is to enhance the accuracy of AIGC image discrimination and, particularly, the generalization capability for identifying unknown samples.',
        },
        {
          type:'secondaryBlack',
          text:'DEMON: Demonstrative Instruction Following Challenge',
        },
        // https://dcdmllm.github.io/DEMON-challenge
        {
          type:'text',
          text:'<a class="demo-link" href="https://dcdmllm.github.io/DEMON-challenge">https://dcdmllm.github.io/DEMON-challenge</a>',
        },
        {
          type:'secondaryBlack',
          text:'Face-voice Association in Multilingual Environment (FAME) 2024',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://mavceleb.github.io/dataset/competition.html">https://mavceleb.github.io/dataset/competition.html</a>',
        },
        {
          type:'text',
          text:'The Face-voice Association in Multilingual Environment (FAME) 2024 challenge aims to explore face-voice association under a unique condition of multilingual scenario. As half of the population of the world is bilingual and we are more often communicating in multilingual scenarios, therefore, it is essential to investigate the effect of language for associating faces with the voices. The challenge will use the Multilingual Audio-Visual (MAV-Celeb) dataset to analyze the impact of language on face-voice association.',
        },
        {
          type:'secondaryBlack',
          text:'Facial Micro-Expression Grand Challenge (MEGC) 2024',
        },
        // Website: https://megc2024.github.io
        // Summary:
        // Computational affect analysis and understanding of facial micro-expressions is an emerging area in multimedia research, which has seen a Grand Challenge proposed every year since 2018; in the last three years, 2021-2023, it has been held in conjunction with ACM Multimedia. Micro-expression analysis is an interdisciplinary field of research that has recently seen an expansion into more diverse multi-modalities, particularly spurred by the recent establishment of two large-scale multimodal datasets, CAS(ME)³ and 4DME. This year, the challenge focuses on two frontier problems: a cross-cultural spotting task, which entails spotting micro-expressions on an unseen test set that consists of subjects from multiple cultures and ethnicities; and a new spot-then-recognize task, which aims to correctly classify expression sequences following the spotting step in a sequential manner.
        {
          type:'text',
          text:'<a class="demo-link" href="https://megc2024.github.io">https://megc2024.github.io</a>',
        },
        {
          type:'text',
          text:"Computational affect analysis and understanding of facial micro-expressions is an emerging area in multimedia research, which has seen a Grand Challenge proposed every year since 2018; in the last three years, 2021-2023, it has been held in conjunction with ACM Multimedia. Micro-expression analysis is an interdisciplinary field of research that has recently seen an expansion into more diverse multi-modalities, particularly spurred by the recent establishment of two large-scale multimodal datasets, CAS(ME)³ and 4DME. This year, the challenge focuses on two frontier problems: a cross-cultural spotting task, which entails spotting micro-expressions on an unseen test set that consists of subjects from multiple cultures and ethnicities; and a new spot-then-recognize task, which aims to correctly classify expression sequences following the spotting step in a sequential manner."
        },
        {
          type:'secondaryBlack',
          text:'MultiMediate: Multi-modal Group Behaviour Analysis for Artificial Mediation',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://multimediate-challenge.org/">https://multimediate-challenge.org/</a>',
        },
        {
          type:'text',
          text:'Artificial mediators are a promising approach to support conversations, but at present their abilities are limited by insufficient progress in behaviour sensing and analysis. The MultiMediate challenge is designed to work towards the vision of effective artificial mediators by facilitating and measuring progress on key social behaviour sensing and analysis tasks. This year, the challenge focuses on the estimation of engagement across different domains. In addition, we continue to accept submissions to the most popular tasks of previous MultiMediate iterations, including backchannel detection, eye contact detection, and bodily behaviour recognition.',
        },
        {
          type:'secondaryBlack',
          text:'MAC: ACM Multimedia 2024 Micro-Action Analysis Challenge',
        },
        // https://sites.google.com/view/micro-action

        // The Micro-Action Analysis Grand Challenge (MAC 2024) focuses on computer vision and machine learning methods for automatic human behavior based on whole-body micro-action that is bound up with psychological and mental state and emotion state analysis. Micro-actions are spontaneous body movements that indicate a person's true feelings and potential intentions, yet recognizing, distinguishing, and understanding micro-actions are challenging because they are subtle and appear for milliseconds compared to normal actions. We release the MA challenge of single micro-action recognition (MAR) and multi-label micro-action detection (MMAD), and have collected the challenge datasets MA-52 and MMA-52, both including 52 micro-action categories along with seven body parts that were collected for the purpose of MAC 2024. The goal of this Grand Challenge is to foster innovative research in this new direction and to provide benchmark evaluations to inspire a new way of utilizing whole-body micro-action for human behavior understanding, to advance technologies in the deep psychological assessment and human emotion state analysis communities.
        {
          type:'text',
          text:'<a class="demo-link" href="https://sites.google.com/view/micro-action">https://sites.google.com/view/micro-action</a>',
        },
        {
          type:'text',
          text:'The Micro-Action Analysis Grand Challenge (MAC 2024) focuses on computer vision and machine learning methods for automatic human behavior based on whole-body micro-action that is bound up with psychological and mental state and emotion state analysis. Micro-actions are spontaneous body movements that indicate a person\'s true feelings and potential intentions, yet recognizing, distinguishing, and understanding micro-actions are challenging because they are subtle and appear for milliseconds compared to normal actions. We release the MA challenge of single micro-action recognition (MAR) and multi-label micro-action detection (MMAD), and have collected the challenge datasets MA-52 and MMA-52, both including 52 micro-action categories along with seven body parts that were collected for the purpose of MAC 2024. The goal of this Grand Challenge is to foster innovative research in this new direction and to provide benchmark evaluations to inspire a new way of utilizing whole-body micro-action for human behavior understanding, to advance technologies in the deep psychological assessment and human emotion state analysis communities.'
        },
        {
          type:'secondaryBlack',
          text:'MMIS-2024: Multi-rater Medical Image Segmentation for Radiotherapy Planning in Nasopharyngeal Carcinoma and Glioblastoma',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://mmis2024.com/">https://mmis2024.com/</a>',
        },
        {
          type:'secondaryBlack',
          text:'Social Media Prediction (SMP) Challenge',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="http://smp-challenge.com">http://smp-challenge.com</a>',
        },
        {
          type:'text',
          text:'<b>SMP Challenge</b> is an annual challenge that seeks excellent research teams on new ways of forecasting problems and meaningfully improving people’s social lives and business scenarios. The enormous amounts of online content lead to overconsumption, online word-of-mouth helps us to efficiently discover interesting news, emerging topics, the latest stories, or amazing products from the information ocean. Therefore, predicting online popularity became an emerging and significant task for online media, brand marketing, social influencers, or our individuals. The challenge task <b>Social Media Popularity Prediction (SMPP)</b> aims at on predicting the impact of online post sharing on social media. It is central to various scenarios, such as online advertising, social recommendation, demand forecasting, etc.',
        },
        {
          type:'secondaryBlack',
          text:'Visual Spatial Description (VSD) Challenge',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://lllogen.github.io/vsd- challenge.github.io/">https://lllogen.github.io/vsd-challenge.github.io/</a>',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Multimedia Grand Challenge Submissions'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>