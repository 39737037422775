<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'newtitle'">
          <el-col class="newtitle">
            <p class="newtitle" v-html="content.text"></p>
          </el-col>
        </el-row>

      </div>
  </div>
</template>

<script>
export default {
name: "reproducibility-companion-papers",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Reproducibility Companion Papers',
      contents:[
        {
          type:'primaryTitle',
          text:'Introduction',
        },
        {
          type:'text',
          text:`(open only to authors of papers at ACM Multimedia 2023)
          `,
        },
        {
          type:'text',
          text:`Authors of main-conference papers at ACM Multimedia 2023 are invited to make a submission to the new ACM Multimedia Reproducibility track at ACM Multimedia 2024. The track implements the ACM Artifact and Review and Badging process.
          `,
        },
        {
          type:'text',
          text:`In 2024, we will continue to focus on the “Results Reproduced” badge. ACM defines “Results Reproduced” as “The main results of the paper have been obtained in a subsequent study by a person or team other than the authors, using, in part, artifacts provided by the author”
          (from <a class="demo-link" href="https://www.acm.org/publications/policies/artifact-review-and-badging-current">https://www.acm.org/publications/policies/artifact-review-and-badging-current</a>).
          `,
        },
        {
          type:'text',
          text:`The companion paper and the associated artifacts will undergo a reproducibility review, which will result in an accept or a reject decision. Rejected papers are not disclosed.
          `,
        },
        {
          type:'text',
          text:`
          If accepted, the reviewers add a section to the companion paper describing the replication process and become co-authors of the companion paper. The final version may be up to 4 pages (with an optional page for references). A “Results Reproduced” ACM badge is added to the original paper and to the companion paper, which are both stored in the ACM Digital Library, together with the artifacts.
          `,
        },
        {
          type:'text',
          text:`The badged companion paper will appear in the ACM Multimedia 2024 proceedings and will be presented as a poster in the ACM Multimedia 2024 Reproducibility poster session.`,
        },
        {
          type:'primaryTitle',
          text:'Submission Guidelines',
        },
        {
          type:'text',
          text:'A submission to the Reproducibility track is composed of two parts:',
        },
        {
          type:'retract',
          text:'Companion paper: The companion paper is (up to) three and a half pages in length (excluding references). It contains a high-level description of the experiments carried out in the original paper and that are implemented in the archive.',
        },
        {
          type:'retract',
          text:'Archive: Contains the artifacts (e.g., code, scripts, data sets, protocols), which are cleanly packaged, ready for download and use to replicate the results from the original paper. It contains detailed readme file(s), examples, and all information needed to successfully carry out the experiments.',
        },
        // {
        //   type:'text',
        //   text:`For additional information, please see the Call for Reproducibility Papers posted on the ACM Multimedia Reproducibility website:
        //   <a class="demo-link" href="https://project.inria.fr/acmmmreproducibility/cfp2022/">https://project.inria.fr/acmmmreproducibility/cfp2022/</a>.
        //   More about reproducibility in general, and its implementation in the ACM Multimedia conference series is here: 
        //   <a class="demo-link" href="https://project.inria.fr/acmmmreproducibility">https://project.inria.fr/acmmmreproducibility</a>`,
        // },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Submission deadline: <b>June 3, 2024</b>',
        },
        {
          type: 'noList',
          text: 'Camera-ready Submission: <b>August 7, 2024</b>',
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions regarding submissions, please email the Reproducibility Chairs:',
        },
        {
          type:'noList',
          text:'<b>Bohan Zhuang</b>, Monash University, Australia, (Bohan.Zhuang@monash.edu).',
        },
        {
          type:'noList',
          text:'<b> Yang Yinping</b>, IHPC (A*STAR), Singapore, (yangyp@ihpc.a-star.edu.sg).',
        },
      ],
    }
  },
  mounted()
  {
    document.title = 'ACM Multimedia 2024 Reproducibility Companion Papers'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.newtitle {
    font-size: 3rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    color: #0054B0;
    margin-top: 1rem;
}

</style>