<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryMiddleTitle'">
          <el-col class="secondaryMiddleTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'retract_policies'">
          <el-col class="retract_policies">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "reviewer_guidelines",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'AC and SAC Guidelines',
      contents:[
        {
          type:'secondaryMiddleTitle',
          text:'Contacting the program chairs',
        },
        {
          type:'text',
          text:`If you encounter a situation that you are unable to resolve on your own and which is a general question or bug report, please contact the program chairs at acmmm2024pc@gmail.com.
          `,
        },
        {
          type:'text',
          text:`If there are specific questions about handling a particular paper, please email directly your point of contact SAC (for ACs), and SACs should email program chairs at the above address. 
          `,
        },
        {
          type:'secondaryMiddleTitle',
          text:'Introduction',
        },
        {
          type:'text',
          text:'Thank you for agreeing to serve for ACM MM 2024! In addition to the guidelines below, please familiarize yourself with the <a href="https://2024.acmmm.org/reviewer_guidelines">reviewer responsibilities and guidelines</a>.  You will be interacting significantly with reviewers, and the quality and timeliness of their feedback is critical to the success of the peer-reviewing process, so please make sure you understand what is expected of them and how this has been communicated.'
        },
        {
          type:'secondaryMiddleTitle',
          text:'Key dates',
        },
        {
          "type": "retract_policies",
          "text": "April 8, 2024 - Abstract registration deadline"
        },
        {
          "type": "retract_policies",
          "text": "April 12, 2024 - Paper submission deadline"
        },
        {
          "type": "retract_policies",
          "text": "<span style='color:blue'>April 15-23 – SAC + PCs to desk reject papers</span>"
        },
        {
          "type": "retract_policies",
          "text": "April 26, 2024 - Papers assigned to reviewers"
        },
        {
          "type": "retract_policies",
          "text": "May 25, 2024 - Reviews due"
        },
        {
          "type": "retract_policies",
          "text": "May 26-June 10, 2024 - ACs ensure every paper has at least 3 high-quality reviews (will need to verify review quality, remind reviewers, and obtain emergency reviews as needed)"
        },
        {
          "type": "retract_policies",
          "text": "June 10, 2024 - Reviews released to authors"
        },
        {
          "type": "retract_policies",
          "text": "June 10-17, 2024 - Author rebuttal"
        },
        {
          "type": "retract_policies",
          "text": "June 18-24, 2024 - ACs discuss papers with reviewers; reviewers enter final recommendation"
        },
        {
          "type": "retract_policies",
          "text": "July 1, 2024 - ACs preliminary meta-reviews and recommendations due"
        },
        {
          "type": "retract_policies",
          "text": "<span style='color:blue'>July 9, 2024 - ACs discuss with SACs and PCs to discuss borderline decisions</span>"
        },
        {
          "type": "retract_policies",
          "text": "July 13, 2024 – PCs to ensure all decisions and meta-reviews are entered; borderline cases resolved."
        },
        {
          "type": "retract_policies",
          "text": "July 15, 2024 - Final accept/reject decisions to authors."
        },

        {
          type:'secondaryMiddleTitle',
          text:'General information',
        },
        {
          "type": "retract_policies",
          "text": "Please respect deadlines and respond to emails as promptly as possible!"
        },
        {
          "type": "retract_policies",
          "text": "It is crucial that we are able to reach you in a timely manner. We will send most emails using OpenReview (i.e., noreply@openreview.net). Such emails are sometimes accidentally marked as spam. Please check your spam folder regularly and if you find such an email in there, please whitelist the above email address so that you will receive future emails from OpenReview."
        },
        {
          "type": "retract_policies",
          "text": "If you have changed or plan to change your email address, please update OpenReview accordingly. You should also make sure that your domain conflicts in OpenReview are up to date; these are important for preventing conflicts during the review process."
        },
        {
          "type": "retract_policies",
          "text": "Please use these <a href='https://docs.google.com/document/d/1nhI4qKfpU4PNIsIjQgfVKF9iR6nfdTUs8PZA7CkFi2w/edit?usp=sharing'>guidelines to evaluate Conflict of Interest</a>"
        },
        {
          "type": "retract_policies",
          "text": "Please log into <a href='https://openreview.net/login'>OpenReview</a> to make sure that your profile is up to date. Using a stale profile causes poor assignments, outside your area of expertise."
        },

        {
          type:'secondaryMiddleTitle',
          text:'Responsibilities',
        },
        {
          "type": "retract_policies",
          "text": "Senior Area Chair (SAC). SACs work alongside the area chairs (ACs) and program chairs (PCs). Each SAC oversees the work of a number of ACs (around 12), making sure that the reviewing process goes smoothly. SACs serve as the first port of call for ACs if they need assistance or guidance. The reviewing process is double blind at the level of ACs (i.e., ACs cannot see author identities).  SACs are responsible for finalizing the list of desk rejected papers, identifying conflicts of interest (and other unusual activity, such as suspicious bidding patterns) and re-assigning submissions to ACs or reviewers accordingly. Desk rejects are made to save our reviewers’ time. Examples of desk reject reasons are:"
        },
        {
          "type": "retract",
          "text": "Incomplete submissions."
        },
        {
          "type": "retract",
          "text": "The paper lacks anonymization. Leaving the author names in the paper or having a description or an acknowledgements section that reveals authors or the institution (e.g. places where user studies were conducted and specific supporting grant information)."
        },
        {
          "type": "retract",
          "text": "Failure of declaration of concurrent submissions that are closely related to the submission. If you have such a submission, you must include an anonymized version of that submission as supplementary material. The same rule applies if your submission is built directly on a project described in a paper that is unpublished or currently under review at other venues."
        },
        {
          "type": "retract",
          "text": "Failure of declaration and/or citation of authors’ prior publications that are closely related to the submission. Make sure you cite your prior publications by following our Anonymization Policy."
        },
        {
          "type": "retract",
          "text": "Use of wrong formats. All submissions must use the template specified in the MM <a href='https://2024.acmmm.org/regular-papers'>call for papers page</a>."
        },
        {
          "type": "retract",
          "text": "Clearly not within the conference scope(e.g. formal methods for interstellar microcontrollers)."
        },
        {
          "type": "retract",
          "text": "Not written in English."
        },
        {
          "type": "retract",
          "text": "Obviously not a conference paper (patent disclosure, popular press article, a complete book, etc.)."
        },
        {
          "type": "retract",
          "text": "Something is so broken in the paper that makes it impossible to review."
        },
        {
          "type": "retract_policies",
          "text": "Desk rejected submissions will not be assigned to a reviewer, and their authors will receive a brief note about the rejection. SACs are also responsible for helping ACs remind late reviewers, calibrating decisions across ACs, and discussing borderline papers. During the final decision-making phase, SACs will weigh in on borderline papers with ACs and consult PCs as needed. They will also consult with PCs on the overall status, acceptance rates, and trends. There is no physical SAC/AC meeting; most discussions with reviewers, ACs, and PCs will take place via OpenReview or email, with some video conferences toward the end of the reviewing process. Reviewer identities are visible to other reviewers, ACs and SACs. After decisions have been made, reviews and meta-reviews will be made public (but reviewer and SAC/AC identities will remain anonymous)."
        },
        {
          "type": "retract_policies",
          "text": "Area Chair (AC). Each area chair (AC) oversees between 15-20 submissions, making sure that the reviewing process goes smoothly. ACs are the principal contact for reviewers during the whole reviewing process. ACs are responsible for helping the program chairs (PCs) recruit reviewers, recommending reviewers for submissions, chasing late reviewers, facilitating discussions among reviewers, writing meta-reviews, evaluating the quality of reviews, and helping SACs and PCsmake decisions. For a small percentage of cases (e.g., in case of missing reviews), the ACs may be expected to provide detailed personal review before summarizing the review from all reviewers into a meta review. Finally, ACs are responsible for flagging papers to desk reject, providing a light motivation which is going to be shared with authors. <br> The reviewing process is double blind at the level of ACs; each AC will work with a senior area chair (SAC), who is responsible for cross-checking desk reject recommendations, identifying conflicts of interest (and other unusual activity, such as suspicious bidding patterns) and re-assigning submissions to ACs or reviewers accordingly. SACs serve as the first port of call for ACs if they need assistance or guidance throughout the reviewing process. SACs also calibrate decisions across ACs. There are no physical meetings; most discussions with reviewers, SACs, and PCs will take place via OpenReview or email, with some video conferences toward the end of the reviewing process. Reviewer identities are visible to other reviewers, ACs and SACs. AC identities are also visible to reviewers. After decisions have been made, reviews and meta-reviews will be made public (but reviewer and SAC/AC identities will remain anonymous)."
        },
        {
          "type": "retract_policies",
          "text": "Reviewer. Each reviewer will be assigned between 4 - 6 submissions to review. Reviewers are responsible for reviewing submissions, reading author responses, discussing submissions and author responses with other reviewers and area chairs (ACs), and helping make decisions. The reviewing process is double-blind at the level of reviewers. There are no physical meetings; discussions with other reviewers and ACs will take place via OpenReview or email. After decisions have been made, reviews and meta-reviews will be made public (but reviewer and AC identities will remain anonymous). ACs will be asked to evaluate the quality of each review. A prestigious conference like MM’24 demands high-quality reviews, and to incentivise excellence, the top 5% reviewers will receive recognition at the conference. The lowest-scoring reviewers may not be invited to review for future conferences."
        },

        {
          type:'secondaryMiddleTitle',
          text:'SAC best practices',
        },
        {
          "type": "retract_policies",
          "text": "It is okay to be unavailable for part of the review process (e.g., on vacation for a few days), but if you will be unavailable for more than that -- especially during important windows (e.g., decision-making) -- you must let the program chairs know ASAP."
        },
        {
          "type": "retract_policies",
          "text": "With great power comes great responsibility! Take your job seriously and be fair, particularly this year since you will play a key role in the desk rejection process."
        },
        {
          "type": "retract_policies",
          "text": "If you notice a conflict of interest with a submission that is assigned to one of your ACs, please contact your point of contact program chair immediately."
        },
        {
          "type": "retract_policies",
          "text": "DO NOT talk to other SACs about submissions assigned to your ACs without prior approval from the program chairs; other SACs may have conflicts with these submissions."
        },
        {
          "type": "retract_policies",
          "text": "DO NOT talk to other SACs or ACs about your own submissions (i.e., submissions you are an author on) or submissions with which you have a conflict of interest."
        },
        {
          "type": "retract_policies",
          "text": "Be professional and listen to the reviewers and ACs, but do not give in to undue influence."
        },
        {
          "type": "retract_policies",
          "text": "If an AC wants to make a decision that is not clearly supported by the reviews, please check that they justify their decision appropriately, including, but not limited to, reading the submission in depth and writing a detailed meta-review that explains their decision."
        },
        {
          "type": "retract_policies",
          "text": "Help calibrate decisions by working closely with your ACs. Pay particularly close attention to borderline papers."
        },

        {
          type:'secondaryMiddleTitle',
          text:'AC best practices',
        },
        {
          "type": "retract_policies",
          "text": "It is okay to be unavailable for part of the review process (e.g., on vacation for a few days), but if you will be unavailable for more than that -- especially during important windows (e.g., discussion, decision-making) -- you must let your SAC know ASAP."
        },
        {
          "type": "retract_policies",
          "text": "With great power comes great responsibility! Take your job seriously and be fair."
        },
        {
          "type": "retract_policies",
          "text": "If you notice a conflict of interest with a submission that is assigned to you, please contact your SAC immediately so that the paper can be reassigned."
        },
        {
          "type": "retract_policies",
          "text": "Please make sure that every submission in your batch is matched with suitable reviewers whose opinion you can trust on this submission.  It really pays off to invest some time before the reviewing process starts to ensure that your batch has expert reviewers."
        },
        {
          "type": "retract_policies",
          "text": "If two submissions are detected to be sufficiently close by our tools, reviewers will receive both and you will be informed; please ensure that the arguments to discard or accept each submission are thoroughly discussed."
        },
        {
          "type": "retract_policies",
          "text": "DO NOT talk to other ACs about submissions that are assigned to you without prior approval from your SAC; other ACs may have conflicts with these submissions. In general, your primary point of contact for any discussions should be your SAC. Your SAC does not have any conflicts with any of the submissions that are assigned to you."
        },
        {
          "type": "retract_policies",
          "text": "DO NOT talk to other SACs or ACs about your own submissions (i.e., submissions you are an author on) or submissions with which you have a conflict of interest."
        },
        {
          "type": "retract_policies",
          "text": "Be professional and listen to the reviewers, but do not give in to undue influence."
        },
        {
          "type": "retract_policies",
          "text": "Make sure your reviewers read and (if appropriate) respond to all author responses."
        },
        {
          "type": "retract_policies",
          "text": "As soon as the author's response is entered in the system, you must initiate a discussion via OpenReview for each submission and make sure the reviewers engage in the discussion phase. Monitor and moderate the discussion to ensure that it is respectful of everyone’s opinion. Read the papers in your stack to steer the discussion towards the most critical aspects that need discussion. It is your responsibility to moderate and lead the discussion."
        },
        {
          "type": "retract_policies",
          "text": "Read all reviews carefully. After reading a review, please evaluate its quality by indicating (on OpenReview) whether it meets expectations. You should use the information in the reviewer instructions (see the section “Review Content” below), as well as how helpful the review and subsequent discussion by the reviewer were in making your decision about the submission. The 5% of reviewers with the highest scores will receive recognition at the conference."
        },
        {
          "type": "retract_policies",
          "text": "Your meta-review should explain your decision to the authors. Your comments should augment the reviews, and explain how the reviews, author response, and discussion were used to arrive at your decision. Dismissing or ignoring a review is not acceptable unless you have a good reason for doing so. If you want to make a decision that is not clearly supported by the reviews, perhaps because the reviewers did not come to a consensus, please justify your decision appropriately, including, but not limited to, reading the submission in depth and writing a detailed meta-review that explains your decision. You are expected to discuss such difficult cases with your SAC and to have their approval."
        },

        {
          type:'secondaryMiddleTitle',
          text:'Confidentiality',
        },
        {
          type:'text',
          text:'You must keep everything relating to the review process confidential. Do not use ideas, code and results from submissions in your own work until they become publicly available (e.g., via a technical report or a published paper for ideas/results, via open source for code, etc.). Do not talk about or distribute submissions (whether it is the code, or the ideas and results described in them) to anyone without prior approval from the program chairs. Code submitted for reviewing cannot be distributed.',
        },
        {
          type:'secondaryMiddleTitle',
          text:'Acknowledgment for these guidelines',
        },
        {
          type:'text',
          text:'These guidelines build upon those shared by <a href="https://nips.cc/Conferences/2020/PaperInformation/AC-SACGuidelines.">NeurIPS</a> and <a href="https://chi2023.acm.org/submission-guides/papers-review-process/">CHI</a>',
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Multimedia Reviewer Guidelines'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.retract_policies {
    /* padding-left: 2.5rem; */
    text-align: left;
    /*color: #202020;*/
}

</style>