<template>
  <div>
    <!-- <el-row class="mel_banner">
        <img src="@/assets/accommodation/banner.png" alt="acmmm banner">
    </el-row> -->

    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>

    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'centeredTitle'">
        <el-col>
          <p style="font-size: 24px; color: red; text-align: center; font-weight: bold;" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryMiddleTitle'">
        <el-col class="secondaryMiddleTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'image'">
        <el-col>
          <div class="contact_image">
            <img :src="content.src">
          </div>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'retract_policies'">
        <el-col class="retract_policies">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'dateText'">
        <el-col class="dateText">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'imageTable'">
        <el-row class="table-row" v-for="(item, i) in content.table" :key="i">
          <el-col span="8" class="table-col" v-for="(subItem, j) in item" :key="j">
            <div class="vic_image_holder">
              <img class="vic_image" :src="subItem.src" alt="melbourne image">
            </div>
            <div class="vic_image_text">
              <span class="second_vic_title"> {{subItem.title}} </span>
            </div>
          </el-col>
        </el-row>
      </el-row>

      <el-row v-else-if="content.type === 'soldOutTable'">
        <el-row class="table-row" v-for="(item, i) in content.table" :key="i">
          <el-col span="12" class="table-col" v-for="(subItem, j) in item" :key="j">
            <div class="vic_image_text">
              <span class="second_vic_title"> {{subItem.title}} </span>
            </div>
            <div class="vic_image_holder2">
              <img class="vic_image" :src="subItem.src" alt="melbourne image">
            </div>
          </el-col>
        </el-row>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  name: "Dates",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: "Accommodation",
      contents: [        
        // The MM 24 Organising Committee have secured a range of accommodation options from single premier rooms to shared apartments all in the vicinity of the conference venue, the Melbourne Convention and Exhibition Centre (MCEC). 
        {
          type: 'text',
          text: 'The MM 24 Organising Committee have secured a range of accommodation options from single premier rooms to shared apartments all in the vicinity of the conference venue, the Melbourne Convention and Exhibition Centre (MCEC).'
        },
        // To book accommodation for MM 24, please select your preferred hotel from the options below and click on the booking link. Please note, each hotel has its own cancellation policy, so please make sure to review these policies before making your reservation.
        {
          type: 'text',
          text: 'To book accommodation for MM 24 you can review the options below and book directly when completing the online registration form.'
        },
        // Pan Pacific Melbourne - 5-star  
        {
          type: 'secondaryMiddleTitle',
          text: 'Pan Pacific Melbourne - 5-star'
        },
        {
          type: 'text',
          text: '<b>Address:</b> <a href="https://goo.gl/maps/pATHhZGSMBp" target="_blank">South Wharf, 2 Convention Centre Pl, Melbourne VIC 3006.</a>'
        },
        {
          type: 'text',
          text: '<b>Distance to Venue:</b> Connected to the MCEC'
        },
        {
          type: 'text',
          text: '<b>Hotel Information:</b> The only 5-star hotel directly connected to the MCEC, Pan Pacific Melbourne offers guests 396 sophisticated and spacious rooms featuring floor-to-ceiling windows and unrestricted views of Melbourne city or bay.'
        },
        {
          type: 'text',
          text: '<b>Room Rate:</b> Premier King Room - $359 per night room only'
        },
        // Images: https://drive.google.com/drive/folders/1Ikl2OAg7Zx349_Jhc_Hz6F6JhvwD99dS?usp=sharing
        {
          type: 'text',
          text: '<b>Images:</b>',
        },
        {
          type: 'imageTable',
          table: [
            [
              {
                src: require('@/assets/accommodation/pan_pacific/hotel_images/hotel_exterior.jpg'),
                title: 'Hotel Exterior'
              },
              {
                src: require('@/assets/accommodation/pan_pacific/hotel_images/hotel_lobby.jpg'),
                title: 'Hotel Lobby'
              },
            ],
            [
              // src/assets/accommodation/pan_pacific/room_images/deluxe_room.jpeg
              // src/assets/accommodation/pan_pacific/room_images/pan_pacific_melbourne_family_queen_room.jpeg
              // {
              //   src: require('@/assets/accommodation/pan_pacific/room_images/deluxe_room.jpeg'),
              //   title: 'Deluxe Room'
              // },
              // {
              //   src: require('@/assets/accommodation/pan_pacific/room_images/pan_pacific_melbourne_family_queen_room.jpeg'),
              //   title: 'Family Queen Room'
              // },
              {
                src: require('@/assets/accommodation/pan_pacific/room_images/premier_king_room.jpg'),
                title: 'Premier King Room'
              }
            ]
          ]
        },
        // Novotel South Wharf - 4-star  
        // Address: 7 Convention Centre Pl, South Wharf VIC 3006 (https://maps.app.goo.gl/xxmNjZbH4qVp2hrR9)
        // Distance to Venue: Connected to the MCEC
        // Hotel Information: Novotel Melbourne South Wharf stands proudly in one of Melbourne's oldest locations, the historic South Wharf promenade. In contrast to the maritime docks and cargo sheds, is the contemporary architecture of Melbourne's newest buildings and attractions. 
        // Room Rate: Standard Queen Room - $300 per night inclusive of breakfast for one & Standard Queen Room - $330 per night inclusive of breakfast for two
        // Images: https://drive.google.com/drive/folders/1OkVfkWTd1yS3VeUdBVpbZM_B5w7--RcD?usp=drive_link 
        {
          type: 'secondaryMiddleTitle',
          text: 'Novotel South Wharf - 4-star'
        },
        {
          type: 'text',
          text: '<b>Address:</b> <a href="https://maps.app.goo.gl/xxmNjZbH4qVp2hrR9" target="_blank">7 Convention Centre Pl, South Wharf VIC 3006</a>'
        },
        {
          type: 'text',
          text: '<b>Distance to Venue:</b> Connected to the MCEC'
        },
        {
          type: 'text',
          text: '<b>Hotel Information:</b> Novotel Melbourne South Wharf stands proudly in one of Melbourne\'s oldest locations, the historic South Wharf promenade. In contrast to the maritime docks and cargo sheds, is the contemporary architecture of Melbourne\'s newest buildings and attractions.'
        },
        {
          type: 'text',
          text: '<b>Room Rate:</b> Standard Queen Room - $300 per night inclusive of breakfast for one & Standard Queen Room - $330 per night inclusive of breakfast for two'
        },
        {
          type: 'text',
          text: '<b>Images:</b>',
        },
        {
          type: 'imageTable',
          table: [
            [
              {
                src: require('@/assets/accommodation/novotel_south_wharf/hotel_images/entry.png'),
                title: 'Entry'
              },
              {
                src: require('@/assets/accommodation/novotel_south_wharf/hotel_images/novotel_south_wharf.jpg'),
                title: 'Novotel South Wharf'
              },
            ],
            [
              {
                src: require('@/assets/accommodation/novotel_south_wharf/room_images/standard_room.jpg'),
                title: 'Standard Room'
              },
            ]
          ]
        },

        // Ink Hotel Melbourne Southbank
        // Address: 167 City Road, Southbank VIC 3006
        // Distance to Venue: 10 min walking – 800m
        // Hotel Information: Welcome and meet Ink Melbourne Southbank. Your new vibrant hangout right in the heart of Melbourne city. Sitting smack-bang in Southbank near the CBD, our brand new 162 room hotel places you a hop, skip & a jump away from Melbourne’s top attractions, restaurants, and overall cool places to be. Pack your bag amigos, your adventure awaits!
        // Room Rate: The Pocket Room - $250 per night room only
        // Images: https://drive.google.com/drive/folders/160xklVH5aqU-C-eDVRLu5WI2915o1lCG?usp=sharing 

        {
          type: 'secondaryMiddleTitle',
          text: 'Ink Hotel Melbourne Southbank'
        },
        {
          type: 'text',
          text: '<b>Address:</b> 167 City Road, Southbank VIC 3006'
        },
        {
          type: 'text',
          text: '<b>Distance to Venue:</b> 10 min walking – 800m'
        },
        {
          type: 'text',
          text: '<b>Hotel Information:</b> Welcome and meet Ink Melbourne Southbank. Your new vibrant hangout right in the heart of Melbourne city. Sitting smack-bang in Southbank near the CBD, our brand new 162 room hotel places you a hop, skip & a jump away from Melbourne’s top attractions, restaurants, and overall cool places to be. Pack your bag amigos, your adventure awaits!'
        },
        {
          type: 'text',
          text: '<b>Room Rate:</b> The Pocket Room - $250 per night room only'
        },
        {
          type: 'text',
          text: '<b>Images:</b>',
        },
        {
          type: 'imageTable',
          table: [
            [
              {
                src: require('@/assets/accommodation/ink_hotel/hotel_images/INK_Exterior.jpg'),
                title: 'Exterior'
              }
            ],
            [
              {
                src: require('@/assets/accommodation/ink_hotel/room_images/INK_Room.jpg'),
                title: 'Room'
              },
            ]
          ]
        },



        // Crowne Plaza Melbourne – 4-star
        // Address: 1-5 Spencer St, Docklands VIC 3008 (https://maps.app.goo.gl/dLERnA4Af4w69v856)
        // Distance to Venue: 6 min walking – 600m
        // Hotel Information: Melbourne CBD accommodation is made easy with Crowne Plaza Melbourne. Enjoy central accommodation in the heart of Melbourne city in the best hotel room in Melbourne. Featuring large windows that offer spectacular views of Melbourne city and the large heated outdoor swimming pool. Whether you’re looking for a spacious family room for your weekend holiday in Melbourne or simply a spectacular standard hotel room in Melbourne CBD for your Corporate Travel and Business Travel needs.
        // Room Rate: Standard King Room - $360 per night room only
        // Images: https://drive.google.com/drive/folders/1oeMkHSYtg92orDVxeZjIFAw7x74hhqIJ?usp=drive_link 

        {
          type: 'secondaryMiddleTitle',
          text: 'Crowne Plaza Melbourne – 4-star'
        },
        {
          type: 'text',
          text: '<b>Address:</b> <a href="https://maps.app.goo.gl/dLERnA4Af4w69v856" target="_blank">1-5 Spencer St, Docklands VIC 3008</a>'
        },
        {
          type: 'text',
          text: '<b>Distance to Venue:</b> 6 min walking – 600m'
        },
        {
          type: 'text',
          text: '<b>Hotel Information:</b> Melbourne CBD accommodation is made easy with Crowne Plaza Melbourne. Enjoy central accommodation in the heart of Melbourne city in the best hotel room in Melbourne. Featuring large windows that offer spectacular views of Melbourne city and the large heated outdoor swimming pool. Whether you’re looking for a spacious family room for your weekend holiday in Melbourne or simply a spectacular standard hotel room in Melbourne CBD for your Corporate Travel and Business Travel needs.'
        },
        {
          type: 'text',
          text: '<b>Room Rate:</b> Standard King Room - $360 per night room only'
        },
        {
          type: 'text',
          text: '<b>Images:</b>',
        },
        {
          type: 'imageTable',
          table: [
            [
              {
                src: require('@/assets/accommodation/crowne_plaza/hotel_images/exterior_1.jpg'),
                title: 'Exterior'
              },
              {
                src: require('@/assets/accommodation/crowne_plaza/hotel_images/pool_1.jpg'),
                title: 'Pool'
              },
            ],
            [
              {
                src: require('@/assets/accommodation/crowne_plaza/room_images/standard_king_room.webp'),
                title: 'Standard King Room'
              },
            ],
          ]
        },

        // Crown Promenade - 4-star 
        // Address: 8 Whiteman St, Southbank VIC 3006 (https://goo.gl/maps/zz3CphnwJXXcnqfs5)
        // Distance to Venue: 6 min walking – 600m
        // Hotel Information: Crown Promenade is a modern hotel located within walking distance of Melbourne's Central Business District offering city views and friendly service. A perfect choice for savvy business and leisure travellers who value genuine service and a centralised location. 
        // Room Rate: Standard King Room - $265 per night* room only 
        // *Saturday 26 October, Saturday 2 November and Sunday 3 November are $420/night. Friday 1 November is $320/night. 
        // Images: https://drive.google.com/drive/folders/11RwlMh7XzmaRFooWkUmWWS1IiLl7-c28?usp=drive_link 

        // {
        //   type: 'secondaryMiddleTitle',
        //   text: 'Crown Promenade - 4-star'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Address:</b> <a href="https://goo.gl/maps/zz3CphnwJXXcnqfs5" target="_blank">8 Whiteman St, Southbank VIC 3006</a>'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Distance to Venue:</b> 6 min walking – 600m'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Hotel Information:</b> Crown Promenade is a modern hotel located within walking distance of Melbourne\'s Central Business District offering city views and friendly service. A perfect choice for savvy business and leisure travellers who value genuine service and a centralised location.'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Room Rate:</b> Standard King Room - $265 per night* room only'
        // },
        // {
        //   type: 'text',
        //     text: '<i>*Saturday 26 October, Saturday 2 November and Sunday 3 November are $420/night. Friday 1 November is $320/night.</i>'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Images:</b>',
        // },
        // {
        //   // src/assets/accommodation/crown_promenade/hotel_images/lobby.jpg
        //   // src/assets/accommodation/crown_promenade/hotel_images/pool.jpeg
        //   // src/assets/accommodation/crown_promenade/room_images/bathroom.jpg
        //   // src/assets/accommodation/crown_promenade/room_images/standard_king.jpg
        //   type: 'imageTable',
        //   table: [
        //     [
        //       {
        //         src: require('@/assets/accommodation/crown_promenade/hotel_images/lobby.jpg'),
        //         title: 'Hotel Lobby'
        //       },
        //       {
        //         src: require('@/assets/accommodation/crown_promenade/hotel_images/pool.jpeg'),
        //         title: 'Pool'
        //       }
        //     ],
        //     [
        //       {
        //         src: require('@/assets/accommodation/crown_promenade/room_images/bathroom.jpg'),
        //         title: 'Bathroom'
        //       },
        //       {
        //         src: require('@/assets/accommodation/crown_promenade/room_images/standard_king.jpg'),
        //         title: 'Standard King Room'
        //       }
        //     ]
        //   ]
        // },

        // AC Hotel Melbourne Southbank - Marriott – 4-star 
        // Address: 199-201 Normanby Rd, Southbank VIC 3006. (https://goo.gl/maps/zD5LXLPL5YXyFwWS8)
        // Distance to Venue: 10 min walking – 1km 
        // Hotel Description: AC Hotel by Marriott Melbourne Southbank is a design-led hotel for the guest who is intentional in everything they do. Through overthinking every element of your stay, AC provides everything you need and nothing you don’t. 
        // Room Rate: Deluxe Room - $240 per night room only 
        // Room Description: AC Marriott Southbank will provide guests with air-conditioned rooms with a 
        // Images: https://drive.google.com/drive/folders/1G8szXfmnHM61c3shsKGBlZtxV-jap1Gq?usp=drive_link 

        // {
        //   type: 'secondaryMiddleTitle',
        //   text: 'AC Hotel Melbourne Southbank - Marriott – 4-star'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Address:</b> <a href="https://goo.gl/maps/zD5LXLPL5YXyFwWS8" target="_blank">199-201 Normanby Rd, Southbank VIC 3006.</a>'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Distance to Venue:</b> 10 min walking – 1km'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Hotel Description:</b> AC Hotel by Marriott Melbourne Southbank is a design-led hotel for the guest who is intentional in everything they do. Through overthinking every element of your stay, AC provides everything you need and nothing you don’t.'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Room Rate:</b> Deluxe Room - $240 per night room only'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Room Description:</b> AC Marriott Southbank will provide guests with air-conditioned rooms with a'
        // },
        // {
        //   type: 'text',
        //   text: '<b>Images:</b>',
        // },
        // {
        //   type: 'imageTable',
        //   table: [
        //     [
        //       {
        //         src: require('@/assets/accommodation/ac_marriott/hotel_images/exterior_2.png'),
        //         title: 'Hotel Exterior'
        //       },
        //       {
        //         src: require('@/assets/accommodation/ac_marriott/hotel_images/infinity_pool.png'),
        //         title: 'Infinity Pool'
        //       },
        //       {
        //         src: require('@/assets/accommodation/ac_marriott/hotel_images/room_with_dog.jpg'),
        //         title: 'Room with Dog'
        //       },
        //     ],
        //     [
        //       {
        //         src: require('@/assets/accommodation/ac_marriott/room_images/deluxe_room.png'),
        //         title: 'Deluxe Room'
        //       }
        //     ]
        //   ]
        // },

        // ibis Styles Kingsgate – 3-star 
        // Address: 131 King St, Melbourne VIC 3000. (https://maps.app.goo.gl/BWipdKgqBeLeLmpi6)
        // Distance to Venue: 15 min walking – 1.5km 
        // Hotel Description: ibis Styles Kingsgate Melbourne Hotel provides modern facilities with all the unique charm of a 1926 heritage building. The hotel offers affordable and convenient accommodation, whether you are travelling alone or bringing the whole family ibis Styles Kingsgate Hotel has a wide variety of room types to suit your travelling needs.
        // Room Rate: Standard Double Room – $150 per night room only
        // Images: https://drive.google.com/drive/folders/1dFaExe6GaHR2wYtZ8He2kQHAjjXVL0oo?usp=drive_link 

        {
          type: 'secondaryMiddleTitle',
          text: 'ibis Styles Kingsgate – 3-star'
        },
        {
          type: 'text',
          text: '<b>Address:</b> <a href="https://maps.app.goo.gl/BWipdKgqBeLeLmpi6" target="_blank">131 King St, Melbourne VIC 3000.</a>'
        },
        {
          type: 'text',
          text: '<b>Distance to Venue:</b> 15 min walking – 1.5km'
        },
        {
          type: 'text',
          text: '<b>Hotel Description:</b> ibis Styles Kingsgate Melbourne Hotel provides modern facilities with all the unique charm of a 1926 heritage building. The hotel offers affordable and convenient accommodation, whether you are travelling alone or bringing the whole family ibis Styles Kingsgate Hotel has a wide variety of room types to suit your travelling needs.'
        },
        {
          type: 'text',
          text: '<b>Room Rate:</b> Standard Double Room – $150 per night room only'
        },
        {
          type: 'text',
          text: '<b>Images:</b>',
        },
        {
          type: 'imageTable',
          table: [
            [
              {
                src: require('@/assets/accommodation/ibis_kingsgate/hotel_images/exterior.jpg'),
                title: 'Exterior'
              },
              {
                src: require('@/assets/accommodation/ibis_kingsgate/hotel_images/lobby.jpg'),
                title: 'Lobby'
              },
            ],
            [
              {
                src: require('@/assets/accommodation/ibis_kingsgate/room_images/standard_double_room.jpg'),
                title: 'Standard Double Room'
              }
            ]
          ]
        },

        // Sold Out Hotels:
        // Batman’s Hill on Collins 			Best Western Melbourne
        // @/assets/accommodation/batmans_on_collins/hotel_images/exterior.jpg @/assets/accommodation/best_western/hotel_images/front.jpg

        // soldOutTable
        {
          type: 'centeredTitle',
          text: 'Sold Out Hotels:'
        },
        {
          type: 'soldOutTable',
          table: [
            [
              {
                src: require('@/assets/accommodation/batmans_on_collins/hotel_images/exterior.jpg'),
                title: 'Batman’s Hill on Collins'
              },
              {
                src: require('@/assets/accommodation/best_western/hotel_images/front.jpg'),
                title: 'Best Western Melbourne'
              }
            ],
            [
            {
                src: require('@/assets/accommodation/ac_marriott/hotel_images/exterior_2.png'),
                title: 'AC Hotel Melbourne Southbank – Marriott'
              },
              {
                src: require('@/assets/accommodation/crown_promenade/hotel_images/lobby.jpg'),
                title: 'Crown Promenade'
              }
            ]
          ]
        },

        // // Best Western Melbourne – 3-star
        // {
        //   type: 'secondaryMiddleTitle',
        //   text: 'Best Western Melbourne – 3-star'
        // },
        // // BOOKING LINK
        // // {
        // //   type: 'text',
        // //   text: '<a href="https://www.bestwestern.com/en_US/book/hotel-rooms.97465.html?groupId=T21HD2A5" target="_blank"><b>BOOKING LINK</b></a>'
        // // },
        // // Address: [16 Spencer St, Melbourne VIC 3000](https://maps.app.goo.gl/keDtoJGLzS6WT9jD8)
        // {
        //   type: 'text',
        //   text: '<b>Address:</b> <a href="https://maps.app.goo.gl/keDtoJGLzS6WT9jD8" target="_blank">16 Spencer St, Melbourne VIC 3000</a>'
        // },
        // // Distance to Venue: 6 mins – 500m 
        // {
        //   type: 'text',
        //   text: '<b>Distance to Venue:</b> 6 mins – 500m'
        // },
        // // Hotel Information: Excellent value property with flexible different room options.  Ideal for low-cost or student sharing.  One of its best advantages is its proximity to all your essential services, from public transport and shopping - to countless restaurants and dining options.
        // {
        //   type: 'text',
        //   text: '<b>Hotel Information:</b> Excellent value property with flexible different room options.  Ideal for low-cost or student sharing.  One of its best advantages is its proximity to all your essential services, from public transport and shopping - to countless restaurants and dining options.'
        // },
        // // Room Rate: Matchbox Room – $135 per night room only, Shoebox Room – $145 per night room only & Twin Room – $145 per night room only
        // {
        //   type: 'text',
        //   text: '<b>Room Rate:</b> Matchbox Room – $135 per night room only, Shoebox Room – $145 per night room only & Twin Room – $145 per night room only'
        // },
        // // Room Description: 
        // {
        //   type: 'text',
        //   text: '<b>Room Description:</b>'
        // },
        // // •	Matchbox Room – While you want something that’s inexpensive, you also want something that’s clean and hygienic. You might need ‘just’ a bed for the night, but you understandably want comfort as well. Luckily, you can have both when you book a Matchbox Room at Best Western Hotel on Spencer St in Melbourne.
        // // •	Shoebox Room – As comfortable as it is compact, the Shoebox Room provides all the amenities and features you need at a price you can afford. Ideal for a single person looking for a one night stay hotel, but equally as suitable for two people.
        // // •	Twin Room – Perfectly situated within walking distance of the best food and fashion destinations, the finest dining, many of city’s popular tourism sites and attractions, and a public transport system that takes you anywhere you need to go, when it comes to finding a comfortable yet affordable twin room in Melbourne, think Best Western. 
        // {
        //   type: 'retract',
        //   text: 'Matchbox Room – While you want something that’s inexpensive, you also want something that’s clean and hygienic. You might need ‘just’ a bed for the night, but you understandably want comfort as well. Luckily, you can have both when you book a Matchbox Room at Best Western Hotel on Spencer St in Melbourne.'
        // },
        // {
        //   type: 'retract',
        //   text: 'Shoebox Room – As comfortable as it is compact, the Shoebox Room provides all the amenities and features you need at a price you can afford. Ideal for a single person looking for a one night stay hotel, but equally as suitable for two people.'
        // },
        // {
        //   type: 'retract',
        //   text: 'Twin Room – Perfectly situated within walking distance of the best food and fashion destinations, the finest dining, many of city’s popular tourism sites and attractions, and a public transport system that takes you anywhere you need to go, when it comes to finding a comfortable yet affordable twin room in Melbourne, think Best Western.'
        // },
        // // Images: https://drive.google.com/drive/folders/1eEFZRtuoPLAWn1NzCrfXb_EnKODKxTuB?usp=drive_link
        // {
        //   type: 'text',
        //   text: '<b>Images:</b> ',
        // },
        // {
        //   // src/assets/accommodation/best_western/hotel_images/front.jpg
        //   // src/assets/accommodation/best_western/hotel_images/lobby-2.jpg
        //   // src/assets/accommodation/best_western/room_images/matchbox.jpg
        //   // src/assets/accommodation/best_western/room_images/sbox.jpg
        //   // src/assets/accommodation/best_western/room_images/twin-2.jpg
        //   type: 'imageTable',
        //   table: [
        //     [
        //       {
        //         src: require('@/assets/accommodation/best_western/hotel_images/front.jpg'),
        //         title: 'Hotel Front'
        //       },
        //       {
        //         src: require('@/assets/accommodation/best_western/hotel_images/lobby-2.jpg'),
        //         title: 'Hotel Lobby'
        //       }
        //     ],
        //     [
        //       {
        //         src: require('@/assets/accommodation/best_western/room_images/matchbox.jpg'),
        //         title: 'Matchbox Room'
        //       },
        //       {
        //         src: require('@/assets/accommodation/best_western/room_images/sbox.jpg'),
        //         title: 'Shoebox Room'
        //       },
        //       {
        //         src: require('@/assets/accommodation/best_western/room_images/twin-2.jpg'),
        //         title: 'Twin Room'
        //       }
        //     ]
        //   ]
        
        // },

        // // Batman’s Hill on Collins – 4-star 
        // {
        //   type: 'secondaryMiddleTitle',
        //   text: 'Batman’s Hill on Collins – 4-star'
        // },
        // // BOOKING LINK
        // // {
        // //   type: 'text',
        // //   text: '<a href="https://www.idem.events/r/acmmm-2024-icms-b6cc4384" target="_blank"><b>BOOKING LINK</b></a>'
        // // },
        // // Address: [623 Collins St, Melbourne VIC 3000](https://goo.gl/maps/xCLTof1G94fVZ5i79)
        // {
        //   type: 'text',
        //   text: '<b>Address:</b> <a href="https://goo.gl/maps/xCLTof1G94fVZ5i79" target="_blank">623 Collins St, Melbourne VIC 3000</a>'
        // },
        // // Distance to Venue: 8 mins – 800m 
        // {
        //   type: 'text',
        //   text: '<b>Distance to Venue:</b> 8 mins – 800m'
        // },
        // // Hotel Information: In the heart of Melbourne CBD, Batman’s Hill Hotel on Collins Street is opposite Southern Cross Station and within walking distance of many of the city’s most sought-after attractions. With 190 rooms, suites and apartments offering great value for money, Batman’s Hill is ideal for both short trips and long-term stays.
        // {
        //   type: 'text',
        //   text: '<b>Hotel Information:</b> In the heart of Melbourne CBD, Batman’s Hill Hotel on Collins Street is opposite Southern Cross Station and within walking distance of many of the city’s most sought-after attractions. With 190 rooms, suites and apartments offering great value for money, Batman’s Hill is ideal for both short trips and long-term stays.'
        // },
        // // Room Rate: Standard Queen/Twin Room - $149 per night room only
        // {
        //   type: 'text',
        //   text: '<b>Room Rate:</b> Standard Queen/Twin Room - $149 per night room only'
        // },
        // // Room Description: At Batman’s Hill on Collins the Standard Rooms combine convenience and comfort. Appointed with contemporary fittings, they offer a 3.5 star budget option with a 4 stars service and a priceless location.
        // {
        //   type: 'text',
        //   text: '<b>Room Description:</b> At Batman’s Hill on Collins the Standard Rooms combine convenience and comfort. Appointed with contemporary fittings, they offer a 3.5 star budget option with a 4 stars service and a priceless location.'
        // },
        // // Images: https://drive.google.com/drive/folders/1Jo8RNIkpncLbawYZd4MQ1HeUO_ulQVng?usp=drive_link
        // {
        //   type: 'text',
        //   text: '<b>Images:</b> ',
        // },
        // {
        //   // src/assets/accommodation/batmans_on_collins/hotel_images/bar.jpg
        //   // src/assets/accommodation/batmans_on_collins/hotel_images/exterior.jpg
        //   // src/assets/accommodation/batmans_on_collins/room_images/standard_queen_room.jpg
        //   // src/assets/accommodation/batmans_on_collins/room_images/standard_room.jpg
        //   // src/assets/accommodation/batmans_on_collins/room_images/standard_twin.jpg
        //   type: 'imageTable',
        //   table: [
        //     [
        //       {
        //         src: require('@/assets/accommodation/batmans_on_collins/hotel_images/bar.jpg'),
        //         title: 'Bar'
        //       },
        //       {
        //         src: require('@/assets/accommodation/batmans_on_collins/hotel_images/exterior.jpg'),
        //         title: 'Hotel Exterior'
        //       }
        //     ],
        //     [
        //       {
        //         src: require('@/assets/accommodation/batmans_on_collins/room_images/standard_queen_room.jpg'),
        //         title: 'Standard Queen Room'
        //       },
        //       {
        //         src: require('@/assets/accommodation/batmans_on_collins/room_images/standard_room.jpg'),
        //         title: 'Standard Room'
        //       },
        //       {
        //         src: require('@/assets/accommodation/batmans_on_collins/room_images/standard_twin.jpg'),
        //         title: 'Standard Twin Room'
        //       }
        //     ]
        //   ]
        // },

        // Accommodation Deadline: Thursday 26 September
        // Cancellations
        // NO ACCOMMODATION PAYMENTS WILL BE REFUNDED ON OR AFTER 26 SEPTEMBER 2024. Delegates who confirm an accommodation booking with a credit card or full pre-payment and who wish to cancel on or after 26 September 2024 are liable for the full cost of their accommodation nights cancelled. Credit Card details or full pre-payments will be held on file as a guarantee of booking and shared with the hotel upon situations of no-shows, or late cancellations, to charge at full rate upon their discretion. To cancel your reservation, please contact the Conference Secretariat. Name changes to a reservation can be made at no charge.
        // Deposits
        // Accommodation will not be confirmed until payment of the first night is received by Credit Card or full pre-payment is received by Cheque or Electronic Funds Transfer. Your payment will be forwarded to your hotel and credited to your room account and credit card details will be retained on file as a guarantee of booking. Delegates must settle the balance of their account with the hotel concerned upon check in/out depending on the hotels policy. If accommodation deposits are not received before 26 September 2024 your room will be cancelled and released back to the hotel. The Conference Secretariat does not take responsibility for accommodation if payment is not received by this date.
        // Change of booking
        // Any change in booking must be made in writing to the Conference Secretariat and NOT directly to the hotel before 26 September 2024. Any changes received after 26 September 2024 will be at the hotels discretion and based on availability.
        // Early arrival
        // As check in at most hotels is not until 14:00, it is possible that your room may not be ready for immediate occupation on your early arrival. Whilst hotels will do everything possible to accommodate early arrivals, if you wish to be guaranteed immediate occupation you can pay an extra day’s tariff for the night before you are due to arrive. If you wish to take this precaution, please advise the Conference Secretariat so we can notify the hotel that your room is required early.
        // No show policy
        // In the event of a “No Show” on the date of arrival, full cancellation penalties apply to all nights of the stay.

        {
          type: 'secondaryMiddleTitle',
          text: 'Accommodation Deadline: Thursday 26 September'
        },
        {
          type: 'text',
          text: '<b>Cancellations</b>'
        },
        {
          type: 'text',
          text: 'NO ACCOMMODATION PAYMENTS WILL BE REFUNDED ON OR AFTER 26 SEPTEMBER 2024. Delegates who confirm an accommodation booking with a credit card or full pre-payment and who wish to cancel on or after 26 September 2024 are liable for the full cost of their accommodation nights cancelled. Credit Card details or full pre-payments will be held on file as a guarantee of booking and shared with the hotel upon situations of no-shows, or late cancellations, to charge at full rate upon their discretion. To cancel your reservation, please contact the Conference Secretariat. Name changes to a reservation can be made at no charge.'
        },
        {
          type: 'text',
          text: '<b>Deposits</b>'
        },
        {
          type: 'text',
          text: 'Accommodation will not be confirmed until payment of the first night is received by Credit Card or full pre-payment is received by Cheque or Electronic Funds Transfer. Your payment will be forwarded to your hotel and credited to your room account and credit card details will be retained on file as a guarantee of booking. Delegates must settle the balance of their account with the hotel concerned upon check in/out depending on the hotels policy. If accommodation deposits are not received before 26 September 2024 your room will be cancelled and released back to the hotel. The Conference Secretariat does not take responsibility for accommodation if payment is not received by this date.'
        },
        {
          type: 'text',
          text: '<b>Change of booking</b>'
        },
        {
          type: 'text',
          text: 'Any change in booking must be made in writing to the Conference Secretariat and NOT directly to the hotel before 26 September 2024. Any changes received after 26 September 2024 will be at the hotels discretion and based on availability.'
        },
        {
          type: 'text',
          text: '<b>Early arrival</b>'
        },
        {
          type: 'text',
          text: 'As check in at most hotels is not until 14:00, it is possible that your room may not be ready for immediate occupation on your early arrival. Whilst hotels will do everything possible to accommodate early arrivals, if you wish to be guaranteed immediate occupation you can pay an extra day’s tariff for the night before you are due to arrive. If you wish to take this precaution, please advise the Conference Secretariat so we can notify the hotel that your room is required early.'
        },
        {
          type: 'text',
          text: '<b>No show policy</b>'
        },
        {
          type: 'text',
          text: 'In the event of a “No Show” on the date of arrival, full cancellation penalties apply to all nights of the stay.'
        },

        // Enquiries
        // Please direct all accommodation enquiries to:
        // MM 2024 Conference Secretariat
        // Managed by ICMS Australasia Pty Ltd
        // P: +61 3 9682 0500
        // E: acmmm24@icmsaust.com.au  
        {
          type: 'text',
          text: '<b>Enquiries</b>' + '<br>Please direct all registration enquiries to:' + '<br><b style="color:#2C9370;">MM 24 Conference Secretariat</b>' + '<br>Managed by ICMS Australasia Pty Ltd' + '<br>P: +61 3 9682 0500' + '<br>E: <a href="mailto:acmmm24@icmsaust.com.au">acmmm24@icmsaust.com.au</a>',
        },

      ],
    }
  },
  methods:{
    openLink: function(link){
      window.open(link, '_blank');
    }
  },
}
</script>

<style scoped>

@import "../../css/responsive.css";


.icon {
  margin-top: .6rem;
  margin-right: 1rem;
  width: 3.6rem;
}

.title {
  margin-left: -1.8rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* color: #202020; */
  color: black;
}

.head_title {
  margin-left: 0rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /*color: black;*/
  /* color: #202020; */
  color: black;
}

.black * {
  color: black !important;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  .icon {
    margin-top: 0rem;
  }

  .title {
    margin-left: 0;
  }
}

.mel_banner{
    /* width: 40rem;
    height: 40rem; */
    position:relative;
    padding-bottom: 35%;
    /* position: relative; */
    /* text-align: center; */
    /* width: 200px;
    height: 200px; */
    /* overflow: hidden; */
}

.mel_banner > img{
  position:absolute;
  /* object-fit: cover; */
  top:0;left:0;
  width:100%;
  /* height:100%; */
}

.table-row {
    padding-bottom: 20px;
}

/* .table-row {
  height: 10rem;
}

.table-col {
  width: 30%;
} */

.vic_image_holder > img{
  width: 100%;
  height: 100%;
}

.vic_image_holder {
  /* display: inline-block; */
  margin-top: 0.2rem;
  /* margin-bottom: 0.2rem; */
  display: flex;
  flex-direction: row;
  justify-content:center;
  height: 20rem;
  /* align-content: center; */
  align-items: center;
}


.vic_image_holder2 > img{
  width: 100%;
  height: 100%;
}

.vic_image_holder2 {
  /* display: inline-block; */
  margin-top: 0.2rem;
  /* margin-bottom: 0.2rem; */
  display: flex;
  flex-direction: row;
  justify-content:center;
  height: 30rem;
  /* align-content: center; */
  align-items: center;
}

.second_vic_title{
  /* margin-left: -1.8rem; */
  /*margin-left: 100.0px;*/
  
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* font-weight: ; */
  /* color: #202020; */
}

.vic_image_text {
    /* border:1px solid black; */
    /* font-size: 1.5rem; */
    text-align: center;
    color: black;
    font-family: "Roboto", sans-serif;
    /* background: #05cedd; */
}

</style>
