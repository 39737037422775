<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Brave New Ideas (BNI) Track, ACMMM 2024',
      contents:[
        {
          type:'primaryTitle',
          text:'Overview',
        },
        {
          type:'text',
          text:'The Brave New Idea (BNI) Track solicits papers containing original ideas and research vision that draw attention to novel directions in multimedia research. We are particularly calling for papers offering: (1) novel, exploratory solutions with sufficient evidence of proof-of-concept; (2) visions describing a new or open problem in multimedia research; and (3) a novel perspective on existing multimedia research.',
        },
        {
          type:'text',
          text:'BNI papers are considered as outstanding ACMMM full papers, and accepted BNI papers will appear in the <a class="demo-link" href="https://2024.acmmm.org">ACMMM 2024</a>  main conference proceedings.',
        },
        {
          type:'text',
          text:'Submit your paper to Open Review: <a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Track/BNI">https://openreview.net/group?id=acmmm.org/ACMMM/2024/Track/BNI</a>.',
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Paper Submission: <b>April 12, 2024</b>',
        },
        {
          type:'noList',
          text:'Paper Reviews to Author: <b>June 10, 2024</b>',
        },
        {
          type:'noList',
          text:'Paper Rebuttal: <b>June 17, 2024</b>',
        },
        {
          type:'noList',
          text:'Paper Notification: <b>July 8, 2024</b>',
        },
        {
          type:'noList',
          text:'Camera-ready Submission: <b>August 7, 2024</b>',
        },
        {
          type:'primaryTitle',
          text:'Submission Guidelines',
        },
        {
          type:'text',
          text:'BNI papers are expected to have a high component of novelty. They can also address an understudied, open problem in multimedia, which may receive less attention in the full paper track. However, BNI papers should still support their ideas with sufficient scientific argumentation, and where appropriate experimentation or proof. The papers also require high clarity in presentation. ',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Difference between Regular Full Papers and BNI Papers</span>',
        },
        {
          type:'text',
          text:'BNI submissions do not require new empirical results that outperform a state-of-the-art baseline, unlike traditional multimedia submissions. However, BNI submissions that focus on novel, exploratory solutions still need to support their ideas with sufficient experimental evidence that the method works and is not just a wild idea. ',
        },
        {
          type:'text',
          text:'BNI submissions that focus on novel perspectives on existing problems, or new research visions do not require empirical results but are still expected to defend their position with solid scientific arguments based on the relevant literature. In-depth discussions and explanations of implications are also expected. ',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Examples of Appropriate BNI Submissions</span>',
        },
        {
          type: 'noList',
          text: 'Submissions presenting novel, exploratory solutions that have never previously been applied to multimedia tasks.'
        },
        {
          type: 'noList',
          text: 'Submissions presenting novel solutions from outside the SIGMM community that may enable fundamental, significant advances in multimedia research. '
        },
        {
          type: 'noList',
          text: 'Research trajectory/agenda for a new and currently unsolved multimedia problem, with a literature study based on a solid exhaustive search protocol demonstrating that it has not been addressed.'
        },
        {
          type: 'noList',
          text: 'Proposals envisioning a uniquely different solution for a resolved problem using evidence from the literature (possibly from other disciplines) and/or proof-of-concept.'
        },
        {
          type: 'noList',
          text: 'A scientific critique of a core assumption underlying multimedia research or systems, supported by relevant literature from other disciplines and/or sufficient experimental evidence. '
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Examples of Inappropriate BNI Submissions</span>',
        },
        {
          type: 'noList',
          text: 'A submission that is suitable for the multimedia full paper track. '
        },
        {
          type: 'noList',
          text: 'A literature review without novel arguments.'
        },
        {
          type: 'noList',
          text: 'A submission without sufficient connection to multimedia.'
        },
        {
          type: 'noList',
          text: 'An opinion piece about a core assumption underlying multimedia research without scientific evidence or support from the relevant literature. '
        },
        {
          type:'primaryTitle',
          text:'Reviewing Criteria',
        },
        {
          type:'text',
          text:'A separate reviewing committee comprising of senior researchers will review the BNI papers. The reviewing will be a similarly rigorous process that all regular papers go through. However, the reviewing criteria will be different from the full paper track, with high scores being assigned to novelty and impact components, while less on the limitations or current performance of the method.',
        },
        {
          type:'text',
          text:'Submissions will be evaluated using the following criteria:',
        },
        {
          type: 'noList',
          text: 'Novelty: Are people already studying this problem in the multimedia community?  In other communities?'
        },
        {
          type: 'noList',
          text: 'Conceptual leap: How does it change our way of thinking about a problem, a method, or its execution? How much will research in this area advance our state of knowledge?'
        },
        {
          type: 'noList',
          text: 'Depth of impact: How many supporting sub-problems requiring foundational research might arise from the observations in the submission?'
        },
        {
          type: 'noList',
          text: 'Breadth of impact: What stakeholders inside or outside of the SIGMM community are impacted? What new applications might this idea trigger?'
        },
        {
          type: 'noList',
          text: 'Relevance to SIGMM: Who from the SIGMM community might this problem be directly relevant to?'
        },
        {
          type:'primaryTitle',
          text:'Submission Instructions',
        },
        {
          type:'text',
          text:'<b>Submission System: </b><a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Track/BNI">https://openreview.net/group?id=acmmm.org/ACMMM/2024/Track/BNI</a>.',
        },
        {
          type:'text',
          text:'The submission instructions, paper format, paper length (up to 8 pages), and review procedures will follow <a class="demo-link" href="https://2024.acmmm.org/regular-papers">those of regular papers</a>.',
        },
        {
          type:'primaryTitle',
          text:'BNI Track Co-Chairs',
        },
        {
          type:'noList',
          text:'<b><a href="https://www.chuatatseng.com/">Chua, Tat-Seng</a></b>, National University of Singapore, Singapore.',
        },
        {
          type:'noList',
          text:'<b><a href="https://www.mmlab-ntu.com/person/ccloy/">Loy, Chen Change</a></b>, Nanyang Technological University, Singapore.',
        },
        {
          type:'noList',
          text:'<b><a href="https://staff.fnwi.uva.nl/m.worring/">Worring, Marcel</a></b>, University of Amsterdam, Netherlands.',
        },
        {
          type:'primaryTitle',
          text:'Contact',
        },
        {
          type:'text',
          text:'For further information, please contact the ACMMM 2024 BNI Co-chairs by email to: <a class="demo-link" href="mailto:acmmm2024bni@gmail.com "> &lt;acmmm2024bni@gmail.com &gt;</a>',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Brave New Ideas'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>