<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Industry Expert Talks',
      contents: [
        {
          type: 'text',
          text: 'The ACM MM 2024 organization committee is inviting presentation proposals for the "Industry Expert Talks" event, focusing on industry and application trends in Multimedia. Each speaker will be allotted approximately 30 minutes for their oral presentation, including a Q&A session (around 5 minutes). We welcome experts and leaders to submit their proposals for a talk in one of the following three formats related to the use of multimedia in industry and practical applications:'
        },
        {
          type: 'noList',
          text: 'A general overview of one technology area; or',
        },
        {
          type: 'noList',
          text: 'An in-depth treatment of one specific technical issue; or',
        },
        {
          type: 'noList',
          text: 'A special focus on standardization and deployment challenges.',
        },
        {
          type: 'text',
          text: 'Presentations may also include a brief introduction of your organization\'s current research and development. The speaker is not limited to those who work in a company. We also welcome presentations from university professors who have experience working in a company, or those who have extensive experience in collaborative research with companies. The Industry Expert Talks will be highly attractive to attendees, but space is limited.'
        },
        {
          type: 'text',
          text: 'The deadline for proposal submission (second round) is September 6, 2024 (11:59pm AoE), and notifications of acceptance will be sent by September 13, 2024 (AoE). Proposals of approximately 300 words should be submitted using the online template below, including the following information:'
        },
        {
          type: 'noList',
          text: 'Title of the presentation',
        },
        {
          type: 'noList',
          text: 'Speaker’s brief biography',
        },
        {
          type: 'noList',
          text: 'Summary of the Presentation (describe in around 300 words the proposed presentation in terms of its relevance and attractiveness to ACM MM, technical contents, novelty, inspirations, and motivations to the audience)',
        },
        {
          type: 'text',
          text: 'Proposals will be evaluated and selected based on novelty, significance, and interest to the ACM MM community, as well as the credentials of the speakers. The presentation will be given on-site. Please note that you will need to register at ACM MM 2024 in order to present your talk.'
        },
        {
          type: 'primaryTitle',
          text: 'Contact',
        },
        {
          type: 'text',
          text: 'For all inquiries about the Industry Expert Talks, please contact the chairs:'
        },
        {
          type: 'text',
          text: 'Yukiko Yanagawa, Omron Corporation (yukiko.yanagawa@omron.com)',
        },
        {
          type: 'text',
          text: 'Jianquan Liu, NEC Corporation (jqliu.japan@ieee.org)',
        },
        {
          type: 'primaryTitle',
          text: 'Submission of Proposals',
        },
        {
          type: 'text',
          text: 'Proposals should be submitted via Google Form.',
        },
        {
            type: 'text',
            text: '<a class="demo-link" href="https://forms.gle/wEde1pJfx4C8AaLSA">https://forms.gle/wEde1pJfx4C8AaLSA</a>'
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Industrial Papers'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>