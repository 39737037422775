<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "panel-proposals",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Panel Proposals',
      contents:[
        {
          type:'primaryTitle',
          text:'Introduction',
        },
        {
          type:'text',
          text:`ACM Multimedia is the premier international conference in the area of multimedia within the field of computer science. Multimedia research focuses on technologies that enable the use and exchange of content integrating the multiple perspectives of different digital modalities, including images, text, video, audio, speech, music, and sensor data.`
        },
        {
          type:'text',
          text:`To promote a constructive discussion on the ever-growing area of multimedia research, ACM MM 2024 panels are expected to focus on topics of current interest. Preferably, the topic of panel discussion should be controversial, or a topic that has not yet received much attention, but is rapidly gaining importance. Recently, significant changes in multimedia research, development, and application landscape have taken place in order to deal with a multitude of contemporary technologies and applications, including emerging technologies, cloud computing, social networking, interactive and immersive experiences, personal multimedia, massive user-generated media data, learning-based multimedia systems, and media-rich mobile applications.`
        },
        {
          type:'text',
          text:`We are inviting newcomers, as well as seasoned multimedia researchers to propose panel discussions for ACM MM 2024. We welcome panel organizers from both academia and industry. New and emerging perspectives from multimedia-related industrial corporations and researchers are particularly welcome.
          `},
        {
          type:'text',
          text:`We look forward to receiving high-quality proposals from the multimedia community. Expert panelists are expected to share their views and experiences on the topic of the panel and discuss them with other panelists and the audience. The composition of panels should be diverse, representing a broad range of backgrounds and perspectives. Note that the panels at ACM MM 2024 are not intended as an avenue for panelists to present their own personal research or a narrow-scope research project.`,
        },
        {
          type:'primaryTitle',
          text:'Call for Proposals',
        },
        {
          type:'text',
          text:`Proposals are solicited for panels that can be scheduled for 60 or 90 minutes in duration. The panel should allocate at least 30 minutes for interaction with the audience. Please submit a cover sheet and an extended summary using the conference submission system.
          `,
        },
        {
          type:'text',
          text:`The complete proposal should be no more than 6 pages long and include:`,
        },
        {
          type:'retract',
          text:'Panel topic and motivation.',
        },
        {
          type:'retract',
          text:'Complete contact information for the panel organizers.',
        },
        {
          type:'retract',
          text:'(Potential) panelists and their expertise; preferably, the panelists should be (mostly) confirmed when submitting the proposal.',
        },
        {
          type:'retract',
          text:'A brief biography for each organizer and panellist.',
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Submission deadline: <b>June 3, 2024</b>',
        },
        {
          type:'noList',
          text:'Notification of acceptance: <b>July 5, 2024</b>',
        },
        {
          type:'noList',
          text:'Camera-ready deadline: <b>August 7, 2024</b>',
        },
        // {
        //   type:'text',
        //   text:'Submissions should be made via email to the panel chairs, at <a class="demo-link" href="mailto:mm24-panel@sigmm.org"> &lt;mm24-panel@sigmm.org&gt;</a>:',
        // },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions regarding submissions, please email the Panel Chairs:',
        },
        {
          type:'noList',
          text:'<b>Helen Zi Huang</b>, The University of Queensland, Australia, (huang@itee.uq.edu.au).',
        },
        {
          type:'noList',
          text:'<b>Phoebe Chen</b>, La Trobe University, Australia, (Phoebe.Chen@latrobe.edu.au).',
        },
        {
          type:'noList',
          text:'<b>Shuicheng Yan</b>, BAAI, China, (shuicheng.yan@gmail.com).',
        },
        // {
        //   type:'noListBlack',
        //   text:'<b>Xiaochun Cao</b> (caoxiaochun@iie.ac.cn), Chinese Academy of Sciences, China',
        // }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Panel Proposals'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

</style>