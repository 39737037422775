<template>
  <div>

    <el-row class="mel_banner">
        <img onclick=" window.open('https://www.melbournecb.com.au/welcome-to-melbourne','_blank')" src="@/assets/explore_melbourne/banner_hd.gif" alt="melbourne banner">
    </el-row>

    <!-- <el-row>
        <div class="vic_button" >
            <button onclick=" window.open('https://www.melbournecb.com.au/welcome-to-melbourne','_blank')">Welcome to Melbourne</button>
        </div>
    </el-row> -->

    <el-row class="el-rows">

      <p class="head_title"> Magic of Melbourne </p>
    </el-row>

    <div v-for="(content,index) in contents" :key="index">

      <el-row v-if="content.type === 'text'">
        <el-col class="demo-text">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>

    <el-row class="mel_video">
        <!-- <video controls>
            <source src="@/assets/explore_melbourne/mel_vid.mp4" type="video/mp4">
        </video> -->
        <!-- <video controls>
            <source src="@/assets/explore_melbourne/mel_vid.mp4" type="video/mp4">
        </video> -->
        <iframe src="https://drive.google.com/file/d/1I7LH5m9L6sHBpxr7zVBZoEE4KFZPUhhC/preview"></iframe>
    </el-row>

    <hr>

    <el-row class="mel_row">
      <p class="smallTitle"> What’s on in Melbourne during ACMMM 2024? </p>
    </el-row>

    <div v-for="(item,j) in contents_location" :key="j">

        <el-row v-if="item.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="item.text"></div>
            </li>
          </el-col>
        </el-row>
    </div>

    <div class="vic_button">
        <button onclick=" window.open('https://whatson.melbourne.vic.gov.au/search/from-2024-10-16+to-2024-11-08','_blank')">FIND OUT MORE</button>
    </div>

    <el-row class="mel_row">
      <p class="smallTitle"> Tick these iconic locations off your Melbourne Bucket List </p>
    </el-row>

    <div>
        <el-row class="parent_holder" v-for="(item ,i) in table" :key="i">
            <div >
                <el-col span="8">
                    <div class="vic_image_holder">
                        <img class="vic_image" :src="item.src_1" alt="melbourne image">
                    </div>
                    <div class="vic_image_text">
                        <span class="second_vic_title"> {{item.main_title_1}} </span>
                    </div>
                </el-col>
                <el-col span="8">
                    <div class="vic_image_holder">
                        <img class="vic_image" :src="item.src_2" alt="melbourne image">
                    </div>
                    <div class="vic_image_text">
                        <span class="second_vic_title"> {{item.main_title_2}} </span>
                    </div>
                </el-col>
                <el-col span="8">
                    <div class="vic_image_holder">
                        <img class="vic_image" :src="item.src_3" alt="melbourne image">
                    </div>
                    <div class="vic_image_text">
                        <span class="second_vic_title"> {{item.main_title_3}} </span>
                    </div>
                </el-col>
            </div>
            
        </el-row>
    </div>

  </div>
</template>

<script>
export default {
  name: "Explore_Melbourne",
  data() {
    return {
      iconImage: "",
      contents: [
        {
          type: 'text',
          text: "A packed agenda of food, wine, sports and arts is your introduction to the best of Melbourne from its creative, exciting city centre, to its buzzing neighbourhood hubs. It's the gateway to Victoria's world-class wineries, natural springs, peninsulas, spectacular coastline and alpine villages, making it the ideal destination for easy access to pre and post conference touring."
        },
        {
          type: 'text',
          text: "Melbourne has a lively passion for social eating and drinking, which is reflected in the thousands of restaurants serving up gastronomic experiences from around the world. Everywhere you will uncover a vast array of fashionable cafes where you can enjoy Melbourne's existential coffee and cafe culture to the fullest."
        },
        {
          type: 'text',
          text: "Maximise your visit to Melbourne and the premier Multimedia Conference of 2024."
        },
      ],
      contents_location: [
          {
              type: 'retract',
              text: "<a class='demo-link' href='https://whatson.melbourne.vic.gov.au/things-to-do/wurrdha-marra'>Wurrdha Marra</a> – witness the work of First Nation Australian artists across time and place."
          },
          {
              type: 'retract',
              text: "<a class='demo-link' href='https://whatson.melbourne.vic.gov.au/eat-and-drink/sojourn-melbourne'>Soujourn Melbourne</a> – an outdoor laneway dining experience."
          },
          {
              type: 'retract',
              text: "<a class='demo-link' href='https://www.racingvictoria.com.au/the-sport/springracingcarnival'>Spring Racing Carnival</a> – the yearly horse racing festival commences the week of ACMMM 2024 and is a highlight on the Melbourne sporting calendar."
          },
      ],
      table: [
        {
          src_1: require("@/assets/explore_melbourne/Federation_Square.jpg"),
          main_title_1: 'Federation Square',
          src_2: require("@/assets/explore_melbourne/Hosier_Lane.jpg"),
          main_title_2: 'Hosier Lane',
          src_3: require("@/assets/explore_melbourne/NGV_International.jpg"),
          main_title_3: 'NGV International',

        },
        {
          src_1: require("@/assets/explore_melbourne/Flinders_and_hertitage_tram.jpg"),
          main_title_1: 'Flinders Street Station',
          src_2: require("@/assets/explore_melbourne/Queen_Vic_Market.jpg"),
          main_title_2: 'Queen Victoria Market',
          src_3: require("@/assets/explore_melbourne/Melb_Central.jpg"),
          main_title_3: 'Melbourne Central',

        },
        
      ],
    }
  }
}
</script>

<style scoped>

@import "../../css/responsive.css";

.mel_row {
  padding-top: 5rem;
}

.mel_video{
    /* width: 40rem;
    height: 40rem; */
    position:relative;
    padding-bottom:55%;
}

.mel_video > video{
  position:absolute;
  top:0;left:0;
  width:100%;
  height:100%;
}

.mel_video > iframe{
  position:absolute;
  top:0;left:0;
  width:100%;
  height:100%;
}

.mel_banner{
    /* width: 40rem;
    height: 40rem; */
    position:relative;
    padding-bottom:15%;
    /* position: relative; */
    /* text-align: center; */
    /* width: 200px;
    height: 200px; */
    /* overflow: hidden; */
}

.mel_banner > img{
  position:absolute;
  /* object-fit: cover; */
  top:0;left:0;
  width:100%;
  height:100%;
}

.parent_holder {
    padding-bottom: 20px;
}


.vic_image_holder > img{
    width: 100%;
    height: 100%;
}


.vic_image_holder {
  /* display: inline-block; */
  margin-top: 0.2rem;
  /* margin-bottom: 0.2rem; */
  display: flex;
  flex-direction: row;
  justify-content:center;
  height: 20rem;
  /* align-content: center; */
  align-items: center;
}

.main_title {
  /* margin-left: -1.8rem; */
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 2.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* color: #202020; */
  color: black;
}

.second_title {
  /* margin-left: -1.8rem; */
  /*margin-left: 100.0px;*/
  
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* font-weight: ; */
  /* color: #202020; */
  
}
.second_vic_title{
  /* margin-left: -1.8rem; */
  /*margin-left: 100.0px;*/
  
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* font-weight: ; */
  /* color: #202020; */
}

.vic_image_text {
    /* border:1px solid black; */
    /* font-size: 1.5rem; */
    text-align: center;
    color: black;
    font-family: "Roboto", sans-serif;
    /* background: #05cedd; */
}

.head_title {
  margin-left: 0rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /*color: black;*/
  /* color: #202020; */
  color: black;
}

.vic_button{
    /* display: inline-block; */
    margin-top: 3rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content:center;
    /* align-content: center; */
    align-items: center;
    /* background: #05cedd; */
    text-align: center;
    /* color: #FFFFFF; */
}

.vic_button > button{
    border: none;
    padding: 1.1rem 5rem;
    text-align: center;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    display: inline-block;
    font-size: 1.5rem;
    background: #2C9370;
    color: #FFFFFF;
    /* border-radius: 1rem; */
}

.smallTitle {
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    /*color: #0054B0;*/
    /* color: #05cedd; */
    color: black;
    margin-top: 1rem;
}


.black * {
  color: black !important;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  .icon {
    margin-top: 0rem;
  }

  .title {
    margin-left: 0;
  }
}
</style>
