<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{content.text}}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "workshop-papers",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Workshops',
      contents: [
        {
          type: 'secondaryBlack',
          text: 'HCMA\'24: The 5th International Workshop on Human-centric Multimedia Analysis',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://hcma2024.github.io/">https://hcma2024.github.io/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'MMSports’24: 7th ACM International Workshop on Multimedia Content Analysis in Sports',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="http://mmsports.multimedia-computing.de/mmsports2024/index.html">http://mmsports.multimedia-computing.de/mmsports2024/index.html</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'The 5th International Multimodal Sentiment Analysis Challenge and Workshop – MuSe 2024',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://www.muse-challenge.org/">https://www.muse-challenge.org/</a>',
        },
        {
          type: 'secondaryBlack',
          text: '1st Workshop on Advancements in Brain-Computer Interfaces (BCI) for Multimedia Understanding',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://hail-au.github.io/">https://hail-au.github.io/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'MIS\'24: 1st ACM Multimedia Workshop on Multi-modal Misinformation Governance in the Era of Foundation Models',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://mis-24.github.io/">https://mis-24.github.io/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'MRAC\'24: 2nd International Workshop on Multimodal and Responsible Affective Computing',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://react-ws.github.io/2024_mrac/">https://react-ws.github.io/2024_mrac/</a>',
        },
        {
          type: 'secondaryBlack',
          text: '3rd Workshop on Quality of Experience in Visual Multimedia Applications',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://qoevma2024.youku.com/">https://qoevma2024.youku.com/</a>',
        },
        {
          type: 'secondaryBlack',
          text: '1st International Workshop on Efficient Multimedia Computing under Limited Resources',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://emclr-acmmm.github.io/">https://emclr-acmmm.github.io/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'LGM3A \'24: 2nd Workshop on Large Generative Models Meet Multimodal Applications ',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://lgm3a.github.io/LGM3A2024/">https://lgm3a.github.io/LGM3A2024/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'The 2nd Workshop on UAVs in Multimedia: Capturing the World from a New Perspective',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://www.zdzheng.xyz/ACMMM2024Workshop-UAV/">https://www.zdzheng.xyz/ACMMM2024Workshop-UAV/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'The 1st International Workshop on Multimedia Computing for Health and Medicine',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://visualcom-group.github.io/mchm-24/">https://visualcom-group.github.io/mchm-24/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'McGE ’24: The 2nd International Workshop on Multimedia Content Generation and Evaluation: New Methods and Practice',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://cjinfdu.github.io/mcge.html">https://cjinfdu.github.io/mcge.html</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'ESGMFM \'24: The 1st Workshop on Efficiency, Security, and Generalization of Multimedia Foundation Models',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://esgmfm.site/">https://esgmfm.site/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'ACMMM CL\'24: 1st Continual Learning meets Multimodal Foundation Models: Fundamentals and Advances In Conjunction with ACM MM 2024',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://acmmm-cl.github.io/">https://acmmm-cl.github.io/</a>',
        },
        {
          type: 'secondaryBlack',
          text: '2nd International Workshop on Methodologies for Multimedia',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://meet4mm.wordpress.com/">https://meet4mm.wordpress.com/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'The 2nd International Workshop on Deep Multi-modal Generation and Retrieval',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://videorelation.nextcenter.org/MMGR24/">https://videorelation.nextcenter.org/MMGR24/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'SUMAC 2024: 6th workshop on the analySis, Understanding and proMotion of heritAge Contents',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://sumac-workshops.github.io/2024/">https://sumac-workshops.github.io/2024/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'VLM4Bio\'24: First International Workshop on Vision-Language Models for Biomedical Applications',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://sites.google.com/view/vlm4bio">https://sites.google.com/view/vlm4bio</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'The 2nd Workshop on UAVs in Multimedia: Capturing the World from a New Perspective',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://www.zdzheng.xyz/ACMMM2024Workshop-UAV/">https://www.zdzheng.xyz/ACMMM2024Workshop-UAV/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'MuSe’24: The 5th Multimodal Sentiment Analysis Challenge and Workshop:  Social Perception and Humor',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://www.muse-challenge.org/">https://www.muse-challenge.org/</a>',
        },
        {
          type: 'secondaryBlack',
          text: 'MMGR\'24: The 2nd International Workshop on Deep Multimodal Generation and Retrieval',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://videorelation.nextcenter.org/MMGR24/">https://videorelation.nextcenter.org/MMGR24/</a>',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Workshops'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>