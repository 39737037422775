<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "workshop-proposals",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Workshop Proposals',
      contents:[
        {
          type:'primaryTitle',
          text:'Introduction',
        },
        {
          type:'text',
          text:'We are soliciting proposals for workshops to be held in conjunction with ACM Multimedia 2024. The purpose of the workshops is to provide a comprehensive forum on current and emerging topics that will not be fully explored during the main conference and to encourage in-depth discussion of technical and application issues.',
        },
        {
          type:'primaryTitle',
          text:'Proposal Format',
        },
        {
          type:'text',
          text:'Each workshop proposal (maximum 4 pages, in PDF format) must include:',
        },
        {
          type:'text',
          text:'1.Title of the workshop.',
        },
        {
          type:'text',
          text:'2.Workshop organizers (name, affiliation and short biography).',
        },
        {
          type:'text',
          text:'3.Scope and topics of the workshop.',
        },
        {
          type:'text',
          text:'4.Rationale:',
        },
        {
          type:'retract',
          text:'Why the workshop is related to ACM Multimedia 2024.',
        },
        {
          type:'retract',
          text:'Why the topic is important.',
        },
        {
          type:'retract',
          text:'Why the workshop may attract a significant number of attendees.',
        },
        {
          type:'retract',
          text:'A brief biography for each organizer and panellist.',
        },
        {
          type:'text',
          text:'5.Workshop details:',
        },
        {
          type:'retract',
          text:'A draft call for papers (including organizers, program committee, and steering committee if any). Organizers are expected to be fully committed and physically present at the workshop.',
        },
        {
          type:'retract',
          text:'Workshop tentative schedule (number of expected papers, number of expected attendees, duration full/half day, format talks/posters, etc.). We encourage events that demonstrate the interest of the community in the proposed topic and guarantee the commitment of the organizers.',
        },
        {
          type:'retract',
          text:'Names of potential participants and invited speakers (if any).',
        },
        {
          type:'text',
          text:'6.Workshop history:',
        },
        {
          type:'retract',
          text:'If there are past workshops, the history of the workshop.',
        },
        {
          type:'primaryTitle',
          text:'Submission',
        },
        {
          type:'text',
          text:'Please submit your proposals at: <a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Workshop_Proposals">https://openreview.net/group?id=acmmm.org/ACMMM/2024/Workshop_Proposals</a>.',
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Workshop proposal submission: <b>March 4, 2024</b>',
        },
        {
          type:'noList',
          text:'Decision notification: <b>March 25, 2024</b>',
        },
        {
          type: 'noList',
          text: 'Workshop paper submission: <b>July 19, 2024</b>',
        },
        {
          type: 'noList',
          text: 'Workshop paper notification: <b>August 5, 2024</b>',
        },
        {
          type: 'noList',
          text: 'Workshop paper camera-ready: <b>August 22, 2024</b>',
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For questions regarding the submission you can email the workshop chairs:',
        },
        {
          type:'noList',
          text:'<b>Zhiyong Wang</b>, The University of Sydney, Australia, (zhiyong.wang@sydney.edu.au).',
        },
        {
          type:'noList',
          text:'<b>Jiaying Liu</b>, Peking University, China, (liujiaying@pku.edu.cn).',
        },
        {
          type:'noList',
          text:'<b>Tatsuya Harada</b>, University of Tokyo, Japan, (harada@mi.t.u-tokyo.ac.jp).',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Workshop Proposals'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";
</style>