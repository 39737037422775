<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{ mainTitle }}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Technical Demos and Videos Program',
      contents: [
        {
          type: 'primaryTitle',
          text: 'Overview',
        },
        {
          type: 'text',
          text: 'ACM Multimedia is the premier international conference in the area of multimedia within the field of computer science. Multimedia research focuses on technologies that enable the use and exchange of content integrating the multiple perspectives of different digital modalities, including images, text, video, audio, speech, music, and sensor data.',
        },
        {
          type: 'text',
          text: 'As in previous years, ACM Multimedia will provide demonstration and video sessions. Demos and videos are intended as real, practical, and interactive proof of the presenters\' research ideas and scientific or engineering contributions, with the goal of providing multimedia researchers and practitioners with the opportunity to discuss working multimedia systems, applications, prototypes, or proof-of-concepts. Such a setting allows conference attendants to view and interact firsthand with live evidence of innovative solutions and ideas in the field of multimedia and to see leading-edge research at work.',
        },
        {
          type: 'primaryTitle',
          text: 'Expected Contents',
        },
        {
          type: 'text',
          text: 'Submissions are encouraged in all areas related to multimedia, as described in the ACM MM 2024 general call for papers: <a class="demo-link" href="https://2024.acmmm.org/regular-papers/">https://2024.acmmm.org/regular-papers/</a>.',
        },
        {
          type: 'primaryTitle',
          text: 'Facilities',
        },
        {
          type: 'text',
          text: 'Once accepted, demonstrators and video presenters will be provided with a table, a poster board, a power outlet and (shared) wireless Internet. Demo and video presenters are expected to bring with themselves everything else needed for their demo and video presentations, such as hardware, laptops, sensors, PCs, etc. However, if you have special requests such as a larger space, special lighting conditions and so on, please inform us as soon as possible and we will do our best to arrange them.',
        },
        {
          type: 'text',
          text: '<b>Important note for the authors:</b> The official publication date is the date the proceedings are made available in the ACM Digital Library. This date may be up to two weeks prior to the first day of the conference. The official publication date affects the deadline for any patent applications related to the published work.',
        },
        {
          type: 'primaryTitle',
          text: 'Submission Guidelines',
        },
        // {
        //   type: 'text',
        //   text: 'The documents should be submitted through the conference submission system selecting the <b>“Demos and Videos”</b> track. <a class="demo-link" href="https://cmt3.research.microsoft.com/ACMMM2021/">https://cmt3.research.microsoft.com/ACMMM2021/</a>',
        // },
        {
          type: 'text',
          text: 'All submissions will be peer-reviewed, and the demos or videos will be selected through a jury process. Please submit a two-page demo/video proposal (excluding references) in standard ACM MM 2024 format, with the exception that the papers need not be submitted anonymously. Also, each demo/video submission must contain a supplementary file, either in PowerPoint format (no more than 10 slides) or in video (no longer than 3 minutes) explaining to the jury:',
        },
        {
          type: 'retract',
          text: 'What is the scientific or engineering concept behind the work?',
        },
        {
          type: 'retract',
          text: 'What is the novelty of the work and how is the work different from existing systems or techniques?',
        },
        {
          type: 'retract',
          text: 'What is the expected impact on the MM community?',
        },
        {
          type: 'retract',
          text: 'What will be actually shown during the demo?',
        },
        {
          type: 'retract',
          text: 'What type of interactivity will be supported, for both the presential and online scenarios?',
        },
        {
          type: 'text',
          text: 'In addition, a one-page PDF file with a description of your demo booth configuration may be submitted if you have special requests. Please note that the submission system can accept only files with a maximum size of 100MB. If the total size of your supplementary materials exceeds this, please contact the demo/video chairs or arrange a way to submit the file.',
        },
        {
          type: 'text',
          // https://openreview.net/group?id=acmmm.org/ACMMM/2024/Track/Demo
          text: '<b>Submission details:</b> <a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Track/Demo">https://openreview.net/group?id=acmmm.org/ACMMM/2024/Track/Demo</a>.',
        },
        {
          type: 'primaryTitle',
          text: 'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type: 'noList',
          text: 'Submission deadline: <b>June 17, 2024</b>'
        },
        {
          type: 'noList',
          text: 'Notification of acceptance: <b>July 8, 2024</b>'
        },
        {
          type: 'noList',
          text: 'Camera-ready deadline: <b>August 7, 2024</b>',
        },
        {
          type: 'primaryTitle',
          text: 'Contacts',
        },
        {
          type: 'text',
          text: 'For any questions regarding submissions, please email the Technical Demos and Videos Program Chairs:'
        },
        {
          type: 'noList',
          text: '<b>Abhinav Dhall</b> , Indian Institute of Technology Ropar, India, (abhinav@iitrpr.ac.in).'
        },
        {
          type: 'noList',
          text: '<b>Qianru Sun</b>, Singapore Management University, Singapore, (qianrusun@smu.edu.sg).'
        },
        {
          type:'noList',
          text:'<b>Chi-Wing Fu, Philip</b>, The Chinese University of Hong Kong, China, (cwfu@cse.cuhk.edu.hk).',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Technical Demos and Videos Program'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>