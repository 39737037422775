<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "multimedia-grand-challenge-proposals",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Call for Grand Challenge Proposals',
      contents:[
        {
          type:'primaryTitle',
          text:'Introduction',
        },
        {
          type:'text',
          text:`ACM Multimedia is the premier international conference in the area of multimedia within the field of computer science. Multimedia research focuses on integration of the multiple perspectives offered by different digital modalities including images, text, video, music, sensor data, and spoken audio.
          `,
        },
        {
          type:'text',
          text:`ACM Multimedia is calling for proposals for Grand Challenges in 2024. Proposers with an innovative idea of a Multimedia Grand Challenge, should gather an organizational team with the capacity to carry out the organization of a challenge, and submit a proposal according to the instructions below. In 2024, we are emphasizing the continuity of Grand Challenges, which is important in order to support sustained and substantial progress in the state of the art. We ask that organizer teams who would like to propose Grand Challenges to express a commitment to organize their Grand Challenge multiple years in a row.
          `,
        },
        {
          type:'text',
          text:`The Multimedia Grand Challenge was first presented as part of ACM Multimedia 2009 and has established itself as a prestigious competition in the multimedia community. The purpose of the Multimedia Grand Challenge is to engage the multimedia research community by establishing well-defined and objectively judged challenge problems intended to exercise state-of-the-art methods and inspire future research directions. The key criteria for Grand Challenges are that they should be useful, interesting, and their solution should involve a series of research tasks over a long period of time, with pointers toward longer-term research.`,
        },
        {
          type:'text',
          text:`A Multimedia Grand Challenge proposal should include:`,
        },
        {
          type:'retract',
          text:'A brief description to explain why the challenge problem is important and relevant to the multimedia research community, industry, and society over the <b>next 3-5 years</b> or a longer horizon.',
        },
        {
          type:'retract',
          text:'A description of a specific set of research tasks or sub-tasks to be carried out towards tackling the challenge problem in the long run.',
        },
        {
          type:'retract',
          text:'An outline of current state-of-the-art techniques and why this Grand Challenge would help accelerate research in this important area.',
        },
        {
          type:'retract',
          text:'Link to sites containing relevant datasets to be used for objective training and evaluation of the grand challenge tasks. Full appropriate documentation on the datasets should be provided or made accessible.',
        },
        {
          type:'retract',
          text:'A description of rigorously defined objective criteria and/or procedures on how the submissions will be evaluated or judged.',
        },
        {
          type:'retract',
          text:'A commitment to publish and maintain a website related to their specific Grand Challenge containing the information, datasets, tasks for the Grand Challenge at least the <b>next 3 years</b>.',
        },
        {
          type:'retract',
          text:'Work with ACM Multimedia Conference organizers to publicize the Grand Challenge tasks to researchers for participation.',
        },
        {
          type:'retract',
          text:'Contact information of at least <b>two</b> organizers who will be responsible for organizing, publicizing, reviewing and judging the Grand Challenge submissions as described in the proposal.',
        },{
          type:'retract',
          text:'Note that although we ask organizers to express a multi-year commitment to their Grand Challenge, the Challenge will still undergo a new review each year. Priority will be given to Grand Challenges which have been successful in the past and are clearly contributing to continuity.',
        },
        {
          type:"secondaryTitle",
          text:'<br>Submission Website:'
        },
        {
          type:'text',
          text:'Please send your Grand Challenge proposals to <a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Grand_Challenge_Proposals#tab-recent-activity">this link</a> by the deadline listed below.'
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Submission of Grand Challenge Proposals: <b>February 19, 2024</b>',
        },
        {
          type:'noList',
          text:'Notification of Acceptance: <b>March 4, 2024</b>',
        },
        {
          type:'noList',
          text:'Grand Challenge Solutions Submission: <b>July 12, 2024</b>',
        },

        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For questions regarding the Grand Challenges you can email the Multimedia Grand Challenge Chairs:'
        },
        {
          type:'noList',
          text:'<b>Hanwang Zhang</b> , Nanyang Technological University, Singapore, (hanwangzhang@ntu.edu.sg).',
        },
        {
          type:'noList',
          text:'<b>Elisa Ricci</b> , University of Trento, Italy, (e.ricci@unitn.it).',
        },
        {
          type:'noList',
          text:'<b>Si Liu</b> , Beihang University, China, (liusi@buaa.edu.cn).',
        },
        {
          type:'noList',
          text:'<b>Wanli Ouyang</b> , Shanghai AI Lab, China, (wanli.ouyang@gmail.com).',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Multimedia Grand Challenge Proposals'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

</style>