<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'retract_policies'">
          <el-col class="retract_policies">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "outstanding-ac-reviewer",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'ACM Multimedia Outstanding AC & Reviewer',
      contents:[
        {
            type:'primaryTitle',
            text:'Outstanding Area Chairs',
        },
        {
            type:'text',
            text:`
                <ul>
                <li>Abdelhak Bentaleb</li>
                <li>Alexander Raake</li>
                <li>Chong Luo</li>
                <li>Claudio Ferrari</li>
                <li>Dong Gong</li>
                <li>Feng Liu</li>
                <li>Florian Metze</li>
                <li>Frank Hopfgartner</li>
                <li>Hehe Fan</li>
                <li>Henghui Ding</li>
                <li>Jianquan Liu</li>
                <li>Jiaying Liu</li>
                <li>Lei Wang</li>
                <li>Mang Ye</li>
                <li>Marc A. Kastner</li>
                <li>Maria Torres Vega</li>
                <li>Mustafa Sert</li>
                <li>Mylene Farias</li>
                <li>Nan Pu</li>
                <li>Nimesha Ranasinghe</li>
                <li>Oya Celiktutan</li>
                <li>Qiang Sheng</li>
                <li>Silvia Rossi</li>
                <li>Song Yang</li>
                <li>Tiesong Zhao</li>
                <li>Usman Naseem</li>
                <li>Valerie Gouet-Brunet</li>
                <li>Vittorio Cuculo</li>
                <li>Vittorio Murino</li>
                <li>Wei Cai</li>
                <li>Weijian Deng</li>
                <li>Xiaohan Yu </li>
                <li>Xun Xu</li>
                <li>Yadan Luo</li>
                <li>Ye Wang</li>
                <li>Yiming Li</li>
                <li>Yinqiang Zheng</li>
                <li>Yoko Yamakata</li>
                <li>Zhendong Mao</li>
                <li>Ravi Kiran Sarvadevabhatla</li>
            `,
        },
        {
            type:'primaryTitle',
            text:'Outstanding Reviewers',
          },
          {
              type: 'text',
              text: `
                <ul>
                <li>Alex Falcon</li>
                <li>Adam George Craig</li>
                <li>Anlan Zhang</li>
                <li>Anqi Zhu</li>
                <li>Bin Chen</li>
                <li>Bin Yang</li>
                <li>Boheng Li</li>
                <li>Chang Liu</li>
                <li>Chaolei Tan</li>
                <li>Chaoran Cui</li>
                <li>Cheng-Hsin Hsu</li>
                <li>Chengliang Liu</li>
                <li>Chenglong Ma</li>
                <li>Chengpei Xu</li>
                <li>Chengyao Qian</li>
                <li>Chenxin Li</li>
                <li>Chongyang Zhao</li>
                <li>Chuhao Zhou</li>
                <li>Cristina Palmero Cantarino</li>
                <li>Cuiqun Chen</li>
                <li>Daizong Liu</li>
                <li>Dexuan Ding</li>
                <li>Dong Zhao</li>
                <li>Duo Chen</li>
                <li>Evangelos Alexiou</li>
                <li>Evlampios Apostolidis</li>
                <li>Fangfu Liu</li>
                <li>Fatemeh Ghorbani</li>
                <li>Frank Nack</li>
                <li>Giuseppe Cartella</li>
                <li>Guo Zhong</li>
                <li>Guosen Xie</li>
                <li>Haichao Zhang</li>
                <li>Haiyang Zheng</li>
                <li>Han Yi</li>
                <li>Haoang Chi</li>
                <li>Haodong Lu</li>
                <li>Haoxuan Che</li>
                <li>Haoyuan Liang</li>
                <li>Heming Du</li>
                <li>Hong Wang</li>
                <li>Hongyu Zhu</li>
                <li>Huilin Chen</li>
                <li>Jia-Hong Liu</li>
                <li>Jiancheng Huang</li>
                <li>Jiaqi Jin</li>
                <li>Jiayi Ji</li>
                <li>Jie Zhang</li>
                <li>Jiezhang Cao</li>
                <li>Jing Wu</li>
                <li>Jing Ye</li>
                <li>Jinyang Huang</li>
                <li>Jun Nie</li>
                <li>Jun Zhang</li>
                <li>Junhua Liu</li>
                <li>Kaiyu Song</li>
                <li>Kosetsu Tsukuda</li>
                <li>Kratika Bhagtani</li>
                <li>Maha Abdallah</li>
                <li>Miaoge Li</li>
                <li>Mingjie Qiu</li>
                <li>Minh Tran</li>
                <li>Mu Chen</li>
                <li>Muyao NIU</li>
                <li>Nicola Messina</li>
                <li>Pei Zhang</li>
                <li>Peng Qi</li>
                <li>Qian Jiang</li>
                <li>Qiao Yu</li>
                <li>Qingzhao Zhang</li>
                <li>Qixiang Chen</li>
                <li>Qizhou Qang</li>
                <li>Rajat Modi</li>
                <li>Ren Togo</li>
                <li>Rudinei Goularte</li>
                <li>Rui Song</li>
                <li>Rui Xu</li>
                <li>Runqi Lin</li>
                <li>Sam Van Damme</li>
                <li>Sergey Gorinsky</li>
                <li>Sergi García Bordils</li>
                <li>Shahzad Ahmad</li>
                <li>Shao-Kui Zhang</li>
                <li>Shuo Wang</li>
                <li>Shuo Zhang</li>
                <li>Shuping Zhao</li>
                <li>Shuting He</li>
                <li>Shuyu Yang</li>
                <li>Suyuan Liu</li>
                <li>Tao Meng</li>
                <li>Uttaran Bhattacharya</li>
                <li>Wei He</li>
                <li>Weiqiang Wang</li>
                <li>Wenbo Huang</li>
                <li>Wenmiao Hu</li>
                <li>Wenshuo Li</li>
                <li>Wenyun Li</li>
                <li>Werner Robitza</li>
                <li>Xiangyu Zhao</li>
                <li>Xiaohao Liu</li>
                <li>Xiaomeng Chu</li>
                <li>Xiaoyin Xu</li>
                <li>Xihong Yang</li>
                <li>Xingbo Dong</li>
                <li>Xinyu Han</li>
                <li>Xiuliang Duan</li>
                <li>Xuemei Jia</li>
                <li>Yabin Wang</li>
                <li>Ye Zhu</li>
                <li>Yi Wang</li>
                <li>Yi Wen</li>
                <li>Yifan Chen</li>
                <li>Yifei Cao</li>
                <li>Yihao Ding</li>
                <li>Yili Jin</li>
                <li>Yongyi Su</li>
                <li>You Qin</li>
                <li>Yu-Pei Song</li>
                <li>Yuanxing Xu</li>
                <li>Yuchen Su</li>
                <li>Yue Zhang</li>
                <li>Yuyan Bu</li>
                <li>Yuzhang Shang</li>
                <li>Zanxi Ruan</li>
                <li>Zedong Wang</li>
                <li>Zhaoyu Zhang</li>
                <li>Zheng Chen</li>
                <li>Zhengwentai Sun</li>
                <li>Zhenhua Li</li>
                <li>Zhenxi Song</li>
                <li>Zhi Chen</li>
                <li>Zhi Zhang</li>
                <li>Zhiwei Hao</li>
                <li>Zhiyong Cheng</li>
                <li>Zhonghua Jiang</li>
                <li>Zhuoxiao Chen</li>
                <li>Zixin Wang</li>
                <li>Ziyuan Luo</li>
                <li>Zuoyan Zhao</li>
            `,
          },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Multimedia Outstanding AC & Reviewer'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.retract_policies {
    /* padding-left: 2.5rem; */
    text-align: left;
    /*color: #202020;*/
}

</style>