<template>
  <div>
    <!--内容区域-->
    <el-row class="el-rows">
      <!--熊猫图标-->
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <!--title-->
      <el-col :span="22">
        <p class="title"> Sponsors </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <div class="content-text">
          <news :news="news"/>
        </div>
      </el-col>
    </el-row>
    <el-row v-for="(item ,index) in Sponsors" :key="index">
      <el-col>
        <el-row :gutter="35">
          <el-col class="line" :xs="6" :sm="6" :md="6" :lg="6" :xl="8">
          </el-col>
          <el-col class="sp-title" :xs="12" :sm="12" :md="12" :lg="12" :xl="8">
            <p>{{ item.title }}</p>
          </el-col>
          <el-col class="line" :xs="6" :sm="6" :md="6" :lg="6" :xl="8">
          </el-col>
        </el-row>
        <el-row class="pla-admin">
          <el-col>
            <div v-if="item.type===0" class="pla-parent clearfix">
              <div v-for="(pla ,index) in item.context" :key="index" class="platform first">
                <a :href="pla.link">
                  <img :src="pla.platform" alt="">
                </a>
              </div>
            </div>
            <div v-if="item.type===1" class="pla-parent clearfix">
              <div v-for="(pla ,index) in item.context" :key="index" class="platform diamond">
                <a :href="pla.link">
                  <img :src="pla.platform" alt="">
                </a>
              </div>
            </div>
            <div v-if="item.type===2" class="pla-parent clearfix">
              <div v-for="(pla ,index) in item.context" :key="index" class="platform platinum">
                <a :href="pla.link">
                  <img :src="pla.platform" alt="">
                </a>
              </div>
            </div>
            <div v-if="item.type===3" class="pla-parent clearfix">
              <div v-for="(pla ,index) in item.context" :key="index" class="platform gold">
                <a :href="pla.link">
                  <img :src="pla.platform" alt="">
                </a>
              </div>
            </div>
            <div v-if="item.type===4" class="pla-parent clearfix">
              <div v-for="(pla ,index) in item.context" :key="index" class="platform silver">
                <a :href="pla.link">
                  <img :src="pla.platform" alt="">
                </a>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import news from "@/views/sponsor/news";

export default {
  name: "Sponsors",
  components: {
    news
  },
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      news: [
        `<a href="http://www.sigmm.org/"><b>SIGMM</b></a> has become a sponsor for the ACM MM 2021.`,

        `<a href="https://antgroup.com"><b>Ant Group</b></a> has become a diamond sponsor for the ACM MM 2021.`,
        `<a href="https://arc.tencent.com"><b>Applied Research Center (ARC), Tencent PCG</b></a> has become a diamond sponsor for the ACM MM 2021.`,
        `<a href="https://www.oppo.com/cn/"><b>OPPO</b></a> has become a diamond sponsor for the ACM MM 2021.`,


        `<a href="https://www.alibabagroup.com/en/about/overview"><b>Alibaba Group</b></a> has become a platinum sponsor for the ACM MM 2021.`,
        `<a href="https://about.meituan.com/home"><b>MeiTuan</b></a> has become a platinum sponsor for the ACM MM 2021.`,
        `<a href="https://www.baidu.com"><b>Baidu</b></a> has become a platinum sponsor for the ACM MM 2021.`,
        `<a href="https://www.kuaishou.com/en"><b>KuaiShou</b></a> has become a platinum sponsor for the ACM MM 2021.`,
        `<a href="https://www.huawei.com"><b>HuaWei</b></a> has become a platinum sponsor for the ACM MM 2021.`,
        `<a href=" https://www.bytedance.com/en"><b>ByteDance</b></a> has become a platinum sponsor for the ACM MM 2021.`,
       

        `<a href="https://mtlab.meitu.com/aboutUs#MTJoin"><b>Meitu，MT Lab</b></a> has become a gold sponsor for the ACM MM 2021.`,
        `<a href="https://www.iqiyi.com"><b>iQiyi</b></a> has become a gold sponsor for the ACM MM 2021.`,

        `<b>Xiaohongshu</b></a> has become a gold sponsor for the ACM MM 2021.`,
      ],
      Sponsors: [
        {
          title: "Sponsors",
          type: 0,
          context: [
            {
              platform: "https://cdn.acmmm.hchyun.com/logo_sigmm.png",
              link: "http://www.sigmm.org/",
            },
          ]
        },
        {
          title: "Diamond Sponsors",
          type: 1,
          context: [
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_ant_1.png",
              link: "https://antgroup.com",
            },
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_arc_1.png",
              link: "https://arc.tencent.com",
            },
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_oppo_1.png",
              link: "https://www.oppo.com/cn/",
            },
          ]
        },
        {
          title: "Platinum Sponsors",
          type: 2,
          context: [
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_alibaba_1.png",
              link: "https://www.alibabagroup.com/en/about/overview",
            },
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_meituan_1.png",
              link: "https://about.meituan.com/home",
            },
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_baidu_2.png",
              link: "https://www.baidu.com",
            },
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_kuaishou_1.png",
              link: "https://www.kuaishou.com/en",
            },
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_huawei.png",
              link: "https://www.huawei.com",
            },
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_byte_1.png",
              link: "https://www.bytedance.com/en",
            },
          ]
        },
        {
          title: "Gold Sponsors",
          type: 3,
          context: [
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_iqiyi.png",
              link: "https://www.iqiyi.com",
            },
            {
              platform: "https://cdn.acmmm.hchyun.com/sponsor_meitu_2.png",
              link: "https://mtlab.meitu.com/aboutUs#MTJoin",
            }
          ]
        },
        {
          title: "Silver Sponsors",
          type: 4,
          context: [
            {
              platform: "http://cdn.acmmm.hchyun.com/sponsor_xiaohongshu.png",
            },
          ]
        },
        // {
        //   title: "Bronze Sponsor",
        //   context: [
        //     {
        //       platform: "http://cdn.acmmm.hchyun.com/sponsosr_7.png",
        //     },
        //   ]
        // },
      ]
    }
  },

  mounted() {
    document.title = 'ACM Multimedia 2021 Sponsors'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.sp-title {
  text-align: center;
  font-size: 2.6rem;
  font-family: "Roboto", sans-serif;
  /* font-weight: bold; */
  color: #0054B0;
  line-height: 2.4rem;
}

/* .el-row{float: left;overflow: hidden;} */
.content-text {
  width: 100%;
  height: 9.4rem;
  /*background-color: #2c8df7;*/
  color: #fff;
  /*line-height: 6rem;*/
  margin-bottom: 4rem;
}

.line {
  margin-top: 1.5rem;
  height: .1rem;
  background-color: #B0C6DE;
}

.first {
  /*width: 38rem;*/
  /*height: 23rem;*/
  width: 80%;
  margin-left: 9%   !important;
  margin-right: 5%   !important;
/*  margin-left: .5rem  !important;
  margin-right: .5rem  !important;*/
}

.diamond {
  /*width: 32rem;*/
  /*height: 23rem;*/
  width: 30%;
  margin-left: 1.5%   !important;
  margin-right: 1.5%   !important;
/*  margin-left: .5rem  !important;
  margin-right: .5rem  !important;*/
}

.platinum {
  width: 25%;
  margin-left: 6%   !important;
  /*margin-right: 4%   !important;*/
  /*width: 26.5rem;*/
  /*height: 17rem;*/
  /*margin: auto;*/
  /*margin-left: 4.3rem  !important;*/
  /*margin-right: .5rem  !important;*/
}

.gold {
  /*width: 20rem;*/
  /*height: 14rem;*/
  width: 23%;
  margin-left: 18%   !important;
  /*margin-right: 9%   !important;*/
/*  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;*/
}

.silver {
  /*width: 20rem;*/
  /*height: 14rem;*/
  width: 20%;
  margin-left: 40%   !important;
  /*margin-right: 32%   !important;*/
/*  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;*/
}

.bronze {
  width: 14rem;
  height: 9rem;
}

.pla-admin {
  text-align: center;
  margin-top: 2rem;
}

.pla-parent {
  margin-bottom: 1rem !important;
  display: inline-block !important;
}

.platform {
  float: left;
  /*width: 26rem;*/
  /*height: 16rem;*/
  background: #FFFFFF;
  border-radius: .6rem;
  border: .2rem solid #EEEEEE;
/*  margin-left: 2rem;
  margin-right: 2rem;*/
  text-align: center;
  margin-bottom: 1rem;
}

.platform:hover {
  box-shadow: 0rem 0.2rem 0.5rem 0rem #B3B3B3;
}

.platform > a > img {
  width: 100%;
}

.pla-admin {
  margin-bottom: 1rem;
}

@media screen and (max-width: 750px) {
  .icon {
    margin-top: 0rem;
  }
}
</style>
