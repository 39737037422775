<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryMiddleTitle'">
          <el-col class="secondaryMiddleTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'image'">
          <el-col>
            <div class="contact_image">
              <img :src="content.src">
            </div>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'retract_policies'">
          <el-col class="retract_policies">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "volunteer AC",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Major Differences: MM24 vs Previous',
      contents:[
        {
          type:'primaryTitle',
          text:'Major differences between MM’24 and previous MM editions',
        },
        {
          type:'text',
          text:'1.      Multimedia/multimodality statement for paper submissions'
        },
        {
          type:'text',
          text:'2.      Specifying a Latex template for MM’24 submissions'
        },
        {
          type:'text',
          text:'3.      Publicizing reviews for accepted papers'
        },
        {
          type:'text',
          text:`Please carefully check the differences in the type of submissions, the submission process and the review process between MM’24 and prior editions.`,
        },
        {
          type:'primaryTitle',
          text:`Multimedia/multimodality statement for paper submissions`,
        },
        {
          type:'text',
          text:`As the volume of submissions to the MM conference continues to grow annually, the SIGMM community seeks to distinguish itself from other communities such as NeurIPS, CVPR, and ECCV. Our focus lies in promoting research that is inherently multimedia or multimodal in nature. To achieve this, all paper authors are required to submit a concise 200-word statement outlining how their work contributes to the advancement of multimedia and multimodal processing. While papers that involve unimedia/unimodal processing will not necessarily be rejected, papers that make multimedia/multimodal research contributions will be preferred for publication in the conference proceedings.
          `,
        },
        {
          type:'image',
          text:'<img src="@/assets/major_differences/diagram.png" alt="diagram">',
          src: require("@/assets/major_differences/diagram.png"),
        },
        {
          type: 'primaryTitle',
          text: 'Specifying a Latex template for MM’24 submissions',
        },
        {
          type:'text',
          text:'As the ACM Overleaf page provides a bunch of Latex templates, and we have received several questions from submitting authors regarding which template to use for paper submission, we have now provided the Latex template in the ‘Paper Format’ section within the <a href="https://2024.acmmm.org/regular-papers">Call for Regular Paper Submissions</a> link on the MM’24 website. Pls ensure that you submit your papers subscribing to this format for full consideration during the review process.',
        },
        {
          type:'primaryTitle',
          text:'Publicizing reviews for accepted papers',
        },
        {
          type:'text',
          text:'A primary reason for SIGMM to embrace Openreview as the peer-review interface is to promote transparency and credibility in the review process, and ensure that the reviewers, Area, and Program chairs perform their duties responsibly. To this end, we intend to publicize the reviews, plus the discussions among Area and Program Chairs for all accepted papers. Reviews and discussions for rejected papers may also be made available subject to the authors’ consent. Nevertheless, reviewer identities will not be made public, and only the reviews along with the anonymized reviewer identifiers will be made available. Overall, we hope that this change will ensure high-quality of reviewsing for all the papers.',
        },
        {
          type:'text',
          text:'Thank you,',
        },
        {
          type:'text',
          text:'MM’24 Program Chairs',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Call for Volunteer Area Chairs and Reviewers'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.retract_policies {
    /* padding-left: 2.5rem; */
    text-align: left;
    /*color: #202020;*/
}

</style>