<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryMiddleTitle'">
          <el-col class="secondaryMiddleTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'retract_policies'">
          <el-col class="retract_policies">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "presenter_guidelines",
data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: "MM24 Presenter Information",
      contents: [
        {
          type: "text",
          text: "Please read the following information carefully to ensure you are prepared for your presentation at the MM24 Conference."
        },
        {
          type: "secondaryMiddleTitle",
          text: "Poster Presentations"
        },
        {
          type: "text",
          text: "You will need to create your poster based on the specifications below and have it printed ready to display on your allocated poster board on your presentation day."
        },
        {
          type: "text",
          text: "Posters will be hung in the foyer of the conference venue."
        },
        {
          type: "text",
          text: "<u>Regular Paper and Doctoral Symposium Poster Presentation Format</u>"
        },
        {
          type: "retract_policies",
          text: "A0 in size (one page only)"
        },
        {
          type: "retract_policies",
          text: "Printed (paper or material)"
        },
        {
          type: "retract_policies",
          text: "You will be allocated a posterboard from P001 – P200 and it is important that you ensure your poster is on the correct board on your presentation day."
        },
        {
          type: "retract_policies",
          text: "Your poster should be hung on your poster board from 8:30am to 6:00pm on your allocated presentation day. Your poster should then be removed promptly at 6:00pm at the end of the evening poster session. Any posters not removed by 6:30pm will be disposed of."
        },
        {
          type: "retract_policies",
          text: "Poster presenters are encouraged to stand alongside their poster during the poster session to answer any questions from interested parties."
        },
        {
          type: "retract_policies",
          text: "Interstate or International authors are encouraged to print their poster before arriving in Melbourne but local printing can be requested at <a href='https://www.printondemand.net.au/'> Print on Demand South Melbourne</a> in advance if needed."
        },
        {
          "type": "secondaryMiddleTitle",
          "text": "Oral Presentations"
        },
        {
          "type": "text",
          "text": "You will need to create your Oral presentation based on the requirements below and have it ready on a USB to turn into the Speakers Preparation Room ahead of your scheduled program slot."
        },
        {
          "type": "text",
          "text": "<u>Regular Paper and Brave New Idea Oral Presentation Format</u>"
        },
        {
          "type": "retract_policies",
          "text": "All Oral Presentations will be allocated 12-minutes in the program. This 12-minutes is inclusive of 10-minutes for the presentation and 2-minutes of Q&A."
        },
        {
          "type": "retract_policies",
          "text": "Please ensure that any presentation slides you are using for your presentation are in PowerPoint format."
        },
        {
          "type": "retract_policies",
          "text": "The slide aspect ratio for your presentation should be set to 16:9.<br>To change the aspect ratio on PPT go to the ‘Design’ tab at the top then select ‘Slide Size’ (usually located on the far right) here you will find the ratio options."
        },
        {
          "type": "text",
          "text": "<u>Keynote/Panel Presentation Format</u>"
        },
        {
          "type": "retract_policies",
          "text": "All Keynote Presentations will be allocated 60-minutes in the program. This 60-minutes is inclusive of 50-minutes for the presentation and 10-minutes of Q&A."
        },
        {
          "type": "retract_policies",
          "text": "Please ensure that any presentation slides you are using for your presentation are in PowerPoint format."
        },
        {
          "type": "retract_policies",
          "text": "The slide aspect ratio for your presentation should be set to 16:9.<br>To change the aspect ratio on PPT go to the ‘Design’ tab at the top then select ‘Slide Size’ (usually located on the far right) here you will find the ratio options."
        },
        {
          "type": "text",
          "text": "<u>Uploading your Presentation when onsite</u>"
        },
        {
          "type": "retract_policies",
          "text": "The Speakers Preparation Room will be held in Speakers Room 201 on the second floor of the venue."
        },
        {
          "type": "retract_policies",
          "text": "Speakers should report to the Speakers Preparation Room the day of their presentation <b>at least 30-minutes before</b> their scheduled presentation time."
        },
        {
          "type": "retract_policies",
          "text": "You will need to bring your PowerPoint presentation on a USB with you to the Conference.<br>If you have any video files in your presentation, please have these files saved separately on your USB."
        },
        {
          "type": "retract_policies",
          "text": "The venue has smart lecterns in each room that all presenters must use, therefore you will be unable to use your own laptop."
        },
        {
          "type": "text",
          "text": "<u>Speakers Preparation Room Opening Times</u>"
        },
        {
          "type": "retract_policies",
          "text": "09:30 - 15:00 Tuesday 29 October"
        },
        {
          "type": "retract_policies",
          "text": "09:00 – 12:00 Wednesday 30 October"
        },
        {
          "type": "retract_policies",
          "text": "09:00 - 14:00 Thursday 31 October"
        },
        {
          "type": "secondaryMiddleTitle",
          "text": "Technical Demo/ Video and Open Source Software Program Presentations"
        },
        {
          "type": "text",
          "text": "Technical Demos will occur throughout the Conference in the foyer close to the registration desk area."
        },
        {
          "type": "text",
          "text": "<u>Demo/ Video and Open Source Software Presentation format</u>"
        },
        {
          "type": "retract_policies",
          "text": "Please arrive at the area at least 15-minutes before you are scheduled to start to set up any equipment or presentations you may have."
        },
        {
          "type": "retract_policies",
          "text": "The area will contain 2 x table, 2 x chairs, a posterboard, an electrical outlet, projector screen with HDMI connection, and microphone on stand for your use. Any other equipment that is required, you must bring yourself or arrange to hire with the <a href='mailto:rebekahm@icmsaust.com.au'>Secretariat</a>."
        },
        {
          "type": "retract_policies",
          "text": "You are required to prepare a poster for your demo (Size is A0 – one-sided) and place it onto the posterboard in the demo space ahead of your presentation commencing."
        },
        {
          "type": "retract_policies",
          "text": "If you have any other questions, please contact the <a href='mailto:dhallabhinav@gmail.com'>Technical Demo and Video Chairs</a> or <a href='mailto:dhallabhinav@gmail.com'>Open Source Software Chairs</a>."
        },
        {
          "type": "secondaryMiddleTitle",
          "text": "Workshop/Tutorial/Grand Challenge Presentations"
        },
        {
          "type": "text",
          "text": "Workshops will take place on Monday 28 October and Friday 1 November at the Conference. Please ensure you know when your workshop is occurring in the schedule and to what room it has been allocated."
        },
        {
          "type": "text",
          "text": "<u>Workshop/ Tutorial and Grand Challenge format requirements</u>"
        },
        {
          "type": "retract_policies",
          "text": "You will receive instructions from your workshop organisers who will tell you how long your oral presentation is."
        },
        {
          "type": "retract_policies",
          "text": "Workshop rooms will contain a projector, lectern with laptop connections, and microphone."
        },
        {
          "type": "retract_policies",
          "text": "Presentations to be shared in the room must be created on PowerPoint and set at a slide aspect ratio of 16:9."
        },
        {
          "type": "retract_policies",
          "text": "If you are required to create a poster for your workshop, the posterboards will be in the foyer area near the registration desk."
        },
        {
          "type": "retract_policies",
          "text": "Workshop posters should be put onto their allocated posterboard 30-minutes before the related workshop commences (08:30 or 13:30) and removed at the conclusion of the session (12:30 or 17:30)."
        },
        {
          "type": "retract_policies",
          "text": "Posters must be A0 in size (one-sided)."
        },
        {
          "type": "retract_policies",
          "text": "If you have any further questions, please contact the <a href='mailto:zhiyong.wang@sydney.edu.au'>Workshop Chairs</a>."
        },
        {
          "type": "secondaryMiddleTitle",
          "text": "Industry Track Presentations"
        },
        {
          "type": "text",
          "text": "Industry presentations will take place in Room 219 on Level 2 of MCEC. Please ensure you know when your session is occurring in the schedule."
        },
        {
          "type": "text",
          "text": "<u>Industry Track session format requirements</u>"
        },
        {
          "type": "retract_policies",
          "text": "The industry session room will contain a projector, lectern with laptop connections, and microphone."
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Multimedia Presenter Guidelines'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.retract_policies {
    /* padding-left: 2.5rem; */
    text-align: left;
    /*color: #202020;*/
}

</style>