<template>
  <div>
    <!--内容区域-->
    <el-row class="el-rows">
      <!--熊猫图标-->
      <!-- <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col> -->
      <!--title-->
      <!-- <el-col :span="22">
        <p class="title"> Important Dates </p>
      </el-col> -->

      <p class="head_title"> Important Dates </p>
    </el-row>

    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <!-- <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col> -->
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'dateText'">
        <el-col class="dateText">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
    </div>

    <el-row class="dates-header">
      <el-col :span="16">
        <span class="header-text">SUBJECT</span>
      </el-col>
      <el-col :span="8">
        <span class="header-text">DATE</span>
      </el-col>
    </el-row>

    <div class="table-context">
      <el-row class="table-row" v-for="(item ,index) in table" :key="index">
        <router-link :to="item.link">
          <div v-if="item.bold" class="black" style="color:#000 !important;">
            <el-col :xs="15" :sm="15" :md="15" :lg="16" :xl="16">
              <span class="dates-text">{{ item.subject }}</span>
            </el-col>
            <el-col :xs="9" :sm="9" :md="8" :lg="8" :xl="8">
              <span class="delete-text" v-if="item.delete"><s>{{ item.date }}</s></span>
              <span class="dates-text" v-else v-html="item.date"></span>
            </el-col>
          </div>
          <div v-else>
            <el-col :xs="15" :sm="15" :md="15" :lg="16" :xl="16">
              <span class="dates-text">{{ item.subject }}</span>
            </el-col>
            <el-col :xs="9" :sm="9" :md="9" :lg="8" :xl="8">
              <span class="delete-text" v-if="item.delete"><s>{{ item.date }}</s></span>
              <span class="dates-text" v-else v-html="item.date"></span>
            </el-col>
          </div>
        </router-link>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dates",
  data() {
    return {
      // iconImage: require("@/assets/content/bullet_point.png"),
      iconImage: "",
      contents: [
        {
          type: 'dateText',
          text: 'Updates:',
        },
        {
          type: 'text',
          text: '<b>July 20, 2024:</b> The due date for the camera-ready submission has been extended to <span style="font-weight: bold; color: red;">August 7</span> (Main Proceedings) and <span style="font-weight: bold; color: red;">August 22</span> (Companion Proceedings).',
        },
        {
          type: 'text',
          text: '<b>Main Proceedings</b> include:',
        },
        // Conference Keynote Abstract
        // Oral & Poster Papers
        // BNl Papers
        // Doctoral Symposium Papers
        // Open Source Papers
        // Panel Summaries
        // Demo Papers
        // Reproducibility Papers
        {
          type: 'retract',
          text: 'Conference Keynote Abstract',
        },
        {
          type: 'retract',
          text: 'Oral & Poster Papers',
        },
        {
          type: 'retract',
          text: 'BNI Papers',
        },
        {
          type: 'retract',
          text: 'Doctoral Symposium Papers',
        },
        {
          type: 'retract',
          text: 'Open Source Papers',
        },
        {
          type: 'retract',
          text: 'Panel Summaries',
        },
        {
          type: 'retract',
          text: 'Demo Papers',
        },
        {
          type: 'retract',
          text: 'Reproducibility Papers',
        },
        {
          type: 'text',
          text: '<b>Companion Proceedings</b> include:',
        },
        // Workshops
        // Grand Challenges
        // Tutorial Summaries
        {
          type: 'retract',
          text: 'Workshops',
        },
        {
          type: 'retract',
          text: 'Grand Challenges',
        },
        {
          type: 'retract',
          text: 'Tutorial Summaries',
        },
        {
          type: 'dateText',
          text: 'Please note: The submission deadline is at 11:59 p.m. (<a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>) of the stated deadline date.',
        },
      ],
      table: [
        {
          subject: 'Grand Challenge Proposal Submission',
          date: 'February 19, 2024',
          // link: "/multimedia-grand-challenge-proposals",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Grand Challenge Proposal Notification',
          date: 'March 4, 2024',
          // link: "/multimedia-grand-challenge-proposals",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Workshop Proposals Submission',
          date: 'March 4, 2024',
          // link: "/workshop-proposals",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Workshop Proposals Notification',
          date: 'March 25, 2024',
          // link: "/regular-papers",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Abstract Submission Deadline',
          date: 'April 8, 2024',
          // link: "/regular-papers",
          link: "",
          delete: false,
          bold: true,
        },
        {
          subject: 'Full Paper Submission Deadline',
          date: 'April 12, 2024',
          // link: "/tutorial-proposals",
          link: "",
          delete: false,
          bold: true,
        },
        {
          subject: 'Brave New Ideas Submission ',
          date: 'April 12, 2024',
          // link: "/regular-papers",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Regular paper supplementary',
          date: 'April 19, 2024',
          // link: "/interactive-arts",
          link: "",
          delete: false,
          bold: false,
        },
        // {
        //   subject: 'Industry Workshop/Panel Submission',
        //   date: 'June 3, 2024',
        //   // link: "/doctoral-cymposium",
        //   link: "",
        //   delete: false,
        //   bold: false,
        // },
        // {
        //   type: 'text',
        //   text: 'Industrial Demo Submission site opens: May 20, 2024 (AoE)',
        // },
        // {
        //   type: 'text',
        //   text: 'Industrial Demo Abstract submission deadline: July 31 (11:59pm AoE)',
        // },
        // {
        //   type: 'text',
        //   text: 'Industrial Demo Notification of acceptance/rejection: August 8 (AoE)',
        // },
        {
          subject: 'Industrial Demo Submission Site Opens',
          date: 'May 20, 2024',
          // link: "/industrial-demo",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Panel Proposals Submission',
          date: 'June 3, 2024',
          // link: "/industrial-demo",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Doctoral Symposium Submission',
          date: 'June 3, 2024',
          // link: "/doctoral-cymposium",
          link: "",
          delete: false,
          bold: false,
        },
        // {
        //   subject: 'Interactive Artworks Submission',
        //   date: 'June 3, 2024',
        //   // link: "/doctoral-cymposium",
        //   link: "",
        //   delete: false,
        //   bold: false,
        // },
        {
          subject: 'Reproducibility Companion Paper',
          date: 'June 3, 2024',
          // link: "/reproducibility-companion-papers",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Regular Paper Reviews to Author',
          date: 'June 10, 2024',
          // link: "/brave-new-ideas-proposals",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Tutorial Proposals Submission',
          date: 'June 12, 2024',
          // link: "/technical-demos-and-videos-program",
          link: "",
          delete: false,
          bold: false,
        },       
        {
          subject: 'Regular Paper Rebuttal Deadline',
          date: 'June 17, 2024',
          // link: "/open-source-software-competition",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Technical Demo and Video Program Submission',
          date: 'June 17, 2024',
          // link: "/doctoral-cymposium",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Open Source Competition Submission',
          date: 'June 30, 2024',
          // link: "/multimedia-grand-challenge-submissions",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Grand Challenge Solutions Submission',
          date: 'July 12, 2024',
          // link: "/doctoral-cymposium",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Regular Paper Notification',
          date: 'July 15, 2024',
          // link: "/tutorial-proposals",
          link: "",
          delete: false,
          bold: true,
        },
        {
          subject: 'All other notifications',
          date: 'July 15, 2024',
          // link: "/doctoral-cymposium",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Workshop Papers Submission',
          date: 'July 19, 2024',
          // link: "/doctoral-cymposium",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Workshop Papers Notification',
          date: 'August 5, 2024',
          // link: "/doctoral-cymposium",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Regular Paper Author Registration Deadline',
          date: 'August 7, 2024',
          // link: "/doctoral-cymposium",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Main Proceedings Camera-ready Submission',
          date: 'August 7, 2024',
          // link: "/panel-proposals",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Workshop Paper Author Registration Deadline',
          date: 'August 22, 2024',
          // link: "/doctoral-cymposium",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Workshop Papers (camera-ready)',
          date: 'August 22, 2024',
          // link: "/doctoral-cymposium",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Companion Proceedings Camera-ready Submission',
          date: 'August 22, 2024',
          // link: "/doctoral-cymposium",
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Industrial Demo Abstract Submission Deadline',
          date: 'September 6, 2024',
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Industry Expert Talks Proposal Deadline',
          date: 'September 6, 2024',
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Industrial Demo Notification of Acceptance/Rejection',
          date: 'September 13, 2024',
          link: "",
          delete: false,
          bold: false,
        },
        {
          subject: 'Industry Expert Talks Notification of Acceptance/Rejection',
          date: 'September 13, 2024',
          link: "",
          delete: false,
          bold: false,
        },
      ],
    }
  }
}
</script>

<style scoped>

@import "../../css/responsive.css";


.icon {
  margin-top: .6rem;
  margin-right: 1rem;
  width: 3.6rem;
}

.title {
  margin-left: -1.8rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* color: #202020; */
  color: black;
}

.head_title {
  margin-left: 0rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /*color: black;*/
  /* color: #202020; */
  color: black;
}

.dates-header {
  height: 5rem;
  /*background-color: #5EAAFB;*/
  /* background-color: #05cedd; */
  background-color: #2C9370;
}

.header-text {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-left: 3rem;
  line-height: 5rem;
  color: #FFFFFF;
  /* color: #05cedd; */
}

.delete-text {
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #c0c4cc;
  margin-left: 3rem;
  line-height: 5rem;
}

.dates-text {
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #666666;
  margin-left: 3rem;
  line-height: 5rem;
}

.table-row {
  height: 5rem;
}

.table-row:hover {
  margin-top: .25rem;
  width: 101%;
  margin-left: -0.5%;
  margin-bottom: .25rem;
  -webkit-box-shadow: #666 0px 0px 10px;
  -moz-box-shadow: #666 0px 0px 10px;
  box-shadow: #666 0px 0px 10px;
  background-color: #fff;
}

.table-context {
  min-height: 50rem;
  border: 0.1rem solid #979797;
}

.black * {
  color: black !important;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  .icon {
    margin-top: 0rem;
  }

  .title {
    margin-left: 0;
  }
}
</style>
