<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "authors-advocate",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Author’s Advocate for Main Conference Papers',
      contents:[
        // {
        //   type:'primaryTitle',
        //   text:'Overview',
        // },
        {
          type:'text',
          text:'Please use this Google form <a class="demo-link" href="https://forms.gle/EBxvVvgG86UQdeFZA">https://forms.gle/EBxvVvgG86UQdeFZA</a>.',
        },
        {
          type:'text',
          text:'Authors who can factually demonstrate that they have received a sub-standard review, or that a reviewer has made an entirely false statement, are welcome to send a mediation request to the ACM MM 2024 Author’s Advocate.',
        },
        {
          type:'text',
          text:'Key facts:',
        },
        {
          type:'retract',
          text:'The timeframe for submitting requests is until <b>June 17, 2024</b>.',
        },
        {
          type:'retract',
          text:'Responses to mediation requests (and results of the mediation process) will be available as part of the Notification of Acceptance/Rejection.',
        },
        {
          type:'retract',
          text:'The Author’s Advocate handles Main Conference Papers only (and not workshop papers, or other submissions.)',
        },
        {
          type:'text',
          text:'The Author’s Advocate role was introduced in the ACM MM 2013 conference. His/her role is to listen to the authors, and to help them if reviews are clearly below average quality. The Author’s Advocate will not handle cases in which the authors have a different opinion from the reviewer. Authors who have a difference of opinion with reviewers, should use the rebuttal. The author’s advocate has no special training in psychology or anger management, and does not attempt to handle emotional responses such as disappointment. ',
        },
        {
          type:'text',
          text:'Authors can request the mediation of the Author’s Advocate starting from the moment at which they receive their reviews. When submitting a mediation request, please clearly state the reasons for which mediation is needed (e.g., the reviews appear to be for a different paper). The authors should provide facts, and not opinions (e.g., an opinion is that it was a bad review or that the author does not agree with a reviewer; a fact is that the review contained only two sentence fragments.) A mediation request is not the same as a rebuttal. The rebuttal is intended as a discussion forum between the authors and the reviewers; a mediation request is a formal request for investigation as to whether a review indeed meets the quality standards of the conference.',
        },
        {
          type:'text',
          text:'Requests after the specified period cannot be addressed. When a mediation request is received, the Author’s Advocate will evaluate the case and make a decision. The decision might be to take an action by contacting the corresponding Area Chair or the Technical Program Chairs (in which case, a new review for the article might be requested) or to take no action (in case the request is unjustified or the request is to be determined to actually be a rebuttal). In all cases, the authors will be informed of the decision when the final notifications are sent.',
        },
        {
          type:'text',
          text:'As in past years, we anticipate that only a very small fraction, if any, of the reviews will necessitate mediation of the Author’s Advocate.',
        },
        {
          type:'text',
          text:'For further background on this role, please consult this blog post: <a class="demo-link" href="https://cacm.acm.org/blogs/blog-cacm/169387-acm-multimedia-2013-best-papers-and-the-revision-process/fulltext"> https://cacm.acm.org/blogs/blog-cacm/169387-acm-multimedia-2013-best-papers-and-the-revision-process/fulltext</a>.',
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The time is at 11:59 p.m. of the stated date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Author’s advocate timeframe is until <b>June 17, 2024</b>',
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions, please email the Author’s Advocate Chairs:',
        },
        {
          type:'noList',
          text:'<b>Joao Magalhaes</b>, NOVA University of Lisbon, Portugal, (jmag@fct.unl.pt).',
        },
        // {
        //   type:'noListBlack',
        //   text:'<b>Chen Change Loy</b>, Nanyang Technological University, Singapore, (ccloy@ntu.edu.sg).',
        // },
        // {
        //   type:'noListBlack',
        //   text:'<b>Marcel Worring</b>, University of Amsterdam, Netherlands, (m.worring@uva.nl).',
        // },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Author’s Advocate'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>