<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>

    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#202020" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryMiddleTitle'">
        <el-col class="secondaryMiddleTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'image'">
        <el-col>
          <div class="contact_image">
            <img :src="content.src">
          </div>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'retract_policies'">
        <el-col class="retract_policies">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'dateText'">
        <el-col class="dateText">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  name: "Dates",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: "Social Events",
      contents: [
      // Welcome to the Wild Side! 
        // Join us on Tuesday evening for a truly unforgettable Welcome Reception! Get up close with fascinating Australian reptiles while enjoying delicious food and drinks. This is the perfect opportunity to meet and network with fellow industry professionals in a relaxed, fun setting.
        // When: Tuesday evening
        // Included with full registration, or available for purchase for day registrants.
        // Don’t miss this unique chance to mingle, learn, and make new connections! 

        // A Night Under the Vines 

        // Join us on Wednesday 30 October for an unforgettable Gala Dinner experience, "A Night Under the Vines." Immerse yourself in a stunning setting filled with lush greenery, indulge in amazing food and drinks, and enjoy live performances by The Baker Boys throughout the evening.

        // This magical night will also feature the prestigious MM24 Awards, celebrating excellence in our industry. It promises great entertainment, delicious cuisine, and the perfect opportunity to connect with fellow professionals.

        // When: Wednesday, 30th October
        // Live Entertainment: The Baker Boys
        // Special Presentation: MM24 Awards
        // Limited tickets are available – don’t miss out!

        // Be part of this incredible night of celebration, entertainment, and networking.



        // MM24 Conference: The Ultimate Multimedia Event! 
        // Join us in Melbourne from 28 October to 1 November for the MM24 Conference, the leading international event in multimedia research and technology. With insightful presentations, industry panels, workshops, and tutorials, it’s a must-attend for anyone in the field. Plus, enjoy daily catering for all delegates, so you can stay energised throughout the event!
        // When: Monday 18 October to Friday 1 November
        // Where: Melbourne Convention and Exhibition Centre, Australia 
        // Register now and don’t miss out!


        // Unleash Your Creativity: Dive into Workshops, Tutorials, and Grand Challenges at MM24!

        // On Days 1 and 5, we're hosting Grand Challenges, Workshops, and Tutorials to elevate your multimedia research experience.
        // Grand Challenges focus on contemporary issues like deepfakes detection and multimodal sentiment analysis.
        // Tutorials led by experts will cover advanced topics like curriculum learning and multimodal large language models.
        // Workshops will explore innovative areas such as brain-computer interfaces and multimedia content generation.
        // This is included with full registrations, with separate purchases available for Monday (Day 1) and Friday (Day 5).

        // For more details, visit the MM 24 Conference website.  https://2024.acmmm.org/
        {
          type: "secondaryMiddleTitle",
          text: "Welcome to the Wild Side!",
        },
        {
          type: "text",
          text: `Join us on <b>Tuesday evening</b> for a truly unforgettable <b>Welcome Reception</b>! Get up close with fascinating Australian reptiles while enjoying delicious food and drinks. This is the perfect opportunity to meet and network with fellow industry professionals in a relaxed, fun setting.`,
        },
        {
          type: "text",
          text: `<b>When</b>: Tuesday evening`,
        },
        {
          type: "text",
          text: `<b>Included</b> with full registration, or available for purchase for day registrants.`,
        },
        {
          type: "text",
          text: `Don’t miss this unique chance to mingle, learn, and make new connections!`,
        },
        {
          type: "secondaryMiddleTitle",
          text: "A Night Under the Vines",
        },
        {
          type: "text",
          text: `Join us on Wednesday 30 October for an unforgettable Gala Dinner experience, "A Night Under the Vines." Immerse yourself in a stunning setting filled with lush greenery, indulge in amazing food and drinks, and enjoy live performances by The Baker Boys throughout the evening.`,
        },
        {
          type: "text",
          text: `This magical night will also feature the prestigious MM24 Awards, celebrating excellence in our industry. It promises great entertainment, delicious cuisine, and the perfect opportunity to connect with fellow professionals.`,
        },
        {
          type: "text",
          text: `<b>When</b>: Wednesday, 30th October`,
        },
        {
          type: "text",
          text: `<b>Live Entertainment</b>: The Baker Boys`,
        },
        {
          type: "text",
          text: `<b>Special Presentation</b>: MM24 Awards`,
        },
        {
          type: "text",
          text: `Limited tickets are available – don’t miss out!`,
        },
        {
          type: "text",
          text: `Be part of this incredible night of celebration, entertainment, and networking.`,
        },
        {
          type: "secondaryMiddleTitle",
          text: "MM24 Conference: The Ultimate Multimedia Event!",
        },
        {
          type: "text",
          text: `Join us in Melbourne from 28 October to 1 November for the <b>MM24 Conference</b>, the leading international event in multimedia research and technology. With insightful presentations, industry panels, workshops, and tutorials, it’s a must-attend for anyone in the field. Plus, enjoy daily catering for all delegates, so you can stay energised throughout the event!`,
        },
        {
          type: "text",
          text: `<b>When</b>: Monday 18 October to Friday 1 November`,
        },
        {
          type: "text",
          text: `<b>Where</b>: Melbourne Convention and Exhibition Centre, Australia`,
        },
        {
          type: "text",
          text: `<b>Register now</b> and don’t miss out!`,
        },
        {
          type: "secondaryMiddleTitle",
          text: "Unleash Your Creativity: Dive into Workshops, Tutorials, and Grand Challenges at MM24!",
        },
        {
          type: "text",
          text: `On Days 1 and 5, we're hosting Grand Challenges, Workshops, and Tutorials to elevate your multimedia research experience.`,
        },
        {
          type: "retract",
          text: `<b>Grand Challenges</b> focus on contemporary issues like deepfakes detection and multimodal sentiment analysis.`,
        },
        {
          type: "retract",
          text: `<b>Tutorials</b> led by experts will cover advanced topics like curriculum learning and multimodal large language models.`,
        },
        {
          type: "retract",
          text: `<b>Workshops</b> will explore innovative areas such as brain-computer interfaces and multimedia content generation.`,
        },
        {
          type: "text",
          text: `This is included with full registrations, with separate purchases available for Monday (Day 1) and Friday (Day 5).`,
        },
        // https://2024.acmmm.org/
        {
          type: "text",
          text: `For more details, visit the <a href="https://2024.acmmm.org/">MM 24 Conference website</a>.`,
        },
      ],
    }
  },
  methods:{
    openLink: function(link){
      window.open(link, '_blank');
    }
  },
}
</script>

<style scoped>

@import "../../css/responsive.css";


.icon {
  margin-top: .6rem;
  margin-right: 1rem;
  width: 3.6rem;
}

.title {
  margin-left: -1.8rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /* color: #202020; */
  color: black;
}

.head_title {
  margin-left: 0rem;
  /*margin-left: 100.0px;*/
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /*color: black;*/
  /* color: #202020; */
  color: black;
}

.black * {
  color: black !important;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  .icon {
    margin-top: 0rem;
  }

  .title {
    margin-left: 0;
  }
}

.mel_banner{
    /* width: 40rem;
    height: 40rem; */
    position:relative;
    padding-bottom: 35%;
    /* position: relative; */
    /* text-align: center; */
    /* width: 200px;
    height: 200px; */
    /* overflow: hidden; */
}

.mel_banner > img{
  position:absolute;
  /* object-fit: cover; */
  top:0;left:0;
  width:100%;
  /* height:100%; */
}

</style>
  