<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{content.text}}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'paperTitle'">
        <el-col class="paperTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'paperAuthor'">
        <el-col class="paperAuthor">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Awards",
  data() {
    return {
		iconImage: require("@/assets/content/bullet_point.png"),
		mainTitle: 'Awards',
      contents: [
        
        {
          "type": "primaryTitle",
          "text": "Best Paper Award"
        },

        // From Speaker to Dubber: Movie Dubbing with Prosody and Duration Consistency Learning

        // Zhedong Zhang, Liang Li, Gaoxiang Cong, Haibing YIN, Yuhan Gao, Chenggang Yan, Anton van den Hengel, Yuankai Qi

        // Hangzhou Dianzi University, Institute of Computing Technology, Chinese Academy of Sciences, Shandong University, Tsinghua University, University of Adelaide, Macquarie University

        {
          "type": "paperTitle",
          "text": "<b>From Speaker to Dubber: Movie Dubbing with Prosody and Duration Consistency Learning</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Zhedong Zhang, Liang Li, Gaoxiang Cong, Haibing YIN, Yuhan Gao, Chenggang Yan, Anton van den Hengel, Yuankai Qi"
        },
        {
          "type": "paperAuthor",
          "text": "Hangzhou Dianzi University, Institute of Computing Technology, Chinese Academy of Sciences, Shandong University, Tsinghua University, University of Adelaide, Macquarie University"
        },

        
        {
          "type": "primaryTitle",
          "text": "Best Student Paper Award"
        },

        // AV-Deepfake1M: A Large-Scale LLM-Driven Audio-Visual Deepfake Dataset

        // Zhixi Cai, Shreya Ghosh, Aman Pankaj Adatia, Munawar Hayat, Abhinav Dhall, Tom Gedeon, Kalin Stefanov

        // Monash University, Curtin University, Indian Institute of Technology Ropar, Curtin University of Technology

        // An In-depth Study of Bandwidth Allocation across  Media Sources in Video Conferencing

        // Zejun Zhang, Xiao Zhu, Anlan Zhang, Feng Qian

        // University of Southern California, Google

        {
          "type": "paperTitle",
          "text": "<b>AV-Deepfake1M: A Large-Scale LLM-Driven Audio-Visual Deepfake Dataset</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Zhixi Cai, Shreya Ghosh, Aman Pankaj Adatia, Munawar Hayat, Abhinav Dhall, Tom Gedeon, Kalin Stefanov"
        },
        {
          "type": "paperAuthor",
          "text": "Monash University, Curtin University, Indian Institute of Technology Ropar, Curtin University of Technology"
        },
        {
          "type": "paperTitle",
          "text": "<b>An In-depth Study of Bandwidth Allocation across Media Sources in Video Conferencing</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Zejun Zhang, Xiao Zhu, Anlan Zhang, Feng Qian"
        },
        {
          "type": "paperAuthor",
          "text": "University of Southern California, Google"
        },

        
        // Best Demo Award
        {
          "type": "primaryTitle",
          "text": "Best Demo Award"
        },

        // DanceMimic: Awaken Your Dancing Instinct through a Real-time Dance Imitation Capture System

        // Seongjean Kim, Jungwoo Huh, Yeseung Park, JungSu Kim, Sanghoon Lee

        // Yonsei University

        {
          "type": "paperTitle",
          "text": "<b>DanceMimic: Awaken Your Dancing Instinct through a Real-time Dance Imitation Capture System</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Seongjean Kim, Jungwoo Huh, Yeseung Park, JungSu Kim, Sanghoon Lee"
        },
        {
          "type": "paperAuthor",
          "text": "Yonsei University"
        },

        
        // Best Industry Demo Award

        {
          "type": "primaryTitle",
          "text": "Best Industry Demo Award"
        },

        // DriveCoach: Smart Driving Assistance with Multimodal Risk Prediction and Risk Adaptive Behavior Recommendation

        // Wenbin Gan, Minh-Son Dao, Koji Zettsu 

        // National Institute of Information and Communications Technology

        {
          "type": "paperTitle",
          "text": "<b>DriveCoach: Smart Driving Assistance with Multimodal Risk Prediction and Risk Adaptive Behavior Recommendation</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Wenbin Gan, Minh-Son Dao, Koji Zettsu"
        },
        {
          "type": "paperAuthor",
          "text": "National Institute of Information and Communications Technology"
        },


        {
          "type": "primaryTitle",
          "text": "Honourable Mention Award"
        },
        // Once-for-all: Efficient Visual Face Privacy Protection via Person-specific Veils

        // Zixuan Yang, Yushu Zhang, Tao Wang, Zhongyun Hua, Zhihua Xia, Jian Weng

        // Nanjing University of Aeronautics and Astronautics, Harbin Institute of Technology Shenzhen, Jinan University

        // 4D Gaussian Splatting with Scale-aware Residual Field and Adaptive Optimization for Real-time Rendering of Temporally Complex Dynamic Scenes

        // Jinbo Yan, Rui Peng, Luyang Tang, Ronggang Wang

        // Peking University, Peking University Shenzhen Graduate School

        // VR-Mediated Cognitive Defusion: A Comparative Study for Managing Negative Thoughts

        // Kento Shigyo, Yi-Fan Cao, Kentaro Takahira, Mingming Fan, Huamin Qu

        // Hong Kong University of Science and Technology, 

        // Leveraging Knowledge of Modality Experts for Incomplete Multimodal Learning

        // Wenxin Xu, Hexin Jiang, Xuefeng Liang

        // Xidian University

        // LoopGaussian: Creating 3D Cinemagraph with Multi-view Images via Eulerian Motion Field

        // Jiyang Li, Lechao Cheng, Zhangye Wang, Tingting Mu, Jingxuan He

        // Zhejiang University, Hefei University of Technology, University of Manchester

        // Not All Pairs are Equal: Hierarchical Learning for Average-Precision-Oriented Video Retrieval

        // Yang Liu, Qianqian Xu, Peisong Wen, Siran Dai, Qingming Huang

        // University of the Chinese Academy of Sciences, Institute of Computing Technology, Chinese Academy of Sciences

        // Multi-Modal Diffusion Model for Recommendation

        // Yangqin Jiang, Lianghao Xia, Wei Wei, Da Luo, Kangyi Lin, Chao Huang

        // University of Hong Kong, South China University of Technology, WeChat Open Platform Department

        {
          "type": "paperTitle",
          "text": "<b>Once-for-all: Efficient Visual Face Privacy Protection via Person-specific Veils</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Zixuan Yang, Yushu Zhang, Tao Wang, Zhongyun Hua, Zhihua Xia, Jian Weng"
        },
        {
          "type": "paperAuthor",
          "text": "Nanjing University of Aeronautics and Astronautics, Harbin Institute of Technology Shenzhen, Jinan University"
        },
        {
          "type": "paperTitle",
          "text": "<b>4D Gaussian Splatting with Scale-aware Residual Field and Adaptive Optimization for Real-time Rendering of Temporally Complex Dynamic Scenes</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Jinbo Yan, Rui Peng, Luyang Tang, Ronggang Wang"
        },
        {
          "type": "paperAuthor",
          "text": "Peking University, Peking University Shenzhen Graduate School"
        },
        {
          "type": "paperTitle",
          "text": "<b>VR-Mediated Cognitive Defusion: A Comparative Study for Managing Negative Thoughts</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Kento Shigyo, Yi-Fan Cao, Kentaro Takahira, Mingming Fan, Huamin Qu"
        },
        {
          "type": "paperAuthor",
          "text": "Hong Kong University of Science and Technology"
        },
        {
          "type": "paperTitle",
          "text": "<b>Leveraging Knowledge of Modality Experts for Incomplete Multimodal Learning</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Wenxin Xu, Hexin Jiang, Xuefeng Liang"
        },
        {
          "type": "paperAuthor",
          "text": "Xidian University"
        },
        {
          "type": "paperTitle",
          "text": "<b>LoopGaussian: Creating 3D Cinemagraph with Multi-view Images via Eulerian Motion Field</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Jiyang Li, Lechao Cheng, Zhangye Wang, Tingting Mu, Jingxuan He"
        },
        {
          "type": "paperAuthor",
          "text": "Zhejiang University, Hefei University of Technology, University of Manchester"
        },
        {
          "type": "paperTitle",
          "text": "<b>Not All Pairs are Equal: Hierarchical Learning for Average-Precision-Oriented Video Retrieval</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Yang Liu, Qianqian Xu, Peisong Wen, Siran Dai, Qingming Huang"
        },
        {
          "type": "paperAuthor",
          "text": "University of the Chinese Academy of Sciences, Institute of Computing Technology, Chinese Academy of Sciences"
        },
        {
          "type": "paperTitle",
          "text": "<b>Multi-Modal Diffusion Model for Recommendation</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Yangqin Jiang, Lianghao Xia, Wei Wei, Da Luo, Kangyi Lin, Chao Huang"
        },
        {
          "type": "paperAuthor",
          "text": "University of Hong Kong, South China University of Technology, WeChat Open Platform Department"
        },



        // Honorable Mention Demo Award 
        {
          "type": "primaryTitle",
          "text": "Honorable Mention Demo Award"
        },

        // Muskits-ESPnet: A Comprehensive Toolkit for Singing Voice Synthesis in New Paradigm

        // Yuning Wu, Jiatong Shi, Yifeng Yu, Yuxun Tang, Qian Tao, Yueqian Lin, Jionghao Han, Xinyi Bai, Shinji Watanabe, Qin Jin

        // Renmin University of China, Carnegie Mellon University, Georgia Institute of Technology, Duke Kunshan University, Cornell University

        {
          "type": "paperTitle",
          "text": "<b>Muskits-ESPnet: A Comprehensive Toolkit for Singing Voice Synthesis in New Paradigm</b>"
        },
        {
          "type": "paperAuthor",
          "text": "Yuning Wu, Jiatong Shi, Yifeng Yu, Yuxun Tang, Qian Tao, Yueqian Lin, Jionghao Han, Xinyi Bai, Shinji Watanabe, Qin Jin"
        },
        {
          "type": "paperAuthor",
          "text": "Renmin University of China, Carnegie Mellon University, Georgia Institute of Technology, Duke Kunshan University, Cornell University"
        },

        // MM’24 Outstanding Service Awards
        {
          "type": "primaryTitle",
          "text": "MM'24 Outstanding Service Awards"
        },

        {
          "type": "paperTitle",
          "text": "<b>Liang Zheng</b>"
        },
        {
          "type": "paperAuthor",
          "text": "TPC Co-Chair"
        },
        {
          "type": "paperTitle",
          "text": "<b>Yadan Luo</b>",
        },
        {
          "type": "paperAuthor",
          "text": "Publication Co-Chair"
        },

        {
          "type": "primaryTitle",
          "text": "Best Paper Nominations"
        },

        // 1863	From Speaker to Dubber: Movie Dubbing with Prosody and Duration Consistency Learning
        // 2569	A Picture Is Worth a Graph: A Blueprint Debate Paradigm for Multimodal Reasoning
        // 2607	Not All Pairs are Equal: Hierarchical Learning for Average-Precision-Oriented Video Retrieval
        // 2782	U2UData: A Large-scale Cooperative Perception Dataset for Swarm UAVs Autonomous Flight
        // 4894	Cross-modal Observation Hypothesis Inference
        // 5216	Navigating Beyond Instructions: Vision-and-Language Navigation in Obstructed Environments
        // 2843	Gait Recognition in Large-scale Free Environment via Single LiDAR
        // 457	Hypergraph Multi-modal Large Language Model: Exploiting EEG and Eye-tracking Modalities to Evaluate Heterogeneous Responses for Video Understanding
        // 4318	Multi-Modal Diffusion Model for Recommendation
        // 5592	Leveraging Knowledge of Modality Experts for Incomplete Multimodal Learning
        // 981	 LMM-PCQA: Assisting Point Cloud Quality Assessment with LMM
        // 3244	HKDSME: Heterogeneous Knowledge Distillation for Semi-supervised Singing Melody Extraction Using Harmonic Supervision
        // 3578	Improving Interaction Comfort in Authoring Task in AR-HRI through Dynamic Dual-Layer Interaction Adjustment
        // 4352	VR-Mediated Cognitive Defusion: A Comparative Study for Managing Negative Thoughts
        // 4896	Evolution-aware VAriance (EVA) Coreset Selection for Medical Image Classification
        // 5	AV-Deepfake1M: A Large-Scale LLM-Driven Audio-Visual Deepfake Dataset
        // 88	ResVR: Joint Rescaling and Viewport Rendering of Omnidirectional Images
        // 226	LoopGaussian: Creating 3D Cinemagraph with Multi-view Images via Eulerian Motion Field
        // 2993	Investigating Conceptual Blending of a Diffusion Model for Improving Nonword-to-Image Generation
        // 4096	4D Gaussian Splatting with Scale-aware Residual Field and Adaptive Optimization for Real-time rendering of temporally complex dynamic scenes
        // 4102	GPT4Video: A Unified Multimodal Large Language Model for lnstruction-Followed Understanding and Safety-Aware Generation
        // 1157	Reproducing the Past: A Dataset for Benchmarking Inscription Restoration
        // 1299	Video Bokeh Rendering: Make Casual Videography Cinematic
        // 2167	An In-depth Study of Bandwidth Allocation across Media Sources in Video Conferencing
        // 4473	Loc4Plan: Locating Before Planning for Outdoor Vision and Language Navigation
        // 3624	Once-for-all: Efficient Visual Face Privacy Protection via Person-specific Veils
        {
          "type": "paperTitle",
          "text": "1863 <b>From Speaker to Dubber: Movie Dubbing with Prosody and Duration Consistency Learning<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Zhedong Zhang, Liang Li, Gaoxiang Cong, Haibing YIN, Yuhan Gao, Chenggang Yan, Anton Hengel, Yuankai Qi"
        },
        {
          "type": "paperTitle",
          "text": "2569 <b>A Picture Is Worth a Graph: A Blueprint Debate Paradigm for Multimodal Reasoning<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Changmeng Zheng, DaYong Liang, Wengyu ZHANG, Xiaoyong Wei, Tat-seng Chua, Qing Li"
        },
        {
          "type": "paperTitle",
          "text": "2607 <b>Not All Pairs are Equal: Hierarchical Learning for Average-Precision-Oriented Video Retrieval<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Yang Liu, Qianqian Xu, Peisong Wen, Siran Dai, Qingming Huang"
        },
        {
          "type": "paperTitle",
          "text": "2782 <b>U2UData: A Large-scale Cooperative Perception Dataset for Swarm UAVs Autonomous Flight<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Tongtong Feng, Xin Wang, Feilin Han, Leping Zhang, Wenwu Zhu"
        },
        {
          "type": "paperTitle",
          "text": "4894 <b>Cross-modal Observation Hypothesis Inference<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Mengze Li, Kairong Han, Jiahe Xu, Li Yueying, Wu Tao, Zhou Zhao, Jiaxu Miao, Shengyu Zhang, Jingyuan Chen"
        },
        {
          "type": "paperTitle",
          "text": "5216 <b>Navigating Beyond Instructions: Vision-and-Language Navigation in Obstructed Environments<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Haodong Hong, Sen Wang, Zi Huang, Qi Wu, Jiajun Liu"
        },
        {
          "type": "paperTitle",
          "text": "2843 <b>Gait Recognition in Large-scale Free Environment via Single LiDAR<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Xiao Han, Yiming Ren, Peishan Cong, YUJING SUN, Jingya Wang, Lan Xu, Yuexin Ma"
        },
        {
          "type": "paperTitle",
          "text": "457 <b>Hypergraph Multi-modal Large Language Model: Exploiting EEG and Eye-tracking Modalities to Evaluate Heterogeneous Responses for Video Understanding<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Minghui Wu, Chenxu Zhao, Anyang Su, Donglin Di, Tianyu Fu, Da An, Min He, Ya Gao, Meng Ma, Yan Kun, Ping Wang"
        },
        {
          "type": "paperTitle",
          "text": "4318 <b>Multi-Modal Diffusion Model for Recommendation<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Yangqin Jiang, Lianghao Xia, Wei Wei, Da Luo, Kangyi Lin, Chao Huang"
        },
        {
          "type": "paperTitle",
          "text": "5592 <b>Leveraging Knowledge of Modality Experts for Incomplete Multimodal Learning<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Wenxin Xu, Hexin Jiang, xuefeng liang"
        },
        {
          "type": "paperTitle",
          "text": "981 <b>LMM-PCQA: Assisting Point Cloud Quality Assessment with LMM<b>"
        },
        {
          "type": "paperAuthor",
          "text": "zicheng zhang, Haoning Wu, Yingjie Zhou, Chunyi Li, Wei Sun, Chaofeng Chen, Xiongkuo Min, Xiaohong Liu, Weisi Lin, Guangtao Zhai"
        },
        {
          "type": "paperTitle",
          "text": "3244 <b>HKDSME: Heterogeneous Knowledge Distillation for Semi-supervised Singing Melody Extraction Using Harmonic Supervision<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Shuai Yu, Xiaoliang He, Ke Chen, Yi Yu"
        },
        {
          "type": "paperTitle",
          "text": "3578 <b>Improving Interaction Comfort in Authoring Task in AR-HRI through Dynamic Dual-Layer Interaction Adjustment<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Yunqiang Pei, Kaiyue Zhang, Hongrong yang, Yong Tao, Qihang Tang, Jialei Tang, Guoqing Wang, Zhitao Liu, Ning Xie, Peng Wang, Yang Yang, Hengtao Shen"
        },
        {
          "type": "paperTitle",
          "text": "4352 <b>VR-Mediated Cognitive Defusion: A Comparative Study for Managing Negative Thoughts<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Kento Shigyo, Yi-Fan Cao, Kentaro Takahira, Mingming Fan, Huamin Qu"
        },
        {
          "type": "paperTitle",
          "text": "4896 <b>Evolution-aware VAriance (EVA) Coreset Selection for Medical Image Classification<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Yuxin Hong, Xiao Zhang, Xin Zhang, Joey Zhou"
        },
        {
          "type": "paperTitle",
          "text": "5 <b>AV-Deepfake1M: A Large-Scale LLM-Driven Audio-Visual Deepfake Dataset<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Zhixi Cai, Shreya Ghosh, Aman Pankaj Adatia, Munawar Hayat, Abhinav Dhall, Tom Gedeon, Kalin Stefanov"
        },
        {
          "type": "paperTitle",
          "text": "88 <b>ResVR: Joint Rescaling and Viewport Rendering of Omnidirectional Images<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Weiqi Li, Shijie Zhao, Bin Chen, Xinhua Cheng, Junlin Li, Li zhang, Jian Zhang"
        },
        {
          "type": "paperTitle",
          "text": "226 <b>LoopGaussian: Creating 3D Cinemagraph with Multi-view Images via Eulerian Motion Field<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Jiyang Li, Lechao Cheng, Zhangye Wang, Tingting Mu, Jingxuan He"
        },
        {
          "type": "paperTitle",
          "text": "2993 <b>Investigating Conceptual Blending of a Diffusion Model for Improving Nonword-to-Image Generation<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Chihaya Matsuhira, Marc A. Kastner, Takahiro Komamizu, Takatsugu Hirayama, Ichiro Ide"
        },  
        {
          "type": "paperTitle",
          "text": "4096 <b>4D Gaussian Splatting with Scale-aware Residual Field and Adaptive Optimization for Real-time rendering of temporally complex dynamic scenes<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Jinbo Yan, Rui Peng, Luyang Tang, Ronggang Wang"
        },
        {
          "type": "paperTitle",
          "text": "4102 <b>GPT4Video: A Unified Multimodal Large Language Model for lnstruction-Followed Understanding and Safety-Aware Generation<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Zhanyu Wang, Longyue Wang, Zhen Zhao, Minghao Wu, Chenyang Lyu, Huayang Li, Deng Cai, Luping Zhou, Shuming Shi, Zhaopeng Tu"
        },
        {
          "type": "paperTitle",
          "text": "1157 <b>Reproducing the Past: A Dataset for Benchmarking Inscription Restoration<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Shipeng Zhu, hui xue, Na Nie, Chenjie Zhu, Haiyue Liu, Pengfei Fang"
        },
        {
          "type": "paperTitle",
          "text": "1299 <b>Video Bokeh Rendering: Make Casual Videography Cinematic<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Yawen Luo, Min Shi, Liao Shen, Yachuan Huang, Zixuan Ye, Juewen Peng, Zhiguo Cao"
        },  
        {
          "type": "paperTitle",
          "text": "2167 <b>An In-depth Study of Bandwidth Allocation across Media Sources in Video Conferencing<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Zejun Zhang, Xiao Zhu, Anlan Zhang, Feng Qian"
        },
        {
          "type": "paperTitle",
          "text": "4473 <b>Loc4Plan: Locating Before Planning for Outdoor Vision and Language Navigation<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Huilin Tian, Jingke Meng, Wei-Shi Zheng, Yuanming Li, Junkai Yan, Yunong Zhang"
        },
        {
          "type": "paperTitle",
          "text": "3624 <b>Once-for-all: Efficient Visual Face Privacy Protection via Person-specific Veils<b>"
        },
        {
          "type": "paperAuthor",
          "text": "Zixuan Yang, Yushu Zhang, Tao Wang, Zhongyun Hua, Zhihua Xia, Jian Weng"
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Awards”'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>