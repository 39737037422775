<template>
  <div class="block">
    <el-carousel :interval="20000" ref="remarkCarusel" :autoplay="true"
                 :loop="true" @change="getIndex" :height="bannerHeight+'px'">
      <el-carousel-item
          v-for="(item,index) in slideshowImgs"
          :key="index"
      >
        <img class="slideShowImage" :src="item.src" alt="轮播图">
      </el-carousel-item>
    </el-carousel>
    <div class="banner-context">
      <div class="background_box">
        <div class="banner-title">
        <div class="top">
          ACM MULTIMEDIA 2024
        </div>
        <!-- <div class="middle">
          Hybrid (Onsite and Virtual)
        </div> -->
        <div class="bottom" v-html="Australia">
        </div>
      </div>
      <div class="clock">
        <p class="clock-title">Time to ACM Multimedia 2024:</p>
        <div class="clearfix">
          <div>
            <div>{{ time.days }}</div>
            <p>DAYS</p>
          </div>
          <div>
            <div>{{ time.hours }}</div>
            <p>HOURS</p>
          </div>
          <div>
            <div>{{ time.mins }}</div>
            <p>MINS</p>
          </div>
          <div>
            <div>{{ time.secs }}</div>
            <p>SECS</p>
          </div>
        </div>
      </div>
      </div>
      

    </div>
    
  </div>
</template>

<script>
import $ from 'jquery'
// import moment from 'moment'

export default {
  name: "slideShow",
  data() {
    return {
      Australia: "28 October - 1 November 2024, Melbourne, Australia",
      slideshowImgs: [
        // {src: require("@/assets/images/MCEC.jpg")},
        // {src: require("@/assets/images/monash_woodside.jpg")},
        // {src: require("@/assets/images/melbourne_cbd.jpg")},
        // {src: require("@/assets/images/great_ocean_road.jpg")},
        // {src: require("@/assets/images/koala.jpg")},
        // {src: require("@/assets/images/mel_cbd_night.png")},
        // {src: require("@/assets/images/beach.png")},
        {src: require("@/assets/images/MCEC_3.jpg")},
        {src: require("@/assets/images/koala_2.jpg")},
        // {src: require("@/assets/images/mel_center.jpg")},
        // {src: require("@/assets/images/flinder_station_2.jpg")},
        // {src: require("@/assets/images/great_ocean_road_2.jpg")},
        {src: require("@/assets/images/mel_tram.jpg")},
        {src: require("@/assets/images/mel_skyline.jpg")},
        {src: require("@/assets/images/mel_skyline_2.jpg")},
        {src: require("@/assets/images/flinder_station_3.jpg")},
      ],
      bannerCir: "http://cdn.acmmm.hchyun.com/sy_icon_tys@2x.png",
      time: {
        days: 23,
        hours: 10,
        mins: 10,
        secs: 25,
      },
      // 图片父容器高度
      bannerHeight: 964,
      // 浏览器宽度
      screenWidth: 0
    }
  },
  methods: {
    setSize() {
      //通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight = (964 / 1920) * this.screenWidth;
    },
    // format_date(value){
    //      if (value) {
    //        return moment(String(value)).format('DD-MM-YYYY')
    //       }
    //   },
    getDaysBetween() {
      let startDate = new Date();
      let endDate = Date.parse('28 Oct 2024 00:00:00 GMT');
      let days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      let hours = parseFloat('0.' + (days + '').split('.')[1]) * 24
      let mins = parseFloat('0.' + (hours + '').split('.')[1]) * 60
      let secs = parseFloat('0.' + (mins + '').split('.')[1]) * 60
      this.time.days = Math.floor(days)
      this.time.hours = Math.floor(hours)
      this.time.mins = Math.floor(mins)
      this.time.secs = Math.floor(secs)
      if (this.time.days <= -1) {
        this.time.days = 0
        this.time.hours = 0
        this.time.mins = 0
        this.time.secs = 0
      }
    },
    getIndex(index) {
      let node = $('.banner-context')
      // node.addClass('define')
      if (index == 6) {
        node.removeClass('green')
        node.removeClass('define')
        // node.addClass('red');
        node.addClass('define')
      } else if (index === 1) {
        node.removeClass('red')
        node.removeClass('define')
        // node.addClass('green')
        node.addClass('define')
      } else if (index === 2 || index === 3 || index === 4 || index === 5 || index === 0 || index === 7) {
        node.removeClass('red')
        node.removeClass('green')
        node.addClass('define')
      }
    },
    timepiece() {
      let time = this.time
      time.secs = this.time.secs - 1
      if (time.secs<0){
        time.secs=0
      }
      if (time.secs === 0) {
        if (time.mins>0||time.hours>0||time.days>0){
          time.secs = 59
          time.mins = time.mins - 1
        }
        if (time.mins === 0) {
          if (time.hours>0||time.days>0){
            time.mins = 59
            time.hours = time.hours - 1
          }
          if (time.hours === 0) {
            if (time.days>0){
              time.hours = 23
              time.days = time.days - 1
            }
          }
        }
      }
    }
  },
  mounted() {

    let startDate = new Date();
    let endDate = Date.parse('28 Oct 2024 00:00:00 GMT');
    if (endDate - startDate>0){
      this.getDaysBetween();
      setInterval(this.timepiece, 1000)
    }else {
      let clock = $('.clock')
      clock.addClass("none")
      this.Australia = "<p> Melbourne, Australia</p><p>Oct 28 - Nov 1, 2024</p>"
    }
    //首次加载时,需要调用一次
    this.screenWidth = window.innerWidth
    if (this.screenWidth <= 750) {
      $('.el-carousel__indicators').css('display', 'none')
    }
    this.setSize();
    //窗口大小发生改变时,调用一次
    window.onresize = () => {
      if (this.screenWidth <= 750) {
        $('.el-carousel__indicators').css('display', 'none')
      }
      this.screenWidth = window.innerWidth
      this.setSize()
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};

</script>

<style scoped>


.background_box{
  background: rgba(0, 0, 0, 0.46);
  padding: 3rem 8rem;
}


.banner-context {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  /*color: #F6F6F6;*/
  /* color: #05cedd; */
  /* color: #99CCFF; */
  /* color: #2C9370; */
  color: #FFF5EB;
  /* text-shadow: 2px 2px black; */
}

.red, .red .clock-title, .red .clearfix > div > p {
  color: red !important;
}

.red .clearfix > div > div {
  background-color: red;
}

.green .clearfix > div > div {
  background-color: #03AC34;
}

.green, .green .clock-title, .green .clearfix > div > p {
  color: #03AC34;
}
.none{
  display: none !important;
}
.top{
  top: 16vw !important;
}

.define, .define .clock-title, .define .clearfix > div > p {
  color: #FFF5EB;
  /*color: black;*/
  /* color: #05cedd; */
  /* color: #2C9370; */
  /* text-shadow: 2px 2px black; */
}

.define .clearfix > div > div {
  /*background-color: #3075E2;*/
  /* background-color: #05cedd; */
  background-color: black;
}

.banner-context .bottom {
  font-size: 1.88vw;
}

.banner-context .top {
  font-size: 3.5vw;
}

.middle {
  font-size: 1.88vw;
}

.banner-context .top img {
  padding-bottom: .7vw;
  width: 3.2vw;
}

.clock {
  margin-top: 1vw;
}

.clock-title {
  font-size: 0.83vw;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #FFF5EB;
  /* color: #2C9370; */
  /* color: #05cedd; */
  /* text-shadow: 2px 2px black; */
}

.slideShowImage {
  width: 100%;
  height: 100%;
}

.block {
  position: relative;
}

.banner-context {
  top: 8vw;
  left: 8vw;
  position: absolute;
  z-index: 2;
}

.clearfix > div > p {
  font-size: 0.83vw;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #FFF5EB;
  /* color: #2C9370; */
  /* color: #05cedd; */
  /* text-shadow: 2px 2px black; */
  text-align: center;
}

.clearfix > div > div {
  width: 4.06vw;
  height: 4.06vw;
  font-size: 2vw;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #FFF5E6;
  /* color: #55BB8A; */
  /* color: #2C9370; */
  /* color: #05cedd; */
  line-height: 4.06vw;
  text-align: center;
  /*background-color: rgba(48, 117, 226, 0.8);*/
  background-color: black;
  /* background-color: #05cedd; */
  border-radius: 2.03vw;
}


.clearfix > div {
  float: left;
  margin-right: 1.51vw;
}
</style>
